<template>
    <li :class="getClass()">
        <div class="meta">
            <span class="arrow">
                <kaveh-icon-bootstrap v-if="node.hasChild"
                               v-on:click="expand"
                               v-bind:name="getArrow()"
                               size="13px" />
            </span>

            <div v-if="checkType == 'checkbox'" class="form-check d-inline-block">
                <input class="form-check-input"
                       v-bind:id="node.id"
                       v-bind:checked="node.checked"
                       v-on:change="handelCheckbox" type="checkbox">

                <label v-bind:for="node.id" class="form-check-label">
                    {{ nodeLocal.text }}
                </label>
            </div>

            <div v-else class="form-check d-inline-block">
                <input class="form-check-input"
                       v-bind:id="node.id"
                       v-bind:checked="node.checked"
                       v-on:change="handelRadio" name="radio" type="radio">

                <label v-bind:for="node.id" class="form-check-label">
                    {{ nodeLocal.text }}
                </label>
            </div>
        </div>
        <slot v-if="nodeLocal.expand" :key="nodeLocal.checked"></slot>
    </li>
</template>

<script>
export default {
  emits: ['change'],

  props:
        {
          node:
            {
              type: Object
            },

          checkType: {
            type: String
          }
        },

  data () {
    return {
      nodeLocal: this.node
    }
  },

  beforeMount () {
    this.nodeLocal = this.nodeLocal || {}

    this.nodeLocal.checked =
                this.nodeLocal.checked || false
  },

  methods:
        {
          expand () {
            this.nodeLocal.expand =
                    !this.nodeLocal.expand
          },

          handelCheckbox (e) {
            var self = this; var check = (node, checked) => {
              node.checked = checked
              self.handelChange(node)

              if ((node.children || []).length > 0) {
                for (var child of node.children) { check(child, checked) }
              }
            }

            check(this.nodeLocal, e.target.checked)
          },

          handelRadio (e) {
            this.nodeLocal.checked = e.target.checked
            this.handelChange(this.nodeLocal)
          },

          handelChange (node) {
            this.$emit('change', node)
          },

          getClass () {
            return `node${this.nodeLocal.hasChild ? ' has-child' : ''}`
          },

          getArrow () {
            return this.nodeLocal.expand
              ? 'bi-chevron-down' : 'bi-chevron-left'
          }
        }
}
</script>
