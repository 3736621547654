
const INPUT_TYPE =
{
	TEXT: 'text',
	HIDDEN: 'hidden',
	TEXTARRAY: 'textarray',
	TEXTAREA: 'textarea',
	NUMBER: 'number',
	FLOAT: 'float',
	PASSWORD: 'password',
	EMAIL: 'email',
	DATE: 'date',
	TIME: 'time',
	SELECT: 'select',
	CHECKBOX: 'checkbox',
	CHECKDROP: 'checkdrop',
	COLOR: 'color',
	NONE: 'none',
	FORM: 'form',
	UPLOADER: 'uploader',
	DATA_PICKER: 'date-picker',
	TIME_PICKER: 'time-picker',
	TEXT_EDITOR: 'text-editor',
	CODE_EDITOR: 'code-editor',
	SIGNATURE_PAD: 'signature-pad',
	IRAN_PLAQUE: 'iran-plaque',
	HR: 'hr',
	PLAIN_TEXT: 'plain-text',
	HTML_EDITOR: 'html-editor',
	HEADING: 'heading',
	LABLE: 'lable'
};

export default Object.freeze(INPUT_TYPE);
