//GeneratorFlag:[NoChange]
import BlogContentService from './blog-content-service'; //GeneratorFlag:[BlogContent]
import StaticContentService from './static-content-service'; //GeneratorFlag:[StaticContent]
import StaticPageService from './static-page-service'; //GeneratorFlag:[StaticPage]
import BlogCategoryService from './blog-category-service'; //GeneratorFlag:[BlogCategory]
//GeneratorFlag:[NewImport]
export default {
BlogContentService, //GeneratorFlag:[BlogContent]
StaticContentService, //GeneratorFlag:[StaticContent]
StaticPageService, //GeneratorFlag:[StaticPage]
BlogCategoryService, //GeneratorFlag:[BlogCategory]
//GeneratorFlag:[NewExport]
}
export{
BlogContentService, //GeneratorFlag:[BlogContent]
StaticContentService, //GeneratorFlag:[StaticContent]
StaticPageService, //GeneratorFlag:[StaticPage]
BlogCategoryService, //GeneratorFlag:[BlogCategory]
//GeneratorFlag:[NewExport]
}
