<template>
	<div class="letter-compose-step-2 pt-2" v-if="props">
		<kaveh-content width="800px">


			<div class="mb-3 row">
				<label v-once for="title" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.title.resourceKey)}}</span>
					<span v-if="isRequired('title')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<input v-model="props.title.value" @keyup="onHandelKeyUp('title')" @change="onHandelChange('title')" dir="rtl" type="text" class="form-control" id="title" autocomplete="off">
					<small class="text-danger" v-if="props.title.errors.length > 0">
						{{getFirstError('title')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="indicator" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.indicator.resourceKey)}}</span>
					<span v-if="isRequired('indicator')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<input v-model="props.indicator.value" @keyup="onHandelKeyUp('indicator')" @change="onHandelChange('indicator')" dir="ltr" type="text" class="form-control" id="indicator" autocomplete="off">
					<small class="text-danger" v-if="props.indicator.errors.length > 0">
						{{getFirstError('indicator')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="deadlineHour" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.deadlineHour.resourceKey)}}</span>
					<span v-if="isRequired('deadlineHour')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<input v-model="props.deadlineHour.value" @keyup="onHandelKeyUp('deadlineHour')" @change="onHandelChange('deadlineHour')" dir="ltr" type="number" class="form-control" id="deadlineHour" autocomplete="off">
					<small class="text-danger" v-if="props.deadlineHour.errors.length > 0">
						{{getFirstError('deadlineHour')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="letterCategoryId" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.letterCategoryId.resourceKey)}}</span>
					<span v-if="isRequired('letterCategoryId')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<select class="form-select" v-bind:class="{'is-loading': letterCategoryListIsLoading}" v-model="props.letterCategoryId.value" @change="onHandelChange('letterCategoryId')" id="letterCategoryId">
						<option v-for="(item,index) in letterCategoryList" :value="item.letterCategoryId" :key="index">
							{{item.title}}
						</option>
					</select>
					<small class="text-danger" v-if="props.letterCategoryId.errors.length > 0">
						{{getFirstError('letterCategoryId')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="letterClassificationId" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.letterClassificationId.resourceKey)}}</span>
					<span v-if="isRequired('letterClassificationId')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<select class="form-select" v-bind:class="{'is-loading': letterClassificationListIsLoading}" v-model="props.letterClassificationId.value" @change="onHandelChange('letterClassificationId')" id="letterClassificationId">
						<option v-for="(item,index) in letterClassificationList" :value="item.letterClassificationId" :key="index">
							{{item.title}}
						</option>
					</select>
					<small class="text-danger" v-if="props.letterClassificationId.errors.length > 0">
						{{getFirstError('letterClassificationId')}}
					</small>
				</div>
			</div>


			<div class="mb-3 row">
				<label v-once for="letterPriorityId" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.letterPriorityId.resourceKey)}}</span>
					<span v-if="isRequired('letterPriorityId')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<select class="form-select" v-bind:class="{'is-loading': letterPriorityListIsLoading}" v-model="props.letterPriorityId.value" @change="onHandelChange('letterPriorityId')" id="letterPriorityId">
						<option v-for="(item,index) in letterPriorityList" :value="item.letterPriorityId" :key="index">
							{{item.title}}
						</option>
					</select>
					<small class="text-danger" v-if="props.letterPriorityId.errors.length > 0">
						{{getFirstError('letterPriorityId')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="letterDocumentTypeId" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.letterDocumentTypeId.resourceKey)}}</span>
					<span v-if="isRequired('letterDocumentTypeId')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<select class="form-select" v-bind:class="{'is-loading': letterDocumentTypeListIsLoading}" v-model="props.letterDocumentTypeId.value" @change="onHandelChange('letterDocumentTypeId')" id="letterDocumentTypeId">
						<option v-for="(item,index) in letterDocumentTypeList" :value="item.letterDocumentTypeId" :key="index">
							{{item.title}}
						</option>
					</select>
					<small class="text-danger" v-if="props.letterDocumentTypeId.errors.length > 0">
						{{getFirstError('letterDocumentTypeId')}}
					</small>
				</div>
			</div>

		</kaveh-content>
	</div>
</template>
<script>
	import validator from '@/core/validator'
	import BasicService from '@/core/server/basic-service'
	import { Letter } from '@/modules/aut/data/letter/create'


	export default {
		props: {
			useValidator: {
				type: Boolean,
				default: true
			}
		},

		data()
		{
			return {
				props: null,
				letterTypeList: [],
				letterPriorityList: [],
				letterCategoryList: [],
				letterTopicList: [],
				letterClassificationList: [],
				letterDocumentTypeList: [],

				letterTypeListIsLoading: true,
				letterPriorityListIsLoading: true,
				letterCategoryListIsLoading: true,
				letterTopicListIsLoading: true,
				letterClassificationListIsLoading: true,
				letterDocumentTypeListIsLoading: true,
			}
		},

		async beforeMount()
		{
			this.init()

			await this.initList('letterPriority','letterPriorityId');
			await this.initList('letterCategory','letterCategoryId');
			await this.initList('letterTopic','letterTopicId');
			await this.initList('letterClassification','letterClassificationId');
			await this.initList('letterDocumentType','letterDocumentTypeId');
		},

		methods:
		{
			init()
			{
				const payload = {
					letterTypeId: 1,
					letterPriorityId: 1,
					letterCategoryId: 1,
					letterTopicId: 1,
					letterClassificationId: 1,
					letterDocumentTypeId:  1
				}

				const props = new Letter({ value: payload }).props;

				this.props = {
					title: props.title,
					deadlineHour: props.deadlineHour,
					indicator: props.indicator,
					letterTypeId: props.letterTypeId,
					letterPriorityId: props.letterPriorityId,
					letterCategoryId: props.letterCategoryId,
					letterPatternId: props.letterPatternId,
					letterTopicId: props.letterTopicId,
					letterClassificationId: props.letterClassificationId,
					letterDocumentTypeId: props.letterDocumentTypeId,
				}
			},

			async initList(entityName, propName)
			{
				try
				{
					const payload = { start: 0, length: 1000 };
					const apiAddress = this.props[propName].serverAddress;

					this[`${entityName}ListIsLoading`] = true;
					const res = await new BasicService().get(payload, apiAddress);

					if (res.status == 200)
					{
						this[`${entityName}List`] = this.$loc.fromList(res.data.items);
					}

				} catch (e)
				{
					console.log(e)
				}

				this[`${entityName}ListIsLoading`] = false;
			},

			isRequired(name)
			{
				return this.props[name].setConfig.patterns.includes('required');
			},

			onHandelChange(name)
			{
				if (this.useValidator)
				{
					this.props[name].errors =
						validator.getPropErrors(this.props[name]);
				}

				this.props[name].isChanged = true;
			},

			onHandelKeyUp(name)
			{
				if (this.useValidator && this.props[name].isChanged)
				{
					this.props[name].errors =
						validator.getPropErrors(this.props[name]);
				}
			},


			getFirstError(name)
			{
				const prop = this.props[name];

				if (prop.errors.length > 0)
				{
					return this.$loc.fromResource(prop.errors[0].resourceKey, {
						label: this.$loc.fromResource(prop.resourceKey),
					})
				}

				return ''
			},

			getValue()
			{
				if (this.useValidator)
				{
					if (validator.getObjectErrorLength(this.props) > 0)
					{
						return undefined;
					}
				}

				var value = {};
				var keys = Object.keys(this.props);
				for (var i = 0; i < keys.length; i++)
				{
					value[keys[i]] = this.props[keys[i]].value
				}

				return value
			}
		}
	}
</script>
