import ext from './de-ext.js'
export default {
	"name":"aut",
	"ext": ext,
	"layout":{
		"basis": "Grundinformationen", 
		"report": "Berichte", 
	},
	'activity':'Aktivität',
	'activityActivityId':'ID',
	'activityActivityPriority':'Priorität',
	'activityActivityPriorityId':'Prioritäts-ID',
	'activityActivityPriorityTitle':'Priorität',
	'activityActivityStatus':'Status',
	'activityActivityStatusId':'Status-ID',
	'activityActivityStatusTitle':'Status',
	'activityActivityTrack':'Aktivitätsverfolgungsinformationen',
	'activityAttachment':'Dateianhang',
	'activityCreate':'Neue Aktivität erstellen',
	'activityCreateActivityId':'ID',
	'activityCreateActivityPriorityId':'Prioritäts-ID',
	'activityCreateBranchId':'ID',
	'activityCreateComment':'Anforderungstext',
	'activityCreateDeadlineHour':'Prozess-/Zeitfriststunde',
	'activityCreateFormValue':'Formulardaten',
	'activityCreateProcessId':'ID',
	'activityCustomerCreate':'Aktivität',
	'activityDeadlineHour':'Prozess-/Zeitfriststunde',
	'activityDelete':'Aktivität löschen',
	'activityDetail':'Aktivitätsinformationen Details',
	'activityFileLengths':'Anzahl der Prozesszyklusdateien',
	'activityFirstActivistName':'Initiator',
	'activityFirstActivistRoleName':'Position des Initiators in der Organisation',
	'activityFirstActivityComment':'Initiator Kommentare',
	'activityFirstActivityCreateTime':'Startzeit',
	'activityFirstActivityLocalDate':'Startdatum',
	'activityFormActivists':'Prozesszyklus-Aktivisten',
	'activityFormComments':'Prozesszyklus-Kommentare',
	'activityFormValues':'Prozesszyklus-Formulare',
	'activityFullReport':'Aktivitätsberichte',
	'activityLastActivistName':'Letzter Aktivist',
	'activityLastActivistRoleName':'Letzte Aktivist-Position in der Organisation',
	'activityLastProcessNodeTitle':'Letzte Aktion',
	'activityLayout':'Aktivitäten',
	'activityList':'Aktivitätsliste',
	'activityLocalDateTimes':'Prozesszykluszeiten',
	'activityNextNode':'Nächster Knoten',
	'activityNextProcessNodeId':'Nächstes Level',
	'activityNode':'Knoten',
	'activityPriorityActivityPriorityId':'ID',
	'activityPriorityCaption':'Beschreibung',
	'activityPriorityHexColor':'Farbe',
	'activityPriorityTitle':'Titel',
	'activityProcess':'ID',
	'activityProcessId':'ID',
	'activityProcessTitle':'Prozessname',
	'activityState':'Aktivität',
	'activityStaticFile':'Statische Datei',
	'activityStatusActivityStatusId':'ID',
	'activityStatusCaption':'Beschreibung',
	'activityStatusHexColor':'Farbe',
	'activityStatusTitle':'Titel',
	'letter':'Brief',
	'letterAttachment':'Dateianhang',
	'letterBody':'Briefinhalt',
	'letterBranch':'Zweig',
	'letterCategory':'Kategorie',
	'letterClassification':'Klassifikation',
	'letterCreate':'Neuen Brief erstellen',
	'letterCreateImport':'Briefe über Excel registrieren',
	'letterDeadlineHour':'Frist für Aktionen nach Stunde',
	'letterDelete':'Brief löschen',
	'letterDetail':'Briefinformationen Details',
	'letterDocumentType':'Dokumententyp',
	'letterIndicator':'Indikator',
	'letterLayout':'Briefe',
	'letterLetterCategoryId':'Kategorie',
	'letterLetterClassificationId':'Klassifikation',
	'letterLetterDocumentTypeId':'Dokumententyp',
	'letterLetterId':'ID',
	'letterLetterPatternId':'Briefvorlage',
	'letterLetterPriorityId':'Priorität',
	'letterLetterTopicId':'Archivthema',
	'letterLetterTypeId':'Briefart',
	'letterList':'Briefliste',
	'letterNode':'Knoten',
	'letterPattern':'Briefvorlage',
	'letterPatternAttachment':'Dateianhang',
	'letterPatternCaption':'Beschreibung',
	'letterPatternCreate':'Neue Briefvorlage erstellen',
	'letterPatternCreateImport':'Briefvorlagen über Excel registrieren',
	'letterPatternDelete':'Briefvorlage löschen',
	'letterPatternDetail':'Briefvorlageninformationen Details',
	'letterPatternIdentifier':'Primärschlüssel',
	'letterPatternLayout':'Briefvorlagen',
	'letterPatternLetterPatternId':'ID',
	'letterPatternLetterPatternTrack':'Briefvorlagen-Verfolgungsinformationen',
	'letterPatternList':'Briefvorlagenliste',
	'letterPatternPatternBody':'Vorlagenkörper',
	'letterPatternTitle':'Vorlagenname',
	'letterPatternUpdate':'Briefvorlage aktualisieren',
	'letterPriority':'Priorität',
	'letterStaticFile':'Statische Datei',
	'letterTag':'Brieftag',
	'letterTagAttachment':'Dateianhang',
	'letterTagCaption':'Beschreibung',
	'letterTagCreate':'Neuen Brieftag erstellen',
	'letterTagCreateImport':'Brieftags über Excel registrieren',
	'letterTagDelete':'Brieftag löschen',
	'letterTagDetail':'Brieftag-Informationen Details',
	'letterTagHexColor':'Farbe',
	'letterTagIdentifier':'Primärschlüssel',
	'letterTagLayout':'Brieftags',
	'letterTagLetterTagId':'ID',
	'letterTagLetterTagTrack':'Brieftag-Verfolgungsinformationen',
	'letterTagList':'Brieftagliste',
	'letterTagTitle':'Tag-Titel',
	'letterTagUpdate':'Brieftag aktualisieren',
	'letterTitle':'Brieftitel',
	'letterTopic':'Archivthema',
	'letterTopicAttachment':'Dateianhang',
	'letterTopicCaption':'Beschreibung',
	'letterTopicCreate':'Neues Archivthema erstellen',
	'letterTopicCreateImport':'Archivthemen über Excel registrieren',
	'letterTopicDelete':'Archivthema löschen',
	'letterTopicDetail':'Archivthemen-Informationen Details',
	'letterTopicIdentifier':'Primärschlüssel',
	'letterTopicLayout':'Archivthemen',
	'letterTopicLetterTopicId':'ID',
	'letterTopicLetterTopicTrack':'Archivthema-Verfolgungsinformationen',
	'letterTopicList':'Archivthemenliste',
	'letterTopicTitle':'Titel',
	'letterTopicUpdate':'Archivthema aktualisieren',
	'letterType':'Typ',
	'nextNodeStateCompleteTitle':'Titel der Schaltfläche Abschließen',
	'nextNodeStateContinueTitle':'Titel der Schaltfläche Fortsetzen',
	'nextNodeStateReturnTitle':'Titel der Schaltfläche Zurück',
	'nextNodeStateStopTitle':'Titel der Schaltfläche Stoppen',
	'nodeListCompleteTitle':'Titel der Schaltfläche Abschließen',
	'nodeListContinueTitle':'Titel der Schaltfläche Fortsetzen',
	'nodeListReturnTitle':'Titel der Schaltfläche Zurück',
	'nodeListStopTitle':'Titel der Schaltfläche Stoppen',
	'nodeStateCompleteTitle':'Titel der Schaltfläche Abschließen',
	'nodeStateContinueTitle':'Titel der Schaltfläche Fortsetzen',
	'nodeStateReturnTitle':'Titel der Schaltfläche Zurück',
	'nodeStateStopTitle':'Titel der Schaltfläche Stoppen',
	'process':'Prozess',
	'processAttachment':'Dateianhang',
	'processCaption':'Beschreibung',
	'processCreate':'Neuen Prozess erstellen',
	'processCreateImport':'Prozesse über Excel registrieren',
	'processDelete':'Prozess löschen',
	'processDetail':'Prozessinformationen Details',
	'processGroup':'Prozessgruppe',
	'processGroupAttachment':'Dateianhang',
	'processGroupCaption':'Beschreibung',
	'processGroupCreate':'Neue Prozessgruppe erstellen',
	'processGroupCreateImport':'Prozessgruppen über Excel registrieren',
	'processGroupDelete':'Prozessgruppe löschen',
	'processGroupDetail':'Prozessgruppeninformationen Details',
	'processGroupHexColor':'Farbe',
	'processGroupIdentifier':'Primärschlüssel',
	'processGroupLayout':'Prozessgruppen',
	'processGroupList':'Prozessgruppenliste',
	'processGroupProcessGroupId':'ID',
	'processGroupProcessGroupTrack':'Prozessgruppen-Verfolgungsinformationen',
	'processGroupTitle':'Titel',
	'processGroupUpdate':'Prozessgruppe aktualisieren',
	'processIdentifier':'Eindeutiger Schlüssel',
	'processLayout':'Prozesse',
	'processList':'Prozessliste',
	'processNode':'Aufgabe',
	'processNodeLayout':'Aufgaben',
	'processNodeManagement':'Aufgabenverwaltung',
	'processNodeManagementCaption':'Beschreibung',
	'processNodeManagementCompleteTitle':'Titel der Schaltfläche Abschließen',
	'processNodeManagementContinueTitle':'Titel der Schaltfläche Fortsetzen',
	'processNodeManagementEventPattern':'Ereignis',
	'processNodeManagementFormPattern':'Formular',
	'processNodeManagementHasAccessToComplete':'Kann Abschließen/Beenden',
	'processNodeManagementHasAccessToContinue':'Kann Fortsetzen',
	'processNodeManagementHasAccessToReturn':'Kann Zurückgehen',
	'processNodeManagementHasAccessToStop':'Kann Stoppen/Beenden ohne Erfolg',
	'processNodeManagementIsArrayFormValue':'Daten aus Excel eingeben',
	'processNodeManagementIsLimitedToUnit':'Beschränkt auf Organisationseinheit',
	'processNodeManagementMessagePatternName':'SMS-Vorlagenname',
	'processNodeManagementOrderNumber':'Priorität',
	'processNodeManagementProcessBranchAgents':'Zweigagenten',
	'processNodeManagementProcessId':'Prozess',
	'processNodeManagementProcessNodeId':'ID',
	'processNodeManagementProcessRoleAgents':'Benutzerrollen',
	'processNodeManagementReturnNodes':'Rückkehraktionen',
	'processNodeManagementReturnTitle':'Titel der Schaltfläche Zurück',
	'processNodeManagementStopTitle': 'Titel der Schaltfläche Stoppen',
	'processNodeManagementProcessWorkerAgent': 'Worker',
	'processNodeManagementTitle':'Titel',
	'processNodeWorkerAgent':'Agentendienst',
	'processProcessGroup':'Gruppe',
	'processProcessGroupId':'Gruppe',
	'processProcessId':'ID',
	'processProcessTrack':'Prozess-Verfolgungsinformationen',
	'processTitle':'Titel',
	'processUpdate':'Prozess aktualisieren',

}
