<template>
	<div class="activity-compose-step-2 pt-2">
		<kaveh-content width="800px">

			<div v-if="$parent.formPattern">
				<kaveh-form v-if="!$parent.isArrayFormValue" :props="$parent.formPattern" ref="FormRef" />
				<kaveh-excel-import v-if="$parent.isArrayFormValue" :props="$parent.formPattern" ref="ExcelRef" />
				<hr />
			</div>

			<div class="row">
				<div class="col-12 pt-3">
					<span>{{$loc.fromResource(form.comment.resourceKey)}}</span>
				</div>
				<div class="col-12 pt-3">
					<kaveh-text-editor ref="Editor" @change="(x) => { form.comment.value = x; }" />
				</div>
			</div>

			<hr />

			<div class="mb-3 row">
				<label for="deadlineHour" class="col-4 col-md-3">
					<span>{{$loc.fromResource(form.deadlineHour.resourceKey)}}</span>
					<span v-if="isRequired('deadlineHour')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<input v-model="form.deadlineHour.value" @keyup="onHandelKeyUp('deadlineHour')" @change="onHandelChange('deadlineHour')" dir="ltr" type="number" class="form-control" id="deadlineHour" autocomplete="off">
					<small class="text-danger" v-if="form.deadlineHour.errors.length > 0">
						{{getFirstError('deadlineHour')}}
					</small>
				</div>
			</div>


			<div class="mb-3 row">
				<label for="activityPriorityId" class="col-4 col-md-3">
					<span>{{$loc.fromResource(form.activityPriorityId.resourceKey)}}</span>
					<span v-if="isRequired('activityPriorityId')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<select class="form-select" v-bind:class="{'is-loading': activityPriorityListIsLoading}" v-model="form.activityPriorityId.value" @change="onHandelChange('activityPriorityId')" id="activityPriorityId">
						<option v-for="(item,index) in activityPriorityList" :value="item.activityPriorityId" :key="index">
							{{item.title}}
						</option>
					</select>
					<small class="text-danger" v-if="form.activityPriorityId.errors.length > 0">
						{{getFirstError('activityPriorityId')}}
					</small>
				</div>
			</div>
		</kaveh-content>
	</div>
</template>
<script>
	import { ENUMS } from '@/core/data'
	import validator from '@/core/validator'
	import { ActivityPriorityService, ProcessNodeService } from '@/modules/aut/services';

	export default {
		data() {
			return {
				useValidator:true,
				activityPriorityList: [],
				activityPriorityListIsLoading: true,
				form: {
					comment: {
						type: ENUMS.PROP_TYPE.STRING,
						resourceKey: 'aut.activityCreateComment',
						patterns: [],
						errors: [],
						value: null,
					},
					deadlineHour: {
						type: ENUMS.PROP_TYPE.INT16,
						resourceKey: 'aut.activityCreateDeadlineHour',
						patterns: [],
						errors: [],
						value: null,
					},
					activityPriorityId: {
						type: ENUMS.PROP_TYPE.BYTE,
						resourceKey: 'aut.activityCreateActivityPriorityId',
						patterns: ['required'],
						errors: [],
						value: 1,
					},
				}
			}
		},

		async beforeMount() {
			this.activityPriorityList = await this.getActivityPriorityList();
		},

		methods:
		{
			async getActivityPriorityList() {
				var items = [];
				var service = new ActivityPriorityService();

				try {
					const res = await service.list({
						start: 0,
						length: 1000
					}, { HER: true });

					if (res.status == 200) {
						items = res.data.items || [];
					}

				} catch (e) { }

				this.activityPriorityListIsLoading = false;
				return items;
			},

			isRequired(name) {
				return this.form[name].patterns.includes('required');
			},

			onHandelChange(name) {
				if (this.useValidator) {
					this.form[name].errors =
						validator.getPropErrors(this.form[name]);
				}

				this.form[name].isChanged = true;
			},

			onHandelKeyUp(name) {
				if (this.useValidator && this.form[name].isChanged) {
					this.form[name].errors =
						validator.getPropErrors(this.form[name]);
				}
			},

			getFirstError(name) {
				const prop = this.form[name];

				if (prop.errors.length > 0) {
					return this.$loc.fromResource(prop.errors[0].resourceKey, {
						label: this.$loc.fromResource(prop.resourceKey),
					})
				}

				return ''
			},

			getValue()
			{
				if (this.useValidator) {
					if (validator.getObjectErrorLength(this.form) > 0) {
						return undefined;
					}
				}


				var basicValue = {};
				var customValue = null;
				var keys = Object.keys(this.form);

				if (this.$refs.FormRef)
				{
					if (this.$refs.FormRef.getErrorLength() > 0)
					{
						return undefined;
					}

					var customValue =
						this.$refs.FormRef.getValue();
				}

				if (this.$refs.ExcelRef)
				{
					var customValue =
						this.$refs.ExcelRef.getValue();
				}

				for (var i = 0; i < keys.length; i++) {
					basicValue[keys[i]] = this.form[keys[i]].value
				}


				if (basicValue || customValue) {
					return {
						basicValue, customValue
					}
				}


				return undefined
			}
		}
	}
</script>
