<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" />
</template>
<script>
	import { StaticContent, StaticContentFilter } from '@/modules/cms/data/static-content/list'
	import { StaticContentService } from '@/modules/cms/services'
	export default {
		computed:
		{
			entity()
			{
				return StaticContent;
			},
			
			filterEntity()
			{
				return StaticContentFilter;
			},

			service()
			{
				return new StaticContentService();
			},

			actions()
			{
				return [
					{
						routeName: 'cms.staticContent.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'cms.staticContent.createImport',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						routeName: 'cms.staticContent.attachment',
						resourceKey: 'action.fileAttachment',
						iconName: 'bi-cloud-arrow-up',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'cms.staticContent.update',
						resourceKey: 'action.update',
						iconName: 'bi-pen',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'cms.staticContent.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [{
							routeName: 'cms.staticContent.detail',
							resourceKey: 'cms.staticContentDetail',
							iconName: 'bi-list-columns',
							activeMode: 'single',
							dataTransferMode: 'store',
						}]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>