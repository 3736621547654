<template>
	<kaveh-collapse :collapsed="isParent">
		<template v-slot:header v-if="isShowHeader">
			<span>{{ headerTitle }}</span>
			<small v-if="isRequired" class="text-danger"> * </small>
			<span v-if="showError" class="text-danger float-end me-2 ms-2">
				<kaveh-icon-bootstrap size="17px" name="bi-exclamation-circle" />
			</span>
		</template>
		<template v-slot:body>
			<div class="row">
				<template :key="i" v-for="(prop,i) in props">
					<form-collapse v-if="prop.type == 'object' && prop.entity"
								   :mode="mode"
								   :col="col"
								   :is-parent="false"
								   :entity="prop.entity"
								   :display-type="displayType"
								   :traslation-mode="traslationMode"
								   :prop-resource-key="prop.resourceKey"
								   :prop-label="prop.label"
								   :use-validation="useValidation"
								   :language-direction="languageDirection"
								   :is-required="entity.propHasRequired(prop)"
								   v-on:generator-up="(x) => {$emit('generator-up', x)}"
								   v-on:generator-down="(x) => {$emit('generator-down', x)}"
								   v-on:generator-remove="(x) => {$emit('generator-remove', x)}"
								   v-on:generator-select="(x) => {$emit('generator-select', x)}" />

					<form-control v-else-if="isShowProp(prop)"
								  :mode="mode"
								  :prop="prop"
								  :col="col"
								  :display-type="displayType"
								  :use-validation="useValidation"
								  :language-direction="languageDirection"
								  v-on:submit="$parent.submit"
								  v-on:change="(x) => { $emit('change', x) }"
								  v-on:generator-up="(x) => {$emit('generator-up', x)}"
								  v-on:generator-down="(x) => {$emit('generator-down', x)}"
								  v-on:generator-remove="(x) => {$emit('generator-remove', x)}"
								  v-on:generator-select="(x) => {$emit('generator-select', x)}" />

				</template>
			</div>
		</template>
	</kaveh-collapse>
</template>
<script>
	import formControl from './form-control'
	export default {
		emits: ['change', 'generator-remove', 'generator-up', 'generator-down', 'generator-select'],

		components: {
			'form-control': formControl
		},

		props: {

			'entity': {
				type: Object
			},

			'mode': {
				type: String
			},

			'displayType': {
				type: String
			},

			'isParent': {
				type: Boolean,
				default: true
			},

			'isRequired': {
				type: Boolean,
				default: false
			},

			'useValidation': {
				type: Boolean,
				default: true
			},

			'traslationMode': {
				type: Boolean,
				default: false
			},

			'col': {
				type: String
			},

			'propResourceKey': {
				type: String,
				default: undefined
			},

			'propLabel': {
				type: String,
				default: undefined
			},

			'languageDirection': {
				type: String,
				default: undefined
			},
		},

		computed:
		{
			showError()
			{
				if (this.entity && this.entity.type == 'object')
				{
					return (this.useValidation && this.entity.getFormErrorLength() > 0)
				}

				return false
			},

			props()
			{
				return this.entity.getFormProps();
			},

			headerTitle()
			{
				if (this.propLabel)
				{
					return this.propLabel
				}

				if (this.propResourceKey)
				{
					return this.$loc.fromResource(this.propResourceKey)
				}

				return this.$loc.fromResource(this.entity.resourceKey)
			},

			isShowHeader()
			{
				if (!this.isParent)
				{
					return true;
				}

				if (this.isParent)
				{
					return false;
				}


				for (var i = 0; i < this.props.length; i++)
				{
					const prop = this.props[i];
					if (prop.type == 'object' && prop.entity)
					{
						return true;
					}
				}

				return false;
			},


		},

		methods:
		{
			isShowProp(prop)
			{
				if (this.traslationMode && !prop.isMultiLanguages)
				{
					return false;
				}

				return true;

			}
		}
	}
</script>
