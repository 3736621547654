<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" />
</template>
<script>
	import { Process, ProcessFilter } from '@/modules/aut/data/process/list'
	import { ProcessService } from '@/modules/aut/services'
	export default {
		computed:
		{
			entity()
			{
				return Process;
			},
			
			filterEntity()
			{
				return ProcessFilter;
			},

			service()
			{
				return new ProcessService();
			},

			filterData()
			{
				const processId =
					this.$route.query['processId'];

				if (processId)
				{
					return {
						'processId': processId
					};
				};
				return null;
			},

			actions()
			{
				return [
					{
						routeName: 'aut.process.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'aut.process.createImport',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						routeName: 'aut.process.update',
						resourceKey: 'action.update',
						iconName: 'bi-pen',
						activeMode: 'single',
						dataTransferMode: 'store',
					},
					{
						routeName: 'aut.processNode.management',
						resourceKey: 'aut.ext.process.nodeManagement',
						iconName: 'bi-activity',
						activeMode: 'single',
						dataTransferMode: 'query',
						dataTransferKeys:['processId']
					},
					{
						routeName: 'aut.process.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [{
							routeName: 'aut.process.detail',
							resourceKey: 'aut.views.process.detail',
							iconName: 'bi-list-columns',
							activeMode: 'single',
							dataTransferMode: 'store',
						}]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				try
				{
					return await this.service.list(payload);
				} catch (e) { console.log(e) }
			}
		}
	}
</script>
