//GeneratorFlag:[NoChange]
export default {
	path: 'delivery-date-type',
	name: 'store.basis.deliveryDateType.layout',
	redirect: to => {
		return {name: 'store.deliveryDateType.list'};
	},
	component: () => import('@/modules/store/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/store/basis/delivery-date-type"],
		resourceKey: 'store.deliveryDateTypeLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[DeliveryDateTypeCreate]"
		{
			path: 'create',
			name: 'store.deliveryDateType.create',
			component: () => import('@/modules/store/views/delivery-date-type/delivery-date-type-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.deliveryDateTypeCreate',
				clientAccessKeys:["/store/basis/delivery-date-type/create"],
				serverAccessKeys:["/store/delivery-date-type/create","/store/delivery-date-type/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DeliveryDateTypeCreateImport]"
		{
			path: 'create-import',
			name: 'store.deliveryDateType.createImport',
			component: () => import('@/modules/store/views/delivery-date-type/delivery-date-type-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.deliveryDateTypeCreateImport',
				clientAccessKeys:["/store/basis/delivery-date-type/create-import"],
				serverAccessKeys:["/store/delivery-date-type/create","/store/delivery-date-type/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DeliveryDateTypeDelete]"
		{
			path: 'delete',
			name: 'store.deliveryDateType.delete',
			component: () => import('@/modules/store/views/delivery-date-type/delivery-date-type-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.deliveryDateTypeDelete',
				clientAccessKeys:["/store/basis/delivery-date-type/delete"],
				serverAccessKeys:["/store/delivery-date-type/delete","/store/delivery-date-type/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DeliveryDateTypeDetail]"
		{
			path: 'detail',
			name: 'store.deliveryDateType.detail',
			component: () => import('@/modules/store/views/delivery-date-type/delivery-date-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.deliveryDateTypeDetail',
				clientAccessKeys:["/store/basis/delivery-date-type/detail"],
				serverAccessKeys:["/store/delivery-date-type/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DeliveryDateTypeList]"
		{
			path: 'list',
			name: 'store.deliveryDateType.list',
			component: () => import('@/modules/store/views/delivery-date-type/delivery-date-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.deliveryDateTypeList',
				clientAccessKeys:["/store/basis/delivery-date-type/list"],
				serverAccessKeys:["/store/delivery-date-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DeliveryDateTypeAttachment]"
		{
			path: 'attachment',
			name: 'store.deliveryDateType.attachment',
			component: () => import('@/modules/store/views/delivery-date-type/delivery-date-type-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.deliveryDateTypeAttachment',
				clientAccessKeys:["/store/basis/delivery-date-type/attachment"],
				serverAccessKeys:["/store/delivery-date-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DeliveryDateTypeUpdate]"
		{
			path: 'update',
			name: 'store.deliveryDateType.update',
			component: () => import('@/modules/store/views/delivery-date-type/delivery-date-type-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.deliveryDateTypeUpdate',
				clientAccessKeys:["/store/basis/delivery-date-type/update"],
				serverAccessKeys:["/store/delivery-date-type/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}