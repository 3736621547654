export default {
	"room": {
		"create": "Create New Chat",
		"filter": "Search Chats...",
		"delete": "Delete Chat",
		"createTitle": "Select the person or People you want to start the conversation.",
		"errors": {
			"error1": "Please enter the title of the group"
		}
	},
	"chat": {
		"delete": "Delete Message",
		"deleteForAll": "Delete Message for All",
		"reply": "Reply",
		"copy": "Copy Message Text",
		"showOldChats": "Show previous messages"
	}
}

