<template>
	<div class="letter-forward-step-5" v-if="payload != null">

		<div class="mb-2 p-3 bg-white">
			<strong class="d-block mb-2">
				{{$loc.fromResource('aut.ext.letter.forward.preview.primaryList')}}
			</strong>
			<label class="d-inline-block pe-2">
				<small>{{receiver.personName}}&nbsp;</small>
				<small class="text-muted">{{receiver.branchName}}</small>
			</label>
		</div>

		<div class="mb-4 p-3 bg-white">
			<strong class="mb-5 d-block">{{payload.letter.title}}</strong>
			<div v-html="payload.body"></div>
		</div>

		<div class="mb-5 p-3 bg-white" v-if=" payload.attachments.length > 0">
			<strong class="d-block mb-4">
				{{$loc.fromResource('aut.ext.letter.forward.preview.attachments')}}
			</strong>
			<div class="file" v-for="(item, index) in payload.attachments" :key="index">
				<span dir="ltr">{{ item.name }}</span>&nbsp;
			</div>
		</div>
	</div>
</template>
<script>
	export default {

		data()
		{
			return {
				payload: null
			}
		},

		computed:
		{
			receiver()
			{
				if (this.payload)
				{
					return (this.payload.letter.nodes || []).find((x) =>
					{
						return x.letterNodeTypeId == 1
					});
				}

				return null
			}
		},

		methods:
		{
			init(payload)
			{
				this.payload = payload
			},
		}
	}
</script>
<style>
	.letter-forward-step-5 {
		width: 100%;
		height: auto;
		margin: 20px auto 0 auto;
		max-width:800px;
	}
</style>
