<template>
	<div class="row" :key="ver" v-if="temp">
		<div class="col-12 mb-3">
			<div class="bg-light rounded p-3">
				{{$loc.fromResource('hub.ext.branch.position.copy.title',{label: temp.branchName})}}
			</div>
		</div>
		<div class="col-12 col-lg-6">
			<div class="bg-light rounded p-3">
				<div class="alert alert-info">
					{{$loc.fromResource('hub.ext.branch.position.copy.treeTitle')}}
				</div>
				<div class="mt-2">
					<branch-tree :is-multiple="false" @change="branchTreeChange" ref="branchTreeRef" />
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-6">
			<div class="bg-light rounded p-3">
				<div class="alert alert-info">
					{{$loc.fromResource('hub.ext.branch.position.copy.listTitle')}}
				</div>
				<div class="mt-2">
					<kaveh-table :selectable="false" :task="positionList" :entity="listEntity" />
				</div>
			</div>
		</div>
		<div class="col-12 mt-3">
			<kaveh-button :is-loading="isLoading" @click="submit" class="btn btn-dark">
				{{$loc.fromResource('action.save')}}
			</kaveh-button>
		</div>
	</div>
</template>
<script>
	import BranchTree from './position-tree/branch-position-tree'

	import { toast } from '@/plugins'
	import { ServerUtility } from '@/core/server'
	import { PositionService } from '@/modules/hub/services'
	import { Position as PositionListEntity } from '@/modules/hub/data/position/list'

	export default {

		components: {
			'branch-tree': BranchTree
		},

		data()
		{
			return {
				isLoading: false,
				isLoadingList: false,
				positions: [],
				temp: null,
				ver: 0,
				targetBranch: null
			}
		},

		computed:
		{
			listEntity()
			{
				return PositionListEntity;
			},
		},

		async beforeMount()
		{
			await this.initTemp()
		},

		methods:
		{
			async initTemp()
			{
				this.temp = await this.getDataFromTemp();
				if (this.temp == undefined)
				{
					this.$router.push({
						name: 'hub.branch.list'
					})
				}
			},

			async getDataFromTemp()
			{
				const store = this.$store;
				const router = this.$route;
				const temp =
					await store.dispatch(
						'temp/findByQuery', router.query
					);

				return temp ? temp.value : undefined;
			},

			async positionList(payload)
			{
				try
				{
					payload.filter = payload.filter || {};
					payload.filter.branchId = this.temp.branchId;

					return await new PositionService().list(payload);

				} catch (e) { console.log(e) }
			},

			branchTreeChange(branch)
			{
				this.targetBranch = branch
			},

			async submit()
			{
				if (this.targetBranch == null || this.targetBranch.branchTypeId != 3)
				{
					toast.error('hub.ext.branch.position.copy.errors.error1')
					return
				}

				if (this.temp.branchId == this.targetBranch.branchId)
				{
					toast.error('hub.ext.branch.position.copy.errors.error2')
					return
				}



				try
				{
					const payload = {
						start: 0,
						length: 10000,
						filter: {
							branchId: this.temp.branchId
						}
					};

					this.isLoading = true;
					const service = new PositionService();
					const res = await service.list(payload);

					if (res.status == 200)
					{
						const items =
							res.data.items || [];

						for (var i = 0; i < items.length; i++)
						{
							const item = {
								...items[i],
								branchId: this.targetBranch.branchId
							};

							await service.create(item, { HSR: false, HER: false });
						}

						toast.success('status.ok')
						this.isLoading = false;

						this.$router.push({
							name: 'hub.branch.list'
						})
					}

				} catch (e)
				{
					this.isLoading = false;
					new ServerUtility().showErrorMessage(e)
				}
			}
		}
	}
</script>
