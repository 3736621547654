//GeneratorFlag:[NoChange]
export default {
	path: 'product',
	name: 'store.product.layout',
	redirect: to => {
		return {name: 'store.product.list'};
	},
	component: () => import('@/modules/store/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/store/product"],
		resourceKey: 'store.productLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[ProductCreate]"
		{
			path: 'create',
			name: 'store.product.create',
			component: () => import('@/modules/store/views/product/product-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.productCreate',
				clientAccessKeys:["/store/product/create"],
				serverAccessKeys:["/store/product/create","/store/product/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductCreateImport]"
		{
			path: 'create-import',
			name: 'store.product.createImport',
			component: () => import('@/modules/store/views/product/product-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.productCreateImport',
				clientAccessKeys:["/store/product/create-import"],
				serverAccessKeys:["/store/product/create","/store/product/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductDelete]"
		{
			path: 'delete',
			name: 'store.product.delete',
			component: () => import('@/modules/store/views/product/product-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.productDelete',
				clientAccessKeys:["/store/product/delete"],
				serverAccessKeys:["/store/product/delete","/store/product/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductDetail]"
		{
			path: 'detail',
			name: 'store.product.detail',
			component: () => import('@/modules/store/views/product/product-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.productDetail',
				clientAccessKeys:["/store/product/detail"],
				serverAccessKeys:["/store/product/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductList]"
		{
			path: 'list',
			name: 'store.product.list',
			component: () => import('@/modules/store/views/product/product-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.productList',
				clientAccessKeys:["/store/product/list"],
				serverAccessKeys:["/store/product/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductAttachment]"
		{
			path: 'attachment',
			name: 'store.product.attachment',
			component: () => import('@/modules/store/views/product/product-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.productAttachment',
				clientAccessKeys:["/store/product/attachment"],
				serverAccessKeys:["/store/product/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductImageAttachment]"
		{
			path: 'image-attachment',
			name: 'store.product.imageAttachment',
			component: () => import('@/modules/store/views/product/product-image-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.productImageAttachment',
				clientAccessKeys:["/store/product/image-attachment"],
				serverAccessKeys:["/store/product/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductUpdate]"
		{
			path: 'update',
			name: 'store.product.update',
			component: () => import('@/modules/store/views/product/product-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.productUpdate',
				clientAccessKeys:["/store/product/update"],
				serverAccessKeys:["/store/product/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductSeoDetail]"
		{
			path: 'product-seo-detail',
			name: 'store.product.productSeoDetail',
			component: () => import('@/modules/store/views/product/product-seo-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.productSeoDetail',
				clientAccessKeys:["/store/product/product-seo-detail"],
				serverAccessKeys:["/store/product-seo/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductSeoUpdate]"
		{
			path: 'product-seo-update',
			name: 'store.product.productSeoUpdate',
			component: () => import('@/modules/store/views/product/product-seo-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.productSeoUpdate',
				clientAccessKeys:["/store/product/product-seo-update"],
				serverAccessKeys:["/store/product-seo/update","/store/product-seo/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}