//GeneratorFlag:[NoChange]
import ext from './fa-ext.js'
export default {
	"name":"asset",
	"ext": ext,
	"layout":{
//GeneratorFlag:[NewLayout]
	},
	'virtualFolder':'پوشه',
	'virtualFolderAttachment':'پیوست فایل',
	'virtualFolderCreate':'ثبت پوشه جدید',
	'virtualFolderCreateImport':'ثبت پوشه ها از طریق اکسل',
	'virtualFolderDelete':'حذف پوشه',
	'virtualFolderDetail':'جزئیات اطلاعات پوشه',
	'virtualFolderIdentifier':'کلید یکتا',
	'virtualFolderLayout':'پوشه ها',
	'virtualFolderList':'فهرست پوشه ها',
	'virtualFolderParentId':'والد',
	'virtualFolderTenantId':'مرکز',
	'virtualFolderTitle':'عنوان پوشه',
	'virtualFolderTree':'درخت',
	'virtualFolderUpdate':'ویرایش پوشه',
	'virtualFolderVirtualFolderId':'شناسه',
	'virtualFolderVirtualFolderTrack':'اطلاعات رهگیری ثبت',

}