//GeneratorFlag:[NoChange]
export default {
	path: 'process-group',
	name: 'aut.basis.processGroup.layout',
	redirect: to => {
		return {name: 'aut.processGroup.list'};
	},
	component: () => import('@/modules/aut/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/aut/basis/process-group"],
		resourceKey: 'aut.processGroupLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[ProcessGroupCreate]"
		{
			path: 'create',
			name: 'aut.processGroup.create',
			component: () => import('@/modules/aut/views/process-group/process-group-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.processGroupCreate',
				clientAccessKeys:["/aut/basis/process-group/create"],
				serverAccessKeys:["/aut/process-group/create","/aut/process-group/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProcessGroupCreateImport]"
		{
			path: 'create-import',
			name: 'aut.processGroup.createImport',
			component: () => import('@/modules/aut/views/process-group/process-group-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.processGroupCreateImport',
				clientAccessKeys:["/aut/basis/process-group/create-import"],
				serverAccessKeys:["/aut/process-group/create","/aut/process-group/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProcessGroupDelete]"
		{
			path: 'delete',
			name: 'aut.processGroup.delete',
			component: () => import('@/modules/aut/views/process-group/process-group-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.processGroupDelete',
				clientAccessKeys:["/aut/basis/process-group/delete"],
				serverAccessKeys:["/aut/process-group/delete","/aut/process-group/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProcessGroupDetail]"
		{
			path: 'detail',
			name: 'aut.processGroup.detail',
			component: () => import('@/modules/aut/views/process-group/process-group-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.processGroupDetail',
				clientAccessKeys:["/aut/basis/process-group/detail"],
				serverAccessKeys:["/aut/process-group/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProcessGroupList]"
		{
			path: 'list',
			name: 'aut.processGroup.list',
			component: () => import('@/modules/aut/views/process-group/process-group-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.processGroupList',
				clientAccessKeys:["/aut/basis/process-group/list"],
				serverAccessKeys:["/aut/process-group/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProcessGroupAttachment]"
		{
			path: 'attachment',
			name: 'aut.processGroup.attachment',
			component: () => import('@/modules/aut/views/process-group/process-group-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.processGroupAttachment',
				clientAccessKeys:["/aut/basis/process-group/attachment"],
				serverAccessKeys:["/aut/process-group/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProcessGroupUpdate]"
		{
			path: 'update',
			name: 'aut.processGroup.update',
			component: () => import('@/modules/aut/views/process-group/process-group-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.processGroupUpdate',
				clientAccessKeys:["/aut/basis/process-group/update"],
				serverAccessKeys:["/aut/process-group/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}