//GeneratorFlag:[NoChange]
export default {
	path: 'producer',
	name: 'store.basis.producer.layout',
	redirect: to => {
		return {name: 'store.producer.list'};
	},
	component: () => import('@/modules/store/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/store/basis/producer"],
		resourceKey: 'store.producerLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[ProducerCreate]"
		{
			path: 'create',
			name: 'store.producer.create',
			component: () => import('@/modules/store/views/producer/producer-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.producerCreate',
				clientAccessKeys:["/store/basis/producer/create"],
				serverAccessKeys:["/store/producer/create","/store/producer/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProducerCreateImport]"
		{
			path: 'create-import',
			name: 'store.producer.createImport',
			component: () => import('@/modules/store/views/producer/producer-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.producerCreateImport',
				clientAccessKeys:["/store/basis/producer/create-import"],
				serverAccessKeys:["/store/producer/create","/store/producer/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProducerDelete]"
		{
			path: 'delete',
			name: 'store.producer.delete',
			component: () => import('@/modules/store/views/producer/producer-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.producerDelete',
				clientAccessKeys:["/store/basis/producer/delete"],
				serverAccessKeys:["/store/producer/delete","/store/producer/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProducerDetail]"
		{
			path: 'detail',
			name: 'store.producer.detail',
			component: () => import('@/modules/store/views/producer/producer-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.producerDetail',
				clientAccessKeys:["/store/basis/producer/detail"],
				serverAccessKeys:["/store/producer/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProducerList]"
		{
			path: 'list',
			name: 'store.producer.list',
			component: () => import('@/modules/store/views/producer/producer-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.producerList',
				clientAccessKeys:["/store/basis/producer/list"],
				serverAccessKeys:["/store/producer/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProducerAttachment]"
		{
			path: 'attachment',
			name: 'store.producer.attachment',
			component: () => import('@/modules/store/views/producer/producer-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.producerAttachment',
				clientAccessKeys:["/store/basis/producer/attachment"],
				serverAccessKeys:["/store/producer/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProducerUpdate]"
		{
			path: 'update',
			name: 'store.producer.update',
			component: () => import('@/modules/store/views/producer/producer-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.producerUpdate',
				clientAccessKeys:["/store/basis/producer/update"],
				serverAccessKeys:["/store/producer/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}