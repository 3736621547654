<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-content :loading="loading" width="800px">
		<kaveh-form v-if="temp" :key="ver" :entity="entity" :task="submit" :value="temp" />
	</kaveh-content>
</template>
<script>
	import { LetterTopic } from '@/modules/aut/data/letter-topic/update'
	import { LetterTopicService } from '@/modules/aut/services'
	export default {
		data()
		{
			return {
				ver: 0,
				temp: null,
				loading: true
			}
		},
		computed:
		{
			entity()
			{
				return LetterTopic
			},
			service()
			{
				return new LetterTopicService()
			},
		},
		async beforeMount()
		{
			await this.init()
		},
		methods:
		{
			async init()
			{
				this.temp = await this.getDataFromService();
				if (this.temp == undefined)
				{
					this.$router.go(-1)
				}
			},
			async getDataFromService()
			{
				var result;
				const temp = await this.getDataFromTemp();

				if (temp)
				{
					try
					{
						this.loading = true;
						const payload = {'letterTopicId': temp['letterTopicId']};
						const res = await this.service.detail(payload,{HSR: false, HER: false});

						if(res.status == 200)
						{
							result = res.data;
						}

					} catch (e) 
					{ 
						if(e.response && e.response.status == 404)
						{
							result = { 'letterTopicId': temp['letterTopicId']}
						}
					}
				}

				this.loading = false;
				return result;
			},

			async getDataFromTemp()
			{
				const store = this.$store;
				const router = this.$route;
				const temp =
					await store.dispatch(
						'temp/findByQuery', router.query
					);

				return temp ? temp.value : undefined;
			},

			async submit(payload)
			{
				try
				{
					const res = await this.service.update(payload);
					if (res.status == 200)
					{
						this.$router.go(-1)
					}

				} catch (e) { console.log(e) }
			}
		}
	}
</script>