//GeneratorFlag:[NoChange]
import routes from './routes'
import services from './services'
import resources from './resources'
import toolbar from './toolbar'

const cms = {
	name: 'cms',
	foldreName: 'cms',
	routes,
	services,
	resources,
	toolbar
}

export default cms
export { routes, services, resources, toolbar }