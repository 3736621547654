<template>
	<label :for="prop.id" :name="prop.id" v-if="!isHidden" >
		<span class="pe-2">{{ label }}</span>
		<span class="text-primary me-1" title="Unique Filed" v-if="isUnique">
			<kaveh-icon-bootstrap name="bi-circle-fill" class="position-relative" style="top:-2px;" size="4px" />
		</span>
		<span class="text-danger" v-if="isRequired">
			<kaveh-icon-bootstrap name="bi-circle-fill" class="position-relative" style="top:-2px;" size="4px" />
		</span>
	</label>
</template>
<script>
	export default {
		props:
		{
			'prop': {
				type: Object,
				default: null
			},

			'useValidation': {
				type: Boolean,
				default: true
			}
		},

		computed: {
			label()
			{
				if (this.prop.label) {
					return this.prop.label
				}

				return this.$loc.fromResource(this.prop.resourceKey)
			},

			isHidden()
			{
				return this.prop.setConfig.inputType == 'hidden'
			},

			isRequired()
			{
				return this.useValidation && this.prop.setConfig.patterns.includes('required')
			},

			isUnique() {
				return this.useValidation && this.prop.setConfig.isUnique
			}
		},


		methods: {
			getTitle()
			{
				var value = {
					label: this.label
				};

				if (this.isRequired && this.isUnique)
				{
					return this.$loc.fromResource('com.form.title.requireUnique', value)
				}

				if (this.isRequired)
				{
					return this.$loc.fromResource('com.form.title.require', value)
				}

				if (this.isUnique)
				{
					return this.$loc.fromResource('com.form.title.unique', value)
				}

				return ''
			}
		}
	}
</script>
