<template>
	<div class="kaveh-text-editor">
		<div class="header">
			<div class="btn border-0" @click="onClickAction('removeFormat')">
				<kaveh-icon-bootstrap name="bi-eraser-fill" size="20px" />
			</div>
			<div class="btn border-0" @click="onClickAction('justifyRight')">
				<kaveh-icon-bootstrap name="bi-text-right" size="20px" />
			</div>
			<div class="btn border-0" @click="onClickAction('justifyCenter')">
				<kaveh-icon-bootstrap name="bi-text-center" size="20px" />
			</div>
			<div class="btn border-0" @click="onClickAction('justifyLeft')">
				<kaveh-icon-bootstrap name="bi-text-left" size="20px" />
			</div>
			<div class="btn border-0" @click="onClickAction('insertOrderedList')">
				<kaveh-icon-bootstrap name="bi-list-ol" size="20px" />
			</div>
			<div class="btn border-0" @click="onClickAction('insertUnorderedList')">
				<kaveh-icon-bootstrap name="bi-list-ul" size="20px" />
			</div>
			<div class="btn border-0" @click="onClickAction('strikethrough')" v-bind:class="{'active': states.includes('strikethrough')}">
				<kaveh-icon-bootstrap name="bi-type-strikethrough" size="20px" />
			</div>
			<div class="btn border-0" @click="onClickAction('italic')" v-bind:class="{'active': states.includes('italic')}">
				<kaveh-icon-bootstrap name="bi-type-italic" size="20px" />
			</div>
			<div class="btn border-0" @click="onClickAction('underline')" v-bind:class="{'active': states.includes('underline')}">
				<kaveh-icon-bootstrap name="bi-type-underline" size="20px" />
			</div>
			<div class="btn border-0" @click="onClickAction('bold')" v-bind:class="{'active': states.includes('bold')}">
				<kaveh-icon-bootstrap name="bi-type-bold" size="20px" />
			</div>
			<div class="btn border-0" v-bind:class="{'active': states.includes('table')}">
				<popper class="popper-light m-0 border-0" arrow>
					<kaveh-icon-bootstrap name="bi bi-table" size="20px" />
					<template #content>
						<div style="width:200px;">
							<table class="table table-sm table-bordered">
								<tbody>
									<tr>
										<td class="w-50">{{$loc.fromResource('com.textEditor.table.rowCount')}}</td>
										<td class="w-50"><input type="number" v-model="table.insert.rowCount" class="border form-control form-control-sm" /></td>
									</tr>
									<tr>
										<td>{{$loc.fromResource('com.textEditor.table.colCount')}}</td>
										<td><input type="number" v-model="table.insert.columnCount" class="border form-control form-control-sm" /></td>
									</tr>
									<tr>
										<td colspan="2">
											<button type="button" class="btn btn-info" @click="tableAdd">
												{{$loc.fromResource('com.textEditor.table.create')}}
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</template>
				</popper>
			</div>
			<div class="btn border-0" @click="onClickAction('hr')" v-bind:class="{'active': states.includes('hr')}">
				<kaveh-icon-bootstrap name="bi bi-hr" size="20px" />
			</div>
		</div>
		<div class="content" contenteditable="true" @input="onInput" @paste="onPaste" @keydown="onKeydown" @keyup="onHandelKeyup" ref="Editor"></div>
	</div>
</template>

<script>
	export default {
		emit: ['change', 'keyup', 'update:modelValue'],
		data()
		{
			return {
				states: [],
				table: {
					isShowPanel: false,
					insertMode: false,
					width: 300,
					height: 300,
					insert: {
						rowCount: 2,
						columnCount: 2,
						className: "table table-bordered"
					}
				}
			}
		},

		mounted()
		{
			this.execCommand('styleWithCSS')
		},

		methods: {
			tableShowInsert()
			{
				this.table.isShowPanel = true;
				this.table.insertMode = true;


			},
			tableAdd()
			{
				var t = "<div class='table-responsive'> <table class='table table-bordered'> <thead> [(th)] </thead> <tbody> [(body)] </tbody>  </table> </div>";
				var trTemplate = "<tr> [(row)] </tr>";
				var tdTemplate = "<td class='align-top'> &nbsp; </td>";
				var thTemplate = "<th> &nbsp; </th>";

				var th = "";
				for (var i = 0; i < this.table.insert.columnCount; i++)
				{
					th += thTemplate;
				}
				t = t.replace("[(th)]", th);

				var rows = "";
				for (var i = 0; i < this.table.insert.rowCount; i++)
				{

					var col = "";
					for (var j = 0; j < this.table.insert.columnCount; j++)
					{
						col += tdTemplate;
					}
					rows += trTemplate.replace("[(row)]", col);
				}
				t = t.replace("[(body)]", rows);
				console.log(t);
				this.execCommand("insertHTML", t);
				this.table.isShowPanel = false;
				this.table.insertMode = false;

			},
			execCommand(command, value)
			{
				document.execCommand(command, false, value || null);
			},

			onInput(event)
			{
				var editor = this.$refs.Editor;
				var firstChild = event.target.firstChild;

				if (firstChild && firstChild.nodeType === 3)
				{
					this.execCommand('formatBlock', '<div>');
				}
				else if (editor.innerHTML === '<br>')
				{
					editor.innerHTML = '';
				}

				this.$emit('update:modelValue', editor.innerHTML);
				this.$emit('change', editor.innerHTML)
			},

			onKeydown(event)
			{
				if (event.key === 'Tab')
				{
					event.preventDefault();
				}
				else if (event.key === 'Enter' && document.queryCommandValue('formatBlock') === 'blockquote')
				{
					return this.execCommand('formatBlock', '<div>');
				}
			},

			onHandelKeyup()
			{
				this.onHandelState();

				var editor = this.$refs.Editor;
				this.$emit('update:modelValue', editor.innerHTML);
				this.$emit('keyup', editor.innerHTML)
			},

			onPaste(e)
			{
				e.preventDefault();

				var html = ""
				var text = (e.originalEvent || e).clipboardData.getData('text/plain');
				var lines = text.split("\n");

				for (var i = 0; i < lines.length; i++)
				{
					const line =
						lines[i].trim();

					html += `<div>${(line != '' ? line : '<br/>')}</div>`;
				}

				this.execCommand("insertHTML", html)
			},

			onClickAction(command)
			{

				if (command == 'hr')
				{
					this.execCommand("insertHTML", '<hr/>')
				}
				if (command == "h3")
				{

					this.execCommand("insertHTML", '<h3> Head 3 </h3>')
				}
				this.execCommand(command);
				this.onHandelState();
				this.$refs.Editor.focus();
			},

			onHandelState()
			{
				var actives = [];
				const states = ['bold', 'underline', 'italic', 'strikethrough'];

				for (var i = 0; i < states.length; i++)
				{
					if (document.queryCommandState(states[i]))
					{
						actives.push(states[i]);
					}
				}

				this.states = actives;
			},

			getValue()
			{
				return this.$refs.Editor.innerHTML
			},

			setValue(value)
			{
				this.$refs.Editor.innerHTML = value
				this.$emit('update:modelValue', value);
			}
		},
	}
</script>
<style>
	.kaveh-text-editor {
		border-radius: 5px;
		background-color: #fff;
		border: #ddd solid 1px;
	}

		.kaveh-text-editor .header {
			border-bottom: #ddd solid 2px;
			text-align: center;
			padding-top:5px;
		}

		.kaveh-text-editor .content {
			height: 300px;
			outline: 0;
			overflow-y: auto;
			padding: 10px;
		}

		.kaveh-text-editor .active {
			color: var(--kaveh-base-color);
		}
</style>
