//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseTrack } from '@/core/data'
import { BlogCategory } from '@/modules/cms/data/blog-content/detail'; 
export default class BlogContent extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'blogContent';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'cms.blogContent';
	props = {
		blogContentId: {
			primarykey: true,
			name: 'blogContentId',
			resourceKey: 'cms.blogContentBlogContentId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		slug: {
			name: 'slug',
			resourceKey: 'cms.blogContentSlug',
			type: ENUMS.PROP_TYPE.STRING,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 100,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		title: {
			name: 'title',
			resourceKey: 'cms.blogContentTitle',
			type: ENUMS.PROP_TYPE.STRING,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 50,
				isUnique: true,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		caption: {
			name: 'caption',
			resourceKey: 'cms.blogContentCaption',
			type: ENUMS.PROP_TYPE.STRING,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXTAREA,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 150,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		stringContent: {
			name: 'stringContent',
			resourceKey: 'cms.blogContentStringContent',
			type: ENUMS.PROP_TYPE.STRING,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		keywords: {
			name: 'keywords',
			resourceKey: 'cms.blogContentKeywords',
			type: ENUMS.PROP_TYPE.STRING,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		isActive: {
			name: 'isActive',
			resourceKey: 'cms.blogContentIsActive',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		blogCategoryId: {
			foreignKey: true,
			name: 'blogCategoryId',
			resourceKey: 'cms.blogContentBlogCategoryId',
			type: ENUMS.PROP_TYPE.INT16,
			serverAddress: '/cms/blog-category/list',
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'title',
				valueKey: 'blogCategoryId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		translations: {
			name: 'translations',
			resourceKey: 'cms.blogContentTranslations',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		blogCategory: {
			name: 'blogCategory',
			resourceKey: 'cms.blogContentBlogCategory',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BlogCategory(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		blogContentTrack: {
			name: 'blogContentTrack',
			resourceKey: 'cms.blogContentBlogContentTrack',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BaseTrack(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: 'data.track.summary',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}