<template>
	<span v-if="prop.value" style="text-align:justify-all;"> {{prop.value}} </span>
</template>
<script>
	export default {
		emits: ['change', 'keyup', 'submit'],
		props: {
			prop: {
				type: Object
			}
		},

		data()
		{
			return {
				value: null
			}
		},

		beforeMount()
		{
			this.value = this.prop.value
		},

		methods: {

			onHandelChange()
			{
				this.$emit('change', this.value)
			},

			onHandelKeyUp(e)
			{
				if (e.key === 'Enter' || e.keyCode === 13)
				{
					this.$emit('submit')
				}
				else
				{
					this.$emit('keyup', this.value)
				}
			}
		}
	}
</script>
