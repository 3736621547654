<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" />
</template>
<script>
	import { FeatureGroup, FeatureGroupFilter } from '@/modules/store/data/feature-group/list'
	import { FeatureGroupService } from '@/modules/store/services'
	export default {
		computed:
		{
			entity()
			{
				return FeatureGroup;
			},
			
			filterEntity()
			{
				return FeatureGroupFilter;
			},

			service()
			{
				return new FeatureGroupService();
			},

			actions()
			{
				return [
					{
						routeName: 'store.featureGroup.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'store.featureGroup.createImport',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						routeName: 'store.featureGroup.attachment',
						resourceKey: 'action.fileAttachment',
						iconName: 'bi-cloud-arrow-up',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'store.featureGroup.update',
						resourceKey: 'action.update',
						iconName: 'bi-pen',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'store.featureGroup.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [
							{
								routeName: 'store.feature.list',
								resourceKey: 'store.featureList',
								iconName: 'bi-list-columns',
								activeMode: 'single',
								dataTransferMode: 'query',
								dataTransferKeys: ['featureGroupId'],
							},
							{
								routeName: 'store.featureGroup.list',
								resourceKey: 'store.featureGroupList',
								iconName: 'bi-list-columns',
								activeMode: 'single',
								dataTransferMode: 'query',
								dataTransferKeys: ['featureGroupId'],
							},

						]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>