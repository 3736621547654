//GeneratorFlag:[NoChange]
import routes from './routes'
import services from './services'
import resources from './resources'
import toolbar from './toolbar'

const messenger = {
	name: 'messenger',
	foldreName: 'messenger',
	routes,
	services,
	resources,
	toolbar
}

export default messenger
export { routes, services, resources, toolbar }