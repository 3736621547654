<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" />
</template>
<script>
	import { Feature, FeatureFilter } from '@/modules/store/data/feature/list'
	import { FeatureService } from '@/modules/store/services'
	export default {
		computed:
		{
			entity()
			{
				return Feature;
			},
			
			filterEntity()
			{
				return FeatureFilter;
			},

			service()
			{
				return new FeatureService();
			},

			actions()
			{
				return [
					{
						routeName: 'store.feature.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'store.feature.createImport',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						routeName: 'store.feature.attachment',
						resourceKey: 'action.fileAttachment',
						iconName: 'bi-cloud-arrow-up',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'store.feature.update',
						resourceKey: 'action.update',
						iconName: 'bi-pen',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'store.feature.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [
							{
								routeName: 'store.pricing.list',
								resourceKey: 'store.pricingList',
								iconName: 'bi-list-columns',
								activeMode: 'single',
								dataTransferMode: 'query',
								dataTransferKeys: ['featureId'],
							},

						]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>