import fileManagerRoute from './file-manager-route';
//GeneratorFlag:[NewImport]

const routes = [{
	path: 'asset',
	name: 'asset',
	component: () => import('@/modules/asset/views/layout.vue'),
	meta: {
		requiresAuth: true,
		clientAccessKeys: ['/asset'],
		serverAccessKeys: [],
		resourceKey: 'service.asset',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-images',
		}
	},
	children: [
		fileManagerRoute
		//GeneratorFlag:[NewLayout]
	]
}]

export default routes
export
{
	fileManagerRoute
//GeneratorFlag:[NewExport]
}
