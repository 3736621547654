<template>
	<div class="letter-compose-step-1">
		<div class="row">

			<div class="col-12 col-lg-6">
				<div class="bg-white p-2" style="height: 360px;">
					<branch-tree @change="branchTreeChange" ref="branchTreeRef" />
					<button v-bind:disabled="branches.length == 0"
							@click="addReceivers('primaryList')"
							class="btn btn-light border m-1">
						<kaveh-icon-bootstrap name="bi-plus" size="15px" />
						<span>{{$loc.fromResource('aut.ext.letter.compose.receivers.primaryList')}}</span>
					</button>
					<button v-bind:disabled="branches.length == 0"
							@click="addReceivers('ccList')"
							class="btn btn-light border m-1">
						<kaveh-icon-bootstrap name="bi-plus" size="15px" />
						<span>{{$loc.fromResource('aut.ext.letter.compose.receivers.ccList')}}</span>
					</button>
					<button v-bind:disabled="branches.length == 0"
							@click="addReceivers('bccList')"
							class="btn btn-light border m-1">
						<kaveh-icon-bootstrap name="bi-plus" size="15px" />
						<span>{{$loc.fromResource('aut.ext.letter.compose.receivers.bccList')}}</span>
					</button>
				</div>
			</div>

			<div class="col-12 col-lg-6">
				<div v-if="!isEmptyReceivers" class="bg-white p-2" style="height: 360px;">
					<div class="mt-2" v-if="primaryList.length > 0">
						<div class="col-12 text-base-color-dark">
							<strong>{{$loc.fromResource('aut.ext.letter.compose.receivers.primaryList')}}</strong>
						</div>
						<div class="col-12 mt-1 mb-2 recivers">
							<div class="pt-1 pb-1 ps-2 pe-2 m-1 text-bg-light d-inline-block" v-for="(item,index) in primaryList" :key="index">
								<small>{{item.personName}}</small>&nbsp;
								<small class="text-muted"><i>[{{item.branchName}}]</i></small>
								<button class="btn btn-sm btn-light ms-2"
										@click="removeReceiver('primaryList',item.branchId)">
									<kaveh-icon-bootstrap name="bi-trash" size="inherit" />
								</button>
							</div>
						</div>
					</div>
					<div class="mt-4" v-if="ccList.length > 0">
						<div class="col-12 text-base-color-dark">
							<strong class="border ps-1 me-2 rounded pe-1">CC</strong>
							<strong>{{$loc.fromResource('aut.ext.letter.compose.receivers.ccList')}}</strong>
						</div>
						<div class="col-12 mt-2 mb-2 recivers">
							<div class="pt-1 pb-1 ps-2 pe-2 m-1 text-bg-light d-inline-block" v-for="(item,index) in ccList" :key="index">
								<small>{{item.personName}}</small>&nbsp;
								<small class="text-muted"><i>[{{item.branchName}}]</i></small>
								<button class="btn btn-sm btn-light ms-2"
										@click="removeReceiver('ccList',item.branchId)">
									<kaveh-icon-bootstrap name="bi-trash" size="inherit" />
								</button>
							</div>
						</div>
					</div>
					<div class="mt-4" v-if="bccList.length > 0">
						<div class="col-12 text-base-color-dark">
							<strong class="border ps-1 me-2 rounded pe-1">BCC</strong>
							<strong>{{$loc.fromResource('aut.ext.letter.compose.receivers.bccList')}}</strong>
						</div>
						<div class="col-12 mt-2 mb-2 recivers">
							<div class="pt-1 pb-1 ps-2 pe-2 m-1 text-bg-light d-inline-block" v-for="(item,index) in bccList" :key="index">
								<small>{{item.personName}}</small>&nbsp;
								<small class="text-muted"><i>[{{item.branchName}}]</i></small>
								<button class="btn btn-sm btn-light ms-2"
										@click="removeReceiver('bccList',item.branchId)">
									<kaveh-icon-bootstrap name="bi-trash" size="inherit" />
								</button>
							</div>
						</div>
					</div>
				</div>

				<div v-else class="bg-white text-center ps-2 pe-2 pt-5" style="height:360px;">
					<kaveh-icon-bootstrap name="bi-info-circle" size="40px" />
					<div>{{$loc.fromResource('aut.ext.letter.compose.receivers.emptyList')}}</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { toast } from '@/plugins'
	import BranchTree from '@/modules/hub/views/branch/employee-tree/branch-employee-tree'

	export default {

		components: {
			'branch-tree': BranchTree,
		},

		data()
		{
			return {
				primaryList: [],
				ccList: [],
				bccList: [],
				branches: [],
				receivers: [],
			}
		},

		computed:
		{
			isEmptyReceivers()
			{
				var len = 0;
				len += this.ccList.length;
				len += this.bccList.length;
				len += this.primaryList.length;

				return len == 0
			}
		},

		methods:
		{
			branchTreeChange(branches)
			{
				this.branches = branches;
			},

			getDuplicat()
			{
				for (var i = 0; i < this.branches.length; i++)
				{
					const branch = this.branches[i];
					const index = this.receivers.findIndex((x) =>
					{
						return x.branchId == branch.branchId;
					});

					if (index > -1)
					{
						return branch.personFullName;
					}
				}

				return undefined;
			},


			showDuplicateError(label)
			{
				const message =
					this.$loc.fromResource(
						'aut.ext.letter.compose.duplicateError', {
						label: label
					});

				toast.error(message)
			},

			addReceivers(name)
			{
				const duplicate =
					this.getDuplicat();

				if (duplicate == undefined)
				{
					this[name] = [...this[name], ...this.branches];
					this.receivers = [...this.receivers, ...this.branches];

					this.$refs.branchTreeRef.reset()
				}
				else this.showDuplicateError(duplicate);
			},

			removeReceiver(name, branchId)
			{
				const index = this[name].findIndex((x) =>
				{
					return x.branchId == branchId
				})

				this[name].splice(index, 1);
				this.receivers = [
					...this.ccList,
					...this.bccList,
					...this.primaryList
				];
			},

			getValue()
			{
				return JSON.parse(JSON.stringify({
					ccList: this.ccList,
					bccList: this.bccList,
					primaryList : this.primaryList,
				}))
			}

		}
	}
</script>
<style>
	.letter-compose-step-1 .recivers {
		display: block !important;
		overflow-x: auto;
		white-space: nowrap;
	}
</style>
