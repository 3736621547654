<template>
	<div :class="className">
		<div class="ord-node" :style="{'width': width , 'height': height, 'background':background}">
			<slot name="body"></slot>
		</div>
		<div v-if="$slots.node" class="ord-node-wrapper-inner">
			<slot name="node"></slot>
		</div>
	</div>
</template>

<script>

	export default {
		props:
		{
			cildrenLength: {
				type: Number
			},

			width: {
				type: String,
				default:'200px'
			},

			height: {
				type: String,
				default: '150px'
			},

			background: {
				type: String,
				default: '#E7EAED'
			}
		},

		computed:
		{
			className()
			{
				return `ord-node-wrapper ord-node-wrapper--${this.cildrenLength} ord-node-wrapper--horizontal`
			}
		}
	}
</script>

<style>
	.ord-node-wrapper {
		position: relative;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: flex-start;
		background: #f8f8f8;
	}

	.ord-node-wrapper-inner {
		position: relative;
	}

		.ord-node-wrapper-inner .ord-node-wrapper::before {
			content: '';
			position: absolute;
			border-width: 1px;
			border-style: solid;
			border-color: var(--kaveh-base-color);
		}

	.ord-node-wrapper.ord-node-wrapper--1 > .ord-node-wrapper-inner > .ord-node-wrapper::before {
		display: none;
	}

	.ord-node-wrapper-inner::after,
	.ord-node-wrapper-inner .ord-node::before {
		content: '';
	}

	.ord-node-wrapper-inner::after, .ord-node-wrapper-inner .ord-node::before {
		position: absolute;
	}

	.ord-node-wrapper-inner::after, .ord-node-wrapper-inner .ord-node::before {
		border-width: 1px;
		border-style: solid;
		border-color: var(--kaveh-base-color);
	}

	.ord-node {
		padding: 12px;
		border-radius: 0.5rem;
		position: relative;
	}

	/* vertical */
	.ord-node-wrapper--vertical {
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}

		.ord-node-wrapper--vertical .ord-node-wrapper-inner {
			padding-left: 20px;
			padding-right: 20px;
		}

	.ord-node-wrapper-inner .ord-node-wrapper--vertical::before {
		width: 1px;
		height: 100%;
		right: 0px;
	}

	.ord-node-wrapper-inner .ord-node-wrapper--vertical:first-child::before,
	.ord-node-wrapper-inner .ord-node-wrapper--vertical:last-child::before {
		height: calc(50% + 1px);
	}

	.ord-node-wrapper-inner .ord-node-wrapper--vertical:first-child::before {
		top: 50%;
	}

	.ord-node-wrapper-inner .ord-node-wrapper--vertical + .ord-node-wrapper--vertical::before {
		top: 0;
	}

	.ord-node-wrapper--vertical .ord-node-wrapper-inner::after,
	.ord-node-wrapper--vertical .ord-node-wrapper-inner .ord-node::before {
		height: 1px;
		transform: translateY(-50%);
	}

	.ord-node-wrapper--vertical .ord-node-wrapper-inner::after,
	.ord-node-wrapper--vertical .ord-node-wrapper-inner .ord-node::before {
		top: 50%;
	}

	.ord-node-wrapper--vertical .ord-node-wrapper-inner .ord-node::before {
		right: -20px;
		width: 20px;
	}

	.ord-node-wrapper--vertical .ord-node-wrapper-inner::after {
		right: 0;
		width: 2.5rem;
	}

	/* horizontal */
	.ord-node-wrapper--horizontal {
		flex-direction: column;
		padding: 20px;
	}

		.ord-node-wrapper--horizontal .ord-node {
			min-height: 140px;
		}

		.ord-node-wrapper--horizontal .ord-node-wrapper-inner {
			padding-top: 20px;
			padding-bottom: 20px;
			display: flex;
		}

	.ord-node-wrapper-inner .ord-node-wrapper--horizontal::before {
		height: 1px;
		width: 100%;
		top: 0px;
	}

	.ord-node-wrapper-inner .ord-node-wrapper--horizontal:first-child::before,
	.ord-node-wrapper-inner .ord-node-wrapper--horizontal:last-child::before {
		width: calc(50% + 1px);
	}

	.ord-node-wrapper-inner .ord-node-wrapper--horizontal:first-child::before {
		right: calc(50% + 1px);
	}

	.ord-node-wrapper-inner .ord-node-wrapper--horizontal:last-child::before {
		right: 0;
	}

	.ord-node-wrapper--horizontal .ord-node-wrapper-inner::after,
	.ord-node-wrapper--horizontal .ord-node-wrapper-inner .ord-node::before {
		width: 1px;
		transform: translateX(-50%);
	}

	.ord-node-wrapper--horizontal .ord-node-wrapper-inner::after,
	.ord-node-wrapper--horizontal .ord-node-wrapper-inner .ord-node::before {
		right: 50%;
	}

	.ord-node-wrapper--horizontal .ord-node-wrapper-inner .ord-node::before {
		top: -20px;
		height: 20px;
	}

	.ord-node-wrapper--horizontal .ord-node-wrapper-inner::after {
		top: 0;
		height: 20px;
	}

	[dir="ltr"] .ord-node-wrapper-inner .ord-node-wrapper--horizontal:first-child::before {
		right:unset;
		left: calc(50% + 1px);
	}

	[dir="ltr"] .ord-node-wrapper-inner .ord-node-wrapper--horizontal:last-child::before {
		right: unset;
		left: 0;
	}

</style>
