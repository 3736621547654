<template>
	<div class="letter-reply" v-if="branchId > 0">

		<div class="steps bg-light pt-4 pb-4 ps-3 pe-3 mb-2">
			<div class="row">
				<div class="col step" v-bind:class="{'active': step >= 1}">
					<div class="circle-wrapper">
						<div class="circle">
							<kaveh-icon-bootstrap cursor="default" name="bi-eye" size="20px" />
						</div>
					</div>
					<div>{{$loc.fromResource('aut.ext.letter.reply.step1')}}</div>
				</div>
				<div class="col step" v-bind:class="{'active': step >= 2}">
					<div class="circle-wrapper">
						<div class="circle">
							<kaveh-icon-bootstrap cursor="default" name="bi-file-earmark-text-fill" size="20px" />
						</div>
					</div>
					<div>{{$loc.fromResource('aut.ext.letter.reply.step2')}}</div>
				</div>
				<div class="col step" v-bind:class="{'active': step >= 3}">
					<div class="circle-wrapper">
						<div class="circle">
							<kaveh-icon-bootstrap cursor="default" name="bi-paperclip" size="20px" />
						</div>
					</div>
					<div>{{$loc.fromResource('aut.ext.letter.reply.step3')}}</div>
				</div>
				<div class="col step" v-bind:class="{'active': step == 4}">
					<div class="circle-wrapper">
						<div class="circle">
							<kaveh-icon-bootstrap cursor="default" name="bi-send-check-fill" size="20px" />
						</div>
					</div>
					<div>{{$loc.fromResource('aut.ext.letter.reply.step4')}}</div>
				</div>
			</div>
		</div>



		<div class="content bg-light p-3">

			<letter-reply-step1 :class="{'d-none': step != 1}" ref="LetterRef" />
			<letter-reply-step2 :class="{'d-none': step != 2}" ref="BodyRef" />
			<letter-reply-step3 :class="{'d-none': step != 3}" ref="AttachmentRef" />
			<letter-reply-step4 :class="{'d-none': step != 4}" ref="ConfirmRef" />

			<div class="mt-3 p-3 text-center bg-white" v-if="loading">
				<div class="spinner-border spinner-border-sm me-2" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
				<span>{{$loc.fromResource('aut.ext.letter.reply.loading')}}</span>
			</div>
			<div class="mt-3 p-3 text-center bg-white" v-else>
				<div class="text-danger pb-4" v-if="error != ''">
					<small>{{ error }}</small>
				</div>
				<button @click="prev" class="btn btn-light border me-3 ps-5 pe-5" v-bind:disabled="step == 1">
					{{$loc.fromResource('aut.ext.letter.reply.prevStep')}}
				</button>
				<button @click="next" class="btn btn-light border me-3 ps-5 pe-5">
					<span v-if="step < 5">{{$loc.fromResource('aut.ext.letter.reply.nextStep')}}</span>
					<span v-else>{{$loc.fromResource('aut.ext.letter.reply.finalStep')}}</span>
				</button>
			</div>
		</div>

	</div>
</template>

<script>
	import { url } from '@/plugins'
	import { LetterService } from '@/modules/aut/services'
	import LetterReplyStep1 from './letter-reply-step1'
	import LetterReplyStep2 from './letter-reply-step2'
	import LetterReplyStep3 from './letter-reply-step3'
	import LetterReplyStep4 from './letter-reply-step4'

	export default {
		emits: ['complete'],

		components: {
			'letter-reply-step1': LetterReplyStep1,
			'letter-reply-step2': LetterReplyStep2,
			'letter-reply-step3': LetterReplyStep3,
			'letter-reply-step4': LetterReplyStep4,
		},

		data()
		{
			return {
				step: 1,
				error: "",
				loading: false
			}
		},

		computed:
		{
			branchId()
			{
				return this.$store.state.client.branchId
			},

			letterId()
			{
				return url.decript(this.$route.query.key)
			},

			clientId()
			{
				return this.$store.state.client.id
			}
		},

		async beforeMount()
		{
			if (this.branchId == 0)
			{
				this.$router.push({ name: 'hub.branch.accessDenied' })
			}
		},

		methods:
		{
			prev(e)
			{
				if (this.step > 1)
				{
					this.error = "";
					this.step = this.step - 1;
				}

				e.target.blur()
			},

			next(e)
			{
				this.error = "";
				this[`handelStep${this.step}`]();

				e.target.blur()
			},

			handelStep1()
			{
				this.step = 2;
			},

			handelStep2()
			{
				const value =
					this.$refs.BodyRef.getValue();

				if (value != '')
				{
					this.step = 3;
				}
				else
				{
					this.error =
						this.$loc.fromResource(
							'aut.ext.letter.reply.step2Error');
				}
			},

			handelStep3()
			{
				this.$refs.ConfirmRef.init({
					letter: this.$refs.LetterRef.getData(),
					body: this.$refs.BodyRef.getValue(),
					attachments: this.$refs.AttachmentRef.getValue()
				})

				this.step = 4;
			},



			async handelStep4()
			{
				this.loading = true;


				const letter = this.$refs.LetterRef.getData();
				const body = this.$refs.BodyRef.getValue();
				const attachment = this.$refs.AttachmentRef.getValue();

				var payload = {
					title: letter.title,
					indicator: null,
					deadlineHour: 0,
					letterTypeId: 2,
					letterPriorityId: letter.priority.letterPriorityId,
					letterCategoryId: letter.category.letterCategoryId,
					letterClassificationId: letter.classification.letterClassificationId,
					letterDocumentTypeId: letter.documentType.letterDocumentTypeId,
					letterTopicId: letter.topic.letterTopicId,
					body: body,
					parentId: letter.letterId,
					masterId: (letter.masterId || letter.letterId),
					branches: [{
						letterBoxId:2,
						letterNodeTypeId: 1,
						branchId: this.branchId,
						personId: this.clientId
					}],
					staticFiles: []
				}

				const senderNode = (letter.nodes || []).find((x) =>
				{
					return x.letterNodeTypeId == 1
				});

				payload.branches.push({
					letterBoxId: 1,
					letterNodeTypeId: 2,
					branchId: senderNode.branchId,
					personId: senderNode.personId
				})

				for (var i = 0; i < attachment.length; i++)
				{
					payload.staticFiles.push({
						fileName: attachment[i].name,
						dataUrl: attachment[i].dataUrl
					})
				}

				try
				{
					const res =
						await new LetterService().create(payload);

					if (res && res.status == 200)
					{
						this.$emit('complete', payload)
					}

				} catch (e)
				{
					console.log(e)
				}


				this.loading = false;

			},
		}
	}
</script>

<style>
	.letter-reply .steps .step {
		text-align: center;
		transition: all .5s;
		padding: 0;
	}

		.letter-reply .steps .step.active .circle {
			color: var(--kaveh-base-color);
		}

		.letter-reply .steps .step.active .circle-wrapper::before,
		.letter-reply .steps .step.active .circle-wrapper::after {
			border-top-style: solid;
		}


	.letter-reply .steps .circle-wrapper {
		position: relative;
	}

	.letter-reply .steps .step .circle-wrapper::before,
	.letter-reply .steps .step .circle-wrapper::after {
		content: "";
		position: absolute;
		top: 50%;
		width: calc(50% - 10px);
		height: 2px;
		border-top: #ddd dashed 2px;
		transition: all .5s;
	}

	.letter-reply .steps .step .circle-wrapper::before {
		left: 5px;
	}

	.letter-reply .steps .step .circle-wrapper::after {
		right: 5px;
	}

	.letter-reply .steps .circle {
		width: 40px;
		height: 40px;
		text-align: center;
		padding-top: 5px;
		font-size: 15px;
		display: block;
		border: #E7EAED solid 1px;
		border-radius: 100%;
		margin: 0 auto 10px auto;
		background: #ffffff;
		position: relative;
		z-index: 1;
	}
</style>
