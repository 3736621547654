export default {
	"room": {
		"create": "Neuen Chat erstellen",
		"filter": "Chats durchsuchen...",
		"delete": "Chat löschen",
		"createTitle": "Wählen Sie die Person oder Personen aus, mit denen Sie das Gespräch beginnen möchten.",
		"errors": {
			"error1": "Bitte geben Sie den Titel der Gruppe ein"
		}
	},
	"chat": {
		"delete": "Nachricht löschen",
		"deleteForAll": "Nachricht für alle löschen",
		"reply": "Antworten",
		"copy": "Nachricht kopieren",
		"showOldChats": "Vorherige Nachrichten anzeigen"
	}
}
