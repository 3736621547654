<template>
	<div v-if="temp">
		<div class="alert alert-warning text-center">
			<strong>شما در حال انتقال شاخه سازمانی {{temp.branchName}} هستید</strong>
		</div>
		<div class="mt-3">
			<branch-tree :exclude-tree="temp.tree" :is-multiple="false" @change="branchTreeChange" ref="branchTreeRef" />
		</div>
		<kaveh-button class="btn btn-dark" :is-loading="isLoading" @click="move">انتقال</kaveh-button>
	</div>
</template>
<script>
	import Branch from './data/branch.js'
	import BranchTree from '@/modules/hub/views/branch/tree/branch-tree'
	import { BranchService } from '@/modules/hub/services'
	import { toast } from '@/plugins'
	export default {

		components: {
			'branch-tree': BranchTree,
		},

		data()
		{
			return {
				ver: 0,
				temp: null,
				isLoading: false,
				branch: null,
				targetBranchId: null
			}
		},
		computed:
		{
			entity()
			{
				return Branch
			},

			service()
			{
				return new BranchService()
			},
		},
		async beforeMount()
		{
			await this.init()
		},
		methods:
		{
			async init()
			{
				this.temp = await this.getDataFromTemp();
				if (this.temp == undefined)
				{
					this.$router.go(-1)
				}
			},

			async getDataFromTemp()
			{
				const store = this.$store;
				const router = this.$route;
				const temp =
					await store.dispatch(
						'temp/findByQuery', router.query
					);

				return temp ? temp.value : undefined;
			},

			branchTreeChange(branch)
			{
				this.branch = branch
				this.targetBranchId = branch.branchId
			},

			getError()
			{
				const typeId =
					this.branch.branchTypeId;

				switch (this.temp.branchTypeId)
				{
					case 3:
						return (typeId == 2 || typeId == 3) ? undefined : "hub.ext.branch.move.errors.error3"; 
					case 2:
						return (typeId == 1 || typeId == 3) ? undefined : "hub.ext.branch.move.errors.error2";
					case 1:
						return typeId == 3 ? undefined : "hub.ext.branch.move.errors.error1";
				}
			},

			async move()
			{
				try
				{
					if (this.targetBranchId)
					{
						const error = this.getError();
						if (!error)
						{
							this.isLoading = true;
							const payload = {
								branchId: this.temp.branchId,
								targetBranchId: this.targetBranchId
							}

							const res = await this.service.move(payload);
							if (res.status == 200)
							{
								this.$router.push({
									name: 'hub.branch.list'
								})
							}
						}
						else
						{
							toast.error(error)
						}
					}

				} catch (e) { console.log(e) }

				this.isLoading = false;
			}
		}
	}
</script>
