<template>
	<div class="form-iran-plaque-wrapper">
	<div class="form-iran-plaque">
		<div class="blue-column">
			<div class="flag"><div></div><div></div><div></div></div>
			<div class="text"><div>I.R.</div><div>IRAN</div></div>
		</div>
		<input v-model="plaque_1" :name="`plaque1_{prop.id}`" placeholder="**" type="text" maxlength="2" tabindex="1" @keyup="onHandelKeyUp" @change="onHandelChange" autocomplete="off" />
		<input v-model="plaque_2" :name="`plaque2_{prop.id}`" placeholder="*" type="text" maxlength="1" tabindex="2" @keyup="onHandelKeyUp" @change="onHandelChange" autocomplete="off" class="alphabet-column" />
		<input v-model="plaque_3" :name="`plaque3_{prop.id}`" placeholder="***" type="text" maxlength="3" tabindex="3" @keyup="onHandelKeyUp" @change="onHandelChange" autocomplete="off" />
		<input v-model="plaque_4" :name="`plaque4_{prop.id}`" placeholder="**" type="text" maxlength="2" tabindex="4" @keyup="onHandelKeyUp" @change="onHandelChange" autocomplete="off" class="iran-column" />
	</div>
	</div>
</template>
<script>
	export default {
		emits: ['change', 'keyup', 'submit', 'update:modelValue'],

		props: {
			prop: {
				type: Object
			}
		},

		data()
		{
			return {
				value: null,

				plaque_1: '',
				plaque_2: '',
				plaque_3: '',
				plaque_4: '',
			}
		},

		beforeMount()
		{
			this.value = this.prop.value
		},

		methods: {

			getValue()
			{
				const value = `${this.plaque_1} ${this.plaque_2} ${this.plaque_3} ${this.plaque_4}`

				if (this.plaque_1 != '' &&
					this.plaque_2 != '' &&
					this.plaque_3 != '' &&
					this.plaque_4 != '')
				{
					return value;
				}
			},

			onHandelChange()
			{
				const value =
					this.getValue()

				if (value)
				{
					this.$emit('change', value)
					this.$emit('update:modelValue', value);
				}
			},



			onHandelKeyUp(e)
			{
				if (e.key === 'Enter' || e.keyCode === 13)
				{
					this.$emit('submit')
				}
				else
				{
					const value =
						this.getValue()

					if (value)
					{
						this.$emit('keyup', value)
						this.$emit('update:modelValue', value);
					}
				}
			}
		}
	}
</script>
<style>

	.form-iran-plaque-wrapper {
		width: 100%;
		height: 70px;
		background: #FDFDFD;
		border-radius: 4px;
		padding-top: 10px;
	}
		.form-iran-plaque-wrapper .form-iran-plaque {
			max-width: 280px;
			width: 100%;
			margin:0 auto;
			border-radius: 10px;
			border: 1px solid #ddd;
			height: 50px;
			background-color: #f8f8f8;
			text-align: center;
			overflow: hidden;
		}

			.form-iran-plaque-wrapper .form-iran-plaque > .blue-column {
				width: 40px;
				height: 100%;
				float: left;
				background-color: #042591;
				position: relative;
			}

				.form-iran-plaque-wrapper .form-iran-plaque > .blue-column > .flag {
					margin: 10%;
				}

					.form-iran-plaque-wrapper .form-iran-plaque > .blue-column > .flag :nth-child(1) {
						height: 5px;
						background-color: green;
					}

					.form-iran-plaque-wrapper .form-iran-plaque > .blue-column > .flag :nth-child(2) {
						height: 5px;
						background-color: #fff;
					}

					.form-iran-plaque-wrapper .form-iran-plaque > .blue-column > .flag :nth-child(3) {
						height: 5px;
						background-color: red;
					}

				.form-iran-plaque-wrapper .form-iran-plaque > .blue-column > .text {
					color: #ffffff;
					font-size: 8px;
					line-height: 8px;
					text-align: left;
					position: absolute;
					bottom: 4px;
					left: 4px;
				}

			.form-iran-plaque-wrapper .form-iran-plaque > input {
				width: calc(25% - 10px);
				height: 100%;
				font-size: 20px;
				display: inline-block;
				font-weight: bold;
				float: left;
				text-align: center;
				border: unset;
				position: relative;
			}

			.form-iran-plaque-wrapper .form-iran-plaque input:focus {
				background: #f8f8f8;
				outline: 0;
			}

			.form-iran-plaque-wrapper .form-iran-plaque input::after {
				content: "-";
				right: 0;
				top: 20px;
				position: absolute;
				width: 5px;
				height: 5px;
			}

			.form-iran-plaque-wrapper .form-iran-plaque > .iran-column {
				border-left: 2px solid #ddd;
			}
</style>
