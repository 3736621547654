export default {
	path: 'access-agent',
	name: 'hub.accessAgent.layout',
	redirect: to => {
		return { name: 'hub.accessAgent.save' };
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys: [],
		clientAccessKeys: ["/hub/access-agent"],
		resourceKey: 'hub.accessAgentLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-fingerprint',
		}
	},
	children: [
		//GeneratorFlag:[AccessAgentSave]"
		{
			path: 'save',
			name: 'hub.accessAgent.save',
			component: () => import('@/modules/hub/views/access-agent/access-agent-save.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.accessAgentSave',
				clientAccessKeys: ["/hub/access-agent/save"],
				serverAccessKeys: ["/hub/access-agent/list", "/hub/access-agent/save", "/hub/person/list", "/hub/person-role/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
		//GeneratorFlag:[NewRoute]

	]
}
