//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class CityService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[City.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/city/create', params || { });
	};

	//GeneratorFlag:[City.Delete]
	async delete(cityId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:cityId
		}
	
		return await _server.delete('/hub/city/delete', {'params':params})
	};

	//GeneratorFlag:[City.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/hub/city/detail', {'params':params});
	};

	//GeneratorFlag:[City.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/hub/city/list', {'params':params});
	};

	//GeneratorFlag:[City.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/city/update', params || { });
	};

	//GeneratorFlag:[City.CacheInfo]
	async cacheInfo(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/hub/city/cache-info', {'params':params});
	};

//GeneratorFlag:[NewAction]
}