<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-content width="800px">
		<kaveh-form :key="ver" :entity="entity" :task="submit" />
	</kaveh-content>
</template>
<script>
	import { LetterPattern } from '@/modules/aut/data/letter-pattern/create'
	import { LetterPatternService } from '@/modules/aut/services'
	export default {
		data()
		{
			return {
				ver: 0
			}
		},
		computed: 
		{
			entity()
			{
				return LetterPattern
			},
			service()
			{
				return new LetterPatternService()
			},
		},
		methods:
		{
			async submit(payload)
			{
				try
				{
					const res = await this.service.create(payload);
					if (res.status == 200)
					{
						this.ver += 1
					}

				} catch (e) { console.log(e) }
			}
		}
	}
</script>