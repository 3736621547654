import { toast } from '@/plugins'
import store from '@/core/store'
import Localization from '../localization'
import _ from 'lodash'

export default class ServerUtility
{
	constructor()
	{
		this.loc = new Localization();
	}

	logOut()
	{
		for (var key in localStorage)
		{
			if (typeof (key) == 'string')
			{
				if (!key.includes('kaveh.app') && key.includes('kaveh'))
				{
					localStorage.removeItem(key)
				}
			}
		}

		store.dispatch('server/clearSocket');
		store.dispatch('client/resetState');

		setTimeout(() =>
		{
			window.$router.push('/auth')
		}, 500)
	}

	getParsedHttpResponse(error)
	{
		var httpStatus = 200;
		var statusCode = undefined;
		var detail = undefined;
		var httpHeaders = undefined;
		var message = '';
		var resource = this.loc.fromResource;
		var errorStr = error.toString();

		if (error.response)
		{
			httpStatus = error.response.statusCode;
			httpHeaders = (error.response || {}).headers;
			statusCode = (error.response.data || {}).statusCode;
			detail = (error.response.data || {}).detail;
		}
		else
		{
			const flatError = String(error).replace(/(\r\n|\n|\r)/gm, '')
			const pattern = /RpcException: Status\(StatusCode="(?<statusCode>.*)", Detail="(?<detail>.*)"\)/g
			const execResult = pattern.exec(flatError);

			if (execResult && execResult.groups)
			{
				httpStatus = 500;
				statusCode = execResult.groups.statusCode;
				detail = execResult.groups.detail;

				switch (statusCode)
				{
					case 'InvalidArgument': httpStatus = 400; break;
					case 'AlreadyExists': httpStatus = 400; break;
					case 'NotFound': httpStatus = 404; break;
					case 'PermissionDenied': httpStatus = 403; break;
					case 'Unauthenticated': httpStatus = 401; break;
					case 'Internal': httpStatus = 500; break;
				}
			}
			else
			{
				if (String(error).indexOf("because user is unauthorized") > -1)
				{
					httpStatus = 403;
					statusCode = 'PermissionDenied';
					detail = "Permission Denied";
				}
				else if (String(error).indexOf("Status code '401'") > -1)
				{
					httpStatus = 401;
					statusCode = 'Unauthenticated';
					detail = "Unauthenticated";
				}
			}
		}


		if (statusCode && detail)
		{
			var message = undefined;
			switch (detail)
			{
				case "Permission Denied":
					message = resource(`message.permissionDenied`);
					break;
				case "Delete Failed - Relation Error":
					message = resource(`message.deleteRelationError`);
					break;
				case "Unavailable Service":
					message = resource(`message.unavailableService`);
					break;
				default:
					message = resource(`status.${_.camelCase(statusCode)}`, { message: detail });
					break;
			}
		}
		else
		{
			if (errorStr.includes('Network Error') || errorStr.includes('Failed to complete negotiation with the server'))
			{
				message = resource(`message.networkError`);
			}
			else
			{
				message = errorStr;
			}
		}

		return {
			detail: message, httpStatus
		};
	}


	showErrorMessage(error)
	{
		const { detail, httpStatus } =
			this.getParsedHttpResponse(error)

		if (httpStatus == 401 || httpStatus == 403)
		{
			this.logOut()
		}
		else
		{
			toast.error(null, detail);
		}
	}


	getModuleNameFromPath(path)
	{
		if (String(path).includes("/"))
		{
			return String(path).split('/')[1];
		}

		return path
	}

	pathToMethod(path)
	{
		const clearAndUpper = (text) =>
		{
			return text.replace(/-/, "").toUpperCase();
		}

		const toPascalCase = (text) =>
		{
			return text.replace(/(^\w|-\w)/g, clearAndUpper);
		}

		const items =
			String(path).split("/");

		var result = ""
		for (var i = 1; i < items.length; i++)
		{
			result += toPascalCase(items[i])
		}

		return result;
	}

	getServerConfig(path)
	{
		const servers = store.state.server.servers;
		const module = this.getModuleNameFromPath(path);

		const serverIndex = servers.findIndex((s) =>
		{
			return s.modules.findIndex((m) =>
			{
				return m == module
			}) > -1
		})

		if (serverIndex > -1)
		{
			return servers[serverIndex];
		}

		toast.error(null, 'ws server not found')
	}
}
