//GeneratorFlag:[NoChange]
export default {
	path: 'discount',
	name: 'store.discount.layout',
	redirect: to => {
		return {name: 'store.discount.list'};
	},
	component: () => import('@/modules/store/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/store/discount"],
		resourceKey: 'store.discountLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[DiscountCreate]"
		{
			path: 'create',
			name: 'store.discount.create',
			component: () => import('@/modules/store/views/discount/discount-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.discountCreate',
				clientAccessKeys:["/store/discount/create"],
				serverAccessKeys:["/store/discount/create","/store/discount/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DiscountCreateImport]"
		{
			path: 'create-import',
			name: 'store.discount.createImport',
			component: () => import('@/modules/store/views/discount/discount-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.discountCreateImport',
				clientAccessKeys:["/store/discount/create-import"],
				serverAccessKeys:["/store/discount/create","/store/discount/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DiscountDelete]"
		{
			path: 'delete',
			name: 'store.discount.delete',
			component: () => import('@/modules/store/views/discount/discount-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.discountDelete',
				clientAccessKeys:["/store/discount/delete"],
				serverAccessKeys:["/store/discount/delete","/store/discount/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DiscountDetail]"
		{
			path: 'detail',
			name: 'store.discount.detail',
			component: () => import('@/modules/store/views/discount/discount-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.discountDetail',
				clientAccessKeys:["/store/discount/detail"],
				serverAccessKeys:["/store/discount/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DiscountList]"
		{
			path: 'list',
			name: 'store.discount.list',
			component: () => import('@/modules/store/views/discount/discount-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.discountList',
				clientAccessKeys:["/store/discount/list"],
				serverAccessKeys:["/store/discount/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DiscountAttachment]"
		{
			path: 'attachment',
			name: 'store.discount.attachment',
			component: () => import('@/modules/store/views/discount/discount-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.discountAttachment',
				clientAccessKeys:["/store/discount/attachment"],
				serverAccessKeys:["/store/discount/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DiscountUpdate]"
		{
			path: 'update',
			name: 'store.discount.update',
			component: () => import('@/modules/store/views/discount/discount-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.discountUpdate',
				clientAccessKeys:["/store/discount/update"],
				serverAccessKeys:["/store/discount/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}