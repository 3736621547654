//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
export default class ActivityFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'activityFilter';
	schema = ENUMS.SCHEMA_TYPE.NONE;
	resourceKey = 'global.filters';
	props = {
		processTitle: {
			name: 'processTitle',
			resourceKey: 'aut.activityProcessTitle',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: true,
				patterns: [],
			},
			errors: [],
			value: null
		},
		activityStatusTitle: {
			name: 'activityStatusTitle',
			resourceKey: 'aut.activityActivityStatusTitle',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: true,
				patterns: [],
			},
			errors: [],
			value: null
		},
		activityPriorityTitle: {
			name: 'activityPriorityTitle',
			resourceKey: 'aut.activityActivityPriorityTitle',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: true,
				patterns: [],
			},
			errors: [],
			value: null
		},
		lastProcessNodeTitle: {
			name: 'lastProcessNodeTitle',
			resourceKey: 'aut.activityLastProcessNodeTitle',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: true,
				patterns: [],
			},
			errors: [],
			value: null
		},
		firstActivistName: {
			name: 'firstActivistName',
			resourceKey: 'aut.activityFirstActivistName',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 100,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		firstActivistRoleName: {
			name: 'firstActivistRoleName',
			resourceKey: 'aut.activityFirstActivistRoleName',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 100,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		firstActivityComment: {
			name: 'firstActivityComment',
			resourceKey: 'aut.activityFirstActivityComment',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		lastActivistName: {
			name: 'lastActivistName',
			resourceKey: 'aut.activityLastActivistName',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 100,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		lastActivistRoleName: {
			name: 'lastActivistRoleName',
			resourceKey: 'aut.activityLastActivistRoleName',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 100,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		formValues: {
			name: 'formValues',
			resourceKey: 'aut.activityFormValues',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		formComments: {
			name: 'formComments',
			resourceKey: 'aut.activityFormComments',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		formActivists: {
			name: 'formActivists',
			resourceKey: 'aut.activityFormActivists',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		fileLengths: {
			name: 'fileLengths',
			resourceKey: 'aut.activityFileLengths',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		localDateTimes: {
			name: 'localDateTimes',
			resourceKey: 'aut.activityLocalDateTimes',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		firstActivityLocalDate: {
			name: 'firstActivityLocalDate',
			resourceKey: 'aut.activityFirstActivityLocalDate',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATA_PICKER,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 10,
				isUnicode: false,
				isUnique: false,
				patterns: ['date'],
			},
			errors: [],
			value: null
		},
		firstActivityCreateTime: {
			name: 'firstActivityCreateTime',
			resourceKey: 'aut.activityFirstActivityCreateTime',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TIME_PICKER,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 10,
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}