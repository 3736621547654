<template>
	<div class="kaveh-popup">
		<div class="popup-backdrop" @click="closePopup"></div>
		<div class="popup-content" :style="{'max-width': width}">
			<div class="popup-header" :style="{'max-width': width}">
				<strong class="popup-header-back">
					<kaveh-icon-bootstrap @click="closePopup" name="bi-x-circle me-3" size="20px" />
				</strong>
			</div>
			<div class="popup-body" v-bind:class="{'p-0': noPadding}">
				<slot></slot>
				<div style="height:30px;"></div>
			</div>
		</div>
	</div>
</template>
<script>

	export default {
		name: 'Popup',
		props: {
			name: {
				type: String,
				default: ''
			},

			width: {
				type: String,
				default: '600px'
			},

			color: {
				type: String,
				default: 'inherit'
			},

			close: {
				type: Function,
			},

			confirmWhenClose: {
				type: Boolean,
				default: false,
			},

			noPadding: {
				type: Boolean,
				default: false,
			}
		},

		data() {
			return {
				isShow: true
			}
		},

		methods: {

			closePopup() {

				const fn = () => {
					if (typeof (this.close) == 'function') {
						return this.close();
					}

					this.isShow = false;
				}

				if (this.confirmWhenClose == true) {
					if (confirm(this.$loc.fromResource('com.popup.closeConfirm'))) {
						fn();
					}
				}
				else {
					fn();
				}
			}
		}
	}
</script>

<style scoped>

	.kaveh-popup,
	.kaveh-popup .popup-backdrop {
		width: 100%;
		height: 100%;
	}
		.kaveh-popup .popup-body,
		.kaveh-popup .popup-content {
			width: calc(100% - 20px);
			height: calc(100% - 20px);
		}

	.kaveh-popup {
		top: 0;
		left: 0;
		z-index: 10;
		position: fixed;
	}

		.kaveh-popup .popup-backdrop {
			z-index: 11;
			position: fixed;
			background: rgba(0,0,0,0.5);
		}

		.kaveh-popup .popup-content {
			float: left;
			overflow-y: hidden;
			background: #f1f1f1;
			position: relative;
			z-index: 12;
			margin: 10px;
			animation: kavehPopupAnimation 0.3s linear 0s 1 normal forwards;
			border-radius:10px;
		}

		.kaveh-popup .popup-header {
			top: 10px;
			left: 10px;
			padding: 10px;
			width: 100%;
			height: 50px;
			border-bottom: #ddd solid 1px;
		}

			.kaveh-popup .popup-header .popup-header-back {
				cursor: pointer;
			}

		.kaveh-popup .popup-body {
			float: left;
			width: 100%;
			padding: 10px;
			overflow-y: auto;
		}


	@keyframes kavehPopupAnimation {
		0% {
			opacity: 0;
			transform: translateX(-250px);
		}

		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}
</style>
