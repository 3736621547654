import ENUMS from '../enums'
import BaseEntity from './base-entity'

export default class BaseTrack extends BaseEntity
{
	constructor(arg)
	{
		super()
		super.prepair(arg)
	}

	setValue(value,entity)
	{
		if (value)
		{
			value.createDate = (value.createDate || "").replace(' 12:00:00 AM', '')
			value.createUtcDate = (value.createUtcDate || "").replace(' 12:00:00 AM', '')

			if (value.modifyDate)
			{
				value.modifyDate = value.modifyDate.replace(' 12:00:00 AM', '')
			}

			if (value.modifyUtcDate)
			{
				value.modifyUtcDate = value.modifyUtcDate.replace(' 12:00:00 AM', '')
			}
		}

		super.setValue(value, entity)
	}

	name = 'track';
	schema = ENUMS.SCHEMA_TYPE.SUB_INFO;
	resourceKey = 'data.track.self';
	props = {
		creatorId: {
			name: 'creatorId',
			resourceKey: 'data.track.creatorId',

			type: ENUMS.PROP_TYPE.NUMBER,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.LTR
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				patterns: []
			}
		},
		creatorIp: {
			name: 'creatorIp',
			resourceKey: 'data.track.creatorIp',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				patterns: []
			}
		},
		creatorName: {
			name: 'creatorName',
			resourceKey: 'data.track.creatorName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				patterns: []
			}
		},

		createDate: {
			name: 'createDate',
			resourceKey: 'data.track.createDate',
			type: ENUMS.PROP_TYPE.DATE,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.DATE,
				patterns: []
			}
		},
		createUtcDate: {
			name: 'createUtcDate',
			resourceKey: 'data.track.createUtcDate',
			type: ENUMS.PROP_TYPE.DATE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.DATE,
				patterns: []
			}
		},
		localCreateDate: {
			name: 'localCreateDate',
			resourceKey: 'data.track.localCreateDate',
			type: ENUMS.PROP_TYPE.PDATE,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.PDATE,
				patterns: []
			}
		},

		createTime: {
			name: 'createTime',
			resourceKey: 'data.track.createTime',
			type: ENUMS.PROP_TYPE.TIME,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TIME,
				patterns: []
			}
		},

		createUtcTime: {
			name: 'createUtcTime',
			resourceKey: 'data.track.createUtcTime',
			type: ENUMS.PROP_TYPE.TIME,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TIME,
				patterns: []
			}
		},

		modifierId: {
			name: 'modifierId',
			resourceKey: 'data.track.modifierId',
			type: ENUMS.PROP_TYPE.NUMBER,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				patterns: []
			}
		},

		modifierIp: {
			name: 'modifierIp',
			resourceKey: 'data.track.modifierIp',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				patterns: []
			}
		},

		modifierName: {
			name: 'modifierName',
			resourceKey: 'data.track.modifierName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				patterns: []
			}
		},
		modifyDate: {
			name: 'modifyDate',
			resourceKey: 'data.track.modifyDate',
			type: ENUMS.PROP_TYPE.DATE,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.DATE,
				patterns: []
			}
		},

		modifyUtcDate: {
			name: 'modifyUtcDate',
			resourceKey: 'data.track.modifyUtcDate',
			type: ENUMS.PROP_TYPE.DATE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.DATE,
				patterns: []
			}
		},

		localModifyDate: {
			name: 'localModifyDate',
			resourceKey: 'data.track.localModifyDate',
			type: ENUMS.PROP_TYPE.PDATE,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.PDATE,
				patterns: []
			}
		},
		modifyTime: {
			name: 'modifyTime',
			resourceKey: 'data.track.modifyTime',
			type: ENUMS.PROP_TYPE.time,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TIME,
				patterns: []
			}
		},

		modifyUtcTime: {
			name: 'modifyUtcTime',
			resourceKey: 'data.track.modifyUtcTime',
			type: ENUMS.PROP_TYPE.time,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TIME,
				patterns: []
			}
		}
	}
}
