//GeneratorFlag:[NoChange]
export default {
	path: 'feature-group',
	name: 'store.basis.featureGroup.layout',
	redirect: to => {
		return {name: 'store.featureGroup.list'};
	},
	component: () => import('@/modules/store/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/store/basis/feature-group"],
		resourceKey: 'store.featureGroupLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[FeatureGroupCreate]"
		{
			path: 'create',
			name: 'store.featureGroup.create',
			component: () => import('@/modules/store/views/feature-group/feature-group-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.featureGroupCreate',
				clientAccessKeys:["/store/basis/feature-group/create"],
				serverAccessKeys:["/store/feature-group/create","/store/feature-group/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureGroupCreateImport]"
		{
			path: 'create-import',
			name: 'store.featureGroup.createImport',
			component: () => import('@/modules/store/views/feature-group/feature-group-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.featureGroupCreateImport',
				clientAccessKeys:["/store/basis/feature-group/create-import"],
				serverAccessKeys:["/store/feature-group/create","/store/feature-group/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureGroupDelete]"
		{
			path: 'delete',
			name: 'store.featureGroup.delete',
			component: () => import('@/modules/store/views/feature-group/feature-group-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.featureGroupDelete',
				clientAccessKeys:["/store/basis/feature-group/delete"],
				serverAccessKeys:["/store/feature-group/delete","/store/feature-group/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureGroupDetail]"
		{
			path: 'detail',
			name: 'store.featureGroup.detail',
			component: () => import('@/modules/store/views/feature-group/feature-group-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.featureGroupDetail',
				clientAccessKeys:["/store/basis/feature-group/detail"],
				serverAccessKeys:["/store/feature-group/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureGroupList]"
		{
			path: 'list',
			name: 'store.featureGroup.list',
			component: () => import('@/modules/store/views/feature-group/feature-group-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.featureGroupList',
				clientAccessKeys:["/store/basis/feature-group/list"],
				serverAccessKeys:["/store/feature-group/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureGroupAttachment]"
		{
			path: 'attachment',
			name: 'store.featureGroup.attachment',
			component: () => import('@/modules/store/views/feature-group/feature-group-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.featureGroupAttachment',
				clientAccessKeys:["/store/basis/feature-group/attachment"],
				serverAccessKeys:["/store/feature-group/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureGroupUpdate]"
		{
			path: 'update',
			name: 'store.featureGroup.update',
			component: () => import('@/modules/store/views/feature-group/feature-group-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.featureGroupUpdate',
				clientAccessKeys:["/store/basis/feature-group/update"],
				serverAccessKeys:["/store/feature-group/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}