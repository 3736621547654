//GeneratorFlag:[NoChange]
export default {
	path: 'tenant',
	name: 'hub.basis.tenant.layout',
	redirect: to => {
		return {name: 'hub.tenant.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/basis/tenant"],
		resourceKey: 'hub.tenantLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[TenantCreate]"
		{
			path: 'create',
			name: 'hub.tenant.create',
			component: () => import('@/modules/hub/views/tenant/tenant-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.tenantCreate',
				clientAccessKeys:["/hub/basis/tenant/create"],
				serverAccessKeys:["/hub/tenant/create","/hub/tenant/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TenantCreateImport]"
		{
			path: 'create-import',
			name: 'hub.tenant.createImport',
			component: () => import('@/modules/hub/views/tenant/tenant-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.tenantCreateImport',
				clientAccessKeys:["/hub/basis/tenant/create-import"],
				serverAccessKeys:["/hub/tenant/create","/hub/tenant/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TenantDelete]"
		{
			path: 'delete',
			name: 'hub.tenant.delete',
			component: () => import('@/modules/hub/views/tenant/tenant-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.tenantDelete',
				clientAccessKeys:["/hub/basis/tenant/delete"],
				serverAccessKeys:["/hub/tenant/delete","/hub/tenant/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TenantDetail]"
		{
			path: 'detail',
			name: 'hub.tenant.detail',
			component: () => import('@/modules/hub/views/tenant/tenant-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.tenantDetail',
				clientAccessKeys:["/hub/basis/tenant/detail"],
				serverAccessKeys:["/hub/tenant/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TenantList]"
		{
			path: 'list',
			name: 'hub.tenant.list',
			component: () => import('@/modules/hub/views/tenant/tenant-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.tenantList',
				clientAccessKeys:["/hub/basis/tenant/list"],
				serverAccessKeys:["/hub/tenant/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TenantUpdate]"
		{
			path: 'update',
			name: 'hub.tenant.update',
			component: () => import('@/modules/hub/views/tenant/tenant-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.tenantUpdate',
				clientAccessKeys:["/hub/basis/tenant/update"],
				serverAccessKeys:["/hub/tenant/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}