//GeneratorFlag:[NoChange]
import activityRoute from './activity-route'; //GeneratorFlag:[Activity]
import letterRoute from './letter-route'; //GeneratorFlag:[Letter]
import processRoute from './process-route'; //GeneratorFlag:[Process]
import basisLetterPatternRoute from './basis/letter-pattern-route'; //GeneratorFlag:[BasisLetterPattern]
import basisLetterTagRoute from './basis/letter-tag-route'; //GeneratorFlag:[BasisLetterTag]
import basisLetterTopicRoute from './basis/letter-topic-route'; //GeneratorFlag:[BasisLetterTopic]
import basisProcessGroupRoute from './basis/process-group-route'; //GeneratorFlag:[BasisProcessGroup]
import reportActivityRoute from './report/activity-route'; //GeneratorFlag:[ReportActivity]
import processNodeRoute from './process-node-route'; //GeneratorFlag:[ProcessNode]
//GeneratorFlag:[NewImport]
const routes = [{
	path: 'aut',
	name: 'aut',
	component: () => import('@/modules/aut/views/layout.vue'),
	meta: {
		requiresAuth: true,
		clientAccessKeys:['/aut'],
		serverAccessKeys:[],
		resourceKey: 'service.aut',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-pc-display',
		}
	},
	children: [
activityRoute, letterRoute, processRoute, processNodeRoute, //GeneratorFlag:[NewChild]
		//GeneratorFlag:[BasisLayout]
		{
			path: 'basis',
			name: 'aut.basis',
			component: () => import('@/modules/aut/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.layout.basis',
				clientAccessKeys:['/aut/basis'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-database-fill-gear',
				}
			},
			children: [
			basisLetterPatternRoute, basisLetterTagRoute, basisLetterTopicRoute, basisProcessGroupRoute, //GeneratorFlag:[NewBasisChild]
			]
		},
		//GeneratorFlag:[ReportLayout]
		{
			path: 'report',
			name: 'aut.report',
			component: () => import('@/modules/aut/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.layout.report',
				clientAccessKeys:['/aut/report'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-layers-half',
				}
			},
			children: [
			reportActivityRoute, //GeneratorFlag:[NewReportChild]
			]
		},
//GeneratorFlag:[NewLayout]
	]
}]
export default routes
export{
activityRoute, letterRoute, processRoute, basisLetterPatternRoute, basisLetterTagRoute, basisLetterTopicRoute, basisProcessGroupRoute, reportActivityRoute, processNodeRoute, //GeneratorFlag:[NewExport]
}