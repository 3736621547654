<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" />
</template>
<script>
	import { BlogCategory, BlogCategoryFilter } from '@/modules/cms/data/blog-category/list'
	import { BlogCategoryService } from '@/modules/cms/services'
	export default {
		computed:
		{
			entity()
			{
				return BlogCategory;
			},
			
			filterEntity()
			{
				return BlogCategoryFilter;
			},

			service()
			{
				return new BlogCategoryService();
			},

			actions()
			{
				return [
					{
						routeName: 'cms.blogCategory.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'cms.blogCategory.createImport',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						routeName: 'cms.blogCategory.imageAttachment',
						resourceKey: 'action.imageAttachment',
						iconName: 'bi-images',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'cms.blogCategory.update',
						resourceKey: 'action.update',
						iconName: 'bi-pen',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'cms.blogCategory.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [
							{
								routeName: 'cms.blogContent.list',
								resourceKey: 'cms.blogContentList',
								iconName: 'bi-list-columns',
								activeMode: 'single',
								dataTransferMode: 'query',
								dataTransferKeys: ['blogCategoryId'],
							},
							{
								routeName: 'cms.blogCategory.list',
								resourceKey: 'cms.blogCategoryList',
								iconName: 'bi-list-columns',
								activeMode: 'single',
								dataTransferMode: 'query',
								dataTransferKeys: ['blogCategoryId'],
							},

						]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>