//GeneratorFlag:[NoChange]
export default {
	path: 'pricing',
	name: 'store.pricing.layout',
	redirect: to => {
		return {name: 'store.pricing.list'};
	},
	component: () => import('@/modules/store/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/store/pricing"],
		resourceKey: 'store.pricingLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PricingCreate]"
		{
			path: 'create',
			name: 'store.pricing.create',
			component: () => import('@/modules/store/views/pricing/pricing-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.pricingCreate',
				clientAccessKeys:["/store/pricing/create"],
				serverAccessKeys:["/store/pricing/create","/store/pricing/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PricingCreateImport]"
		{
			path: 'create-import',
			name: 'store.pricing.createImport',
			component: () => import('@/modules/store/views/pricing/pricing-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.pricingCreateImport',
				clientAccessKeys:["/store/pricing/create-import"],
				serverAccessKeys:["/store/pricing/create","/store/pricing/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PricingDelete]"
		{
			path: 'delete',
			name: 'store.pricing.delete',
			component: () => import('@/modules/store/views/pricing/pricing-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.pricingDelete',
				clientAccessKeys:["/store/pricing/delete"],
				serverAccessKeys:["/store/pricing/delete","/store/pricing/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PricingDetail]"
		{
			path: 'detail',
			name: 'store.pricing.detail',
			component: () => import('@/modules/store/views/pricing/pricing-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.pricingDetail',
				clientAccessKeys:["/store/pricing/detail"],
				serverAccessKeys:["/store/pricing/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PricingList]"
		{
			path: 'list',
			name: 'store.pricing.list',
			component: () => import('@/modules/store/views/pricing/pricing-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.pricingList',
				clientAccessKeys:["/store/pricing/list"],
				serverAccessKeys:["/store/pricing/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PricingUpdate]"
		{
			path: 'update',
			name: 'store.pricing.update',
			component: () => import('@/modules/store/views/pricing/pricing-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.pricingUpdate',
				clientAccessKeys:["/store/pricing/update"],
				serverAccessKeys:["/store/pricing/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}