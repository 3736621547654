//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class RoomService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[Room.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/messenger/room/create', params || { });
	};

	//GeneratorFlag:[Room.Delete]
	async delete(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/messenger/room/delete', params || { });
	};

	//GeneratorFlag:[Room.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/messenger/room/detail', {'params':params});
	};

	//GeneratorFlag:[Room.Left]
	async left(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/messenger/room/left', params || { });
	};

	//GeneratorFlag:[Room.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/messenger/room/list', {'params':params});
	};

//GeneratorFlag:[NewAction]
}