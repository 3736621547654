//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class LetterService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[Letter.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/aut/letter/create', params || { });
	};

	//GeneratorFlag:[Letter.Delete]
	async delete(letterId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:letterId
		}
	
		return await _server.delete('/aut/letter/delete', {'params':params})
	};

	//GeneratorFlag:[Letter.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/aut/letter/detail', {'params':params});
	};

	//GeneratorFlag:[Letter.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/aut/letter/list', {'params':params});
	};

	//GeneratorFlag:[Letter.Archive]
	async archive(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/aut/letter/archive', params || { });
	};

	//GeneratorFlag:[Letter.MarkAsUnread]
	async markAsUnread(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/aut/letter/mark-as-unread', params || { });
	};

	//GeneratorFlag:[Letter.Notification]
	async notification(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/aut/letter/notification', {'params':params});
	};

	//GeneratorFlag:[Letter.Pin]
	async pin(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/aut/letter/pin', params || { });
	};

	//GeneratorFlag:[Letter.Read]
	async read(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/aut/letter/read', params || { });
	};

//GeneratorFlag:[NewAction]
}