<template>
	<i v-bind:class="className"
	   :style="{
       'font-size': size ,
       'color': color,
       'cursor':cursor,
	   'transform':transform
       }"></i>
</template>
<script>

	export default {
		props: {
			name: {
				type: String,
				default: ''
			},

			size: {
				type: String,
				default: '25px'
			},

			color: {
				type: String,
				default: 'inherit'
			},

			cursor: {
				type: String,
				default: 'inherit'
			},

			isFlip: {
				type: Boolean,
				default: false
			},

			transform: {
				type: String,
				default: 'unset'
			}
		},


		computed: {
			className()
			{
				var className = `bi ${this.name}`;
				if (this.isFlip)
				{
					className += ' kaveh-icon-bootstrap-flip'
				}

				return className;
			}
		}
	}
</script>
<style>
	.kaveh-icon-bootstrap-flip::before {
		transform: scaleX(-1)
	}
</style>
