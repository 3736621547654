<template>
	<kaveh-overlay :is-loading="isLoading" :is-empty="!isLoading && !activity" class="activity-list">
		<div class="row">
			<div class="col-12 mb-3">
				<div class="p-3 bg-white">
					<table class="table table-sm table-borderless">
						<tbody>
							<tr>
								<td style="width: 150px;">شناسه فعالیت</td>
								<td>{{activity.activityId}}</td>
							</tr>
							<tr>
								<td>وضعیت</td>
								<td>
									<small :style="{'color': activity.activityStatusHexColor}">
										{{ activity.activityStatusTitle }}
									</small>
								</td>
							</tr>
							<tr>
								<td>عنوان فرآیند</td>
								<td>{{ activity.processTitle }}</td>
							</tr>
							<tr>
								<td>آغازگر</td>
								<td>
									<span>{{activity.nodes[0].activistName}} <small class="text-muted"><i>[ {{ activity.nodes[0].activistRoleName }} ]</i></small></span>
								</td>
							</tr>
							<tr>
								<td>اولویت</td>
								<td>
									{{ activity.activityPriorityTitle }}
								</td>
							</tr>
							<tr>
								<td>تاریخ ثبت</td>
								<td>
									<small dir="ltr">{{ activity.localCreateDate }} - {{activity.createTime}}</small>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="col-12 col-lg-4" v-if="showTimeline">
				<div class="timeline-item float-start" v-for="(item, index) in activity.nodes" v-bind:class="{'active': selectedIndex == index }" @click="onHandleSelectNode(index)">
					<div class="date float-start">
						<div class="badge mb-1 text-bg-dark">{{item.orderNumber}}</div>
						<div><small>{{ getWordDate(item.localCreateDate) }}</small></div>
						<div><small>{{ item.createTime }}</small></div>
						<div class="badge mt-3 text-bg-light">{{item.activityActionTitle}}</div>
					</div>
					<div class="info float-start">
						<div>{{item.processNodeTitle}}</div>
						<div class="text-muted"><small>{{item.processNodeCaption}}</small></div>
						<div class="mt-2 text-end arrow" v-if="selectedIndex == index">
							<kaveh-icon-bootstrap color="var(--kaveh-base-color)" name="bi-arrow-left" />
						</div>
					</div>
				</div>
				<div class="timeline-item float-start" v-if="activity.nextNode" v-bind:class="{'active': selectedIndex == -1 }" @click="onHandleSelectNode(-1)">
					<div class="date float-start">
						<div class="spinner-grow spinner-grow-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</div>
					<div class="info float-start">
						<div>{{nextNode.processNodeTitle}}</div>
						<div class="text-muted"><small>{{nextNode.processNodeCaption}}</small></div>
						<div class="mt-2 text-end arrow" v-if="selectedIndex == -1">
							<kaveh-icon-bootstrap class="d-block" color="var(--kaveh-base-color)" name="bi-arrow-left" />
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 col-lg-8" :key="contentVersion" v-if="showContent">
				<div class="bg-white p-4">
					<div class="mb-2 text-center" @click="backToTimeLine" v-if="deviceType != 'desktop'">
						{{ $loc.fromResource('action.back') }}
					</div>
					<div v-if="selectedIndex == -1">

						<div class="mb-1" v-if="isLoadingSave">
							{{$loc.fromResource('aut.ext.activity.node.plaeseWait')}}
						</div>

						<div class="mb-2 mt-4" v-else>
							<div class="row justify-content-md-center row-cols-auto">
								<div class="col mb-2" v-if="nextNode.hasAccessToContinue">
									<button class="btn border btn-outline-primary" @click="createAction(2)">
										{{ nextNode.continueTitle || $loc.fromResource('aut.ext.activity.node.continue')}}
									</button>
								</div>
								<div class="col mb-2" v-if="nextNode.hasAccessToReturn && nextNode.returnNodes.length > 0">
									<button class="btn border me-2 btn-outline-warning" v-for="(processNodeId,i) in nextNode.returnNodes" :key="i" @click="createAction(3, processNodeId)">
										{{$loc.fromResource('aut.ext.activity.node.returnTo', {'label': getReturnNode(processNodeId).processNodeTitle})}}
									</button>
								</div>
								<div class="col mb-2" v-if="nextNode.hasAccessToComplete">
									<button class="btn border btn-outline-success" @click="createAction(4)">
										{{ nextNode.completeTitle || $loc.fromResource('aut.ext.activity.node.complete')}}
									</button>
								</div>
								<div class="col mb-2" v-if="nextNode.hasAccessToStop">
									<button class="btn border btn-outline-danger" @click="createAction(5)">
										{{ nextNode.stopTitle || $loc.fromResource('aut.ext.activity.node.stop')}}
									</button>
								</div>
							</div>
						</div>


						<div class="mb-1" v-if="formPattern">
							<kaveh-form :props="formPattern" ref="FormRef" :value="nextNode.oldFormValue" />
						</div>

						<div class="mb-1">
							<kaveh-text-editor ref="EditorRef" @change="(x) => { comment = x; }" />
						</div>

						<div class="mb-4">
							<kaveh-uploader ref="UploaderRef" />
						</div>
					</div>

					<div v-else>

						<table class="table">
							<tbody>
								<tr>
									<td>اقدامگر</td>
									<td>
										<kaveh-icon-bootstrap size="16px" class="me-2" name="bi-person-fill" />
										<span>{{node.activistName}} &nbsp; </span>
										<small><i class="text-muted">[ {{node.activistRoleName}} ]</i></small>
									</td>
								</tr>
								<tr v-if="node.comment">
									<td>توضیحات</td>
									<td>
										<div v-html="node.comment"></div>
									</td>
								</tr>
								<tr v-if="node.formPattern">
									<td>اطلاعات فرم</td>
									<td>
										<kaveh-list v-if="!node.isArrayFormValue" :props="node.formPattern" :data="node.formValue" />
										<kaveh-table v-else :props="node.formPattern" :data="node.formValue" :selectable="false" />
									</td>
								</tr>
								<tr v-if="node.attachments && node.attachments.length > 0">
									<td>پیوست ها</td>
									<td>
										<kaveh-button :is-loading="item.isLoading" class="btn p-2 me-2 mb-2 rounded bg-light pointer hover-shadow d-inline-block"
													  v-for="(item, index) in node.attachments" :key="index" @click="downloadFile(item)">
											{{ item.fileName }}
										</kaveh-button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</kaveh-overlay>
</template>
<script>
	import { url, dataUrl, persian, deviceType } from '@/plugins'
	import { StaticFileService } from '@/modules/asset/services'
	import { ActivityService, ActivityNodeService } from '@/modules/aut/services'

	export default {

		data()
		{
			return {
				isLoading: false,
				isLoadingSave: false,
				activity: null,
				selectedIndex: 0,
				formValue: null,
				comment: null,
				attachments: [],
				contentVersion: 1,
				showTimeline: true,
				showContent: false,
				deviceType: deviceType()
			}
		},

		beforeMount()
		{
			if (this.deviceType == 'desktop')
			{
				this.showContent = true;
			}

			this.init();
		},

		computed:
		{
			branchId()
			{
				return this.$store.state.client.branchId;
			},

			activityId()
			{
				return Number(url.decript(this.$route.query.key));
			},

			node()
			{
				if (this.activity)
				{
					const index = this.selectedIndex;
					const nodes = this.activity.nodes || [];

					if (index > -1 && nodes.length > 0)
					{
						return this.jsonParse({ ...nodes[index] });
					}
				}

				return undefined
			},

			nextNode()
			{
				if (this.activity)
				{
					const nextNode = { ...this.activity.nextNode };

					if (nextNode)
					{
						if (nextNode.returnNodes)
							nextNode.returnNodes = JSON.parse(nextNode.returnNodes);

						const oldNode =
							this.getReturnNode(nextNode.processNodeId);

						if (oldNode)
						{
							nextNode.oldFormValue = oldNode.formValue
						}

						return nextNode;
					}
				}

				return undefined
			},

			formPattern()
			{
				const nextNode = { ...this.nextNode }

				if (nextNode && nextNode.formPattern)
				{
					return JSON.parse(nextNode.formPattern)
				}

				return undefined
			},

			maxHeight()
			{
				return `${window.innerHeight - 200}px`;
			}
		},

		methods:
		{
			async init()
			{
				var activity = null;

				try
				{
					this.isLoading = true
					const res = await new ActivityService().state({
						branchId: this.branchId,
						activityId: this.activityId,
					}, { HSR: false });

					if (res.status == 200)
					{
						activity = res.data;
					}

				} catch (e) { console.log(e); }

				this.activity = activity;
				this.isLoading = false;

				this.initAttachments(0);
			},

			async initAttachments(index)
			{
				if (index > -1)
				{
					const node =
						this.activity.nodes[index];

					if (node.attachments == undefined)
					{
						var items = [];

						try
						{
							const service = new StaticFileService();
							const res = await service.list({
								start: 0,
								length: 1000,
								filter: {
									identifier: node.identifier
								}
							}, { HSR: false });

							if (res.status == 200)
								items = res.data.items || [];

						} catch (e) { console.log(e); }

						this.activity.nodes[index].attachments = items;
					}
				}
			},

			async downloadFile(item)
			{
				item.isLoading = true;
				this.$assetDownload(item.systemName, item.fileName).then(() =>
				{
					item.isLoading = false;
				})
			},

			async createAction(activityActionId, returnProcessNodeId)
			{

				var payload = {
					branchId: this.branchId,
					activityId: this.activityId,
					activityActionId: activityActionId,
					processNodeId: this.nextNode.processNodeId,
					returnProcessNodeId: returnProcessNodeId,
					staticFiles: []
				};

				if (this.$refs.FormRef != null)
				{
					const formValue =
						this.$refs.FormRef.getValue();

					if (formValue)
					{
						payload.formValue = JSON.stringify(formValue)
					} else return;
				}

				if (this.$refs.EditorRef)
				{
					payload.comment =
						this.$refs.EditorRef.getValue();
				}

				if (this.$refs.UploaderRef)
				{
					const attachments = this.$refs.UploaderRef.getValue();
					for (var i = 0; i < attachments.length; i++)
					{
						payload.staticFiles.push({
							fileName: attachments[i].name,
							dataUrl: attachments[i].dataUrl
						})
					}
				}

				try
				{
					this.isLoadingSave = true
					const res = await new ActivityNodeService().create(payload);

					if (res.status == 200)
					{
						this.$router.push({
							name: 'aut.activity.list',
						});
					}

				} catch (e) { console.log(e); }

				this.isLoadingSave = false;
			},

			getReturnNode(processNodeId)
			{
				var nodes = (this.activity.nodes || []).filter((x) =>
				{
					return x.processNodeId == processNodeId
				});

				if (nodes.length > 0)
				{
					return this.jsonParse(nodes[nodes.length - 1])
				}

				return undefined
			},

			onHandleSelectNode(index)
			{
				if (this.deviceType != 'desktop')
				{
					this.showTimeline = false;
					this.showContent = true;
				}

				this.selectedIndex = index;
				this.contentVersion = this.contentVersion + 1;

				this.initAttachments(index)
			},

			backToTimeLine()
			{
				this.showContent = false;
				this.showTimeline = true;
			},

			getWordDate(date)
			{
				return persian.word(date)
			},

			jsonParse(node)
			{
				if (!node.jsonParsed)
				{
					if (node.formPattern)
						node.formPattern = JSON.parse(node.formPattern);

					if (node.formValue)
						node.formValue = JSON.parse(node.formValue);

					if (node.returnNodes)
						node.returnNodes = JSON.parse(node.returnNodes);

					node.jsonParsed = true
				}

				return node;
			}
		}
	}
</script>
<style>

	.activity-list .timeline-item {
		width: 100%;
		min-height: 120px;
		height: auto;
		padding: 15px;
		margin-bottom: 10px;
		background: #fff;
		border: #fff dashed 2px;
		position: relative;
	}

		.activity-list .timeline-item * {
			cursor: pointer;
		}

		.activity-list .timeline-item:hover {
			background: #f8f8f8;
		}

		.activity-list .timeline-item.active {
			background: #f8f8f8;
			border: var(--kaveh-base-color) dashed 2px;
		}


			.activity-list .timeline-item.active .date i {
				color: var(--kaveh-base-color) !important;
			}

		.activity-list .timeline-item .date {
			width: 140px;
		}

		.activity-list .timeline-item .info {
			width: calc(100% - 140px);
		}

		.activity-list .timeline-item::after {
			content: "";
			position: absolute;
			width: 5px;
			background:#ddd;
			border-radius:5px;
			height: 20px;
			bottom: -17px;
			right: 10px;
			z-index: 1;
		}

		.activity-list .timeline-item:last-child::after {
			content: unset;
		}

	.activity-list .timeline-item .arrow {
		position: absolute;
		bottom: 0;
		left: 10px;
	}


	[dir="ltr"] .activity-list .timeline-item::after {
		right:unset;
		left: 10px;
	}

	[dir="ltr"] .activity-list .timeline-item .arrow {
		left: unset;
		right:10px;
		transform:rotate(180deg);
	}
</style>
