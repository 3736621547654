export default {
	"accessAgent": {
		"personRoleTab": "اختصاص دسترسی به نقش های کاربری",
		"personTab": "اختصاص دسترسی به کاربران",
	},


	"branch": {

		"list": {
			"showInOrd": "نمایش به صورت نموداری",
			"showInTree": "نمایش به صورت زیرمجوعه ای"
		},

		"create": {
			"officeName": "عنوان نمایندگی",
			"unitName": "عنوان واحد سازمانی",
			"positionName": "عنوان جایگاه سازمانی",

			"officeNode": "افزودن نمایندگی",
			"unitNode": "افزودن واحد سازمانی",
			"positionNode": "افزودن جایگاه سازمانی",
			"personNode": "تخصیص پرسنل"
		},

		"tree": {
			"positionSelect": "انتخاب پرسنل",
			"officeSelect": "انتخاب سازمان / واحد",
		},

		"choose": {
			"head": "جهت فعالیت در اتوماسوین لطفا جایگاه سازمانی مورد نظر خود را انتخاب نمایید.",
			"empty": "هیچ جایگاه سازمانی برای شما یافت نشد.",
			"warning": "دقت فرمایید بعد از انتخاب و یا تغییر جایگاه سازمانی، تمامی نامه ها و اقدامات سازمانی شما با جایگاه جدید ثبت خواهد شد.",
			"selected": "فعال",
			"accessDenied": "شما به این بخش دسترسی ندارید، ابتدا باید جایگاه سازمانی شما مشخص شود. با استفاده از دکمه زیر، جایگاه سازمانی خود را انتخاب نمایید.",
			"noFound": "هیچ جایگاه سازمانی برای شما تعریف نشده است، لطفا با مسئول اتوماسیون سازمان خود هماهنگ بفرمایید."
		},

		"views": {
			"choose": "انتخاب جایگاه سازمانی",
			"accessDenied": "عدم دسترسی",
			"position": "تخصیص جایگاه به پرسنل",
			"copyPosition": "کپی پوزیشن های فعال به جایگاه دیگر",
			"move": "انقال"
		},

		"position": {
			"mode": {
				"create": "تخصیص پرسنل جدید",
				"update": "ویرایش جایگاه پرسنل {label}"
			},
			"create": {
				"title": "اختصاص پرسنل به جایگاه {label}",
			},
			"copy": {
				"title": "کپی پرسنل از جایگاه {label}",
				"treeTitle": "جهت کپی پرسنل سازمانی لطفا جایگاه مقصد را انتخاب نمایید",
				"listTitle": "لیست پرسنل های فعلی درون جایگاه مبدا",
				"errors": {
					"error1": "لطفا یک جایگاه را انتخاب نمایید",
					"error2": "جایگاه مقصد با مبدا برابر است و امکان کپی وجود ندارد",
					}
			}
		},

		"move": {
			"errors": {
				"error1":"شعبه یا نمایندگی باید زیرمجموعه یک جایگاه سازمانی قرار گیرد.",
				"error2":"واحد سازمانی باید زیرمجموعه یک جایگاه یا شعبه قرار گیرد.",
				"error3": "جایگاه سازمانی باید زیر مجموعه یک واحد یا جایگاه دیگر قرار گیرد."
			}
		}

	},

	"person": {
		"changePassword": {
			"invalidCurrentPassword": "کلمه عبور فعلی اشتباه است",
			"fcp": 'کاربر گرامی! <br/><br/> شما با کلمه عبور پیشفرض وارد سیستم شده اید.<br/> جهت حفظ امنیت حساب کاربری، ابتدا باید کلمه عبور خود را تغییر دهید.'
		},

		"createReal": "شخص حقیقی",
		"createLegal": "شخص حقوقی",
		"createSystem": "کاربر سیستمی/ ربات"
	}

}
