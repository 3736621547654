//GeneratorFlag:[NoChange]
export default {
	path: 'notice-pattern',
	name: 'notification.noticePattern.layout',
	redirect: to => {
		return {name: 'notification.noticePattern.list'};
	},
	component: () => import('@/modules/notification/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/notification/notice-pattern"],
		resourceKey: 'notification.noticePatternLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[NoticePatternCreate]"
		{
			path: 'create',
			name: 'notification.noticePattern.create',
			component: () => import('@/modules/notification/views/notice-pattern/notice-pattern-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'notification.noticePatternCreate',
				clientAccessKeys:["/notification/notice-pattern/create"],
				serverAccessKeys:["/notification/notice-pattern/create","/notification/notice-pattern/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NoticePatternCreateImport]"
		{
			path: 'create-import',
			name: 'notification.noticePattern.createImport',
			component: () => import('@/modules/notification/views/notice-pattern/notice-pattern-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'notification.noticePatternCreateImport',
				clientAccessKeys:["/notification/notice-pattern/create-import"],
				serverAccessKeys:["/notification/notice-pattern/create","/notification/notice-pattern/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NoticePatternDelete]"
		{
			path: 'delete',
			name: 'notification.noticePattern.delete',
			component: () => import('@/modules/notification/views/notice-pattern/notice-pattern-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'notification.noticePatternDelete',
				clientAccessKeys:["/notification/notice-pattern/delete"],
				serverAccessKeys:["/notification/notice-pattern/delete","/notification/notice-pattern/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NoticePatternDetail]"
		{
			path: 'detail',
			name: 'notification.noticePattern.detail',
			component: () => import('@/modules/notification/views/notice-pattern/notice-pattern-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'notification.noticePatternDetail',
				clientAccessKeys:["/notification/notice-pattern/detail"],
				serverAccessKeys:["/notification/notice-pattern/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NoticePatternList]"
		{
			path: 'list',
			name: 'notification.noticePattern.list',
			component: () => import('@/modules/notification/views/notice-pattern/notice-pattern-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'notification.noticePatternList',
				clientAccessKeys:["/notification/notice-pattern/list"],
				serverAccessKeys:["/notification/notice-pattern/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NoticePatternUpdate]"
		{
			path: 'update',
			name: 'notification.noticePattern.update',
			component: () => import('@/modules/notification/views/notice-pattern/notice-pattern-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'notification.noticePatternUpdate',
				clientAccessKeys:["/notification/notice-pattern/update"],
				serverAccessKeys:["/notification/notice-pattern/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}