import qs from 'qs';
import axios from 'axios'
import { toast } from '@/plugins'
import store from '@/core/store'
import ServerUtility from './server-utility'
import Ws from './ws'

export default class Server
{
	constructor(config)
	{
		this.gateway = 'public';
		this.config = config || {};

		if (!config.useHttp && store.state.server.socket != null)
		{
			this.initSocket()
		}
		else
		{
			this.initAxios()
		}
	}

	initSocket()
	{
		const invoke = (path, payload) => new Promise((resolve, reject) =>
		{
			const ws = new Ws();
			const utility = new ServerUtility();

			ws.getConnection(path).then((connection) =>
			{
				const success = (data) =>
				{
					if (this.config.HSR)
					{
						this.handelSuccessResponse({
							status: 200, data: data
						});
					}

					resolve({
						status: 200, data: data
					});
				}

				const error = (data) =>
				{
					if (this.config.HER)
					{
						this.handleErrorResponse(data);
					}

					reject(data)
				}

				const methodName =
					utility.pathToMethod(path);

				if (payload)
				{
					connection.invoke(methodName, payload).
						then(success).catch(error);
				}
				else
				{
					connection.invoke(methodName).
						then(success).catch(error);
				}

			}).catch((e) =>
			{
				if (this.config.HER)
				{
					this.handleErrorResponse(e);
				}

				reject(e)
			})
		})


		this.get = (path, payload) =>
		{
			if (payload.params)
			{
				return invoke(path, payload.params)
			}

			return invoke(path)
		};

		this.post = (path, payload) =>
		{
			return invoke(path, payload)
		};

		this.delete = (path, payload) =>
		{
			if (payload.params)
			{
				if (payload.params.id)
				{
					return invoke(path, payload.params.id)
				}
				else
				{
					return invoke(path, payload.params)
				}
			}

			return invoke(path)
		};;
	}

	initAxios()
	{
		const request = (path) =>
		{
			const utility = new ServerUtility()
			const serverConfig = utility.getServerConfig(path)

			const server = axios.create({
				baseURL: serverConfig.host
			});


			server.interceptors.request.use((config) =>
			{
				if (this.config.headers)
				{
					config.headers = {
						...config.headers,
						...this.config.headers
					}
				}

				if (store.state.client.branchId)
				{
					config.headers['branch-id'] = store.state.client.branchId;
				}

				config.headers['Authorization'] = `Bearer ${store.state.client.token}`;
				config.paramsSerializer = {
					serialize: (params) =>
					{
						return qs.stringify(params, { arrayFormat: 'repeat', allowDots: true, })
					}
				}

				return config;

			}, (error) => { return Promise.reject(error) });


			server.interceptors.response.use((response) =>
			{

				if (this.config.HSR)
				{
					this.handelSuccessResponse(response);
				}

				return response;

			}, (error) =>
			{

				if (this.config.HER)
				{
					this.handleErrorResponse(error);
				}

				return Promise.reject(error)
			});

			return server;
		}


		this.get = (path, data) =>
		{
			return request(path).get(path, data)
		}

		this.post = (path, data) =>
		{
			return request(path).post(path, data)
		}

		this.delete = (path, data) =>
		{
			return request(path).delete(path, data)
		}
	}

	handelSuccessResponse()
	{
		toast.success('status.ok');
	}

	handleErrorResponse(error)
	{
		new ServerUtility().showErrorMessage(error)
	};
}
