import discountRoute from './discount-route'; //GeneratorFlag:[Discount]
import featureRoute from './feature-route'; //GeneratorFlag:[Feature]
import pricingRoute from './pricing-route'; //GeneratorFlag:[Pricing]
import productRoute from './product-route'; //GeneratorFlag:[Product]
import basisDeliveryDateTypeRoute from './basis/delivery-date-type-route'; //GeneratorFlag:[BasisDeliveryDateType]
import basisFeatureGroupRoute from './basis/feature-group-route'; //GeneratorFlag:[BasisFeatureGroup]
import basisProducerRoute from './basis/producer-route'; //GeneratorFlag:[BasisProducer]
import basisProductGroupRoute from './basis/product-group-route'; //GeneratorFlag:[BasisProductGroup]
import basisWarehouseRoute from './basis/warehouse-route'; //GeneratorFlag:[BasisWarehouse]
//GeneratorFlag:[NewImport]
const routes = [{
	path: 'store',
	name: 'store',
	component: () => import('@/modules/store/views/layout.vue'),
	meta: {
		requiresAuth: true,
		clientAccessKeys:['/store'],
		serverAccessKeys:[],
		resourceKey: 'service.store',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-shop',
		}
	},
	children: [
		productRoute, pricingRoute, featureRoute, discountRoute, //GeneratorFlag:[NewChild]

		//GeneratorFlag:[BasisLayout]
		{
			path: 'basis',
			name: 'store.basis',
			component: () => import('@/modules/store/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.layout.basis',
				clientAccessKeys:['/store/basis'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-database-fill-gear',
				}
			},
			children: [
			basisDeliveryDateTypeRoute, basisFeatureGroupRoute, basisProducerRoute, basisProductGroupRoute, basisWarehouseRoute, //GeneratorFlag:[NewBasisChild]
			]
		},
//GeneratorFlag:[NewLayout]
	]
}]
export default routes
export{
discountRoute, featureRoute, pricingRoute, productRoute, basisDeliveryDateTypeRoute, basisFeatureGroupRoute, basisProducerRoute, basisProductGroupRoute, basisWarehouseRoute, //GeneratorFlag:[NewExport]
}
