//GeneratorFlag:[NoChange]
import ext from './fa-ext.js'
export default {
	"name":"cms",
	"ext": ext,
	"layout":{
		"basis": "اطلاعات پایه", //GeneratorFlag:[BasisLayout]
//GeneratorFlag:[NewLayout]
	},
	'blogCategory':'دسته بندی وبلاگ',
	'blogCategoryBlogCategoryId':'شناسه',
	'blogCategoryBlogCategoryTrack':'اطلاعات رهگیری ثبت',
	'blogCategoryCaption':'توضیحات',
	'blogCategoryCreate':'ثبت دسته بندی وبلاگ جدید',
	'blogCategoryCreateImport':'ثبت  دسته بندی های وبلاگ از طریق اکسل',
	'blogCategoryDelete':'حذف دسته بندی وبلاگ',
	'blogCategoryDetail':'جزئیات اطلاعات دسته بندی وبلاگ',
	'blogCategoryIdentifier':'کلید یکتا',
	'blogCategoryImageAttachment':'پیوست تصویر',
	'blogCategoryLayout':'  دسته بندی های وبلاگ',
	'blogCategoryList':'فهرست  دسته بندی های وبلاگ',
	'blogCategoryParentId':'والد',
	'blogCategoryStringContent':'توضیحات کامل',
	'blogCategoryTitle':'عنوان',
	'blogCategoryUpdate':'ویرایش دسته بندی وبلاگ',
	'blogContent':'مطلب وبلاگ',
	'blogContentBlogCategory':'دسته بندی',
	'blogContentBlogCategoryId':'دسته بندی',
	'blogContentBlogContentId':'شناسه',
	'blogContentBlogContentTrack':'اطلاعات رهگیری ثبت',
	'blogContentCaption':'توضیح کوتاه',
	'blogContentCreate':'ثبت مطلب وبلاگ جدید',
	'blogContentCreateImport':'ثبت مطالب وبلاگ از طریق اکسل',
	'blogContentDelete':'حذف مطلب وبلاگ',
	'blogContentDetail':'جزئیات اطلاعات مطلب وبلاگ',
	'blogContentIdentifier':'کلید یکتا',
	'blogContentImageAttachment':'پیوست تصویر',
	'blogContentIsActive':'آیا فعال است؟',
	'blogContentKeywords':'کلمات کلیدی سئو',
	'blogContentLayout':'مطالب وبلاگ',
	'blogContentList':'فهرست مطالب وبلاگ',
	'blogContentSlug':'کلید لینک',
	'blogContentStringContent':'متن کامل',
	'blogContentTitle':'عنوان',
	'blogContentUpdate':'ویرایش مطلب وبلاگ',
	'staticContent':'محتوای ثابت',
	'staticContentAttachment':'پیوست فایل',
	'staticContentContentKey':'کلید فراخوانی',
	'staticContentCreate':'ثبت محتوای ثابت جدید',
	'staticContentCreateImport':'ثبت محتواهای ثابت از طریق اکسل',
	'staticContentDelete':'حذف محتوای ثابت',
	'staticContentDetail':'جزئیات اطلاعات محتوای ثابت',
	'staticContentIdentifier':'کلید یکتا',
	'staticContentLayout':'محتواهای ثابت',
	'staticContentList':'فهرست محتواهای ثابت',
	'staticContentOrderNumber':'ترتیب نمایش',
	'staticContentStaticContentId':'شناسه',
	'staticContentStaticContentTrack':'اطلاعات رهگیری ثبت',
	'staticContentStringContent':'محتوا',
	'staticContentTitle':'عنوان',
	'staticContentUpdate':'ویرایش محتوای ثابت',
	'staticPage':'صفحه',
	'staticPageCaption':'توضیح کوتاه',
	'staticPageCreate':'ثبت صفحه جدید',
	'staticPageCreateImport':'ثبت صفحات ثابت از طریق اکسل',
	'staticPageDelete':'حذف صفحه',
	'staticPageDetail':'جزئیات اطلاعات صفحه',
	'staticPageIdentifier':'کلید یکتا',
	'staticPageImageAttachment':'پیوست تصویر',
	'staticPageIsActive':'آیا فعال است؟',
	'staticPageKeywords':'کلمات کلیدی سئو',
	'staticPageLayout':'صفحات ثابت',
	'staticPageList':'فهرست صفحات ثابت',
	'staticPageSlug':'کلید لینک',
	'staticPageStaticPageId':'شناسه',
	'staticPageStaticPageTrack':'اطلاعات رهگیری ثبت',
	'staticPageStringContent':'متن کامل',
	'staticPageTitle':'عنوان',
	'staticPageUpdate':'ویرایش صفحه',

}