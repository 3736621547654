export default {
	"enums": {
		"letterCategory": {
			"all": "همه نامه ها",
			"public": "عمومی",
			"mailroom":"دبیرخانه",
			"toAct": "جهت اقدام",
			"toRemind": "جهت یادآوری",
		},
		"activityCategory": {
			"public": "عمومی",
		}
	},

	"letter": {

		"compose": {
			"step1": "انتخاب گیرندگان نامه",
			"step2": "اطلاعات اولیه",
			"step3": "محتوای نامه",
			"step4": "فایل های پیوست",
			"step5": "تایید و ارسال",
			"prevStep": "مرحله قبل",
			"nextStep": "مرحله بعد",
			"finalStep": "تایید نهایی و ارسال نامه",
			"loading": "لطفا کمی صبر کنید سیستم در حال ارسال نامه است ...",

			"step1Error": "هیچ گیرنده ای برای نامه انتخاب نشده است!",
			"step2Error": "لطفا ابتدا خطاهای فرم را برطرف نمایید.",
			"step3Error": "لطفا محتوای نامه را وارد نمایید.",
			"duplicateError": "{label} قبلا انتخاب شده است.",

			"receivers": {
				"primaryList": "گیرندگان نامه",
				"ccList": "گیرندگان رونوشت",
				"bccList": "گیرندگان رونوشت مخفی",
				"emptyList": "هیچ گیرنده ای انتخاب نشده است.",
			},

			"preview": {
				"primaryList": "این نامه از طرف شما ارسال خواهد شد به",
				"ccList": "رونوشت به",
				"bccList": "رونوشت مخفی به",
				"attachments": "پیوست ها",
				"showOlder" : "مشاهده بیشتر"
			}
		},


		"reply": {
			"step1": "پیشنمایش",
			"step2": "محتوای پاسخ",
			"step3": "فایل های پیوست",
			"step4": "تایید و ارسال",
			"prevStep": "مرحله قبل",
			"nextStep": "مرحله بعد",
			"finalStep": "تایید نهایی و ارسال پاسخ",
			"loading": "لطفا کمی صبر کنید سیستم در حال ارسال نامه است ...",
			"to": "پاسخ به",
			"step2Error": "لطفا محتوای پاسخ را وارد نمایید.",

			"preview": {
				"primaryList": "این پاسخ از طرف شما ارسال خواهد شد به",
				"attachments": "پیوست ها",
			}
		},

		"forward": {
			"step1": "پیشنمایش",
			"step2": "انتخاب گیرنده",
			"step3": "پاراف",
			"step4": "فایل های پیوست",
			"step5": "تایید و ارسال",
			"prevStep": "مرحله قبل",
			"nextStep": "مرحله بعد",
			"finalStep": "تایید نهایی و ارجاع",
			"loading": "لطفا کمی صبر کنید سیستم در حال ارجاع نامه است ...",
			"step2Error": "هیچ گیرنده ای برای ارجاع نامه انتخاب نشده است!",
			"step3Error": "لطفا پاراف ارجاع را وارد نمایید.",

			"receivers": {
				"primary": "گیرنده ارجاع",
				"emptyList": "هیچ گیرنده ای انتخاب نشده است.",
			},

			"preview": {
				"primaryList": "این نامه از طرف شما ارجاع خواهد شد به",
				"attachments": "پیوست ها",
			}
		},

		"reader": {
			"sender": "<span>این {label} از طرف {personFullName} <small class='text-muted'>{branchName}</small> ارسال شده است به</span>",
			"attachments": "پیوست ها"
		},

		"list": {
			"empty": "هیچ نامه ای یافت نشد!"
		},

		"tag": {
			"self":"برچسب ها",
			"noTag": "همه برچسب ها"
		},

		"letterCategory": {
			"self": "دسته بندی ها",
		},

		"actions": {
			"reply": "پاسخ",
			"forward": "ارجاع",
			"archive": "آرشیو",
			"pin": "سنجاق",
			"unpin": "حذف سنجاق",
			"markAsUnread": "علامت به عنوان خوانده نشده",
		},

		"views": {
			"self": "صفحات",
			"layout": "مدیریت نامه ها",
			"list": "لیست نامه ها",
			"management": "لیست نامه ها",
			"inbox": "نامه های دریافتی",
			"outbox": "نامه های ارسالی",
			"drafts": "نامه های پیشنویس شده",
			"archived": "نامه های آرشیو شده",
			"compose": "ارسال نامه جدید",
			"reply": "ارسال پاسخ",
			"forward": "ارجاع نامه",
			"reader": "مشاهده نامه"
		}
	},

	"process": {
		"nodeManagement": "مدیریت چرخه کار",
		"noFound": "تا کنون هیچ فرآیندی در سیستم تعریف نشده است، جهت تعریف وظایف ابتدا باید فرآیندهای سیستم را تعریف بفرمایید."
	},

	"processNode": {
		"management": {
			"info": "جهت مدیریت وظایف در فرآیندها، لطفا فرآیند مورد نظر خود را انتخاب نمایید. در صورتی که فرآیند مورد نظر شما در این بخش وجود ندارد میتوانید از بخش مدیریت فرآیندها آن را تعریف نمایید.",
		},
		"event": {
			"serviceInfo":"اطلاعات سرویس",
			"loginServiceInfo":"اطلاعات سرویس لاگین",
			"address":"آدرس سرویس",
			"method":"نوع متد",
			"contentType": "نوع محتوا",
			"properties": "فیلدها",
			"tokenPropertyName":"فیلد بازگشتی توکن"
		},
		"worker": {
			"emailDestination":"آدرس ایمیل اقدامگر",
			"properties": "فیلدهای سرویس اقدامگر"
		},
		"returnPh": "عنوان دکمه بازگشت",
		"continuePh": "عنوان دکمه ادامه",
		"stopPh": "عنوان دکمه توقف",
		"completePh": "عنوان دکمه تکمیل",
		"formInfo": "اطلاعات فرم",
		"eventInfo": "اطلاعات رویدادها",
		"formPreview": "پیش نمایش فرم",
		"formManagement": "مدیریت فرم",
		"eventManagement": "مدیریت رویداد",
		"levelName": "سطح {label}",
		"createLevel": "ایجاد سطح جدید",
		"selectEventAlert":"لطفا رویداد مد نظر خود را انتخاب نمایید",
		"noFound": "تا کنون هیچ فرآیندی در سیستم تعریف نشده است، جهت تعریف وظایف ابتدا باید فرآیندهای سیستم را تعریف بفرمایید."
	},


	"activity": {

		"compose": {
			"step1": "انتخاب فرآیند",
			"step2": "اطلاعات درخواست",
			"step3": "فایل های پیوست",
			"step4": "تایید و ارسال",
			"prevStep": "مرحله قبل",
			"nextStep": "مرحله بعد",
			"finalStep": "تایید نهایی و آغاز فرآیند",
			"loading": "لطفا کمی صبر کنید سیستم در حال آغاز فرآیند است ...",

			"step1Error": "هیچ فرآیند ای انتخاب نشده است!",
			"step2Error": "لطفا ابتدا خطاهای فرم را برطرف نمایید.",

			"preview": {
				"processName": "نام فرآیند",
				"customForm": "اطلاعات فرم",
				"comment": "توضیحات درخواست",
				"attachments": "پیوست ها",
				"deadlineHour": "ددلاین {label} ساعت"
			}
		},

		"views": {
			"self":"صفحات",
			"compose": "آغاز فرآیند جدید",
			"ongoing": "فعالیت های در دست اقدام",
			"currents": "فعالیت های جاری",
			"myActivities":"فعالیت های من",
			"completed": "فعالیت های تکمیل شده",
			"stoped": "فعالیت های متوقف شده"
		},

		"priority": {
			"self":"اولویت ها",
			"allPriority": "همه اولویت ها"
		},


		"category": {
			"self": "دسته بندی ها",
		},


		"list": {
			"empty": "هیچ فعالیتی یافت نشد!",

			"newTitle": "آغاز",
			"returnTitle": "بازگشت",
			"continueTitle": "ادامه",
			"stopTitle": "توقف",
			"completeTitle": "تکمیل",

			"caption": "{action} توسط {activist}",
			"showDetail": "مشاهده جزئیات"
		},

		"node": {
			"localCreateDate": "تاریخ",
			"createTime": "ساعت",
			"dateTime":"تاریخ و ساعت",
			"processNodeTitle": "عنوان اقدام",
			"processNodeCaption": "توضیحات تکمیلی اقدام",
			"comment": "توضیحات",
			"employee": "اقدامگر",
			"attachments": "پیوست ها",

			"continue":"ادامه فرآیند",
			"complete":"تکمیل فرآیند",
			"return":"برگشت به قبل ",
			"returnTo":"برگشت به {label} ",
			"stop": "توقف فرآیند",
			"plaeseWait":"لطفا کمی صبر کنید، سیستم در حال ثبت اقدام است ..."
		}
	}
}
