//GeneratorFlag:[NoChange]
export default {
	path: 'blog-category',
	name: 'cms.basis.blogCategory.layout',
	redirect: to => {
		return {name: 'cms.blogCategory.list'};
	},
	component: () => import('@/modules/cms/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/cms/basis/blog-category"],
		resourceKey: 'cms.blogCategoryLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[BlogCategoryCreate]"
		{
			path: 'create',
			name: 'cms.blogCategory.create',
			component: () => import('@/modules/cms/views/blog-category/blog-category-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.blogCategoryCreate',
				clientAccessKeys:["/cms/basis/blog-category/create"],
				serverAccessKeys:["/cms/blog-category/create","/cms/blog-category/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BlogCategoryCreateImport]"
		{
			path: 'create-import',
			name: 'cms.blogCategory.createImport',
			component: () => import('@/modules/cms/views/blog-category/blog-category-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.blogCategoryCreateImport',
				clientAccessKeys:["/cms/basis/blog-category/create-import"],
				serverAccessKeys:["/cms/blog-category/create","/cms/blog-category/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BlogCategoryDelete]"
		{
			path: 'delete',
			name: 'cms.blogCategory.delete',
			component: () => import('@/modules/cms/views/blog-category/blog-category-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.blogCategoryDelete',
				clientAccessKeys:["/cms/basis/blog-category/delete"],
				serverAccessKeys:["/cms/blog-category/delete","/cms/blog-category/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BlogCategoryDetail]"
		{
			path: 'detail',
			name: 'cms.blogCategory.detail',
			component: () => import('@/modules/cms/views/blog-category/blog-category-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.blogCategoryDetail',
				clientAccessKeys:["/cms/basis/blog-category/detail"],
				serverAccessKeys:["/cms/blog-category/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BlogCategoryList]"
		{
			path: 'list',
			name: 'cms.blogCategory.list',
			component: () => import('@/modules/cms/views/blog-category/blog-category-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.blogCategoryList',
				clientAccessKeys:["/cms/basis/blog-category/list"],
				serverAccessKeys:["/cms/blog-category/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BlogCategoryImageAttachment]"
		{
			path: 'image-attachment',
			name: 'cms.blogCategory.imageAttachment',
			component: () => import('@/modules/cms/views/blog-category/blog-category-image-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.blogCategoryImageAttachment',
				clientAccessKeys:["/cms/basis/blog-category/image-attachment"],
				serverAccessKeys:["/cms/blog-category/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BlogCategoryUpdate]"
		{
			path: 'update',
			name: 'cms.blogCategory.update',
			component: () => import('@/modules/cms/views/blog-category/blog-category-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.blogCategoryUpdate',
				clientAccessKeys:["/cms/basis/blog-category/update"],
				serverAccessKeys:["/cms/blog-category/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}