<template>
	<div class="bg-light p-3">
		<div class="d-block">
			<strong class="text-danger" v-if="failedTasks.length > 0">
				{{ $loc.fromResource('com.task.tasksCompletedWithError') }}
			</strong>
			<strong v-else>
				{{ $loc.fromResource('com.task.tasksCompletedSuccess') }}
			</strong>
		</div>
		<div class="d-block bg-white p-3 mt-3" v-if="failedTasks.length > 0">
			<label class="d-block mb-1">
				{{ $loc.fromResource('com.task.tasksSucceedLenght') }} : {{succeedTasks.length }}
			</label>
			<label class="d-block">
				{{ $loc.fromResource('com.task.tasksFailedLenght') }} : {{ failedTasks.length }}
			</label>
			<div class="table-responsive" style="height:300px">
				<table class="table table-striped mt-3">
					<thead>
						<tr>
							<th>{{ $loc.fromResource('com.task.excelRowIndex') }}</th>
							<th>{{ $loc.fromResource('com.task.errorDetail') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in  failedTasks" :key="index">
							<td>{{ item.index + 3 }}</td>
							<td>{{ getDatail(item.error) }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="mt-3">
			<button type="button" @click="$emit('close')" class="btn btn-light border">
				{{ $loc.fromResource('action.back') }}
			</button>
		</div>
	</div>
</template>

<script>
	import { ServerUtility } from '@/core/server'

	export default {
		emits: ['close'],
		props: {
			'total': {
				type: Number,
				default: 0,
			},

			'succeedTasks': {
				type: Array
			},

			'failedTasks': {
				type: Array
			},
		},

		methods: {
			getDatail(item)
			{
				const { detail } =
					new ServerUtility().getParsedHttpResponse(item);

				return detail;
			}
		}
	}

</script>
