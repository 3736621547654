<template>
	<div class="letter-compose" v-if="branchId > 0">

		<div class="steps bg-light pt-4 pb-4 ps-3 pe-3 mb-2">
			<div class="row">
				<div class="col step" v-bind:class="{'active': step >= 1}">
					<div class="circle-wrapper">
						<div class="circle">
							<kaveh-icon-bootstrap cursor="default" name="bi-people-fill" size="20px" />
						</div>
					</div>
					<div class="d-none d-lg-block">{{$loc.fromResource('aut.ext.letter.compose.step1')}}</div>
				</div>
				<div class="col step" v-bind:class="{'active': step >= 2}">
					<div class="circle-wrapper">
						<div class="circle">
							<kaveh-icon-bootstrap cursor="default" name="bi-pencil-fill" size="20px" />
						</div>
					</div>
					<div class="d-none d-lg-block">{{$loc.fromResource('aut.ext.letter.compose.step2')}}</div>
				</div>
				<div class="col step" v-bind:class="{'active': step >= 3}">
					<div class="circle-wrapper">
						<div class="circle">
							<kaveh-icon-bootstrap cursor="default" name="bi-file-earmark-text-fill" size="20px" />
						</div>
					</div>
					<div class="d-none d-lg-block">{{$loc.fromResource('aut.ext.letter.compose.step3')}}</div>
				</div>
				<div class="col step" v-bind:class="{'active': step >= 4}">
					<div class="circle-wrapper">
						<div class="circle">
							<kaveh-icon-bootstrap cursor="default" name="bi-paperclip" size="20px" />
						</div>
					</div>
					<div class="d-none d-lg-block">{{$loc.fromResource('aut.ext.letter.compose.step4')}}</div>
				</div>
				<div class="col step" v-bind:class="{'active': step == 5}">
					<div class="circle-wrapper">
						<div class="circle">
							<kaveh-icon-bootstrap cursor="default" name="bi-send-check-fill" size="20px" />
						</div>
					</div>
					<div class="d-none d-lg-block">{{$loc.fromResource('aut.ext.letter.compose.step5')}}</div>
				</div>
			</div>
		</div>

		<div class="content bg-light p-3">

			<letter-compose-step1 :class="{'d-none': step != 1}" ref="ReceiversRef" />
			<letter-compose-step2 :class="{'d-none': step != 2}" ref="BasicInfoRef" />
			<letter-compose-step3 :class="{'d-none': step != 3}" ref="BodyRef" />
			<letter-compose-step4 :class="{'d-none': step != 4}" ref="AttachmentRef" />
			<letter-compose-step5 :class="{'d-none': step != 5}" ref="ConfirmRef" />

			<div class="mt-3 p-3 text-center bg-white" v-if="loading">
				<div class="spinner-border spinner-border-sm me-2" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
				<span>{{$loc.fromResource('aut.ext.letter.compose.loading')}}</span>
			</div>
			<div class="mt-3 p-3 text-center bg-white" v-else>
				<div class="text-danger pb-4" v-if="error != ''">
					<small>{{ error }}</small>
				</div>
				<button @click="prev" class="btn btn-light border me-3 ps-5 pe-5" v-bind:disabled="step == 1">
					{{$loc.fromResource('aut.ext.letter.compose.prevStep')}}
				</button>
				<button @click="next" class="btn btn-light border me-3 ps-5 pe-5">
					<span v-if="step < 5">{{$loc.fromResource('aut.ext.letter.compose.nextStep')}}</span>
					<span v-else>{{$loc.fromResource('aut.ext.letter.compose.finalStep')}}</span>
				</button>
			</div>

		</div>

	</div>
</template>

<script>

	import { LetterService } from '@/modules/aut/services'
	import LetterComposeStep1 from './letter-compose-step1'
	import LetterComposeStep2 from './letter-compose-step2'
	import LetterComposeStep3 from './letter-compose-step3'
	import LetterComposeStep4 from './letter-compose-step4'
	import LetterComposeStep5 from './letter-compose-step5'

	export default {
		emits:['complete'],
		components: {
			'letter-compose-step1': LetterComposeStep1,
			'letter-compose-step2': LetterComposeStep2,
			'letter-compose-step3': LetterComposeStep3,
			'letter-compose-step4': LetterComposeStep4,
			'letter-compose-step5': LetterComposeStep5,
		},

		data()
		{
			return {
				step: 1,
				error: "",
				loading: false
			}
		},

		computed:
		{
			branchId()
			{
				return this.$store.state.client.branchId
			},

			clientId()
			{
				return this.$store.state.client.id
			}
		},

		async beforeMount()
		{
			if (this.branchId == 0)
			{
				this.$router.push({ name: 'hub.branch.accessDenied' })
			}
		},

		methods:
		{
			prev(e)
			{
				if (this.step > 1)
				{
					this.error = "";
					this.step = this.step - 1;
				}

				e.target.blur()
			},

			next(e)
			{
				this.error = "";
				this[`handelStep${this.step}`]();

				e.target.blur()
			},

			handelStep1()
			{
				const value =
					this.$refs.ReceiversRef.getValue();

				if ((value.primaryList || []).length > 0)
				{
					this.step = 2;
				}
				else
				{
					this.error =
						this.$loc.fromResource(
							'aut.ext.letter.compose.step1Error');
				}
			},

			handelStep2()
			{
				const value =
					this.$refs.BasicInfoRef.getValue();

				if (value != undefined)
				{
					this.step = 3;
				}
				else
				{
					this.error =
						this.$loc.fromResource(
							'aut.ext.letter.compose.step2Error');
				}
			},

			handelStep3()
			{
				const value =
					this.$refs.BodyRef.getValue();

				if (value != '')
				{
					this.step = 4;
				}
				else
				{
					this.error =
						this.$loc.fromResource(
							'aut.ext.letter.compose.step3Error');
				}
			},

			handelStep4()
			{
				this.$refs.ConfirmRef.init({
					receivers: this.$refs.ReceiversRef.getValue(),
					basicInfo: this.$refs.BasicInfoRef.getValue(),
					body: this.$refs.BodyRef.getValue(),
					attachments: this.$refs.AttachmentRef.getValue()
				})

				this.step = 5;
			},

			async handelStep5()
			{
				this.loading = true;

				const receivers = this.$refs.ReceiversRef.getValue();
				const basicInfo = this.$refs.BasicInfoRef.getValue();
				const body = this.$refs.BodyRef.getValue();
				const attachment = this.$refs.AttachmentRef.getValue();

				var payload = {
					title: basicInfo.title,
					indicator: basicInfo.indicator,
					deadlineHour: basicInfo.deadlineHour,
					letterTypeId: basicInfo.letterTypeId,
					letterPriorityId: basicInfo.letterPriorityId,
					letterCategoryId: basicInfo.letterCategoryId,
					letterTopicId: basicInfo.letterTopicId,
					letterClassificationId: basicInfo.letterClassificationId,
					letterDocumentTypeId: basicInfo.letterDocumentTypeId,
					body: body,
					branches: [{
						letterBoxId:2,
						letterNodeTypeId: 1,
						branchId: this.branchId,
						personId: this.clientId
					}],
					staticFiles: []
				}

				for (var i = 0; i < receivers.primaryList.length; i++)
				{
					payload.branches.push({
						letterBoxId: 1,
						letterNodeTypeId: 2,
						branchId: receivers.primaryList[i].branchId,
						personId: receivers.primaryList[i].personId
					})
				}

				for (var i = 0; i < receivers.ccList.length; i++)
				{
					payload.branches.push({
						letterBoxId: 1,
						letterNodeTypeId: 3,
						branchId: receivers.ccList[i].branchId,
						personId: receivers.ccList[i].personId
					})
				}

				for (var i = 0; i < receivers.bccList.length; i++)
				{
					payload.branches.push({
						letterBoxId: 1,
						letterNodeTypeId: 4,
						branchId: receivers.bccList[i].branchId,
						personId: receivers.bccList[i].personId
					})
				}

				for (var i = 0; i < attachment.length; i++)
				{
					payload.staticFiles.push({
						fileName: attachment[i].name,
						dataUrl: attachment[i].dataUrl
					})
				}

				try
				{
					const res =
						await new LetterService().create(payload);

					if (res && res.status == 200)
					{
						this.$emit('complete', payload)
					}

				} catch (e)
				{
					console.log(e)
				}


				this.loading = false;

			},
		}
	}
</script>

<style>
	.letter-compose .steps .step {
		text-align: center;
		transition: all .5s;
		padding: 0;
	}

		.letter-compose .steps .step.active .circle {
			color: var(--kaveh-base-color);
		}

		.letter-compose .steps .step.active .circle-wrapper::before,
		.letter-compose .steps .step.active .circle-wrapper::after {
			border-top-style: solid;
		}


	.letter-compose .steps .circle-wrapper {
		position: relative;
	}

	.letter-compose .steps .step .circle-wrapper::before,
	.letter-compose .steps .step .circle-wrapper::after {
		content: "";
		position: absolute;
		top: 50%;
		width: calc(50% - 10px);
		height: 2px;
		border-top: #ddd dashed 2px;
		transition: all .5s;
	}

	.letter-compose .steps .step .circle-wrapper::before {
		left: 5px;
	}

	.letter-compose .steps .step .circle-wrapper::after {
		right: 5px;
	}

	.letter-compose .steps .circle {
		width: 40px;
		height: 40px;
		text-align: center;
		padding-top: 5px;
		font-size: 15px;
		display: block;
		border: #E7EAED solid 1px;
		border-radius: 100%;
		margin: 0 auto 10px auto;
		background: #ffffff;
		position: relative;
		z-index: 1;
	}
</style>
