//GeneratorFlag:[NoChange]
export default {
	path: 'blog-content',
	name: 'cms.blogContent.layout',
	redirect: to => {
		return {name: 'cms.blogContent.list'};
	},
	component: () => import('@/modules/cms/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/cms/blog-content"],
		resourceKey: 'cms.blogContentLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[BlogContentCreate]"
		{
			path: 'create',
			name: 'cms.blogContent.create',
			component: () => import('@/modules/cms/views/blog-content/blog-content-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.blogContentCreate',
				clientAccessKeys:["/cms/blog-content/create"],
				serverAccessKeys:["/cms/blog-content/create","/cms/blog-content/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BlogContentCreateImport]"
		{
			path: 'create-import',
			name: 'cms.blogContent.createImport',
			component: () => import('@/modules/cms/views/blog-content/blog-content-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.blogContentCreateImport',
				clientAccessKeys:["/cms/blog-content/create-import"],
				serverAccessKeys:["/cms/blog-content/create","/cms/blog-content/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BlogContentDelete]"
		{
			path: 'delete',
			name: 'cms.blogContent.delete',
			component: () => import('@/modules/cms/views/blog-content/blog-content-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.blogContentDelete',
				clientAccessKeys:["/cms/blog-content/delete"],
				serverAccessKeys:["/cms/blog-content/delete","/cms/blog-content/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BlogContentDetail]"
		{
			path: 'detail',
			name: 'cms.blogContent.detail',
			component: () => import('@/modules/cms/views/blog-content/blog-content-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.blogContentDetail',
				clientAccessKeys:["/cms/blog-content/detail"],
				serverAccessKeys:["/cms/blog-content/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BlogContentList]"
		{
			path: 'list',
			name: 'cms.blogContent.list',
			component: () => import('@/modules/cms/views/blog-content/blog-content-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.blogContentList',
				clientAccessKeys:["/cms/blog-content/list"],
				serverAccessKeys:["/cms/blog-content/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BlogContentImageAttachment]"
		{
			path: 'image-attachment',
			name: 'cms.blogContent.imageAttachment',
			component: () => import('@/modules/cms/views/blog-content/blog-content-image-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.blogContentImageAttachment',
				clientAccessKeys:["/cms/blog-content/image-attachment"],
				serverAccessKeys:["/cms/blog-content/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BlogContentUpdate]"
		{
			path: 'update',
			name: 'cms.blogContent.update',
			component: () => import('@/modules/cms/views/blog-content/blog-content-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.blogContentUpdate',
				clientAccessKeys:["/cms/blog-content/update"],
				serverAccessKeys:["/cms/blog-content/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}