<template>
	<kaveh-tree :isRoot="isRoot">
		<kaveh-tree-node v-for="(node,index) in items" :key="index" :is-expand="isRoot">
			<label class="cursor-pointer">

				<template v-if="isMultiple">
					<input class="me-2"
						   type="checkbox"
						   v-model="node.checked"
						   @change="handleChange(node, $event)" />
				</template>

				<template v-else>
					<input class="me-2"
						   type="radio"
						   v-bind:checked="node.checked"
						   @change="handleChange(node, $event)"
						   name="branch_tree" />
				</template>

				<span class="me-2">
					<span> {{node.branchName}}</span>
				</span>

			</label>
			<template v-slot:children v-if="hasChildren(node)">
				<branch-position-tree-node @change="handleChange"
								  :is-multiple="isMultiple"
								  :items="node.children"
								  :is-root="false" />
			</template>
		</kaveh-tree-node>
	</kaveh-tree>
</template>
<script>

	export default {
		emits: ['change'],
		props: {

			items: {
				type: Array
			},

			isMultiple: {
				type: Boolean,
				default: true,
			}
		},

		data()
		{
			return {
				isRoot: true
			}
		},

		methods:
		{
			hasChildren(node)
			{
				return (node.children || []).length > 0
			},

			handleChange(node, e)
			{
				this.$emit('change', node)
			},
		}
	}
</script>
