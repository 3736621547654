<template>
	<div class="row" :key="ver" v-if="temp">
		<div class="col-12 mb-3">
			<div class="bg-light rounded p-3">
				{{$loc.fromResource('hub.ext.branch.position.create.title',{label: temp.branchName})}}
			</div>
		</div>
		<div class="col-12 col-lg-4">
			<div class="bg-light rounded p-3">
				<div class="mt-3" v-if="formValue">
					<div v-if="mode == 'create'" class="alert alert-light">
						{{$loc.fromResource('hub.ext.branch.position.mode.create')}}
					</div>
					<div v-else class="alert alert-warning">
						<span>
							{{$loc.fromResource('hub.ext.branch.position.mode.update',{label : formValue.personName})}}
						</span>
						<button @click="resetForm" class="btn btn-sm btn-light ms-3">{{$loc.fromResource('action.neverMind')}}</button>
					</div>
					<kaveh-form display-type="block" :key="formVersion" :entity="formEntity" :task="positionSubmit" :value="formValue" />
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-8">
			<div class="bg-light rounded p-3">
				<div class="mt-2">
					<kaveh-table :task="positionList" :entity="listEntity" :actions="actions" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	import { PositionService } from '@/modules/hub/services'
	import { Position as PositionListEntity } from '@/modules/hub/data/position/list'
	import { Position as PositionCreateEntity } from '@/modules/hub/data/position/create'
	import { Position as PositionUpdateEntity } from '@/modules/hub/data/position/update'

	export default {
		data()
		{
			return {
				ver: 0,
				formVersion: 0,
				temp: null,
				isLoadingList: false,
				isLoadingSubmit: false,
				formValue: null,
				positions: [],
				mode: 'create'
			}
		},

		computed:
		{
			listEntity()
			{
				return PositionListEntity;
			},

			formEntity()
			{
				if (this.mode == 'create')
				{
					return PositionCreateEntity;
				}

				return PositionUpdateEntity;
			},

			actions()
			{
				return [
					{
						resourceKey: 'action.update',
						iconName: 'bi-pen',
						activeMode: 'single',
						task: (items) =>
						{
							this.mode = 'update'
							this.formValue = items[0];
							this.formVersion = this.formVersion + 1;
						}
					},

					{
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						task: async (items) =>
						{
							const confirmMessage =
								this.$loc.fromResource('message.deleteConfirm')

							if (confirm(confirmMessage))
							{
								for (var i = 0; i < items.length; i++)
								{
									await this.positionDelete(items[0].positionId)
								}

								this.resetForm()
							}
						}
					},
				]
			}
		},

		async beforeMount()
		{
			await this.initTemp()
		},


		methods:
		{
			async initTemp()
			{
				this.temp = await this.getDataFromTemp();
				if (this.temp != undefined)
				{
					this.formValue = {
						branchId: this.temp.branchId
					}
				}
				else
				{
					this.$router.push({
						name: 'hub.branch.list'
					})
				}
			},

			async getDataFromTemp()
			{
				const store = this.$store;
				const router = this.$route;
				const temp =
					await store.dispatch(
						'temp/findByQuery', router.query
					);

				return temp ? temp.value : undefined;
			},

			async positionList(payload)
			{
				try
				{
					payload.filter = payload.filter || {};
					payload.filter.branchId = this.temp.branchId;

					return await new PositionService().list(payload);

				} catch (e) { console.log(e) }
			},

			async positionSubmit(payload)
			{
				if (this.mode == 'create')
				{
					await this.positionSave(payload);
				}
				else
				{
					await this.positionUpdate(payload);
				}
			},

			async positionDelete(payload)
			{
				try
				{
					const service = new PositionService();
					const res = await service.delete(payload);
					if (res.status == 200)
					{
						this.resetForm()
					}

				} catch (e) { console.log(e) }
			},

			async positionSave(payload)
			{
				try
				{
					payload.filter =
						payload.filter || {};

					payload.filter.branchId = this.temp.branchId;
					const service = new PositionService();
					const res = await service.create(payload);
					if (res.status == 200)
					{
						this.resetForm()
					}

				} catch (e) { console.log(e) }
			},

			async positionUpdate(payload)
			{
				try
				{
					payload.filter =
						payload.filter || {};

					payload.filter.branchId = this.temp.branchId;
					const service = new PositionService();
					const res = await service.update(payload);
					if (res.status == 200)
					{
						this.resetForm()
					}

				} catch (e) { console.log(e) }
			},

			resetForm()
			{
				this.formValue = {
					branchId: this.temp.branchId
				}

				this.mode = 'create'
				this.ver += 1
				this.formVersion += 1
			}
		}
	}
</script>
