//GeneratorFlag:[NoChange]
import routes from './routes'
import services from './services'
import resources from './resources'
import toolbar from './toolbar'

const notification = {
	name: 'notification',
	foldreName: 'notification',
	routes,
	services,
	resources,
	toolbar
}

export default notification
export { routes, services, resources, toolbar }