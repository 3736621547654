<template>
	<component :is="`activity-${action}`" @complete="complete" />
</template>
<script>
	import ActivityCompose from './compose/activity-compose.vue'
	export default {

		components: {
			'activity-compose': ActivityCompose,
		},

		data() {
			return {
				action: 'compose'
			}
		},

		beforeMount() {
			if (this.$route.query.action) {
				this.action = this.$route.query.action
			}
		},

		methods: {
			complete() {
				this.$router.push({
					name: 'aut.activity.list',
				});
			}
		}
	}
</script>
