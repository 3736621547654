<template>
	<activity-list-full v-if="deviceType == 'desktop'" />
	<activity-list-tiny v-else />
</template>

<script>
	import { deviceType } from '@/plugins'
	import ActivityListFull from './activity-list/activity-list-full.vue'
	import ActivityListTiny from './activity-list/activity-list-tiny.vue'

	export default {

		components: {
			'activity-list-full': ActivityListFull,
			'activity-list-tiny': ActivityListTiny
		},

		data() {
			return {
				deviceType: deviceType()
			}
		},
	}
</script>
