import ext from './en-ext.js'
export default {
	"name":"aut",
	"ext": ext,
	"layout":{
		"basis": "Basic Information", 
		"report": "Reports", 
	},
	'activity':'Activity',
	'activityActivityId':'ID',
	'activityActivityPriority':'Priority',
	'activityActivityPriorityId':'Priority ID',
	'activityActivityPriorityTitle':'Priority',
	'activityActivityStatus':'Status',
	'activityActivityStatusId':'Status ID',
	'activityActivityStatusTitle':'Status',
	'activityActivityTrack':'Activity Tracking Information',
	'activityAttachment':'File Attachment',
	'activityCreate':'Create New Activity',
	'activityCreateActivityId':'ID',
	'activityCreateActivityPriorityId':'Priority ID',
	'activityCreateBranchId':'ID',
	'activityCreateComment':'Request Text',
	'activityCreateDeadlineHour':'Process/Time Deadline Hour',
	'activityCreateFormValue':'Form Information',
	'activityCreateProcessId':'ID',
	'activityCustomerCreate':'activity',
	'activityDeadlineHour':'Process/Time Deadline Hour',
	'activityDelete':'Delete Activity',
	'activityDetail':'Activity Information Details',
	'activityFileLengths':'Number of Process Cycle Files',
	'activityFirstActivistName':'Initiator',
	'activityFirstActivistRoleName':'Initiator Organizational Position',
	'activityFirstActivityComment':'Initiator Comments',
	'activityFirstActivityCreateTime':'Start Time',
	'activityFirstActivityLocalDate':'Start Date',
	'activityFormActivists':'Process Cycle Activists',
	'activityFormComments':'Process Cycle Comments',
	'activityFormValues':'Process Cycle Forms',
	'activityFullReport':'Activity Reports',
	'activityLastActivistName':'Last Activist',
	'activityLastActivistRoleName':'Last Activist Organizational Position',
	'activityLastProcessNodeTitle':'Last Action',
	'activityLayout':'Activities',
	'activityList':'Activity List',
	'activityLocalDateTimes':'Process Cycle Times',
	'activityNextNode':'Next Node',
	'activityNextProcessNodeId':'Next Level',
	'activityNode':'Node',
	'activityPriorityActivityPriorityId':'ID',
	'activityPriorityCaption':'Description',
	'activityPriorityHexColor':'Color',
	'activityPriorityTitle':'Title',
	'activityProcess':'ID',
	'activityProcessId':'ID',
	'activityProcessTitle':'Process Name',
	'activityState':'Activity',
	'activityStaticFile':'Static File',
	'activityStatusActivityStatusId':'ID',
	'activityStatusCaption':'Description',
	'activityStatusHexColor':'Color',
	'activityStatusTitle':'Title',
	'letter':'Letter',
	'letterAttachment':'File Attachment',
	'letterBody':'Letter Body',
	'letterBranch':'Branch',
	'letterCategory':'Category',
	'letterClassification':'Classification',
	'letterCreate':'Create New Letter',
	'letterCreateImport':'Register Letters via Excel',
	'letterDeadlineHour':'Deadline to Take Action by Hour',
	'letterDelete':'Delete Letter',
	'letterDetail':'Letter Information Details',
	'letterDocumentType':'Document Type',
	'letterIndicator':'Indicator',
	'letterLayout':'Letters',
	'letterLetterCategoryId':'Category',
	'letterLetterClassificationId':'Classification',
	'letterLetterDocumentTypeId':'Document Type',
	'letterLetterId':'ID',
	'letterLetterPatternId':'Letter Template',
	'letterLetterPriorityId':'Priority',
	'letterLetterTopicId':'Archive Topic',
	'letterLetterTypeId':'Letter Type',
	'letterList':'Letter List',
	'letterNode':'Node',
	'letterPattern':'Letter Template',
	'letterPatternAttachment':'File Attachment',
	'letterPatternCaption':'Description',
	'letterPatternCreate':'Create New Letter Template',
	'letterPatternCreateImport':'Register Letter Templates via Excel',
	'letterPatternDelete':'Delete Letter Template',
	'letterPatternDetail':'Letter Template Information Details',
	'letterPatternIdentifier':'Primary Key',
	'letterPatternLayout':'Letter Templates',
	'letterPatternLetterPatternId':'ID',
	'letterPatternLetterPatternTrack':'Letter Template Tracking Information',
	'letterPatternList':'Letter Template List',
	'letterPatternPatternBody':'Template Body',
	'letterPatternTitle':'Template Name',
	'letterPatternUpdate':'Update Letter Template',
	'letterPriority':'Priority',
	'letterStaticFile':'Static File',
	'letterTag':'Letter Tag',
	'letterTagAttachment':'File Attachment',
	'letterTagCaption':'Description',
	'letterTagCreate':'Create New Letter Tag',
	'letterTagCreateImport':'Register Letter Tags via Excel',
	'letterTagDelete':'Delete Letter Tag',
	'letterTagDetail':'Letter Tag Information Details',
	'letterTagHexColor':'Color',
	'letterTagIdentifier':'Primary Key',
	'letterTagLayout':'Letter Tags',
	'letterTagLetterTagId':'ID',
	'letterTagLetterTagTrack':'Letter Tag Tracking Information',
	'letterTagList':'Letter Tag List',
	'letterTagTitle':'Tag Title',
	'letterTagUpdate':'Update Letter Tag',
	'letterTitle':'Letter Title',
	'letterTopic':'Archive Topic',
	'letterTopicAttachment':'File Attachment',
	'letterTopicCaption':'Description',
	'letterTopicCreate':'Create New Archive Topic',
	'letterTopicCreateImport':'Register Archive Topics via Excel',
	'letterTopicDelete':'Delete Archive Topic',
	'letterTopicDetail':'Archive Topic Information Details',
	'letterTopicIdentifier':'Primary Key',
	'letterTopicLayout':'Archive Topics',
	'letterTopicLetterTopicId':'ID',
	'letterTopicLetterTopicTrack':'Archive Topic Tracking Information',
	'letterTopicList':'Archive Topic List',
	'letterTopicTitle':'Title',
	'letterTopicUpdate':'Update Archive Topic',
	'letterType':'Type',
	'nextNodeStateCompleteTitle':'Complete Button Title',
	'nextNodeStateContinueTitle':'Continue Button Title',
	'nextNodeStateReturnTitle':'Return Button Title',
	'nextNodeStateStopTitle':'Stop Button Title',
	'nodeListCompleteTitle':'Complete Button Title',
	'nodeListContinueTitle':'Continue Button Title',
	'nodeListReturnTitle':'Return Button Title',
	'nodeListStopTitle':'Stop Button Title',
	'nodeStateCompleteTitle':'Complete Button Title',
	'nodeStateContinueTitle':'Continue Button Title',
	'nodeStateReturnTitle':'Return Button Title',
	'nodeStateStopTitle':'Stop Button Title',
	'process':'Process',
	'processAttachment':'File Attachment',
	'processCaption':'Description',
	'processCreate':'Create New Process',
	'processCreateImport':'Register Processes via Excel',
	'processDelete':'Delete Process',
	'processDetail':'Process Information Details',
	'processGroup':'Process Group',
	'processGroupAttachment':'File Attachment',
	'processGroupCaption':'Description',
	'processGroupCreate':'Create New Process Group',
	'processGroupCreateImport':'Register Process Groups via Excel',
	'processGroupDelete':'Delete Process Group',
	'processGroupDetail':'Process Group Information Details',
	'processGroupHexColor':'Color',
	'processGroupIdentifier':'Primary Key',
	'processGroupLayout':'Process Groups',
	'processGroupList':'Process Group List',
	'processGroupProcessGroupId':'ID',
	'processGroupProcessGroupTrack':'Process Group Tracking Information',
	'processGroupTitle':'Title',
	'processGroupUpdate':'Update Process Group',
	'processIdentifier':'Unique Key',
	'processLayout':'Processes',
	'processList':'Process List',
	'processNode':'Task',
	'processNodeLayout':'Tasks',
	'processNodeManagement':'Task Management',
	'processNodeManagementCaption':'Description',
	'processNodeManagementCompleteTitle':'Complete Button Title',
	'processNodeManagementContinueTitle':'Continue Button Title',
	'processNodeManagementEventPattern':'Event',
	'processNodeManagementFormPattern':'Form',
	'processNodeManagementHasAccessToComplete':'Can Complete/Finish',
	'processNodeManagementHasAccessToContinue':'Can Continue',
	'processNodeManagementHasAccessToReturn':'Can Return',
	'processNodeManagementHasAccessToStop':'Can Stop/Finish Unsuccessfully',
	'processNodeManagementIsArrayFormValue':'Enter Data from Excel',
	'processNodeManagementIsLimitedToUnit':'Limited to Organizational Unit',
	'processNodeManagementMessagePatternName':'SMS Template Name',
	'processNodeManagementOrderNumber':'Priority',
	'processNodeManagementProcessBranchAgents':'Branch Agents',
	'processNodeManagementProcessId':'Process',
	'processNodeManagementProcessNodeId':'ID',
	'processNodeManagementProcessRoleAgents':'User Roles',
	'processNodeManagementReturnNodes':'Return Actions',
	'processNodeManagementReturnTitle':'Return Button Title',
	'processNodeManagementStopTitle': 'Stop Button Title',
	'processNodeManagementProcessWorkerAgent': 'Worker',
	'processNodeManagementTitle':'Title',
	'processNodeWorkerAgent':'Agent Service',
	'processProcessGroup':'Group',
	'processProcessGroupId':'Group',
	'processProcessId':'ID',
	'processProcessTrack':'Process Tracking Information',
	'processTitle':'Title',
	'processUpdate':'Update Process',
}
