//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class OtpService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[Otp.Check]
	async check(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/notification/otp/check', params || { });
	};

	//GeneratorFlag:[Otp.SendEmail]
	async sendEmail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/notification/otp/send-email', params || { });
	};

	//GeneratorFlag:[Otp.SendMessage]
	async sendMessage(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/notification/otp/send-message', params || { });
	};

//GeneratorFlag:[NewAction]
}