<template>
	<kaveh-content width="800px">
		<div class="mb-5 alert alert-warning" v-if="$store.state.client.fcp"
			 v-html="$loc.fromResource('hub.ext.person.changePassword.fcp')">
		</div>
		<kaveh-form :key="ver" :entity="entity" :task="submit" />
	</kaveh-content>
</template>
<script>
	import { toast } from '@/plugins'
	import { ServerUtility } from '@/core/server'
	import { Person } from '@/modules/hub/data/person/change-password'
	import { PersonService } from '@/modules/hub/services'
	export default {
		data()
		{
			return {
				ver: 0
			}
		},
		computed:
		{
			entity()
			{
				return Person
			},
			service()
			{
				return new PersonService()
			},
		},
		methods:
		{
			async submit(payload)
			{

				try
				{
					const service = new PersonService();
					const res = await service.changePassword(payload, { HSR: false, HER: false });

					if (res.status == 200)
					{
						for (var key in localStorage)
						{
							if (typeof (key) == 'string')
							{
								if (!key.includes('kaveh.app') && key.includes('kaveh'))
								{
									localStorage.removeItem(key)
								}
							}
						}

						this.$router.push('/auth');
						this.$store.dispatch('client/resetState');
					}
				} catch (e)
				{
					if ((e.response || {}).status == 404)
					{
						toast.error('hub.ext.person.changePassword.invalidCurrentPassword')
					}
					else
					{
						new ServerUtility().showErrorMessage(e)
					}
				}
			}
		}
	}
</script>
