<template>
	<kaveh-content :is-loading="processNodeListIsLoading" class="proccess-node-management">
		<div class="row">
			<div class="col-3">
				<div class="sidebar pe-2 pb-5 border-end">
					<div @click="setFormData(index)" class="item" v-for="(item,index) in processNodeList" :key="index" v-bind:class="{'active': currentLevel == item.orderNumber}">
						<small class="level text-bg-light">{{ getOrderLabel(item.orderNumber) }}</small><br />
						<span> {{item.title}}</span><br />
						<small class="text-muted">{{item.caption}}</small>
					</div>
					<div @click="setFormData((processNodeList.length + 1))" class="item" v-bind:class="{'active': currentLevel == processNodeList.length + 1}">
						<span>{{ $loc.fromResource('aut.ext.processNode.createLevel') }}</span>
					</div>
				</div>
			</div>
			<div class="col-9 ps-5" v-if="props">
				<div class="mb-3 row">
					<label v-once for="title" class="col-4 col-md-3">
						<span>{{$loc.fromResource(props.title.resourceKey)}}</span>
						<span v-if="isRequired('title')" class="text-danger"> * </span>
					</label>
					<div class="col-8 col-md-9">
						<input v-model="props.title.value" @keyup="onHandelKeyUp('title')" @change="onHandelChange('title')" dir="rtl" type="text" class="form-control" id="title" autocomplete="off">
						<small class="text-danger" v-if="props.title.errors.length > 0">
							{{getFirstError('title')}}
						</small>
					</div>
				</div>

				<div class="mb-3 row">
					<label v-once for="caption" class="col-4 col-md-3">
						<span>{{$loc.fromResource(props.caption.resourceKey)}}</span>
						<span v-if="isRequired('caption')" class="text-danger"> * </span>
					</label>
					<div class="col-8 col-md-9">
						<input v-model="props.caption.value" @keyup="onHandelKeyUp('caption')" @change="onHandelChange('caption')" dir="rtl" type="text" class="form-control" id="caption" autocomplete="off">
						<small class="text-danger" v-if="props.caption.errors.length > 0">
							{{getFirstError('caption')}}
						</small>
					</div>
				</div>

				<div class="mb-3 row">
					<label v-once for="orderNumber" class="col-4 col-md-3">
						<span>{{$loc.fromResource(props.orderNumber.resourceKey)}}</span>
						<span v-if="isRequired('orderNumber')" class="text-danger"> * </span>
					</label>
					<div class="col-8 col-md-9">
						<input v-model="props.orderNumber.value" @keyup="onHandelKeyUp('orderNumber')" @change="onHandelChange('orderNumber')" dir="ltr" type="number" class="form-control" id="orderNumber" autocomplete="off">
						<small class="text-danger" v-if="props.orderNumber.errors.length > 0">
							{{getFirstError('orderNumber')}}
						</small>
					</div>
				</div>

				<div class="mb-3 row">
					<label v-once for="processBranchAgents" class="col-4 col-md-3">
						<span>{{$loc.fromResource(props.processBranchAgents.resourceKey)}}</span>
						<span v-if="isRequired('processBranchAgents')" class="text-danger"> * </span>
					</label>
					<div class="col-8 col-md-9">
						<div class="bg-white border p-2 rounded">
							<branch-tree :is-multiple="true" @change="onHandleBranchTreeChange" ref="BranchTreeRef" />
						</div>
						<small class="text-danger" v-if="props.processBranchAgents.errors.length > 0">
							{{getFirstError('processBranchAgents')}}
						</small>
					</div>
				</div>

				<div class="mb-3 row">
					<label v-once for="processRoleAgents" class="col-4 col-md-3">
						<span>{{$loc.fromResource(props.processRoleAgents.resourceKey)}}</span>
						<span v-if="isRequired('processRoleAgents')" class="text-danger"> * </span>
					</label>
					<div class="col-8 col-md-9">
						<select multiple class="form-select" v-bind:class="{'is-loading': personRoleListIsLoading}" v-model="props.processRoleAgents.value" @change="onHandelChange('processRoleAgents')" id="processRoleAgents">
							<option v-for="(item,index) in personRoleList" :value="item.personRoleId" :key="index">
								{{item.title}}
							</option>
						</select>
						<small class="text-danger" v-if="props.processRoleAgents.errors.length > 0">
							{{getFirstError('processRoleAgents')}}
						</small>
					</div>
				</div>

				<div class="mb-3 row">
					<label v-once for="processWorkerAgent" class="col-4 col-md-3">
						<span>{{$loc.fromResource(props.processWorkerAgent.resourceKey)}}</span>
						<span v-if="isRequired('processWorkerAgent')" class="text-danger"> * </span>
					</label>
					<div class="col-8 col-md-9">
						<select class="form-select" v-bind:class="{'is-loading': workerListIsLoading}" @change="onHandleChangeWorker" v-model="worker.workerId" id="processWorkerAgent">
							<option v-for="(item,index) in workerList" :value="item.workerId" :key="index">
								{{item.title}}
							</option>
						</select>
						<small class="text-danger" v-if="props.processWorkerAgent.errors.length > 0">
							{{getFirstError('processWorkerAgent')}}
						</small>
					</div>
				</div>
				<div class="mb-3 row" v-if="worker.workerId > 0">
					<label class="col-4 col-md-3">
						<span>{{$loc.fromResource('aut.ext.processNode.worker.properties')}}</span>
					</label>
					<div class="col-8 col-md-9">
						<div class="p-2 bg-white border rounded mb-2" dir="ltr" v-for="(property,i) in worker.properties">
							<div class="mb-1 row">
								<div class="col-5">
									<select dir="ltr"
											v-model="property.valueType"
											@change="() => { property.value = ''; property.processNodeKey = '';}" class="form-select">
										<option value="custom">custom value</option>
										<option value="process">process value</option>
									</select>
								</div>
								<div class="col-2">
									<button class="btn border bg-white text-danger w-100"
											@click="worker.properties.splice(i,1)">
										<span><i class="bi bi-trash"></i></span>
									</button>
								</div>
							</div>
							<div class="row mb-1">
								<div class="col-12">
									<input placeholder="key" v-model="property.key" dir="ltr" type="text" class="form-control" autocomplete="off">
								</div>
							</div>
							<div class="row mb-1">
								<div class="col-12" v-if="property.valueType == 'custom'">
									<input placeholder="value" v-model="property.value" dir="ltr" type="text" class="form-control" autocomplete="off">
								</div>
								<div class="col-12" v-if="property.valueType == 'process'">
									<select dir="rtl" class="form-select" v-model="property.processNodeKey">
										<optgroup v-for="(fp, i2) in formProperties" :key="i2" :label="fp.title">
											<option v-for="(o, i3) in fp.options" :value="`${o.name}:${fp.id}`" :key="i3">
												{{o.label}}
											</option>
										</optgroup>
									</select>
								</div>
							</div>
						</div>
						<button class="btn border text-start bg-white mt-3"
								@click="worker.properties.push({key:'', value:'', valueType:'process'})">
							<span><i class="bi bi-plus"></i></span>
						</button>
					</div>
				</div>

				<div class="mb-3 row" v-if="worker.workerId == 2">
					<label v-once for="processWorkerAgentEmailDestination" class="col-4 col-md-3">
						<span>{{$loc.fromResource('aut.ext.processNode.worker.emailDestination')}}</span>
						<span class="text-danger"> * </span>
					</label>
					<div class="col-8 col-md-9">
						<input v-model="worker.emailPattern.destination" dir="ltr" type="email" class="form-control" id="processWorkerAgentEmailDestination" autocomplete="off">
					</div>
				</div>

				<div class="mb-3 row">
					<label v-once for="isLimitedToUnit" class="col-4 col-md-3">
						<span>{{$loc.fromResource(props.isLimitedToUnit.resourceKey)}}</span>
						<span v-if="isRequired('isLimitedToUnit')" class="text-danger"> * </span>
					</label>
					<div class="col-8 col-md-9">
						<select class="form-select" v-model="props.isLimitedToUnit.value" @change="onHandelChange('isLimitedToUnit')" id="isLimitedToUnit">
							<option :value="false">{{$loc.fromResource('com.form.checkbox.no')}}</option>
							<option :value="true">{{$loc.fromResource('com.form.checkbox.yes')}}</option>
						</select>
						<small class="text-danger" v-if="props.isLimitedToUnit.errors.length > 0">
							{{getFirstError('isLimitedToUnit')}}
						</small>
					</div>
				</div>

				<div class="mb-3 row" v-if="currentLevel > 1">
					<label v-once for="hasAccessToReturn" class="col-4 col-md-3">
						<span>{{$loc.fromResource(props.hasAccessToReturn.resourceKey)}}</span>
						<span v-if="isRequired('hasAccessToReturn')" class="text-danger"> * </span>
					</label>
					<div class="col-2 col-md-2">
						<select class="form-select" v-model="props.hasAccessToReturn.value" @change="onHandelChange('hasAccessToReturn')" id="hasAccessToReturn">
							<option :value="false">{{$loc.fromResource('com.form.checkbox.no')}}</option>
							<option :value="true">{{$loc.fromResource('com.form.checkbox.yes')}}</option>
						</select>
						<small class="text-danger" v-if="props.hasAccessToReturn.errors.length > 0">
							{{getFirstError('hasAccessToReturn')}}
						</small>
					</div>
					<div class="col-4 col-md-4">
						<input v-model="props.returnTitle.value" type="text" class="form-control" :placeholder="$loc.fromResource('aut.ext.processNode.returnPh')" autocomplete="off">
						<small class="text-danger" v-if="props.returnTitle.errors.length > 0">
							{{getFirstError('returnTitle')}}
						</small>
					</div>
					<div class="col-2 col-md-3">
						<kaveh-button class="btn btn-light border me-2 w-100" @click="onHandleEventViewer('return')" :disabled="props.hasAccessToReturn.value == 'false'">
							<kaveh-icon-bootstrap class="me-2" name="bi-activity" size="14px" />
							<span>{{ $loc.fromResource('aut.ext.processNode.eventManagement') }}</span>
						</kaveh-button>
					</div>
				</div>

				<div class="mb-3 row" v-if="hasAccessToReturn">
					<label v-once for="returnNodes" class="col-4 col-md-3">
						<span>{{$loc.fromResource(props.returnNodes.resourceKey)}}</span>
						<span v-if="isRequired('returnNodes')" class="text-danger"> * </span>
					</label>
					<div class="col-8 col-md-9">
						<select multiple class="form-select" v-bind:class="{'is-loading': processNodeListIsLoading}" v-model="props.returnNodes.value" @change="onHandelChange('returnNodes')" id="returnNodes">
							<option v-bind:hidden="item.orderNumber >= currentLevel" v-for="(item,index) in processNodeList" :value="item.processNodeId" :key="index">
								{{item.title }}
							</option>
						</select>
						<small class="text-danger" v-if="props.returnNodes.errors.length > 0">
							{{getFirstError('returnNodes')}}
						</small>
					</div>
				</div>

				<div class="mb-3 row" v-if="currentLevel > 1">
					<label v-once for="hasAccessToContinue" class="col-4 col-md-3">
						<span>{{$loc.fromResource(props.hasAccessToContinue.resourceKey)}}</span>
						<span v-if="isRequired('hasAccessToContinue')" class="text-danger"> * </span>
					</label>
					<div class="col-2 col-md-2">
						<select class="form-select" v-model="props.hasAccessToContinue.value" @change="onHandelChange('hasAccessToContinue')" id="hasAccessToContinue">
							<option :value="false">{{$loc.fromResource('com.form.checkbox.no')}}</option>
							<option :value="true">{{$loc.fromResource('com.form.checkbox.yes')}}</option>
						</select>
						<small class="text-danger" v-if="props.hasAccessToContinue.errors.length > 0">
							{{getFirstError('hasAccessToContinue')}}
						</small>
					</div>
					<div class="col-4 col-md-4">
						<input v-model="props.continueTitle.value" type="text" class="form-control" :placeholder="$loc.fromResource('aut.ext.processNode.continuePh')" autocomplete="off">
						<small class="text-danger" v-if="props.continueTitle.errors.length > 0">
							{{getFirstError('continueTitle')}}
						</small>
					</div>
					<div class="col-2 col-md-3">
						<kaveh-button class="btn btn-light border me-2 w-100" @click="onHandleEventViewer('continue')" :disabled="props.hasAccessToContinue.value == 'false'">
							<kaveh-icon-bootstrap class="me-2" name="bi-activity" size="14px" />
							<span>{{ $loc.fromResource('aut.ext.processNode.eventManagement') }}</span>
						</kaveh-button>
					</div>
				</div>

				<div class="mb-3 row" v-if="currentLevel > 1">
					<label v-once for="hasAccessToComplete" class="col-4 col-md-3">
						<span>{{$loc.fromResource(props.hasAccessToComplete.resourceKey)}}</span>
						<span v-if="isRequired('hasAccessToComplete')" class="text-danger"> * </span>
					</label>
					<div class="col-2 col-md-2">
						<select class="form-select" v-model="props.hasAccessToComplete.value" @change="onHandelChange('hasAccessToComplete')" id="hasAccessToComplete">
							<option :value="false">{{$loc.fromResource('com.form.checkbox.no')}}</option>
							<option :value="true">{{$loc.fromResource('com.form.checkbox.yes')}}</option>
						</select>
						<small class="text-danger" v-if="props.hasAccessToComplete.errors.length > 0">
							{{getFirstError('hasAccessToComplete')}}
						</small>
					</div>
					<div class="col-4 col-md-4">
						<input v-model="props.completeTitle.value" type="text" class="form-control" :placeholder="$loc.fromResource('aut.ext.processNode.completePh')" autocomplete="off">
						<small class="text-danger" v-if="props.completeTitle.errors.length > 0">
							{{getFirstError('completeTitle')}}
						</small>
					</div>
					<div class="col-2 col-md-3">
						<kaveh-button class="btn btn-light border me-2 w-100" @click="onHandleEventViewer('complete')" :disabled="props.hasAccessToComplete.value == 'false'">
							<kaveh-icon-bootstrap class="me-2" name="bi-activity" size="14px" />
							<span>{{ $loc.fromResource('aut.ext.processNode.eventManagement') }}</span>
						</kaveh-button>
					</div>
				</div>

				<div class="mb-3 row" v-if="currentLevel > 1">
					<label v-once for="hasAccessToStop" class="col-4 col-md-3">
						<span>{{$loc.fromResource(props.hasAccessToStop.resourceKey)}}</span>
						<span v-if="isRequired('hasAccessToStop')" class="text-danger"> * </span>
					</label>
					<div class="col-2 col-md-2">
						<select class="form-select" v-model="props.hasAccessToStop.value" @change="onHandelChange('hasAccessToStop')" id="hasAccessToStop">
							<option :value="false">{{$loc.fromResource('com.form.checkbox.no')}}</option>
							<option :value="true">{{$loc.fromResource('com.form.checkbox.yes')}}</option>
						</select>
						<small class="text-danger" v-if="props.hasAccessToStop.errors.length > 0">
							{{getFirstError('hasAccessToStop')}}
						</small>
					</div>
					<div class="col-4 col-md-4">
						<input v-model="props.stopTitle.value" type="text" class="form-control" :placeholder="$loc.fromResource('aut.ext.processNode.stopPh')" autocomplete="off">
						<small class="text-danger" v-if="props.stopTitle.errors.length > 0">
							{{getFirstError('stopTitle')}}
						</small>
					</div>
					<div class="col-2 col-md-3">
						<kaveh-button class="btn btn-light border me-2 w-100" @click="onHandleEventViewer('stop')" :disabled="props.hasAccessToStop.value == 'false'">
							<kaveh-icon-bootstrap class="me-2" name="bi-activity" size="14px" />
							<span>{{ $loc.fromResource('aut.ext.processNode.eventManagement') }}</span>
						</kaveh-button>
					</div>
				</div>

				<div class="mb-3 row">
					<label v-once for="isArrayFormValue" class="col-4 col-md-3">
						<span>{{$loc.fromResource(props.isArrayFormValue.resourceKey)}}</span>
						<span v-if="isRequired('isArrayFormValue')" class="text-danger"> * </span>
					</label>
					<div class="col-8 col-md-9">
						<select class="form-select" v-model="props.isArrayFormValue.value" @change="onHandelChange('isArrayFormValue')" id="isArrayFormValue">
							<option :value="false">{{$loc.fromResource('com.form.checkbox.no')}}</option>
							<option :value="true">{{$loc.fromResource('com.form.checkbox.yes')}}</option>
						</select>
						<small class="text-danger" v-if="props.isArrayFormValue.errors.length > 0">
							{{getFirstError('isArrayFormValue')}}
						</small>
					</div>
				</div>
				<div class="mb-3 row">
					<label v-once class="col-4 col-md-3">
						<span>{{$loc.fromResource('aut.ext.processNode.formInfo')}}</span>
					</label>
					<div class="col-8 col-md-9">
						<kaveh-button class="btn btn-light border me-2" @click="isShowFormGenerator = true">
							<kaveh-icon-bootstrap class="me-2" name="bi-journal-text" size="14px" />
							<span>{{ $loc.fromResource('aut.ext.processNode.formManagement') }}</span>
						</kaveh-button>

						<kaveh-button v-bind:disabled="schema == null" class="btn btn-light border" @click="isShowFormViewer = true">
							<kaveh-icon-bootstrap class="me-2" name="bi-arrows-fullscreen" size="14px" />
							<span>{{ $loc.fromResource('aut.ext.processNode.formPreview') }}</span>
						</kaveh-button>
					</div>
				</div>

				<div class="mb-3 mt-5 row">
					<div class="col">
						<kaveh-button @click="submit" class="btn btn-outline-dark" :is-loading="isLoadingSubmit">
							{{ $loc.fromResource('action.save') }}
						</kaveh-button>
					</div>
				</div>
			</div>
		</div>

		<kaveh-popup width="1200px" v-if="isShowFormGenerator" :close="() => {isShowFormGenerator = false}" no-padding :confirm-when-close="true">
			<kaveh-form :props="schema" mode="generator" @submit="handleFormGeneratorSubmit" />
		</kaveh-popup>

		<kaveh-popup v-if="isShowFormViewer" :close="() => {isShowFormViewer = false}">
			<kaveh-form :props="schema" mode="preview" submit-resource-key="action.showResult" />
		</kaveh-popup>

		<kaveh-popup width="1200px" v-if="isShowEventViewer" :close="() => {isShowEventViewer = false}">
			<div class="row">
				<div class="col-12 col-xl-6">
					<div class="bg-light p-3">
						<div class="alert alert-light">
							{{$loc.fromResource('aut.ext.processNode.event.serviceInfo')}}
						</div>
						<div class="mb-3 row">
							<label for="eventPatternAddress" class="col-4 col-md-3">
								<span>{{$loc.fromResource('aut.ext.processNode.event.address')}}</span>
								<span class="text-danger"> * </span>
							</label>
							<div class="col-8 col-md-9">
								<input v-model="eventPattern[actionName].primaryService.address" dir="ltr" type="url" class="form-control" id="eventPatternAddress" autocomplete="off">
							</div>
						</div>
						<div class="mb-3 row">
							<label for="eventPatternMethod" class="col-4 col-md-3">
								<span>{{$loc.fromResource('aut.ext.processNode.event.method')}}</span>
								<span class="text-danger"> * </span>
							</label>
							<div class="col-8 col-md-9">
								<select v-model="eventPattern[actionName].primaryService.method" dir="ltr" class="form-select">
									<option>get</option>
									<option>post</option>
								</select>
							</div>
						</div>
						<div class="mb-3 row">
							<label for="eventPatternContentType" class="col-4 col-md-3">
								<span>{{$loc.fromResource('aut.ext.processNode.event.contentType')}}</span>
								<span class="text-danger"> * </span>
							</label>
							<div class="col-8 col-md-9">
								<select v-model="eventPattern[actionName].primaryService.contentType" dir="ltr" class="form-select">
									<option>application/json</option>
								</select>
							</div>
						</div>
					</div>
					<div class="bg-light mt-3 p-3">
						<div class="mb-3 row">
							<label class="col-12 mb-2">
								<span>{{$loc.fromResource('aut.ext.processNode.event.properties')}}</span>
							</label>
							<div class="col-12 pb-2 pt-2" dir="ltr" v-for="(property,i) in eventPattern[actionName].primaryService.properties">
								<div class="mb-1 row">
									<div class="col-5">
										<select dir="ltr" v-model="property.location" class="form-select">
											<option value="head">head params</option>
											<option value="body">body params</option>
											<option value="query">query params</option>
										</select>
									</div>
									<div class="col-5">
										<select dir="ltr"
												v-model="property.valueType"
												@change="() => { property.value = ''; property.processNodeKey = '';}" class="form-select">
											<option value="custom">custom value</option>
											<option value="process">process value</option>
										</select>
									</div>
									<div class="col-2">
										<button class="btn border bg-white text-danger w-100"
												@click="eventPattern[actionName].primaryService.properties.splice(i,1)">
											<span><i class="bi bi-trash"></i></span>
										</button>
									</div>
								</div>
								<div class="row mb-1">
									<div class="col-12">
										<input placeholder="key" v-model="property.key" dir="ltr" type="text" class="form-control" autocomplete="off">
									</div>
								</div>
								<div class="row mb-1">
									<div class="col-12" v-if="property.valueType == 'custom'">
										<input placeholder="value" v-model="property.value" dir="ltr" type="text" class="form-control" autocomplete="off">
									</div>
									<div class="col-12" v-if="property.valueType == 'process'">
										<select dir="rtl" class="form-select" v-model="property.processNodeKey">
											<optgroup v-for="(fp, i2) in formProperties" :key="i2" :label="fp.title">
												<option v-for="(o, i3) in fp.options" :value="`${o.name}:${fp.id}`" :key="i3">
													{{o.label}}
												</option>
											</optgroup>
										</select>
									</div>
								</div>
							</div>
							<div class="col-12">
								<button class="btn border text-start bg-white mt-3"
										@click="eventPattern[actionName].primaryService.properties.push({key:'', value:'', location:'body', valueType:'default'})">
									<span><i class="bi bi-plus"></i></span>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-xl-6">
					<div class="bg-light p-3">
						<div class="alert alert-light">
							{{$loc.fromResource('aut.ext.processNode.event.loginServiceInfo')}}
						</div>
						<div class="mb-3 row">
							<label for="eventPatternAddress" class="col-4 col-md-3">
								<span>{{$loc.fromResource('aut.ext.processNode.event.address')}}</span>
								<span class="text-danger"> * </span>
							</label>
							<div class="col-8 col-md-9">
								<input v-model="eventPattern[actionName].loginService.address" dir="ltr" type="url" class="form-control" id="eventPatternAddress" autocomplete="off">
							</div>
						</div>
						<div class="mb-3 row">
							<label for="eventPatternTokenPropertyName" class="col-4 col-md-3">
								<span>{{$loc.fromResource('aut.ext.processNode.event.tokenPropertyName')}}</span>
								<span class="text-danger"> * </span>
							</label>
							<div class="col-8 col-md-9">
								<input v-model="eventPattern[actionName].loginService.tokenPropertyName" dir="ltr" type="url" class="form-control" id="eventPatternTokenPropertyName" autocomplete="off">
							</div>
						</div>

						<div class="mb-3 row">
							<label for="eventPatternMethod" class="col-4 col-md-3">
								<span>{{$loc.fromResource('aut.ext.processNode.event.method')}}</span>
								<span class="text-danger"> * </span>
							</label>
							<div class="col-8 col-md-9">
								<select v-model="eventPattern[actionName].loginService.method" dir="ltr" class="form-select">
									<option>get</option>
									<option>post</option>
								</select>
							</div>
						</div>
						<div class="mb-3 row">
							<label for="eventPatternContentType" class="col-4 col-md-3">
								<span>{{$loc.fromResource('aut.ext.processNode.event.contentType')}}</span>
								<span class="text-danger"> * </span>
							</label>
							<div class="col-8 col-md-9">
								<select v-model="eventPattern[actionName].loginService.contentType" dir="ltr" class="form-select">
									<option>application/json</option>
								</select>
							</div>
						</div>
					</div>
					<div class="bg-light mt-3 p-3">
						<div class="mb-3 row">
							<label class="col-12 mb-2">
								<span>{{$loc.fromResource('aut.ext.processNode.event.properties')}}</span>
							</label>
							<div class="col-12 pb-2 pt-2" dir="ltr" v-for="(property,i) in eventPattern[actionName].loginService.properties">
								<div class="mb-1 row">
									<div class="col-5">
										<select dir="ltr" v-model="property.location" class="form-select">
											<option value="head">head params</option>
											<option value="body">body params</option>
											<option value="query">query params</option>
										</select>
									</div>
									<div class="col-2">
										<button class="btn border bg-white text-danger w-100"
												@click="eventPattern[actionName].loginService.properties.splice(i,1)">
											<span><i class="bi bi-trash"></i></span>
										</button>
									</div>
								</div>
								<div class="row mb-1">
									<div class="col-12">
										<input placeholder="key" required v-model="property.key" dir="ltr" type="text" class="form-control" autocomplete="off">
									</div>
								</div>
								<div class="row mb-1">
									<div class="col-12">
										<input placeholder="value" required v-model="property.value" dir="ltr" type="text" class="form-control" autocomplete="off">
									</div>
								</div>
							</div>
							<div class="col-12">
								<button class="btn border text-start bg-white mt-3"
										@click="eventPattern[actionName].loginService.properties.push({key:'', value:'', location:'body'})">
									<span><i class="bi bi-plus"></i></span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</kaveh-popup>

	</kaveh-content>
</template>
<script>
	import validator from '@/core/validator'
	import BasicService from '@/core/server/basic-service'
	import BranchTree from '@/modules/hub/views/branch/position-tree/branch-position-tree'
	import { numberToWords } from "@persian-tools/persian-tools";
	import { ProcessNode } from '@/modules/aut/data/process-node/management'
	import { ProcessNodeService, WorkerService } from '@/modules/aut/services'
	import { PersonRoleService } from '@/modules/hub/services'
	import store from '@/core/store'
	export default {

		components: {
			'branch-tree': BranchTree,
		},

		data()
		{
			return {
				props: null,
				eventPattern: {},
				isLoadingSubmit: false,
				useValidator: true,
				processList: [],
				processNodeList: [],
				personRoleList: [],
				workerList: [],
				selectedProperty: {},
				workerIsLoading: true,
				processListIsLoading: true,
				processNodeListIsLoading: true,
				personRoleListIsLoading: true,

				worker: {
					workerId: 0,
					emailPattern: {
						destination:null
					},
					properties: []
				},

				actionName: '',
				state: 'loading',
				mode: 'create',
				apiName: '',
				isShowFormGenerator: false,
				isShowEventViewer: false,
				isShowFormViewer: false,
				formValue: null,
			}
		},

		async beforeMount()
		{
			this.getWorkerList();
			this.getPersonRoleList();

			this.initForm(true);
			this.initEventPattern()
		},

		computed:
		{
			processId()
			{
				return Number(this.$route.query.processId)
			},

			currentLevel()
			{
				return Number(this.props.orderNumber.value || 1)
			},

			hasAccessToReturn()
			{
				return this.props.hasAccessToReturn.value
			},

			schema()
			{
				return JSON.parse(this.props.formPattern.value);
			},

			apiProperties()
			{
				if (this.apiName != '')
				{
					const api = this.apiList.find((x) =>
					{
						return x.apiName == this.apiName
					});

					return api ? api.properties : [];
				}

				return [];
			},

			formProperties()
			{
				var properties = [];
				const nodes = this.processNodeList || [];

				if (nodes.length > 0)
				{
					for (var i = 0; i < nodes.length; i++)
					{
						const node = nodes[i];

						if (node.formPattern)
						{
							const formProps = JSON.parse(node.formPattern || "")

							if (formProps)
							{
								var item = {
									id: node.processNodeId,
									title: node.title,
									options: []
								};

								const keys = Object.keys(formProps)
								for (var i2 = 0; i2 < keys.length; i2++)
								{
									const key = keys[i2]
									const formProp = formProps[key]

									item.options.push({
										label: formProp.label,
										name: formProp.name,
									})
								}

								properties.push(item)
							}
						}
					}
				}

				return properties;
			}
		},

		methods:
		{
			async initForm(getProcessNodeList)
			{
				this.props = new ProcessNode().props;
				this.props.processId.value = this.processId;
				this.props.hasAccessToContinue.value = true;
				this.props.hasAccessToComplete.value = false;
				this.props.hasAccessToReturn.value = false;
				this.props.hasAccessToStop.value = false;
				this.props.isLimitedToUnit.value = false;
				this.props.isArrayFormValue.value = false;
				this.props.orderNumber.value = this.processNodeList.length + 1;

				this.worker = {
					workerId: 0,
					emailPattern: {
						destination: null
					},
					properties: []
				};

				if (getProcessNodeList)
				{
					await this.getProcessNodeList();
				}
			},

			async initList(entityName, propName)
			{
				try
				{
					const payload = { start: 0, length: 1000 };
					const apiAddress = this.props[propName].serverAddress;

					this[`${entityName}ListIsLoading`] = true;
					const res = await new BasicService().get(payload, apiAddress);

					if (res.status == 200)
					{
						this[`${entityName}List`] = res.data.items;
					}

				} catch (e)
				{
					console.log(e)
				}

				this[`${entityName}ListIsLoading`] = false;
			},

			initEventPattern()
			{
				this.eventPattern = {
					continue: {
						loginService: {
							address: '',
							method: '',
							contentType: '',
							tokenPropertyName: '',
							loginType: '',
							properties: []
						},
						primaryService: {
							address: '',
							method: '',
							contentType: '',
							properties: []
						}
					},

					complete: {
						loginService: {
							address: '',
							method: '',
							contentType: '',
							tokenPropertyName: '',
							loginType: '',
							properties: []
						},
						primaryService: {
							address: '',
							method: '',
							contentType: '',
							properties: []
						}
					},

					return: {
						loginService: {
							address: '',
							method: '',
							contentType: '',
							tokenPropertyName: '',
							loginType: '',
							properties: []
						},
						primaryService: {
							address: '',
							method: '',
							contentType: '',
							properties: []
						}
					},

					stop: {
						loginService: {
							address: '',
							method: '',
							contentType: '',
							tokenPropertyName: '',
							loginType: '',
							properties: []
						},
						primaryService: {
							address: '',
							method: '',
							contentType: '',
							properties: []
						}
					},
				}
			},

			isRequired(name)
			{
				return this.props[name].setConfig.patterns.includes('required');
			},

			onHandelChange(name)
			{
				if (this.useValidator)
				{
					this.props[name].errors =
						validator.getPropErrors(this.props[name]);
				}

				this.props[name].isChanged = true;
			},

			onHandelKeyUp(name)
			{
				if (this.useValidator && this.props[name].isChanged)
				{
					this.props[name].errors =
						validator.getPropErrors(this.props[name]);
				}
			},

			onHandleEventViewer(actionName)
			{
				this.actionName = actionName;
				this.isShowEventViewer = true;
			},

			onHandleBranchTreeChange(agents)
			{
				var value = [];
				for (var i = 0; i < agents.length; i++)
				{
					value.push(agents[i].branchId);
				}

				this.props.processBranchAgents.value = value;
			},

			onHandleChangeWorker()
			{
				this.worker = {
					workerId: this.worker.workerId,
					emailPattern: {
						destination: null
					},
					properties: []
				}
			},

			getFirstError(name)
			{
				const prop = this.props[name];

				if (prop.errors.length > 0)
				{
					return this.$loc.fromResource(prop.errors[0].resourceKey, {
						label: this.$loc.fromResource(prop.resourceKey),
					})
				}

				return ''
			},

			getOrderLabel(level)
			{
				if (store.state.app.language == 'fa')
				{
					return this.$loc.fromResource(
						'aut.ext.processNode.levelName', { 'label': numberToWords(level) }
					)
				}
				else
				{
					return this.$loc.fromResource(
						'aut.ext.processNode.levelName', { 'label': level }
					)
				}
			},

			getValue()
			{
				if (this.useValidator)
				{
					if (validator.getObjectErrorLength(this.props) > 0)
					{
						return undefined;
					}
				}

				var value = {};
				var keys = Object.keys(this.props);
				for (var i = 0; i < keys.length; i++)
				{
					const key = keys[i];
					const prop = this.props[key];

					value[key] = prop.value
				}

				if (value.hasAccessToReturn == false)
					value.returnNodes = null

				var eventPattern = {};
				if (this.eventPattern.continue.primaryService.address != '')
					eventPattern.continue = this.eventPattern.continue

				if (this.eventPattern.stop.primaryService.address != '')
					eventPattern.stop = this.eventPattern.stop

				if (this.eventPattern.return.primaryService.address != '')
					eventPattern.return = this.eventPattern.return

				if (this.eventPattern.complete.primaryService.address != '')
					eventPattern.complete = this.eventPattern.complete


				if (Object.keys(eventPattern).length > 0)
				{
					value.eventPattern =
						JSON.stringify(eventPattern);
				}

				value.processWorkerAgent = null;
				if (this.worker.workerId > 0)
				{
					value.processWorkerAgent = {
						workerId: this.worker.workerId,
						workerPattern: JSON.stringify(this.worker)
					};
				}

				return value
			},

			async getProcessNodeList()
			{
				var items = [];

				try
				{
					this.processNodeListIsLoading = true;
					const service = new ProcessNodeService();
					const res = await service.list({
						filter: {
							processId: this.processId
						}
					});

					if (res.status == 200)
					{
						items = res.data.items || []
					}

				} catch (e)
				{
					console.log(e)
				}

				this.processNodeListIsLoading = false;
				this.processNodeList = items;
			},

			async getWorkerList()
			{
				var items = [];

				try
				{
					this.workerListIsLoading = true;
					const service = new WorkerService();
					const res = await service.list({ start: 0, length: 50 });

					if (res.status == 200)
					{
						items = res.data.items || []
					}

				} catch (e)
				{
					console.log(e)
				}

				this.workerListIsLoading = false;
				this.workerList = items;
			},

			async getPersonRoleList()
			{
				var items = [];

				try
				{
					this.personRoleListIsLoading = true;
					const service = new PersonRoleService();
					const res = await service.list({
						start: 0,
						length: 1000
					});

					if (res.status == 200)
					{
						items = res.data.items || []
					}

				} catch (e)
				{
					console.log(e)
				}

				this.personRoleListIsLoading = false;
				this.personRoleList = items;
			},

			async setFormData(index)
			{
				if (this.processNodeList.length > index)
				{
					var value =
						{ ...this.processNodeList[index] };

					this.initEventPattern()
					if (value.eventPattern)
					{
						var event = JSON.parse(value.eventPattern);
						var eventKeys = Object.keys(event);

						for (var i = 0; i < eventKeys.length; i++)
						{
							const actionName = eventKeys[i];
							this.eventPattern[actionName] = event[actionName]
						}
					}

					this.$refs.BranchTreeRef.reset()
					if (value.processBranchAgents)
					{
						var processBranchAgents = [];
						for (var i = 0; i < value.processBranchAgents.length; i++)
						{
							processBranchAgents.push({
								branchId: value.processBranchAgents[i]
							})
						}

						this.$refs.BranchTreeRef.bindChecked(processBranchAgents)
					}

					if (value.processWorkerAgent)
					{
						this.worker =
							value.processWorkerAgent.workerPattern == "" ?
							value.processWorkerAgent : JSON.parse(value.processWorkerAgent.workerPattern);
					}

					this.props = new ProcessNode({ value: value }).props;
					this.mode = 'update'
				}
				else
				{
					this.mode = 'create'
					this.initEventPattern()
					this.$refs.BranchTreeRef.reset()
					await this.initForm(false);
				}
			},

			handleFormGeneratorSubmit(pattern)
			{
				this.props.formPattern.value = JSON.stringify(pattern)
				this.isShowFormGenerator = false;
			},

			async submit()
			{
				try
				{
					const payload = this.getValue();

					if (payload)
					{
						this.isLoadingSubmit = true;
						const service = new ProcessNodeService();
						const res = await service.management(payload);

						if (res.status == 200)
						{
							this.initForm(true);
						}
					}

				} catch (e) { console.log(e) }

				this.isLoadingSubmit = false;
			}
		}
	}
</script>
<style>
	.proccess-node-management .sidebar,
	.proccess-node-management .form {
		width: 100%;
		height: auto;
	}

	.proccess-node-management .form {
		background: #f8f8f8;
		padding: 20px;
	}

		.proccess-node-management .sidebar .item {
			width: 100%;
			min-height: 50px;
			border: #ddd solid 2px;
			background: #fff;
			padding: 30px 10px 10px 10px;
			text-align: center;
			cursor: pointer;
			position: relative;
			margin-bottom: 10px;
		}

			.proccess-node-management .sidebar .item .level {
				width: 80px;
				position: absolute;
				top: 10px;
				right: 10px;
				left: 10px;
				border-radius: 4px;
				display: inline-block;
				padding: 2px 0;
			}

			.proccess-node-management .sidebar .item.active {
				border: var(--kaveh-base-color) dashed 2px;
			}

			.proccess-node-management .sidebar .item:hover {
				background: #f8f8f8;
			}
</style>
