import Server from './server'
export default class BasicService
{
	constructor() { }

	server(config)
	{
		return new Server(config)
	}

	async get(params, address)
	{
		const _server =
			this.server({ HSR: false, HER: true });

		return await _server.get(address, { 'params': params });
	};
}
