import { ENUMS, BaseEntity } from '@/core/data'
import PersonAccess from './person-access'
export default class Person extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'person';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'hub.personCreate';
	props = {
		personId: {
			primarykey: true,
			name: 'personId',
			resourceKey: 'hub.personCreatePersonId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		legalName: {
			name: 'legalName',
			resourceKey: 'hub.personLegalLegalName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
				stringLength: 50,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		nationalCode: {
			name: 'nationalCode',
			resourceKey: 'hub.personLegalNationalCode',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
				stringLength: 20,
				isUnicode: false,
				isUnique: true,
				patterns: ['number', 'required'],
			},
			errors: [],
			value: null
		},
		registerNumber: {
			name: 'registerNumber',
			resourceKey: 'hub.personLegalRegisterNumber',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
				stringLength: 20,
				isUnicode: false,
				isUnique: false,
				patterns: ['number'],
			},
			errors: [],
			value: null
		},
		economicNumber: {
			name: 'economicNumber',
			resourceKey: 'hub.personLegalEconomicNumber',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
				stringLength: 20,
				isUnicode: false,
				isUnique: false,
				patterns: ['number'],
			},
			errors: [],
			value: null
		},
		phoneNumber: {
			name: 'phoneNumber',
			resourceKey: 'hub.personLegalPhoneNumber',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
				stringLength: 20,
				isUnicode: false,
				isUnique: true,
				patterns: ['number'],
			},
			errors: [],
			value: null
		},
		fax: {
			name: 'fax',
			resourceKey: 'hub.personLegalFax',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
				stringLength: 20,
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		email: {
			name: 'email',
			resourceKey: 'hub.personLegalEmail',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
				stringLength: 100,
				isUnicode: false,
				isUnique: false,
				patterns: ['email'],
			},
			errors: [],
			value: null
		},
		postalCode: {
			name: 'postalCode',
			resourceKey: 'hub.personLegalPostalCode',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
				stringLength: 20,
				isUnicode: false,
				isUnique: true,
				patterns: ['postalCode', 'number'],
			},
			errors: [],
			value: null
		},
		address: {
			name: 'address',
			resourceKey: 'hub.personLegalAddress',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
				stringLength: 150,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},


		personEntityId: {
			foreignKey: true,
			name: 'personEntityId',
			resourceKey: 'hub.personPersonEntityId',
			type: ENUMS.PROP_TYPE.BYTE,
			serverAddress: '/hub/person-entity/list',
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'personEntityId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: 3
		},
		personGroupId: {
			foreignKey: true,
			name: 'personGroupId',
			resourceKey: 'hub.personPersonGroupId',
			type: ENUMS.PROP_TYPE.BYTE,
			serverAddress: '/hub/person-group/list',
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'personGroupId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		personRoleId: {
			foreignKey: true,
			name: 'personRoleId',
			resourceKey: 'hub.personPersonRoleId',
			type: ENUMS.PROP_TYPE.INT16,
			serverAddress: '/hub/person-role/list',
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'personRoleId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		cityId: {
			foreignKey: true,
			name: 'cityId',
			resourceKey: 'hub.personCityId',
			type: ENUMS.PROP_TYPE.INT16,
			serverAddress: '/hub/city/list',
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'nativeName',
				valueKey: 'cityId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		tenantId: {
			foreignKey: true,
			name: 'tenantId',
			resourceKey: 'hub.personTenantId',
			type: ENUMS.PROP_TYPE.INT16,
			serverAddress: '/hub/tenant/list',
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'tenantId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},


		personAccess: {
			name: 'personAccess',
			resourceKey: 'hub.personPersonAccess',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new PersonAccess(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{userName}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}
