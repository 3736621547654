//GeneratorFlag:[NoChange]
export default {
	path: 'feature',
	name: 'store.feature.layout',
	redirect: to => {
		return {name: 'store.feature.list'};
	},
	component: () => import('@/modules/store/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/store/feature"],
		resourceKey: 'store.featureLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[FeatureCreate]"
		{
			path: 'create',
			name: 'store.feature.create',
			component: () => import('@/modules/store/views/feature/feature-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.featureCreate',
				clientAccessKeys:["/store/feature/create"],
				serverAccessKeys:["/store/feature/create","/store/feature/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureCreateImport]"
		{
			path: 'create-import',
			name: 'store.feature.createImport',
			component: () => import('@/modules/store/views/feature/feature-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.featureCreateImport',
				clientAccessKeys:["/store/feature/create-import"],
				serverAccessKeys:["/store/feature/create","/store/feature/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureDelete]"
		{
			path: 'delete',
			name: 'store.feature.delete',
			component: () => import('@/modules/store/views/feature/feature-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.featureDelete',
				clientAccessKeys:["/store/feature/delete"],
				serverAccessKeys:["/store/feature/delete","/store/feature/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureDetail]"
		{
			path: 'detail',
			name: 'store.feature.detail',
			component: () => import('@/modules/store/views/feature/feature-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.featureDetail',
				clientAccessKeys:["/store/feature/detail"],
				serverAccessKeys:["/store/feature/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureList]"
		{
			path: 'list',
			name: 'store.feature.list',
			component: () => import('@/modules/store/views/feature/feature-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.featureList',
				clientAccessKeys:["/store/feature/list"],
				serverAccessKeys:["/store/feature/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureAttachment]"
		{
			path: 'attachment',
			name: 'store.feature.attachment',
			component: () => import('@/modules/store/views/feature/feature-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.featureAttachment',
				clientAccessKeys:["/store/feature/attachment"],
				serverAccessKeys:["/store/feature/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureUpdate]"
		{
			path: 'update',
			name: 'store.feature.update',
			component: () => import('@/modules/store/views/feature/feature-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.featureUpdate',
				clientAccessKeys:["/store/feature/update"],
				serverAccessKeys:["/store/feature/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}