<template>
	<div class="row">
		<div class="col">
			<div class="action-items mb-2 mb-lg-0 p-0">
				<table-action-change-display :displayType="displayType" v-on:displayChange="displayChange" />
				<table-action-filter v-if="useFilter" :filterLen="filterLen" v-on:cleanFilter="cleanFilter" v-on:showFilter="showFilter" />
				<table-action-export v-on:exportExcel="exportExcel" v-on:createReport="createReport" />

				<div v-if="actions.length > 0" style="width:15px; font-size:20px;" class="d-inline-block text-center text-muted">
					<span>|</span>
				</div>

				<template v-for="(action,index) in actions" :key="index">
					<component v-if="hasAccessAction(action)" :is="(action.children ? 'table-action-dropdown':'table-action-button')"
							   v-on:selectAction="selectAction"
							   :action-index="index"
							   :is-loading="index == loadingIndex"
							   :isDisable="isDisable(action)"
							   :action="action" />
				</template>
			</div>
		</div>
	</div>
</template>
<script>

	import tableActionFilter from './defaults/table-action-filter'
	import tableActionExport from './defaults/table-action-export'
	import tableActionChangeDisplay from './defaults/table-action-change-display'
	import tableActionButton from './table-action-button'
	import tableActionDropdown from './table-action-dropdown'

	export default {
		emits: ['displayChange', 'showFilter', 'cleanFilter', 'selectAction', 'exportExcel', 'createReport'],

		components:
		{
			'table-action-filter': tableActionFilter,
			'table-action-export': tableActionExport,
			'table-action-button': tableActionButton,
			'table-action-dropdown': tableActionDropdown,
			'table-action-change-display': tableActionChangeDisplay,
		},

		props: {
			'displayType': String,
			'actions': Array,
			'checkedLen': {
				type: Number,
				default: 0
			},
			'useFilter': {
				type: Boolean,
				default: true
			},
			'filterLen': {
				type: Number,
				default: 0
			},
			loadingIndex: {
				type: Number,
				default: -1
			}
		},

		methods:
		{
			isDisable(action)
			{
				if (action.activeTask)
				{
					const items =
						this.$parent.getCheckeds();

					if (items.length == 0 || action.activeTask(items) == false)
					{
						return true;
					}
				}

				const activeMode = action.activeMode;
				if (activeMode == 'always') { return false }
				if (activeMode == 'single' && this.checkedLen == 1) { return false }
				if (activeMode == 'multiple' && this.checkedLen > 0) { return false }

				return true
			},

			showFilter()
			{
				this.$emit('showFilter')
			},

			cleanFilter()
			{
				this.$emit('cleanFilter')
			},

			displayChange(displayType)
			{
				this.$emit('displayChange', displayType)
			},

			exportExcel()
			{
				this.$emit('exportExcel')
			},

			createReport()
			{
				this.$emit('createReport')
			},

			selectAction(action)
			{
				this.$emit('selectAction', action)
			},


			hasAccessAction(action)
			{
				let route = this.$router.resolve({
					name: action.routeName
				});

				return route.meta.clientHasAccess
			}
		}
	}
</script>
<style>
	.action-items {
		display: block !important;
		overflow-x: auto;
		overflow-y: hidden;
		white-space: nowrap;
	}

	[dir="rtl"] .action-items button i {
		position: relative;
		bottom: -3px;
	}
</style>
