<template>
	<div class="row mb-3">
		<div class="col">
			<router-link :to="{'name':'aut.letter.create'}" class="btn rounded text-light border-0 btn-base-color w-100">
				<kaveh-icon-bootstrap name="bi-pen" size="18px" />
				<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.letter.views.compose')}}</span>
			</router-link>
		</div>
	</div>
	<div class="row mb-3">
		<div class="col">
			<div class="dropdown">
				<button class="btn btn-light dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
					<span v-if="letterBoxId == 1">{{$loc.fromResource('aut.ext.letter.views.inbox')}}</span>
					<span v-if="letterBoxId == 2">{{$loc.fromResource('aut.ext.letter.views.outbox')}}</span>
					<span v-if="letterBoxId == 3">{{$loc.fromResource('aut.ext.letter.views.drafts')}}</span>
					<span v-if="letterBoxId == 4">{{$loc.fromResource('aut.ext.letter.views.archived')}}</span>
				</button>
				<ul class="dropdown-menu">
					<li class="dropdown-item" @click="handleChangeFilter('letterBoxId',1)">
						<div class="page" v-bind:class="{'text-bold': letterBoxId == 1 }">
							<kaveh-icon-bootstrap name="bi-inbox" size="18px" />
							<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.letter.views.inbox')}}</span>
						</div>
					</li>
					<li class="dropdown-item" @click="handleChangeFilter('letterBoxId',2)">
						<div class="page" v-bind:class="{'text-bold': letterBoxId == 2 }">
							<kaveh-icon-bootstrap name="bi-send" size="18px" />
							<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.letter.views.outbox')}}</span>
						</div>
					</li>
					<li class="dropdown-item" @click="handleChangeFilter('letterBoxId',3)">
						<div class="page" v-bind:class="{'text-bold': letterBoxId == 3 }">
							<kaveh-icon-bootstrap name="bi-file-earmark" size="18px" />
							<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.letter.views.drafts')}}</span>
						</div>
					</li>
					<li class="dropdown-item" @click="handleChangeFilter('letterBoxId',4)">
						<div class="page" v-bind:class="{'text-bold': letterBoxId == 4 }">
							<kaveh-icon-bootstrap name="bi-archive" size="18px" />
							<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.letter.views.archived')}}</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="col">
			<div class="dropdown">
				<button class="btn btn-light dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
					<span v-if="letterTagId == null">{{  $loc.fromResource('aut.ext.letter.tag.noTag')}}</span>
					<span v-for="(tag,index) in tags" :key="index">
						<span v-if="letterTagId == tag.letterTagId">{{tag.title}}</span>
					</span>
				</button>
				<ul class="dropdown-menu">
					<li class="dropdown-item" @click="handleChangeFilter('letterTagId',null)">
						<div class="tag" v-bind:class="{'text-bold': letterTagId == null }">
							<span class="color" style="background:#f8f8f8;"></span>
							<span class="ms-2 me-2">{{  $loc.fromResource('aut.ext.letter.tag.noTag')}}</span>
						</div>
					</li>
					<li class="dropdown-item" v-for="(tag,index) in tags" :key="index" @click="handleChangeFilter('letterTagId',tag.letterTagId)">
						<div class="tag" v-bind:class="{'text-bold': letterTagId == tag.letterTagId }">
							<span class="color" :style="{'background':`${tag.hexColor}`}"></span>
							<span class="ms-2 me-2">{{tag.title}}</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="col">
			<div class="dropdown">
				<button class="btn btn-light dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
					<span v-if="letterCategoryId == 1">{{$loc.fromResource('aut.ext.enums.letterCategory.public')}}</span>
					<span v-if="letterCategoryId == 2">{{$loc.fromResource('aut.ext.enums.letterCategory.mailroom')}}</span>
					<span v-if="letterCategoryId == 3">{{$loc.fromResource('aut.ext.enums.letterCategory.toAct')}}</span>
					<span v-if="letterCategoryId == 4">{{$loc.fromResource('aut.ext.enums.letterCategory.toRemind')}}</span>
				</button>
				<ul class="dropdown-menu">
					<li class="dropdown-item" @click="handleChangeFilter('letterCategoryId',1)">
						<div v-bind:class="{'text-bold': letterCategoryId == 1}">
							<kaveh-icon-bootstrap name="bi-file-earmark-text" size="18px" />
							<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.enums.letterCategory.public')}}</span>
						</div>
					</li>
					<li class="dropdown-item" @click="handleChangeFilter('letterCategoryId',2)">
						<div v-bind:class="{'text-bold': letterCategoryId == 2}">
							<kaveh-icon-bootstrap name="bi-archive" size="18px" />
							<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.enums.letterCategory.mailroom')}}</span>
						</div>
					</li>
					<li class="dropdown-item" @click="handleChangeFilter('letterCategoryId',4)">
						<div v-bind:class="{'text-bold': letterCategoryId == 3}">
							<kaveh-icon-bootstrap name="bi-activity" size="18px" />
							<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.enums.letterCategory.toAct')}}</span>
						</div>
					</li>
					<li class="dropdown-item" @click="handleChangeFilter('letterCategoryId',4)">
						<div v-bind:class="{'text-bold': letterCategoryId == 4}">
							<kaveh-icon-bootstrap name="bi-exclamation-circle" size="18px" />
							<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.enums.letterCategory.toRemind')}}</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<kaveh-overlay :is-loading="lettersState == 'loading'" :is-empty="lettersState != 'loading' && letters.length == 0" class="bg-light rounded pt-4 pb-4 ps-2 pe-2">
				<template v-slot:noContent>
					{{$loc.fromResource('aut.ext.letter.list.empty')}}
				</template>
				<div class="bg-light border-bottom mb-2 p-3" v-for="(item, index) in letters" :key="index">
					<router-link :to="generateLink(item)" :class="{'text-bold': item.isRead == false}">
						<div class="text-end">
							<kaveh-icon-bootstrap v-if="item.isPin" color="var(--kaveh-base-color)" name="bi-pin-fill" size="15px" />
						</div>
						<div>
							<kaveh-icon-bootstrap :name="getLetterIcon(item)" size="15px" />
						</div>
						<div>{{item.letterNumber}}</div>
						<div>{{ item.title }}</div>
						<div>
							<span v-html="item.summary"></span><span>...</span>
						</div>
						<div>
							<small dir="ltr">{{ item.localCreateDate }} - {{item.createTime}}</small>
						</div>
					</router-link>
				</div>
			</kaveh-overlay>
		</div>
	</div>
	<div class="row mt-2" v-if="totalRows > 0">
		<div class="col">
			<letter-list-pagination :total="totalRows"
									:pageSize="pageSize"
									:current="currentPage"
									:totalfilters="totalfilters"
									v-on:change="initLetters" />
		</div>
	</div>
</template>
<script>
	import { url } from '@/plugins'
	import { LetterTagService, LetterService } from '../../../services'
	import LetterListPagination from './letter-list-pagination'

	export default {

		components: {
			'letter-list-pagination': LetterListPagination
		},

		data()
		{
			return {
				tags: [],
				letters: [],
				tagsState: "loading",
				lettersState: "loading",

				pageSize: 10,
				currentPage: 1,
				totalfilters: 0,
				totalRows: 0
			}
		},

		beforeMount()
		{
			if (this.branchId == 0)
			{
				this.$router.push({ name: 'hub.branch.accessDenied' })
			}
			else
			{
				this.initTags();
				this.initLetters();
			}
		},

		computed:
		{
			branchId()
			{
				return this.$store.state.client.branchId
			},

			letterCategoryId()
			{
				const letterCategoryId =
					this.$route.query.letterCategoryId;

				if (letterCategoryId)
				{
					return Number(letterCategoryId)
				}

				return null;
			},

			letterBoxId()
			{
				const letterBoxId =
					this.$route.query.letterBoxId;

				if (letterBoxId)
				{
					return Number(letterBoxId)
				}

				return 1;
			},

			letterTagId()
			{
				const letterTagId =
					this.$route.query.letterTagId;

				if (letterTagId)
				{
					return Number(letterTagId)
				}

				return null;
			},
		},

		methods:
		{
			getLetterIcon(item)
			{

				if (item.isRead)
				{
					return "bi-envelope-open";
				}

				return ['', 'bi-envelope-fill', 'bi-reply', 'bi-forward'][item.letterTypeId]
			},

			generateLink(item)
			{
				return {
					name: 'aut.letter.detail', query: {
						'key': url.encript(item.letterId),
						'node': url.encript(item.letterNodeId)
					}
				}
			},

			async handleChangeFilter(key, value)
			{
				var query =
					Object.assign({},
						this.$route.query
					);

				query[key] = value
				await this.$router.replace({ query });

				await this.initLetters();
			},

			async initTags()
			{
				var items = [];

				try
				{
					const res = await new LetterTagService().list({
						start: 0,
						length: 1000
					});

					if (res.status == 200)
					{
						items = this.$loc.fromList(res.data.items || []);
					}

				} catch (e) { console.log(e); }

				this.tags = items;
				this.tagsState = items.length > 0 ? "ready" : "empty"
			},

			async initLetters()
			{
				var items = [];

				try
				{
					var query = this.$route.query
					this.pageSize = Number(query.pageSize) || 10;
					this.currentPage = Number(query.currentPage) || 1;
					this.query = query.query || "";

					this.lettersState = "loading"
					const res = await new LetterService().list({
						query: this.query,
						length: this.pageSize,
						start: ((this.currentPage - 1) * this.pageSize),
						filter: {
							letterBoxId: Number(this.letterBoxId),
							letterCategoryId: Number(this.letterCategoryId),
							letterTagId: Number(this.letterTagId),
						}
					}, { HSR: false });

					if (res.status == 200)
					{
						items = res.data.items || [];
						this.totalRows = res.data.total
						this.totalfilters = res.data.filtered
					}

				} catch (e) { console.log(e); }

				this.letters = items;
				this.lettersState = items.length > 0 ? "ready" : "empty"
			}
		}
	}

</script>
<style>

	.letter-managemet .page-items,
	.letter-managemet .tag-items {
		list-style: none;
		padding: 20px 10px;
	}

	.letter-managemet .page,
	.letter-managemet .tag {
		margin: 10px 0;
		cursor: pointer;
		transition: all .2s;
	}

		.letter-managemet .page:hover,
		.letter-managemet .tag:hover {
			padding-right: 5px;
		}

			.letter-managemet .page:hover > i,
			.letter-managemet .tag:hover > i {
				color: var(--kaveh-base-color) !important;
			}

		.letter-managemet .tag .color {
			width: 15px;
			height: 15px;
			border-radius: 100%;
			display: inline-block;
			border: #ddd solid 1px;
		}
</style>
