//GeneratorFlag:[NoChange]
import ext from './en-ext.js'
export default {
	"name":"cms",
	"ext": ext,
	"layout":{
		"basis": "Basic Information", //GeneratorFlag:[BasisLayout]
//GeneratorFlag:[NewLayout]
	},
	'blogCategory':'BlogCategory',
	'blogCategoryBlogCategoryId':'blog category id',
	'blogCategoryBlogCategoryTrack':'blog category track',
	'blogCategoryCaption':'caption',
	'blogCategoryCreate':'Create New BlogCategory',
	'blogCategoryCreateImport':'Imports BlogCategory From Excel',
	'blogCategoryDelete':'Delete BlogCategory',
	'blogCategoryDetail':'Detail of BlogCategory',
	'blogCategoryIdentifier':'identifier',
	'blogCategoryImageAttachment':'Attach images',
	'blogCategoryLayout':'Create BlogCategory',
	'blogCategoryList':'List of BlogCategory',
	'blogCategoryParentId':'parent',
	'blogCategoryStringContent':'string content',
	'blogCategoryTitle':'title',
	'blogCategoryUpdate':'Edit BlogCategory',
	'blogContent':'BlogContent',
	'blogContentBlogCategory':'blog category',
	'blogContentBlogCategoryId':'blog category',
	'blogContentBlogContentId':'blog content id',
	'blogContentBlogContentTrack':'blog content track',
	'blogContentCaption':'caption',
	'blogContentCreate':'Create New BlogContent',
	'blogContentCreateImport':'Imports BlogContent From Excel',
	'blogContentDelete':'Delete BlogContent',
	'blogContentDetail':'Detail of BlogContent',
	'blogContentIdentifier':'identifier',
	'blogContentImageAttachment':'Attach images',
	'blogContentIsActive':'is active',
	'blogContentKeywords':'keywords',
	'blogContentLayout':'BlogContent',
	'blogContentList':'List of BlogContent',
	'blogContentSlug':'slug',
	'blogContentStringContent':'string content',
	'blogContentTitle':'title',
	'blogContentUpdate':'Edit BlogContent',
	'staticContent':'StaticContent',
	'staticContentAttachment':'Attach files',
	'staticContentContentKey':'content key',
	'staticContentCreate':'Create New StaticContent',
	'staticContentCreateImport':'Imports StaticContent From Excel',
	'staticContentDelete':'Delete StaticContent',
	'staticContentDetail':'Detail of StaticContent',
	'staticContentIdentifier':'identifier',
	'staticContentLayout':'StaticContent',
	'staticContentList':'List of StaticContent',
	'staticContentOrderNumber':'order number',
	'staticContentStaticContentId':'static content id',
	'staticContentStaticContentTrack':'static content track',
	'staticContentStringContent':'string content',
	'staticContentTitle':'title',
	'staticContentUpdate':'Edit StaticContent',
	'staticPage':'StaticPage',
	'staticPageCaption':'caption',
	'staticPageCreate':'Create New StaticPage',
	'staticPageCreateImport':'Imports StaticPage From Excel',
	'staticPageDelete':'Delete StaticPage',
	'staticPageDetail':'Detail of StaticPage',
	'staticPageIdentifier':'identifier',
	'staticPageImageAttachment':'Attach images',
	'staticPageIsActive':'is active',
	'staticPageKeywords':'keywords',
	'staticPageLayout':'StaticPage',
	'staticPageList':'List of StaticPage',
	'staticPageSlug':'slug',
	'staticPageStaticPageId':'static page id',
	'staticPageStaticPageTrack':'static page track',
	'staticPageStringContent':'string content',
	'staticPageTitle':'title',
	'staticPageUpdate':'Edit StaticPage',

}