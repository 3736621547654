<template>
	<kaveh-excel-import :key="ver" :entity="entity" :task="import" stream-address="hub/person/stream-create" @close="reset" @cancel="reset" />
</template>
<script>
	import { Person as PersonReal } from './data/create-real'
	import { Person as PersonLegal } from './data/create-legal'
	import { Person as PersonSystem } from './data/create-system'
	import { PersonService } from '@/modules/hub/services'
	export default {
		data()
		{
			return {
				ver: 0,
				type: this.$route.query.type
			}
		},
		computed:
		{
			entity()
			{
				if (this.type == "real")
				{
					return PersonReal
				}

				else if (this.type == "legal")
				{
					return PersonLegal
				}

				return PersonSystem
			},

			service()
			{
				return new PersonService()
			},
		},
		methods:
		{
			async import(payload)
			{
				var person = {
					personName: payload.personName,
					personEntityId: payload.personEntityId,
					personGroupId: payload.personGroupId,
					personRoleId: payload.personRoleId,
					cityId: payload.cityId,
					tenantId: payload.tenantId,
					personAccess: payload.personAccess
				}

				if (this.type == "real")
				{
					person.personName =
						`${payload.firstName} ${payload.lastName}`;

					person.personReal = {
						firstName: payload.firstName,
						lastName: payload.lastName,
						fullName: person.personName,
						fatherName: payload.fatherName,
						motherName: payload.motherName,
						nationalCode: payload.nationalCode,
						identification: payload.identification,
						mobile: payload.mobile,
						phoneNumber: payload.phoneNumber,
						email: payload.email,
						placeOfBirth: payload.placeOfBirth,
						postalCode: payload.postalCode,
						birthDate: payload.birthDate,
						workAddress: payload.workAddress,
						homeAddress: payload.homeAddress,
						personNationalityId: payload.personNationalityId,
						personGenderId: payload.personGenderId,
						personGradeId: payload.personGradeId,
						personMaritalId: payload.personMaritalId,
						personMilitaryId: payload.personMilitaryId
					}

					person.personVehicle =
						payload.personVehicle;
				}

				else if (this.type == "legal")
				{
					person.personName =
						payload.legalName;

					person.personLegal = {
						legalName: payload.legalName,
						nationalCode: payload.nationalCode,
						registerNumber: payload.registerNumber,
						economicNumber: payload.economicNumber,
						phoneNumber: payload.phoneNumber,
						fax: payload.fax,
						email: payload.email,
						postalCode: payload.postalCode,
						address: payload.address
					};
				}

				return await this.service.create(person, { HSR: false, HER: false });
			},

			reset()
			{
				this.ver = this.ver + 1;
			}
		}
	}
</script>
