//GeneratorFlag:[NoChange]
export default {
	path: 'warehouse',
	name: 'store.basis.warehouse.layout',
	redirect: to => {
		return {name: 'store.warehouse.list'};
	},
	component: () => import('@/modules/store/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/store/basis/warehouse"],
		resourceKey: 'store.warehouseLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[WarehouseCreate]"
		{
			path: 'create',
			name: 'store.warehouse.create',
			component: () => import('@/modules/store/views/warehouse/warehouse-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.warehouseCreate',
				clientAccessKeys:["/store/basis/warehouse/create"],
				serverAccessKeys:["/store/warehouse/create","/store/warehouse/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WarehouseCreateImport]"
		{
			path: 'create-import',
			name: 'store.warehouse.createImport',
			component: () => import('@/modules/store/views/warehouse/warehouse-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.warehouseCreateImport',
				clientAccessKeys:["/store/basis/warehouse/create-import"],
				serverAccessKeys:["/store/warehouse/create","/store/warehouse/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WarehouseDelete]"
		{
			path: 'delete',
			name: 'store.warehouse.delete',
			component: () => import('@/modules/store/views/warehouse/warehouse-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.warehouseDelete',
				clientAccessKeys:["/store/basis/warehouse/delete"],
				serverAccessKeys:["/store/warehouse/delete","/store/warehouse/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WarehouseDetail]"
		{
			path: 'detail',
			name: 'store.warehouse.detail',
			component: () => import('@/modules/store/views/warehouse/warehouse-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.warehouseDetail',
				clientAccessKeys:["/store/basis/warehouse/detail"],
				serverAccessKeys:["/store/warehouse/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WarehouseList]"
		{
			path: 'list',
			name: 'store.warehouse.list',
			component: () => import('@/modules/store/views/warehouse/warehouse-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.warehouseList',
				clientAccessKeys:["/store/basis/warehouse/list"],
				serverAccessKeys:["/store/warehouse/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WarehouseAttachment]"
		{
			path: 'attachment',
			name: 'store.warehouse.attachment',
			component: () => import('@/modules/store/views/warehouse/warehouse-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.warehouseAttachment',
				clientAccessKeys:["/store/basis/warehouse/attachment"],
				serverAccessKeys:["/store/warehouse/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WarehouseUpdate]"
		{
			path: 'update',
			name: 'store.warehouse.update',
			component: () => import('@/modules/store/views/warehouse/warehouse-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'store.warehouseUpdate',
				clientAccessKeys:["/store/basis/warehouse/update"],
				serverAccessKeys:["/store/warehouse/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}