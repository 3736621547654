import ext from './de-ext.js'
export default {
	"name":"hub",
	"ext": ext,
	"layout":{
		"geographical": "Geografische Informationen",
		"basis": "Grundlegende Informationen"
	},
	"accessAgent": "Zugriffsverwaltung",
	"accessAgentAccessKeys": "Schlüssel",
	"accessAgentLayout": "Zugriffsverwaltung",
	"accessAgentSave": "Zugriffsverwaltung",
	"accessAgentSaveIsSuccess": "Ergebnis",
	"accessAgentSavePersonId": "Benutzer",
	"accessAgentSavePersonRoleId": "Benutzerrolle",
	"accessToken": "Token erhalten",
	"accessTokenGenerate": "Token erhalten",
	"accessTokenGenerateExpirationDate": "Ablaufdatum",
	"accessTokenGeneratePassword": "Passwort",
	"accessTokenGenerateToken": "Token",
	"accessTokenGenerateTokenType": "Token-Typ",
	"accessTokenGenerateUserName": "Benutzername",
	"accessTokenLayout": "Token erhalten",
	"branch": "Organisationale Abteilung",
	"branchAddress": "Büroadresse",
	"branchBranchId": "ID",
	"branchBranchName": "Abteilungsname",
	"branchBranchTrack": "Registrierungsverfolgungsinformationen",
	"branchBranchType": "Eltern",
	"branchBranchTypeId": "Eltern",
	"branchCity": "Bürostadt",
	"branchCityId": "Bürostadt",
	"branchCreate": "Neue organisatorische Abteilung registrieren",
	"branchCreateAddress": "Adresse",
	"branchCreateBranchId": "ID",
	"branchCreateBranchName": "Abteilungstitel",
	"branchCreateBranchTypeId": "Eltern",
	"branchCreateCityId": "Stadt",
	"branchCreateParentId": "Eltern",
	"branchCreatePhone": "Telefon",
	"branchDelete": "Organisationale Abteilung löschen",
	"branchDetail": "Details zur organisatorischen Abteilungsinformation",
	"branchLayout": "Organisationale Abteilungen",
	"branchList": "Liste der organisatorischen Abteilungen",
	"branchMove": "Organisationale Abteilungen verschieben",
	"branchParentId": "Eltern",
	"branchParentName": "Elterntitel",
	"branchPhone": "Bürotelefon",
	"branchPosition": "Position",
	"branchTenantId": "Zentrum",
	"branchTree": "Baum",
	"branchTypeBranchTypeId": "ID",
	"branchTypeCaption": "Beschreibung",
	"branchTypeTitle": "Titel",
	"branchUpdate": "Organisationale Abteilung bearbeiten",
	"branchUpdateAddress": "Adresse",
	"branchUpdateBranchId": "ID",
	"branchUpdateBranchName": "Abteilungstitel",
	"branchUpdateBranchTypeId": "Eltern",
	"branchUpdateCityId": "Stadt",
	"branchUpdateParentId": "Eltern",
	"branchUpdatePhone": "Telefon",
	"city": "Stadt",
	"cityAttachment": "Dateianhang",
	"cityCaption": "Beschreibung",
	"cityCityId": "ID",
	"cityCityTrack": "Registrierungsverfolgungsinformationen",
	"cityCreate": "Neue Stadt registrieren",
	"cityCreateImport": "Städte über Excel registrieren",
	"cityDelete": "Stadt löschen",
	"cityDetail": "Details zur Stadtinformation",
	"cityIdentifier": "Eindeutiger Schlüssel",
	"cityLayout": "Städte",
	"cityList": "Liste der Städte",
	"cityNativeName": "Stadtname",
	"cityProvince": "Provinz",
	"cityProvinceId": "Provinz",
	"cityUpdate": "Stadt bearbeiten",
	"country": "Land",
	"countryAttachment": "Dateianhang",
	"countryCapitalName": "Hauptstadtnamen",
	"countryCaption": "Beschreibung",
	"countryCode": "Ländercode",
	"countryCountryId": "ID",
	"countryCountryTrack": "Registrierungsverfolgungsinformationen",
	"countryCreate": "Neues Land registrieren",
	"countryCreateImport": "Länder über Excel registrieren",
	"countryDelete": "Land löschen",
	"countryDetail": "Details zur Länderinformation",
	"countryIdentifier": "Eindeutiger Schlüssel",
	"countryLayout": "Länder",
	"countryList": "Liste der Länder",
	"countryNativeName": "Ländername",
	"countryUpdate": "Land bearbeiten",
	"keyValue": "Schlüssel",
	"keyValueAttachment": "Dateianhang",
	"keyValueCreate": "Neuen Schlüssel registrieren",
	"keyValueCreateImport": "Schlüssel über Excel registrieren",
	"keyValueDelete": "Schlüssel löschen",
	"keyValueDetail": "Details zur Schlüsselinformation",
	"keyValueIdentifier": "Eindeutiger Schlüssel",
	"keyValueIsPublic": "Ist der Schlüssel öffentlich?",
	"keyValueKeyValueId": "ID",
	"keyValueKeyValueTrack": "Registrierungsverfolgungsinformationen",
	"keyValueLayout": "Schlüssel",
	"keyValueList": "Liste der Schlüssel",
	"keyValueTextKey": "Schlüssel",
	"keyValueTextValue": "Wert",
	"keyValueTitle": "Titel",
	"keyValueUpdate": "Schlüssel bearbeiten",
	"person": "Person",
	"personAccess": "Zugriffsinformationen",
	"personAccessAccessIps": "Autorisierte IPs",
	"personAccessDetail": "Details zu den Zugriffsinformationen",
	"personAccessForceChangePassword": "Passwort beim ersten Login ändern?",
	"personAccessIsActive": "Ist der Zugriff aktiv?",
	"personAccessPassword": "Passwort",
	"personAccessPersonId": "Personen-ID",
	"personAccessTwoStepLogin": "Zwei-Stufen-Anmeldung?",
	"personAccessUpdate": "Zugriffsinformationen bearbeiten",
	"personAccessUseHardwareToken": "Hardware-Token?",
	"personAccessUserName": "Benutzername",
	"personAttachment": "Dateianhang",
	"personChangePassword": "Passwort ändern",
	"personChangePasswordNewPassword": "Neues Passwort",
	"personChangePasswordOldPassword": "Aktuelles Passwort",
	"personCity": "Stadt",
	"personCityId": "Stadt",
	"personCreate": "Neue Person registrieren",
	"personCreateImport": "Personen über Excel registrieren",
	"personDelete": "Person löschen",
	"personDetail": "Details zur Person",
	"personEntityCaption": "Beschreibung",
	"personEntityPersonEntityId": "ID",
	"personEntityTitle": "Titel",
	"personGenderCaption": "Beschreibung",
	"personGenderPersonGenderId": "ID",
	"personGenderTitle": "Titel",
	"personGradeCaption": "Beschreibung",
	"personGradePersonGradeId": "ID",
	"personGradeTitle": "Titel",
	"personGroupCaption": "Beschreibung",
	"personGroupPersonGroupId": "ID",
	"personGroupTitle": "Titel",
	"personIdentifier": "Eindeutiger Schlüssel",
	"personLayout": "Personen",
	"personLegal": "Rechtliche Informationen",
	"personLegalAddress": "Adresse",
	"personLegalDetail": "Details zu den rechtlichen Informationen",
	"personLegalEconomicNumber": "Wirtschafts-ID",
	"personLegalEmail": "E-Mail",
	"personLegalFax": "Fax",
	"personLegalLegalName": "Rechtsname",
	"personLegalNationalCode": "Nationale ID",
	"personLegalPhoneNumber": "Telefonnummer",
	"personLegalPostalCode": "Postleitzahl",
	"personLegalRegisterNumber": "Registrierungs-ID",
	"personLegalUpdate": "Rechtliche Informationen bearbeiten",
	"personList": "Liste der Personen",
	"personMaritalCaption": "Beschreibung",
	"personMaritalPersonMaritalId": "ID",
	"personMaritalTitle": "Titel",
	"personMilitary": "Militärstatus",
	"personMilitaryAttachment": "Dateianhang",
	"personMilitaryCaption": "Beschreibung",
	"personMilitaryCreate": "Neuen Militärstatus registrieren",
	"personMilitaryCreateImport": "Militärstatus über Excel registrieren",
	"personMilitaryDelete": "Militärstatus löschen",
	"personMilitaryDetail": "Details zum Militärstatus",
	"personMilitaryIdentifier": "Eindeutiger Schlüssel",
	"personMilitaryLayout": "Militärstatus",
	"personMilitaryList": "Liste der Militärstatus",
	"personMilitaryPersonMilitaryId": "ID",
	"personMilitaryPersonMilitaryTrack": "Registrierungsverfolgungsinformationen",
	"personMilitaryPersonReal": "Reale Person",
	"personMilitaryTitle": "Titel",
	"personMilitaryUpdate": "Militärstatus bearbeiten",
	"personNationality": "Nationalität",
	"personNationalityAttachment": "Dateianhang",
	"personNationalityCaption": "Beschreibung",
	"personNationalityCreate": "Neue Nationalität registrieren",
	"personNationalityCreateImport": "Nationalitäten über Excel registrieren",
	"personNationalityDelete": "Nationalität löschen",
	"personNationalityDetail": "Details zur Nationalität",
	"personNationalityIdentifier": "Eindeutiger Schlüssel",
	"personNationalityLayout": "Nationalitäten",
	"personNationalityList": "Liste der Nationalitäten",
	"personNationalityPersonNationalityId": "ID",
	"personNationalityPersonNationalityTrack": "Registrierungsverfolgungsinformationen",
	"personNationalityPersonReal": "Reale Person",
	"personNationalityTitle": "Titel",
	"personNationalityUpdate": "Nationalität bearbeiten",
	"personPersonAccess": "Zugriffsinformationen",
	"personPersonEntity": "Einheitstyp",
	"personPersonEntityId": "Einheitstyp",
	"personPersonGroup": "Gruppe",
	"personPersonGroupId": "Gruppe",
	"personPersonId": "ID",
	"personPersonLegal": "Rechtliche Informationen",
	"personPersonName": "Name",
	"personPersonReal": "Reale Informationen",
	"personPersonRole": "Benutzerrolle",
	"personPersonRoleId": "Benutzerrolle",
	"personPersonTrack": "Registrierungsverfolgungsinformationen",
	"personPersonVehicle": "Fahrzeuginformationen",
	"personReal": "Reale Informationen",
	"personRealBirthDate": "Geburtsdatum",
	"personRealDetail": "Details zu den realen Informationen",
	"personRealEmail": "E-Mail",
	"personRealFatherName": "Name des Vaters",
	"personRealFirstName": "Vorname",
	"personRealFullName": "Vollständiger Name",
	"personRealHomeAddress": "Heimatadresse",
	"personRealIdentification": "Identifikationsnummer",
	"personRealLastName": "Nachname",
	"personRealMobile": "Handy",
	"personRealMotherName": "Name der Mutter",
	"personRealNationalCode": "Nationalschlüssel",
	"personRealPersonGender": "Geschlecht",
	"personRealPersonGenderId": "Geschlecht",
	"personRealPersonGrade": "Bildungsgrad",
	"personRealPersonGradeId": "Bildungsgrad",
	"personRealPersonId": "Personen-ID",
	"personRealPersonMarital": "Familienstand",
	"personRealPersonMaritalId": "Familienstand",
	"personRealPersonMilitary": "Militärstatus",
	"personRealPersonMilitaryId": "Militärstatus",
	"personRealPersonNationality": "Nationalität",
	"personRealPersonNationalityId": "Nationalität",
	"personRealPhoneNumber": "Telefonnummer",
	"personRealPlaceOfBirth": "Geburtsort",
	"personRealPostalCode": "Postleitzahl",
	"personRealUpdate": "Reale Informationen bearbeiten",
	"personRealWorkAddress": "Arbeitsadresse",
	"personRole": "Benutzerrolle",
	"personRoleAttachment": "Dateianhang",
	"personRoleCaption": "Beschreibung",
	"personRoleCreate": "Neue Benutzerrolle registrieren",
	"personRoleCreateImport": "Benutzerrollen über Excel registrieren",
	"personRoleDelete": "Benutzerrolle löschen",
	"personRoleDetail": "Details zur Benutzerrolle",
	"personRoleIdentifier": "Eindeutiger Schlüssel",
	"personRoleLayout": "Benutzerrollen",
	"personRoleList": "Liste der Benutzerrollen",
	"personRolePersonRoleId": "ID",
	"personRolePersonRoleTrack": "Registrierungsverfolgungsinformationen",
	"personRoleTenant": "ID",
	"personRoleTenantId": "ID",
	"personRoleTitle": "Titel",
	"personRoleUpdate": "Benutzerrolle bearbeiten",
	"personTenant": "Zentrum",
	"personTenantId": "Zentrum",
	"personUpdate": "Person bearbeiten",
	"personVehicle": "Fahrzeuginformationen",
	"personVehicleCertificateNumber": "Kennzeichennummer",
	"personVehicleDetail": "Details zu den Fahrzeuginformationen",
	"personVehiclePersonId": "ID",
	"personVehicleUpdate": "Fahrzeuginformationen bearbeiten",
	"personVehicleVehicleColor": "Farbe",
	"personVehicleVehicleName": "Fahrzeugname",
	"personVehicleVehiclePlaque": "Nummernschild",
	"position": "Position",
	"positionBranch": "Organisationsposition",
	"positionBranchId": "Organisationsposition",
	"positionCreate": "Neue Position registrieren",
	"positionCreateImport": "Positionen über Excel registrieren",
	"positionCreateTime": "Registrierungszeit",
	"positionCreatorName": "Registrierter Benutzer",
	"positionDelete": "Position löschen",
	"positionDetail": "Details zur Position",
	"positionEndDate": "Enddatum der Position",
	"positionHasAccessExternalSign": "Fähigkeit zum Unterzeichnen externer Briefe",
	"positionHasAccessFirstSign": "Fähigkeit zum Erstunterzeichnen",
	"positionHasAccessInternalSign": "Fähigkeit zum Unterzeichnen interner Briefe",
	"positionHasAccessSecondSign": "Fähigkeit zum Zweitunterzeichnen",
	"positionIsDefaultPosition": "Standardposition",
	"positionLayout": "Position",
	"positionList": "Liste der Positionen",
	"positionLocalCreateDate": "Registrierungsdatum",
	"positionPerson": "Personal",
	"positionPersonId": "Personal",
	"positionPersonName": "Name",
	"positionPositionId": "ID",
	"positionPositionTrack": "Registrierungsverfolgungsinformationen",
	"positionStartDate": "Startdatum der Position",
	"positionUpdate": "Position bearbeiten",
	"province": "Provinz",
	"provinceAttachment": "Dateianhang",
	"provinceCaption": "Beschreibung",
	"provinceCountry": "Land",
	"provinceCountryId": "Land",
	"provinceCreate": "Neue Provinz registrieren",
	"provinceCreateImport": "Provinzen über Excel registrieren",
	"provinceDelete": "Provinz löschen",
	"provinceDetail": "Details zur Provinz",
	"provinceIdentifier": "Eindeutiger Schlüssel",
	"provinceLayout": "Provinzen",
	"provinceList": "Liste der Provinzen",
	"provinceNativeName": "Provinzname",
	"provinceProvinceId": "ID",
	"provinceProvinceTrack": "Registrierungsverfolgungsinformationen",
	"provinceUpdate": "Provinz bearbeiten",
	"region": "Region",
	"regionAttachment": "Dateianhang",
	"regionCaption": "Beschreibung",
	"regionCity": "Stadt",
	"regionCityId": "Stadt",
	"regionCreate": "Neue Region registrieren",
	"regionCreateImport": "Regionen über Excel registrieren",
	"regionDelete": "Region löschen",
	"regionDetail": "Details zur Region",
	"regionIdentifier": "Eindeutiger Schlüssel",
	"regionLayout": "Regionen",
	"regionList": "Liste der Regionen",
	"regionNativeName": "Regionname",
	"regionRegionId": "ID",
	"regionRegionTrack": "Registrierungsverfolgungsinformationen",
	"regionUpdate": "Region bearbeiten",
	"tenant": "Zentrum",
	"tenantAddress": "Adresse",
	"tenantCaption": "Beschreibung",
	"tenantCity": "Stadt",
	"tenantCityId": "Stadt",
	"tenantCreate": "Neues Zentrum registrieren",
	"tenantCreateImport": "Zentren über Excel registrieren",
	"tenantDelete": "Zentrum löschen",
	"tenantDetail": "Details zum Zentrum",
	"tenantInstagramAddress": "Instagram-Adresse",
	"tenantLayout": "Zentren",
	"tenantList": "Liste der Zentren",
	"tenantOtherSocialMedia": "Andere Social-Media-Adresse",
	"tenantParentId": "Tochtergesellschaft",
	"tenantSiteAddress": "Website-Adresse",
	"tenantSubDomain": "Subdomain",
	"tenantTenantId": "ID",
	"tenantTenantTrack": "Registrierungsverfolgungsinformationen",
	"tenantTenantType": "Zentrumstyp",
	"tenantTenantTypeId": "Zentrumstyp",
	"tenantTitle": "Name",
	"tenantTypeCultureId": "Sprache",
	"tenantTypeIsOrigin": "Ursprung",
	"tenantTypeTenantTypeKey": "Schlüssel",
	"tenantTypeTitle": "Titel",
	"tenantUpdate": "Zentrum bearbeiten"

}