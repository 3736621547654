//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class ProcessNodeService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[ProcessNode.Delete]
	async delete(processNodeId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:processNodeId
		}
	
		return await _server.delete('/aut/process-node/delete', {'params':params})
	};

	//GeneratorFlag:[ProcessNode.FormPattern]
	async formPattern(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/aut/process-node/form-pattern', {'params':params});
	};

	//GeneratorFlag:[ProcessNode.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/aut/process-node/list', {'params':params});
	};

	//GeneratorFlag:[ProcessNode.Management]
	async management(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/aut/process-node/management', params || { });
	};

//GeneratorFlag:[NewAction]
}