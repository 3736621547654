<template>
	<div class="row">
		<div class="col-12 col-md-4" v-if="showRooms">
			<div class="rooms p-3 border-base-radius">
				<div class="search-box">
					<input class="form-control" type="search"
						   v-model="roomKeyword" :placeholder="$loc.fromResource('messenger.ext.room.filter')" />
				</div>
				<kaveh-overlay :is-loading="isLoadingRooms" class="items" :style="{'height': heightRooms}">
					<template v-for="(item,index) in rooms" :key="index">
						<div class="item" v-if="isShowRoom(item)">
							<div class="row" @click="selectRoom(item)">
								<div class="col-2 text-center" v-if="item.isPrivate">
									<div class="image-profile" v-bind:class="[getStatus(item.receiverId)]">
										<img width="50" src="../../../assets/images/profile-single.svg" />
									</div>
								</div>
								<div class="col-2 text-center" v-else>
									<div class="image-profile">
										<img width="50" src="../../../assets/images/profile-group.svg" />
									</div>
								</div>
								<div class="col-7">
									<span>{{item.title}}</span><br />
									<small class="text-muted">{{item.summery}}</small>
									<span class="seen-status" v-if="item.lastChatPersonId == personId">
										<kaveh-icon-bootstrap v-if="item.lastChatSeen" name="bi-check-all" color="green" size="16px" />
										<kaveh-icon-bootstrap v-else name="bi-check" size="16px" />
									</span>
									<span class="unseen" v-if="item.unseenCount > 0">{{ item.unseenCount }}</span>
								</div>
								<div class="col-3 text-end">
									<kaveh-icon-bootstrap class="delete-button" @click="deleteRoom(item)" name="bi-trash2-fill" color="red" size="12px" />
									<small class="text-muted ps-2">{{ item.lastChatTime || item.createTime }}</small>
								</div>
							</div>
						</div>
					</template>
				</kaveh-overlay>
				<div class="pt-3">
					<button class="btn btn-sm btn-outline-dark" @click="isShowCreateRoom = true">
						{{$loc.fromResource('messenger.ext.room.create')}}
					</button>
				</div>
			</div>
		</div>
		<div class="col-12 col-md-8" v-if="showChats">
			<div class="mt-2 mb-2 text-center" v-if="deviceType != 'desktop'">
				<button class="btn btn-light rounded-pill w-100" @click="backToRooms">
					{{ $loc.fromResource('action.back') }}
				</button>
			</div>
			<div class="chats p-3 border-base-radius">
				<div class="header border-bottom pb-2">
					<div v-if="room">
						<div class="row" v-if="room.isPrivate">
							<div class="col-2 col-xl-1">
								<img width="50" src="../../../assets/images/profile-single.svg" />
							</div>
							<div class="col-10 col-xl-11 pt-2">
								<span>{{room.title}}</span><br />
								<small class="text-muted">{{getStatus(room.receiverId)}}</small>
							</div>
						</div>
						<div class="row" v-else>
							<div class="col-2 col-xl-1">
								<img width="50" src="../../../assets/images/profile-group.svg" />
							</div>
							<div class="col-10 col-xl-11 pt-2">
								<span>{{room.title}}</span><br />
								<small class="text-muted">{{room.members.length}} عضو</small>
							</div>
						</div>
					</div>

				</div>
				<div class="items" :style="{'height': heightChats}">
					<kaveh-overlay :is-loading="isLoadingChats"></kaveh-overlay>
					<div class="w-100 text-center" v-if="chatTotal > chats.length">
						<button class="btn btn-ligth rounded-pill border mt-2" @click="initChats">{{$loc.fromResource('messenger.ext.chat.showOldChats')}}</button>
					</div>
					<div v-for="(item,index) in chats" :key="index" v-bind:class="[item.personId == personId ? 'item me' : 'item other']">
						<div class="wrapper">
							<div class="chat">
								<div class="mb-1 text-end position-relative actions">
									<button class="btn btn-ligth p-0 btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
										<kaveh-icon-bootstrap name="bi-three-dots-vertical" size="14px" />
									</button>
									<ul class="dropdown-menu">
										<li @click="deleteChat(item, false)">
											<small class="dropdown-item cursor-pointer" href="#">
												{{$loc.fromResource('messenger.ext.chat.delete')}}
											</small>
										</li>
										<li @click="deleteChat(item, true)" v-if="item.personId == personId">
											<small class="dropdown-item cursor-pointer" href="#">
												{{$loc.fromResource('messenger.ext.chat.deleteForAll')}}
											</small>
										</li>
									</ul>
								</div>
								<div v-html="item.body.split('\n').join('<br/>')"></div>
								<kaveh-button :is-loading="item.isLoading" class="rounded mt-3 mb-5 border text-dark p-2" v-if="item.hasMedia">
									<span class="me-4">
										<kaveh-icon-bootstrap name="bi-download" size="15px" cursor="pointer" />
									</span>
									<span @click="downloadFile(item)">
										{{item.fileName}}
									</span>
								</kaveh-button>
								<span class="seen-status" v-if="item.personId == personId">
									<span v-if="item.isSeenAll">
										<kaveh-icon-bootstrap name="bi-check-all" color="green" size="18px" />
									</span>
									<span v-else-if="item.seenCount > 0" style="font-size:11px;">
										<small>{{item.seenCount}}</small> <kaveh-icon-bootstrap name="bi-eye-fill" size="10px" />
									</span>
									<span v-else>
										<kaveh-icon-bootstrap name="bi-check" size="18px" />
									</span>
								</span>
								<div class="text-muted time">
									<small> {{item.personName}} - {{item.createDate}} - {{item.createTime}}</small>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="footer pt-3" v-bind:class="{'kaveh-loading': isSending}" style="height:50px;">
					<div class="row" v-if="room">
						<div class="col-8 col-xl-10">
							<textarea v-model="inputChat" @keydown="inputChatKeydown" class="form-control"></textarea>
						</div>
						<div class="col-4 col-xl-2">
							<div class="border rounded pt-1 pb-1 ps-2">
								<popper class="popper-light m-0 border-0" arrow>
									<kaveh-icon-bootstrap name="bi-emoji-smile" size="18px" cursor="pointer" />
									<template #content>
										<emoji-picker native
													  disable-skin-tones
													  hide-group-names
													  disable-sticky-group-names
													  display-recent @select="(emoji) => inputChat += emoji.i" />
									</template>
								</popper>
								<label class="ms-2" for="MessengerFiles">
									<input id="MessengerFiles" class="form-control d-none" type="file" @change="selectFile" accept=".xlsx,.png,.jpg,.zip,.rar,.pdf,.tiff,.mp3,.mp4" />
									<kaveh-icon-bootstrap class="send" name="bi-paperclip" size="18px" cursor="pointer" />
									<span v-if="staticFiles.length > 0">
										{{staticFiles.length}}
									</span>
								</label>
								<label class="ms-2">
									<kaveh-icon-bootstrap class="send" @click="sendChat" name="bi-send-fill" size="18px" cursor="pointer" />
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<kaveh-popup v-if="isShowCreateRoom" :close="() => {isShowCreateRoom = false}">
		<room-create @submit="createRoom" @complete="isShowCreateRoom = false" />
	</kaveh-popup>
</template>
<script>
	import EmojiPicker from 'vue3-emoji-picker'
	import RoomCreate from './room/room-create'

	import { Ws } from '@/core/server'
	import { toast, dataUrl, deviceType } from '@/plugins'
	import { StaticFileService } from '@/modules/asset/services'
	import { RoomService, ChatService } from '@/modules/messenger/services'

	import 'vue3-emoji-picker/css'
	export default {

		components: {
			'room-create': RoomCreate,
			'emoji-picker': EmojiPicker
		},

		data()
		{
			return {
				isLoadingRooms: false,
				isLoadingChats: false,
				isLoadingConnection: true,
				isShowCreateRoom: false,
				isSending: false,
				chatPage: 0,
				chatTotal: 0,
				roomVer: 0,
				room: null,
				roomKeyword: '',
				rooms: [],
				staticFiles: [],
				chats: [],
				inputChat: '',
				personId: this.$store.state.client.id,
				token: this.$store.state.client.token,
				onlineList: {},
				connection: undefined,
				showChats: false,
				showRooms: true
			}
		},

		computed:
		{
			deviceType()
			{
				return deviceType();
			},

			heightRooms()
			{
				if (this.deviceType != 'desktop')
				{
					return `${window.outerHeight - 250}px`;
				}

				return `${window.outerHeight - 400}px`;
			},

			heightChats()
			{
				if (this.deviceType != 'desktop')
				{
					return `${window.outerHeight - 320}px`;
				}

				return `${window.outerHeight - 430}px`;
			},

			roomService()
			{
				return new RoomService()
			},

			chatService()
			{
				return new ChatService()
			},

			fileService()
			{
				return new StaticFileService()
			}
		},

		beforeMount()
		{
			if (this.deviceType == 'desktop')
			{
				this.showChats = true;
			}

			this.buildConnection()
		},


		unmounted()
		{
			if (this.connection)
			{
				this.connection.handle("MessengerChatDelete", undefined)
				this.connection.handle("MessengerChatPin", undefined)
				this.connection.handle("MessengerChatSeen", undefined)
				this.connection.handle("MessengerChatSend", undefined)
				this.connection.handle("MessengerRoomCreate", undefined)
				this.connection.handle("MessengerRoomLeft", undefined)
				this.connection.handle("MessengerRoomDelete", undefined)
				this.connection.handle("PersonConnected", undefined)
				this.connection.handle("PersonDisconnected", undefined)
			}
		},

		methods: {

			buildConnection()
			{
				new Ws().getConnection('messenger').then((connection) =>
				{
					this.connection = connection;

					const updateChat = async (payload) =>
					{
						const service = this.chatService;
						const res = await service.detail(payload, { HSR: false, HER: true });

						if (res.status == 200)
						{
							this.onChangeChat(res.data)
						}
					}

					const updateRoom = async (payload) =>
					{
						const service = this.roomService;
						const res = await service.detail(payload, { HSR: false, HER: true });

						if (res.status == 200)
						{
							this.onChangeRoom(res.data)
						}
					}

					const updateRoomSelf = async (payload) =>
					{
						const service = this.roomService;
						const res = await service.detail(payload, { HSR: false, HER: true });

						if (res.status == 200)
						{
							const room = res.data;
							if (room.members.findIndex((x) => { return x.personId == this.personId && x.isOwner == true }) > -1)
							{
								this.selectRoom(room)
							}
						}
					}

					this.connection.handle("MessengerChatDelete", async data =>
					{
						this.onDeleteChat(data)
						await updateRoom(data)
					});

					this.connection.handle("MessengerChatPin", async data =>
					{
						await updateChat(data)
						await updateRoom(data)
					});

					this.connection.handle("MessengerChatSeen", async data =>
					{
						await updateChat(data)
						await updateRoom(data)
					});

					this.connection.handle("MessengerChatSend", async data =>
					{
						await updateChat(data)
						await updateRoom(data)
					});

					this.connection.handle("MessengerRoomCreate", async data =>
					{
						await updateRoom(data)
						await updateRoomSelf(data)
					});

					this.connection.handle("MessengerRoomLeft", async data =>
					{
						await updateRoom(data)
					});

					this.connection.handle("MessengerRoomDelete", data =>
					{
						this.onDeleteRoom(data)
					});

					this.connection.handle("PersonConnected", personId =>
					{
						this.onlineList[`person_${personId}`] = true
					});

					this.connection.handle("PersonDisconnected", personId =>
					{
						this.onlineList[`person_${personId}`] = false
					});

					this.initRooms();
					this.initOnlineList();
				})
			},

			async initRooms()
			{
				const payload = {
					start: 0,
					length: 10000
				};

				this.isLoadingRooms = true;
				const service = this.roomService;
				const res = await service.list(payload, { HSR: false, HER: false })

				if (res.status == 200)
				{
					var items = res.data.items || [];
					for (var i = 0; i < items.length; i++)
					{
						this.setRoomDetail(items[i])
						this.joinRoom(items[i])
					}

					this.rooms = items;
					this.isLoadingRooms = false;
				}
			},

			async initChats()
			{
				var length = 100;
				if ((this.room.unseenCount || 0) > length)
				{
					length = this.room.unseenCount
				}

				const start = (length * this.chatPage);
				const payload = {
					start: start,
					length: length,
					filter: {
						roomId: this.room.roomId
					}
				}

				if (!this.isLoadingChats)
				{
					this.isLoadingChats = true;
				}

				const service = this.chatService;
				const res = await service.list(payload, { HSR: false, HER: false })

				if (res.status == 200)
				{
					var total = res.data.total || 0;
					var items = res.data.items || [];

					this.chatPage += 1;
					this.chatTotal = total;
					this.chats = [...items, ...this.chats];
					this.seenChats()
					this.isLoadingChats = false;

					this.setChatScroll(200,
						this.chatPage == 1 ? 'all-message' : 'older-message')
				}
			},

			setChatScroll(delay, type)
			{
				const scroll = () =>
				{
					var element =
						document.querySelector(".chats .items")

					if (element)
					{
						var top = 0;
						var behavior = '';

						if (type == 'older-message')
						{
							behavior = 'instant';
							top = element.scrollHeight - this.chatScrollHeight
						}

						if (type == 'add-message' || type == 'all-message')
						{
							behavior = 'smooth';
							top = element.scrollHeight;
						}

						element.scrollTo({ top, behavior });

						this.chatScrollHeight =
							element.scrollHeight
					}
				}

				setTimeout(scroll, delay)
			},

			async initOnlineList()
			{
				this.connection.invoke('OnlineList').then((items) =>
				{
					for (var i = 0; i < items.length; i++)
					{
						this.onlineList[`person_${items[i]}`] = true;
					}

				}).catch(err => { toast.error(null, err) });
			},

			setRoomDetail(room)
			{
				if (room.isPrivate)
				{
					for (var i = 0; i < room.members.length; i++)
					{
						const member = room.members[i];
						if (member.personId != this.personId)
						{
							room.title = member.personName;
							room.receiverName = member.personName;
							room.summery = room.lastChatBody;
							room.receiverId = member.personId;

							if (room.lastChatBody && room.lastChatBody.length > 30)
							{
								room.summery = `${room.lastChatBody.substr(0, 30)} ...`
							}
						}
					}
				}

				return room;
			},

			async selectRoom(room)
			{
				if (this.deviceType != 'desktop')
				{
					this.showChats = true;
					this.showRooms = false;
				}

				var roomIndex = this.rooms.findIndex((x) =>
				{
					return x.roomId == room.roomId
				})

				if (roomIndex > -1)
				{
					this.isLoadingChats = true;

					this.chatPage = 0;
					this.chatTotal = 0;

					this.chats = [];
					this.room = this.rooms[roomIndex];

					await this.initChats(room)
				}
			},

			backToRooms()
			{
				this.showChats = false;
				this.showRooms = true;
			},

			isShowRoom(item)
			{
				if (this.roomKeyword == '')
				{
					return true;
				}

				return (item.title || '').indexOf(this.roomKeyword) > -1;
			},

			async createRoom(payload)
			{
				this.isShowCreateRoom = false;
				const service = this.roomService;
				await service.create(payload, { HSR: false, HER: true });
			},

			async deleteRoom(payload)
			{
				if (confirm(this.$loc.fromResource('chat.deleteConfirm')))
				{
					const service = this.roomService;
					await service.delete(payload, { HSR: false, HER: true });
				}
			},

			onDeleteRoom(room)
			{
				if (this.room)
				{
					if (room.roomId == this.room.roomId)
					{
						this.room = null;
					}
				}

				var roomIndex = this.rooms.findIndex((x) =>
				{
					return x.roomId == room.roomId
				})

				if (roomIndex > -1)
				{
					this.rooms.splice(roomIndex, 1)
				}
			},

			async deleteChat(payload, forAll)
			{
				if (confirm(this.$loc.fromResource('chat.deleteConfirm')))
				{
					payload.forAll = forAll;
					const service = this.chatService;
					await service.delete(payload, { HSR: false, HER: true });
				}
			},

			onDeleteChat(data)
			{
				if (data.specialPersonId == 0 || data.specialPersonId == this.personId)
				{
					var chatIndex = this.chats.findIndex((x) =>
					{
						return x.chatId == data.chatId
					})

					if (chatIndex > -1)
					{
						this.chats.splice(chatIndex, 1)
					}
				}
			},

			async leftRoom(data)
			{
				const service = this.roomService;
				const res = await service.left(data, { HSR: false, HER: true })
			},

			async sendChat()
			{
				const body =
					this.inputChat.trim();

				if (body != "" || this.staticFiles.length > 0)
				{
					var payload = {
						body: body,
						roomId: this.room.roomId,
						people: []
					}

					for (var i = 0; i < this.room.members.length; i++)
					{
						payload.people.push(this.room.members[i].personId)
					}

					if (this.staticFiles.length > 0)
					{
						this.isSending = true;
						payload.fileName = this.staticFiles[0].fileName
						payload.fileDataUrl = this.staticFiles[0].fileDataUrl
					}

					const service = this.chatService;
					const res = await service.send(payload, { HSR: false, HER: true });
					if (res.status == 200)
					{
						this.isSending = false;
						this.staticFiles = [];
						this.inputChat = "";
						this.setChatScroll(200, 'add-message')
					}

					this.isSending = false;
				}
			},

			async seenChat(chatId)
			{
				var chatIndex = this.chats.findIndex((x) =>
				{
					return x.chatId == chatId
				})

				if (chatIndex > -1)
				{
					const payload = {
						chatId: chatId
					};

					const service = this.chatService;
					await service.seen(payload, { HSR: false, HER: true });
				}
			},

			seenChats()
			{
				for (var i = 0; i < this.chats.length; i++)
				{
					const item = this.chats[i];
					if (item.isSeen == false)
					{
						this.seenChat(item.chatId)
					}
				}
			},

			async selectFile(e)
			{
				const toDataUrl = (file) => new Promise((resolve, reject) =>
				{
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => resolve(reader.result);
					reader.onerror = error => reject(error);
				});

				var files = []
				for (var i = 0; i < e.target.files.length; i++)
				{
					const file = e.target.files[i];

					files.push({
						fileName: file.name,
						fileDataUrl: await toDataUrl(file)
					});
				}

				this.staticFiles = files
			},

			async downloadFile(chat)
			{
				chat.isLoading = true;
				this.$assetDownload(chat.systemFileName, chat.fileName).then(() =>
				{
					chat.isLoading = false;
				})
			},

			inputChatKeydown(e)
			{
				if ((e.key === 'Enter' || e.keyCode === 13) && e.shiftKey == false)
				{
					e.preventDefault();
					this.sendChat()
				}
			},

			onChangeChat(chat)
			{
				if (this.room)
				{
					if (this.room.roomId == chat.roomId)
					{
						var chatIndex = this.chats.findIndex((x) =>
						{
							return x.chatId == chat.chatId
						})

						if (chatIndex > -1)
						{
							this.chats[chatIndex] = chat
						}
						else
						{
							this.chats = [...this.chats, chat]
							this.seenChat(chat.chatId)
						}
					}
				}
			},

			onChangeRoom(room)
			{
				if (room)
				{
					room = this.setRoomDetail(room)
					var roomIndex = this.rooms.findIndex((x) =>
					{
						return x.roomId == room.roomId
					})

					if (roomIndex > -1)
					{
						this.rooms[roomIndex] = room
					}
					else
					{
						this.rooms = [room, ...this.rooms];
						this.joinRoom(room)
					}

					this.sortRooms();
				}
			},

			sortRooms()
			{
				this.rooms.sort((a, b) => { return b.lastChatId - a.lastChatId })
			},

			joinRoom(room)
			{
				this.invoke("Join", `Room_${room.roomId}`);
				for (var i = 0; i < room.members.length; i++)
				{
					if (room.members[i].personId != this.personId)
					{
						this.invoke("Join", `Connection_${room.members[i].personId}`);
					}
				}
			},

			getStatus(personId)
			{
				return this.onlineList[`person_${personId}`] == true ? 'online' : 'offline'
			},

			invoke(method, payload)
			{
				if (payload)
				{
					this.connection.invoke(method, payload).catch(err =>
					{
						toast.error(null, err)
					});
				}
				else
				{
					this.connection.invoke(method).catch(err =>
					{
						toast.error(null, err)
					});
				}
			},
		}
	}
</script>
<style>

	.rooms, .chats {
		background: #F8F9FA;
	}

	.chats {
		background-image: url('../../../assets/images/pattern-5.svg');
		background-repeat: repeat;
		background-size: 350px;
	}

		.rooms .items,
		.chats .items {
			overflow: auto;
			padding: 0 0 20px 0;
		}


	.rooms .item {
		background: #fff;
		margin-top: 7px;
		padding: 10px;
		border-radius: 10px;
		cursor: pointer;
		position: relative;
	}

		.rooms .item:hover {
			background: #f8f8f8;
		}

			.rooms .item:hover .delete-button {
				display: inline;
			}


		.rooms .item .seen-status {
			position: absolute;
			left: 10px;
			bottom: 7px;
		}

		.rooms .item .unseen {
			position: absolute;
			left: 10px;
			bottom: 10px;
			color: green;
			border-radius: 100%;
			font-size: 12px;
			background: #ff6a00;
			width: 20px;
			height: 20px;
			border-radius: 20px;
			color: #fff;
			text-align: center;
			padding-top: 2px;
		}

		.rooms .item .image-profile {
			width: 50px;
			position: relative;
		}

			.rooms .item .image-profile.online::before {
				content: "";
				width: 15px;
				height: 15px;
				background-color: forestgreen;
				position: absolute;
				bottom: 1px;
				right: 1px;
				border: #fff solid 3px;
				border-radius: 100%;
			}

		.rooms .item .delete-button {
			display: none;
		}


		.rooms .item .image-profile img {
			max-width: 100%;
		}

	.chats .header {
		height: 55px;
	}

	.chats .items {
	}

		.chats .items .item {
			width: calc(100% - 20px);
			float: right;
			margin: 5px 10px;
			position: relative;
		}

			.chats .items .item .wrapper {
				width: 50%;
			}

			.chats .items .item .chat {
				width: 100%;
				padding: 8px 15px;
				border-radius: 7px;
				border: #f8f8f8 solid 1px;
				font-size: 13px;
				line-height: 20px;
				position: relative;
			}

			.chats .items .item .chat .actions{
				left:-10px;
			}

			.chats .items .item .time {
				width: 100%;
				font-size: 10px;
				padding-top: 10px;
			}

			.chats .items .item .chat .seen-status {
				position: absolute;
				bottom: 0;
				left: 10px;
			}


			.chats .items .item.me .chat {
				background: #EFFDDE;
			}


			.chats .items .item.other .chat {
				background: #ffffff;
			}


			.chats .items .item.me .wrapper {
				float: right;
			}

			.chats .items .item.other .wrapper {
				float: left;
			}


	.chats .footer textarea {
		resize: none;
		height: 35px;
	}

	.chats .footer .send::before {
		transform: scale(-1) rotate(45deg);
	}

	.v3-emoji-picker {
		box-shadow: none;
	}

		.v3-emoji-picker .v3-body {
			padding: 11px 0 15px 11px;
		}

		.v3-emoji-picker .v3-footer {
			display: none;
		}


	[dir="ltr"] .rooms .item .seen-status {
		left: unset;
		right: 10px;
	}


	[dir="ltr"] .chats .items .item.me .wrapper {
		float: left;
	}

	[dir="ltr"] .chats .items .item.other .wrapper {
		float: right;
	}

	[dir="ltr"] .chats .items .item .chat .seen-status {
		left: unset;
		right: 10px;
	}

	[dir="ltr"] .chats .items .item .chat .actions {
		left:unset;
		right: -10px;
	}

</style>
