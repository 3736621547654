<template>
	<div class="d-inline-block me-1 mb-2">
		<button class="btn bg-base-color text-base-color border-0" @click="$emit('showFilter')">
			<kaveh-icon-bootstrap name="bi-funnel" size="15px" />
		</button>
	</div>
	<div v-if="filterLen > 0" class="d-inline-block me-1 mb-2">
		<button class="btn bg-warning border-0" @click="$emit('cleanFilter')">
			<kaveh-icon-bootstrap name="bi-eraser" size="15px" />
			<span class="badge text-dark bg-light ms-2 rounded-pill">
				{{ filterLen }}
			</span>
		</button>
	</div>
</template>
<script>
	export default {
		emits: ['showFilter', 'cleanFilter'],
		props:
		{
			'filterLen': {
				type: Number,
				default:0
			}
		},
	}
</script>
<style scoped>
	.filter-box {
		width: 400px;
		margin-right: 10px;
	}
</style>
