<template>
	<select class="form-select" v-model="value" v-bind:id="prop.id" v-on:change="onHandelChange">
		<option :value="false">{{$loc.fromResource('com.form.checkbox.no')}}</option>
		<option :value="true">{{$loc.fromResource('com.form.checkbox.yes')}}</option>
	</select>
</template>
<script>
	export default {
		emits: ['change'],

		props: {
			prop: {
				type: Object
			}
		},

		data()
		{
			return {
				value: null
			}
		},

		beforeMount()
		{
			this.value = this.prop.value
		},

		methods: {

			onHandelChange(e)
			{
				this.$emit('change', (e.target.value == 'true' || e.target.value == true))
			}
		}
	}
</script>
