//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class WorkerService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[Worker.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/aut/worker/detail', {'params':params});
	};

	//GeneratorFlag:[Worker.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/aut/worker/list', {'params':params});
	};

	//GeneratorFlag:[Worker.Exist]
	async exist(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/aut/worker/exist', {'params':params});
	};

	//GeneratorFlag:[Worker.Submit]
	async submit(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/aut/worker/submit', params || { });
	};

	//GeneratorFlag:[Worker.Track]
	async track(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/aut/worker/track', params || { });
	};

//GeneratorFlag:[NewAction]
}