<template>
	<div class="letter-forward-step-2">
		<div class="row">

			<div class="col-12 col-lg-6">
				<div class="bg-white p-2" style="height:320px;">
					<branch-tree @change="branchTreeChange" ref="branchTreeRef" />
					<button v-bind:disabled="branches.length == 0"
							@click="addReceivers('primaryList')"
							class="btn btn-light border m-1">
						<kaveh-icon-bootstrap name="bi-plus" size="15px" />
						<span>{{$loc.fromResource('aut.ext.letter.forward.receivers.primary')}}</span>
					</button>
				</div>
			</div>

			<div class="col-12 col-lg-6">
				<div v-if="!isEmptyReceivers" class="bg-white p-2" style="height:320px;">
					<div class="mt-2" v-if="primaryList.length > 0">
						<div class="col-12 text-base-color-dark">
							<strong>{{$loc.fromResource('aut.ext.letter.forward.receivers.primary')}}</strong>
						</div>
						<div class="col-12 mt-1 mb-2 recivers">
							<div class="pt-1 pb-1 ps-2 pe-2 m-1 text-bg-light d-inline-block" v-for="(item,index) in primaryList" :key="index">
								<small>{{item.personFullName}}</small>&nbsp;
								<small class="text-muted">{{item.branchName}}</small>
								<button class="btn btn-sm btn-light ms-2"
										@click="removeReceiver('primaryList',item.branchId)">
									<kaveh-icon-bootstrap name="bi-trash" size="inherit" />
								</button>
							</div>
						</div>
					</div>
				</div>

				<div v-else class="bg-white text-center ps-2 pe-2 pt-5" style="height:320px;">
					<kaveh-icon-bootstrap name="bi-info-circle" size="40px" />
					<div>{{$loc.fromResource('aut.ext.letter.forward.receivers.emptyList')}}</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import BranchTree from '@/modules/hub/views/branch/tree/branch-tree'
	export default {

		components: {
			'branch-tree': BranchTree,
		},

		data()
		{
			return {
				primaryList: [],
				branches: [],
				receivers: [],
			}
		},

		computed:
		{
			isEmptyReceivers()
			{
				return this.primaryList.length == 0;
			}
		},

		methods:
		{
			branchTreeChange(branches)
			{
				this.branches = branches;
			},


			addReceivers(name)
			{
				this[name] = [...this.branches];
				this.receivers = [...this.branches];

				this.$refs.branchTreeRef.reset()
			},

			removeReceiver(name, branchId)
			{
				const index = this[name].findIndex((x) =>
				{
					return x.branchId == branchId
				})

				this[name].splice(index, 1);
				this.receivers = [
					...this.primaryList
				];
			},

			getValue()
			{
				return JSON.parse(JSON.stringify({
					primaryList: this.primaryList,
				}))
			}

		}
	}
</script>
<style>
	.letter-forward-step-2 .recivers {
		display: block !important;
		overflow-x: auto;
		white-space: nowrap;
	}
</style>
