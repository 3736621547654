<template>

	<div class="bg-light p-3">
		<div class="d-block alert alert-warning" v-html="$loc.fromResource('com.task.warning',{'label':label})"></div>
		<div class="mt-3">
			<button type="button" @click="doTasks" class="btn btn-light border me-2">
				<kaveh-icon-bootstrap name="bi-check2-circle" color="green" size="18px" class="me-2" />
				<span>{{ $loc.fromResource('com.task.doTasks') }}</span>
			</button>
			<button type="button" @click="cancel" class="btn btn-light border">
				<kaveh-icon-bootstrap name="bi-x-circle" color="red" size="18px" class="me-2" />
				<span>{{ $loc.fromResource('com.task.cancelTasks') }}</span>
			</button>
		</div>
	</div>

	<div class="mt-3">
		<kaveh-table :data="data" :entity="entity" :selectable="false" :show-foreign-key="true" />
	</div>

</template>
<script>
	export default {
		emits: ['doTasks', 'cancel'],
		props: {

			'entity': {
				type: Function
			},

			'data': {
				type: Array
			},

			'label': {
				type: String
			},
		},

		methods:
		{
			doTasks()
			{
				this.$emit('doTasks')
			},

			cancel()
			{
				this.$emit('cancel')
			}
		}
	}
</script>
