<template>
	<kaveh-content :is-loading="isLoadingPeople">
		<div class="alert alert-light">
			{{$loc.fromResource('messenger.ext.room.createTitle')}}
		</div>
		<div style="height:200px; overflow-x:auto;">
			<div v-for="(person, index) in people" :key="index">
				<div class="border rounded p-2 mb-1" v-if="person.personId != cliendId">
					<div class="form-check">
						<input class="form-check-input border" type="checkbox" v-model="members" :value="person.personId" :id="`MessagePerson_${person.personId}`">
						<label class="form-check-label" :for="`MessagePerson_${person.personId}`">
							{{person.personName}}
						</label>
					</div>
				</div>
			</div>
		</div>

		<div class="mb-3" v-if="members.length > 1">
			<label class="form-label">عنوان گروه</label>
			<input type="text" class="form-control" v-model="title" />
		</div>

		<div class="mt-2" v-if="members.length > 0">
			<kaveh-button class="btn btn-dark mt" @click="submit" :is-loading="isLoadingSubmit">
				آغاز گفتگو
			</kaveh-button>
		</div>

	</kaveh-content>
</template>
<script>

	import { toast } from '@/plugins'
	import { PersonService } from '@/modules/hub/services'

	export default {
		emits: ['submit'],
		data()
		{
			return {
				title: '',
				people: [],
				members: [],
				isLoadingPeople: true,
				isLoadingSubmit: false,
				cliendId: this.$store.state.client.id
			}
		},

		beforeMount()
		{
			this.initPeople()
		},

		methods:
		{
			async initPeople()
			{
				try
				{
					const payload = {
						start: 0,
						length: 1000,
						filter: {
							personGroupId: 1
						}
					}

					const service = new PersonService();
					const res = await service.list(payload, { HSR: false });
					if (res.status == 200)
					{
						this.people = res.data.items
					}

				} catch (e) { console.log(e) }

				this.isLoadingPeople = false
			},

			async submit()
			{
				var payload = {
					members: [this.cliendId, ...this.members]
				};

				if (this.members.length > 1 && this.title == '')
				{
					toast.error('messenger.ext.room.errors.error1');
					return;
				}

				if (this.members.length > 1)
				{
					payload.title = this.title;
				}

				this.$emit('submit', payload)
			}
		}
	}
</script>
