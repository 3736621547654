<template>
	<div class="activity-compose-step-4">
		<div class="mb-2 p-3 bg-white" v-if="process">
			<strong class="d-block mb-2">
				{{process.title}}
			</strong>
			<label class="d-inline-block pe-2">
				<small class="text-muted">{{process.caption}}&nbsp;</small>
				<small v-if="basicFormValue.deadlineHour">
					-- {{$loc.fromResource('aut.ext.activity.compose.preview.deadlineHour',{'label': basicFormValue.deadlineHour})}}
				</small>
			</label>
		</div>

		<div class="mb-4 p-3 bg-white" v-if="basicFormValue.comment">
			<div v-html="basicFormValue.comment"></div>
		</div>

		<div class="mb-4 p-3 bg-white" v-if="formPattern && customFormValue">
			<kaveh-list v-if="!isArrayFormValue" :props="formPattern" :data="customFormValue" />
			<kaveh-table v-if="isArrayFormValue" :props="formPattern" :data="customFormValue" :selectable="false" />
		</div>

		<div class="mb-5 p-3 bg-white" v-if=" attachments.length > 0">
			<strong class="d-block mb-4">
				{{$loc.fromResource('aut.ext.activity.compose.preview.attachments')}}
			</strong>
			<div class="file" v-for="(item, index) in attachments" :key="index">
				<span dir="ltr">{{ item.name }}</span>&nbsp;
			</div>
		</div>
	</div>
</template>
<script>
	export default {

		computed: {
			isArrayFormValue()
			{
				return this.$parent.isArrayFormValue
			},
			basicFormValue() {
				return this.$parent.basicFormValue
			},
			customFormValue() {
				return this.$parent.customFormValue
			},
			attachments() {
				return this.$parent.attachments || []
			},
			process() {
				return this.$parent.process;
			},
			formPattern() {
				return this.$parent.formPattern
			}
		},
	}
</script>
<style>
	.letter-compose-step-5 {
		width: 100%;
		height: auto;
		margin: 20px auto 0 auto;
		max-width:800px;
	}
</style>
