<template>
	<date-picker v-if="language == 'fa'"
				 v-bind:id="prop.id"
				 v-model="value"
				 type="time"
				 v-on:change="onHandelChange"
				 v-on:keyup="onHandelKeyUp" />

	<input v-else
		   v-bind:id="prop.id"
		   v-bind:dir="prop.display.direction"
		   v-on:change="onHandelChange"
		   v-on:keyup="onHandelKeyUp"
		   v-model="value"
		   type="time"
		   class="form-control"
		   autocomplete="off" />

</template>
<script>
	export default {
		emits: ['change', 'keyup', 'submit'],

		props: {
			prop: {
				type: Object
			}
		},

		data()
		{
			return {
				value: null
			}
		},

		beforeMount()
		{
			this.value = this.prop.value
		},

		computed:
		{
			language()
			{
				return this.$store.state.app.language;
			}
		},

		methods: {

			onHandelChange()
			{
				this.$emit('change', this.value)
			},

			onHandelKeyUp(e) {
				if (e.key === 'Enter' || e.keyCode === 13) {
					this.$emit('submit')
				}
				else {
					this.$emit('keyup', this.value)
				}
			}
		}
	}
</script>
