<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" />
</template>
<script>
	import { Person, PersonFilter } from '@/modules/hub/data/person/list'
	import { PersonService } from '@/modules/hub/services'
	export default {
		computed:
		{
			entity()
			{
				return Person;
			},

			filterEntity()
			{
				return PersonFilter;
			},

			service()
			{
				return new PersonService();
			},

			actions()
			{
				return [

					{
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
						children: [
							{
								routeName: 'hub.person.create',
								routeParams: { type: "real" },
								resourceKey: 'hub.ext.person.createReal',
								iconName: 'bi-person-fill',
								activeMode: 'always',
							},
							{
								routeName: 'hub.person.create',
								routeParams: { type: "legal" },
								resourceKey: 'hub.ext.person.createLegal',
								iconName: 'bi-person-square',
								activeMode: 'always',
							},
							{
								routeName: 'hub.person.create',
								resourceKey: 'hub.ext.person.createSystem',
								iconName: 'bi-person-fill-gear',
								activeMode: 'always',
							}
						]
					},

					{
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
						children: [
							{
								routeName: 'hub.person.createImport',
								routeParams: { type: "real" },
								resourceKey: 'hub.ext.person.createReal',
								iconName: 'bi-person-fill',
								activeMode: 'always',
							},
							{
								routeName: 'hub.person.createImport',
								routeParams: { type: "legal" },
								resourceKey: 'hub.ext.person.createLegal',
								iconName: 'bi-person-square',
								activeMode: 'always',
							},
							{
								routeName: 'hub.person.createImport',
								resourceKey: 'hub.ext.person.createSystem',
								iconName: 'bi-person-fill-gear',
								activeMode: 'always',
							}
						]
					},
					{
						resourceKey: 'action.update',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [
							{
								routeName: 'hub.person.personAccessUpdate',
								resourceKey: 'hub.personAccessUpdate',
								iconName: 'bi-pen',
								activeMode: 'single',
								dataTransferMode: 'store',
							},
							{
								routeName: 'hub.person.personLegalUpdate',
								resourceKey: 'hub.personLegalUpdate',
								iconName: 'bi-pen',
								activeMode: 'single',
								dataTransferMode: 'store',
							},
							{
								routeName: 'hub.person.personRealUpdate',
								resourceKey: 'hub.personRealUpdate',
								iconName: 'bi-pen',
								activeMode: 'single',
								dataTransferMode: 'store',
							},
							{
								routeName: 'hub.person.personVehicleUpdate',
								resourceKey: 'hub.personVehicleUpdate',
								iconName: 'bi-pen',
								activeMode: 'single',
								dataTransferMode: 'store',
							},
							{
								routeName: 'hub.person.update',
								resourceKey: 'hub.personUpdate',
								iconName: 'bi-pen',
								activeMode: 'single',
								dataTransferMode: 'store',
							}
						],
					},

					{
						routeName: 'hub.person.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [
							{
								routeName: 'hub.position.list',
								resourceKey: 'hub.positionList',
								iconName: 'bi-list-columns',
								activeMode: 'single',
								dataTransferMode: 'query',
								dataTransferKeys: ['personId'],
							},
							{
								routeName: 'hub.person.personAccessDetail',
								resourceKey: 'hub.personAccessDetail',
								iconName: 'bi-list-columns',
								activeMode: 'single',
								dataTransferMode: 'store',
							},
							{
								routeName: 'hub.person.personLegalDetail',
								resourceKey: 'hub.personLegalDetail',
								iconName: 'bi-list-columns',
								activeMode: 'single',
								dataTransferMode: 'store',
							},
							{
								routeName: 'hub.person.personRealDetail',
								resourceKey: 'hub.personRealDetail',
								iconName: 'bi-list-columns',
								activeMode: 'single',
								dataTransferMode: 'store',
							},
							{
								routeName: 'hub.person.personVehicleDetail',
								resourceKey: 'hub.personVehicleDetail',
								iconName: 'bi-list-columns',
								activeMode: 'single',
								dataTransferMode: 'store',
							},

						]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>
