

// initial state
const state = () => ({
	token: undefined,
	accessKeys: [],
	branches: [],
	branch: null,
	branchId: 0,
	officeId: 0,
	officeCityId: 0,
	name: '',
	role: '',
	fcp:false, //force change password
	id: 0,
	isGuest:true,
	uid:null
})

// getters
const getters = {
}

// actions
const actions = {

	setToken({ commit }, value)
	{
		commit('setToken', value);
		commit('setClientInfo', value);
	},

	setTokenTemp({ commit }, value)
	{
		commit('setTokenTemp', value);
		commit('setClientInfo', value);
	},

	setAccessKeys({ commit }, value)
	{
		commit('setAccessKeys', value);
	},

	setTokenFromStorage({ commit })
	{
		var accessToken =
			localStorage.getItem("kaveh.hub.accessToken");

		if (accessToken)
		{
			commit('setToken', accessToken);
			commit('setClientInfo', accessToken);
		}
	},

	setAccessKeysFromStorage({ commit })
	{
		var accessKeys =
			localStorage.getItem("kaveh.hub.accessKeys");

		if (accessKeys)
		{
			commit('setAccessKeys', JSON.parse(accessKeys));
		}
	},

	setBranch({ commit }, value)
	{
		commit('setBranch', value);
		commit('setLocalStorage', value);
	},

	setBranchFromStorage({ commit })
	{
		var value =
			JSON.parse(
				localStorage.getItem("kaveh.hub.branch")
			);

		if (value)
		{
			commit('setBranch', value);
		}
	},

	resetState({ commit })
	{
		commit('resetState');
	}
}

// mutations
const mutations =
{
	setToken(state, value)
	{
		state.token = value;
		localStorage.setItem("kaveh.hub.accessToken", value);
	},

	setTokenTemp(state, value)
	{
		state.token = value;
	},

	setAccessKeys(state, value)
	{
		state.accessKeys = value;
		localStorage.setItem("kaveh.hub.accessKeys", JSON.stringify(value));
	},

	setClientInfo(state, token)
	{
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c)
		{
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));

		const payload = JSON.parse(jsonPayload);

		state.id = Number(payload.id);
		state.name = payload.name;
		state.role = payload.role;
		state.uid = payload.uid;
		state.branches = JSON.parse(payload.branches || "[]");
		state.fcp = payload.fcp == "1" ? true : false;
		state.isGuest = payload.isguest == "1" ? true : false;

		if (state.branches.length > 0)
		{
			const branch =
				state.branches[0];

			state.branch = branch
			state.branchId = branch.branchId || 0;
			state.officeId = branch.officeId || 0;
			state.officeCityId = branch.officeCityId || 0;
		}
	},

	setBranch(state, value)
	{
		state.branch = value;
		state.branchId = value.branchId || 0;
		state.officeId = value.officeId || 0;
		state.officeCityId = value.officeCityId || 0;
	},

	setLocalStorage(state, value)
	{
		localStorage.setItem('kaveh.hub.branch', JSON.stringify(value));
	},

	resetState(state)
	{
		state.token = undefined;
		state.accessKeys = [];
		state.branches = [];
		state.branch = null;
		state.branchId = 0;
		state.name = '';
		state.role = '';
		state.id = 0;
		state.uid = null;
		state.fcp = false;
		state.isGuest = true;
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
