//GeneratorFlag:[NoChange]
import FileAccessTypeService from './file-access-type-service'; //GeneratorFlag:[FileAccessType]
import FileExtensionService from './file-extension-service'; //GeneratorFlag:[FileExtension]
import StaticFileService from './static-file-service'; //GeneratorFlag:[StaticFile]
import VirtualFolderService from './virtual-folder-service'; //GeneratorFlag:[VirtualFolder]
//GeneratorFlag:[NewImport]
export default {
FileAccessTypeService, //GeneratorFlag:[FileAccessType]
FileExtensionService, //GeneratorFlag:[FileExtension]
StaticFileService, //GeneratorFlag:[StaticFile]
VirtualFolderService, //GeneratorFlag:[VirtualFolder]
//GeneratorFlag:[NewExport]
}
export{
FileAccessTypeService, //GeneratorFlag:[FileAccessType]
FileExtensionService, //GeneratorFlag:[FileExtension]
StaticFileService, //GeneratorFlag:[StaticFile]
VirtualFolderService, //GeneratorFlag:[VirtualFolder]
//GeneratorFlag:[NewExport]
}