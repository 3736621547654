const routes = [{
	path: 'messenger',
	name: 'messenger',
	component: () => import('@/modules/messenger/views/layout.vue'),
	meta: {
		requiresAuth: true,
		clientAccessKeys:['/messenger'],
		serverAccessKeys: ['/messenger/room/list', '/messenger/chat/list','/messenger/room/create'],
		resourceKey: 'service.messenger',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-chat-dots-fill',
		}
	},
}]
export default routes
export{
}