<template>
	<ord-loading v-if="state == 'loading'" />
	<ord-error v-else-if="state == 'error'" />
	<slot name="noContent" v-else-if="state == 'empty'"></slot>
	<div v-else class="ord-panzoom" v-bind:dir="$store.state.app.direction" :style="{'max-height':`${panzoomHeight}px`}">
		<div ref="ordPanzoom">
			<slot></slot>
		</div>
	</div>
</template>
<script>
	import panzoom from 'panzoom'
	import OrdEmpty from './common/ord-empty'
	import OrdLoading from './common/ord-loading'
	import OrdError from './common/ord-error'

	export default {

		components: {
			'ord-empty': OrdEmpty,
			'ord-loading': OrdLoading,
			'ord-error': OrdError,
		},

		props: {
			state: {
				type: String,
				default: "loading"
			}
		},

		computed:
		{
			panzoomHeight()
			{
				return window.outerHeight - 200
			},

			lsKey()
			{
				return `kaveh.com.ord.transform[${window.location.pathname}]`
			}
		},

		updated()
		{
			this.init();
		},

		methods: {
			init()
			{

				if (this.state != "loading" && this.state != "empty" && this.state != "error")
				{
					var transform =
						window.localStorage.getItem(this.lsKey);

					if (transform)
					{
						transform = JSON.parse(transform)
					}
					else
					{
						transform = {
							x: 0,
							y: 0,
							scale: 0.75
						};
					}

					const instance = panzoom(this.$refs.ordPanzoom, {
						maxZoom: 1,
	                    onTouch: function (e) {

                            if (e.target.classList.contains("clickable")) {
                                e.stopImmediatePropagation()
                                return false;
                            }

                            return true;
                        }
					})

					instance.zoomAbs(transform.x, transform.y, transform.scale)
					instance.moveTo(transform.x, transform.y)

					instance.on('zoom', this.saveTransform);
					instance.on('panend', this.saveTransform);
				}
			},

			saveTransform(e)
			{
				window.localStorage.setItem(this.lsKey, JSON.stringify(e.getTransform()))
			},
		}
	}
</script>

<style>
	.ord-panzoom {
		overflow: hidden;
		position: relative;
		max-width: 100%;
		background: #f8f8f8;
		border-radius:var(--kaveh-radius);
	}
</style>
