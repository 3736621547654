//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class ProductGroupService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[ProductGroup.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/store/product-group/create', params || { });
	};

	//GeneratorFlag:[ProductGroup.Delete]
	async delete(productGroupId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:productGroupId
		}
	
		return await _server.delete('/store/product-group/delete', {'params':params})
	};

	//GeneratorFlag:[ProductGroup.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/store/product-group/detail', {'params':params});
	};

	//GeneratorFlag:[ProductGroup.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/store/product-group/list', {'params':params});
	};

	//GeneratorFlag:[ProductGroup.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/store/product-group/update', params || { });
	};

	//GeneratorFlag:[ProductGroup.PublicList]
	async publicList(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/store/product-group/public-list', {'params':params});
	};

//GeneratorFlag:[NewAction]
}