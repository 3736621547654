export default {
	"enums": {
		"letterCategory": {
			"all": "All Letters",
			"public": "Public",
			"mailroom":"Mailroom",
			"toAct": "To Act",
			"toRemind": "To Remind",
		},
		"activityCategory": {
			"public": "Public",
		}
	},

	"letter": {

		"compose": {
			"step1": "Select Letter Recipients",
			"step2": "Initial Information",
			"step3": "Letter Content",
			"step4": "Attachment Files",
			"step5": "Confirmation and Sending",
			"prevStep": "Previous Step",
			"nextStep": "Next Step",
			"finalStep": "Final Confirmation and Sending Letter",
			"loading": "Please wait, system is sending the letter...",

			"step1Error": "No recipients selected for the letter!",
			"step2Error": "Please fix form errors first.",
			"step3Error": "Please enter letter content.",
			"duplicateError": "{label} has already been selected.",

			"receivers": {
				"primaryList": "Primary Recipients",
				"ccList": "CC Recipients",
				"bccList": "BCC Recipients",
				"emptyList": "No recipient selected.",
			},

			"preview": {
				"primaryList": "This letter will be sent by you to",
				"ccList": "CC to",
				"bccList": "BCC to",
				"attachments": "Attachments",
				"showOlder" : "Show Older"
			}
		},


		"reply": {
			"step1": "Preview",
			"step2": "Reply Content",
			"step3": "Attachment Files",
			"step4": "Confirmation and Sending",
			"prevStep": "Previous Step",
			"nextStep": "Next Step",
			"finalStep": "Final Confirmation and Sending Reply",
			"loading": "Please wait, system is sending the reply...",
			"to": "Reply to",

			"step2Error": "Please enter reply content.",

			"preview": {
				"primaryList": "This reply will be sent by you to",
				"attachments": "Attachments",
			}
		},

		"forward": {
			"step1": "Preview",
			"step2": "Select Recipient",
			"step3": "Paragraph",
			"step4": "Attachment Files",
			"step5": "Confirmation and Sending",
			"prevStep": "Previous Step",
			"nextStep": "Next Step",
			"finalStep": "Final Confirmation and Forwarding",
			"loading": "Please wait, system is forwarding the letter...",
			"step2Error": "No recipient selected for forwarding the letter!",
			"step3Error": "Please enter forwarding paragraph.",

			"receivers": {
				"primary": "Forward Recipient",
				"emptyList": "No recipient selected.",
			},

			"preview": {
				"primaryList": "This letter will be forwarded by you to",
				"attachments": "Attachments",
			}
		},

		"reader": {
			"sender": "<span>This {label} has been sent by {personFullName} <small class='text-muted'>{branchName}</small> to</span>",
			"attachments": "Attachments"
		},

		"list": {
			"empty": "No letters found!"
		},

		"tag": {
			"self":"Tags",
			"noTag": "All Tags"
		},

		"letterCategory": {
			"self": "Categories",
		},

		"actions": {
			"reply": "Reply",
			"forward": "Forward",
			"archive": "Archive",
			"pin": "Pin",
			"unpin": "Unpin",
			"markAsUnread": "Mark as Unread",
		},

		"views": {
			"self": "Pages",
			"layout": "Letter Management",
			"list": "Letter List",
			"management": "Letter List",
			"inbox": "Incoming Letters",
			"outbox": "Outgoing Letters",
			"drafts": "Draft Letters",
			"archived": "Archived Letters",
			"compose": "Compose New Letter",
			"reply": "Send Reply",
			"forward": "Forward Letter",
			"reader": "Read Letter"
		}
	},

	"process": {
		"nodeManagement": "Process Management",
		"noFound": "No process defined yet, you must define system processes first to define tasks."
	},

	"processNode": {
		"management": {
			"info": "To manage tasks in processes, please select your desired process. If your desired process is not available here, you can define it from the process management section.",
		},
		"event": {
			"serviceInfo":"Service Information",
			"loginServiceInfo":"Login Service Information",
			"address":"Service Address",
			"method":"Method Type",
			"contentType": "Content Type",
			"properties": "Fields",
			"tokenPropertyName":"Token Return Field"
		},
		"worker": {
			"emailDestination":"Worker Email Address",
			"properties": "Worker Service Fields"
		},
		"returnPh": "Return Button Title",
		"continuePh": "Continue Button Title",
		"stopPh": "Stop Button Title",
		"completePh": "Complete Button Title",
		"formInfo": "Form Information",
		"eventInfo": "Event Information",
		"formPreview": "Form Preview",
		"formManagement": "Form Management",
		"eventManagement": "Event Management",
		"levelName": "Level {label}",
		"createLevel": "Create New Level",
		"selectEventAlert":"Please select your desired event.",
		"noFound": "No process defined yet, you must define system processes first to define tasks."
	},


	"activity": {

		"compose": {
			"step1": "Select Process",
			"step2": "Request Information",
			"step3": "Attachment Files",
			"step4": "Confirmation and Sending",
			"prevStep": "Previous Step",
			"nextStep": "Next Step",
			"finalStep": "Final Confirmation and Starting Process",
			"loading": "Please wait, system is starting the process...",

			"step1Error": "No process selected!",
			"step2Error": "Please fix form errors first.",

			"preview": {
				"processName": "Process Name",
				"customForm": "Form Information",
				"comment": "Request Comments",
				"attachments": "Attachments",
				"deadlineHour": "Deadline {label} Hour"
			}
		},

		"views": {
			"self":"Pages",
			"compose": "Start New Process",
			"ongoing": "Ongoing Activities",
			"currents": "Current Activities",
			"myActivities":"My Activities",
			"completed": "Completed Activities",
			"stoped": "Stopped Activities"
		},

		"priority": {
			"self":"Priorities",
			"allPriority": "All Priorities"
		},


		"category": {
			"self": "Categories",
		},


		"list": {
			"empty": "No activity found!",

			"newTitle": "Start",
			"returnTitle": "Return",
			"continueTitle": "Continue",
			"stopTitle": "Stop",
			"completeTitle": "Complete",

			"caption": "{action} by {activist}",
			"showDetail": "Show Details"
		},

		"node": {
			"localCreateDate": "Date",
			"createTime": "Time",
			"dateTime":"Date and Time",
			"processNodeTitle": "Action Title",
			"processNodeCaption": "Additional Action Description",
			"comment": "Comments",
			"employee": "Activist",
			"attachments": "Attachments",

			"continue":"Continue Process",
			"complete":"Complete Process",
			"return":"Return to Previous",
			"returnTo":"Return to {label}",
			"stop": "Stop Process",
			"plaeseWait":"Please wait, system is registering action..."
		}
	}
}
