//GeneratorFlag:[NoChange]
export default {
	path: 'key-value',
	name: 'hub.basis.keyValue.layout',
	redirect: to => {
		return {name: 'hub.keyValue.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/basis/key-value"],
		resourceKey: 'hub.keyValueLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[KeyValueCreate]"
		{
			path: 'create',
			name: 'hub.keyValue.create',
			component: () => import('@/modules/hub/views/key-value/key-value-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.keyValueCreate',
				clientAccessKeys:["/hub/basis/key-value/create"],
				serverAccessKeys:["/hub/key-value/create","/hub/key-value/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[KeyValueCreateImport]"
		{
			path: 'create-import',
			name: 'hub.keyValue.createImport',
			component: () => import('@/modules/hub/views/key-value/key-value-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.keyValueCreateImport',
				clientAccessKeys:["/hub/basis/key-value/create-import"],
				serverAccessKeys:["/hub/key-value/create","/hub/key-value/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[KeyValueDelete]"
		{
			path: 'delete',
			name: 'hub.keyValue.delete',
			component: () => import('@/modules/hub/views/key-value/key-value-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.keyValueDelete',
				clientAccessKeys:["/hub/basis/key-value/delete"],
				serverAccessKeys:["/hub/key-value/delete","/hub/key-value/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[KeyValueDetail]"
		{
			path: 'detail',
			name: 'hub.keyValue.detail',
			component: () => import('@/modules/hub/views/key-value/key-value-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.keyValueDetail',
				clientAccessKeys:["/hub/basis/key-value/detail"],
				serverAccessKeys:["/hub/key-value/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[KeyValueList]"
		{
			path: 'list',
			name: 'hub.keyValue.list',
			component: () => import('@/modules/hub/views/key-value/key-value-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.keyValueList',
				clientAccessKeys:["/hub/basis/key-value/list"],
				serverAccessKeys:["/hub/key-value/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[KeyValueAttachment]"
		{
			path: 'attachment',
			name: 'hub.keyValue.attachment',
			component: () => import('@/modules/hub/views/key-value/key-value-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.keyValueAttachment',
				clientAccessKeys:["/hub/basis/key-value/attachment"],
				serverAccessKeys:["/hub/key-value/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[KeyValueUpdate]"
		{
			path: 'update',
			name: 'hub.keyValue.update',
			component: () => import('@/modules/hub/views/key-value/key-value-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.keyValueUpdate',
				clientAccessKeys:["/hub/basis/key-value/update"],
				serverAccessKeys:["/hub/key-value/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}