export default {
	"accessAgent": {
		"personRoleTab": "Zugriff auf Benutzerrollen zuweisen",
		"personTab": "Zugriff auf Benutzer zuweisen",
	},

	"branch": {

		"list": {
			"showInOrd": "Als Diagramm anzeigen",
			"showInTree": "Als Untergeordnete anzeigen"
		},

		"create": {
			"officeName": "Titel der Organisation oder Agentur",
			"unitName": "Titel der Organisationseinheit",
			"positionName": "Titel der organisatorischen Position",

			"officeNode": "Organisation/Agentur hinzufügen",
			"unitNode": "Organisationseinheit hinzufügen",
			"positionNode": "Organisationsposition hinzufügen",
			"personNode": "Personal zuweisen"
		},

		"tree": {
			"positionSelect": "Personal auswählen",
			"officeSelect": "Organisation / Einheit auswählen",
		},

		"choose": {
			"head": "Um an der Automatisierung teilzunehmen, wählen Sie bitte Ihre gewünschte organisatorische Position aus.",
			"empty": "Keine organisatorischen Positionen für Sie gefunden.",
			"warning": "Bitte beachten Sie, dass nach der Auswahl oder Änderung der organisatorischen Position alle Ihre organisatorischen Schreiben und Aktionen mit der neuen Position registriert werden.",
			"selected": "Aktiv",
			"accessDenied": "Sie haben keinen Zugriff auf diesen Abschnitt, Sie müssen zuerst Ihre organisatorische Position angeben. Bitte wählen Sie Ihre organisatorische Position mit der unten stehenden Schaltfläche aus.",
			"noFound": "Es wurden keine organisatorischen Positionen für Sie definiert. Bitte koordinieren Sie mit dem Automatisierungsbeauftragten Ihrer Organisation."
		},

		"views": {
			"choose": "Organisatorische Position auswählen",
			"accessDenied": "Zugriff verweigert",
			"position": "Position dem Personal zuweisen",
			"copyPosition": "Aktive Positionen in eine andere Position kopieren",
			"move":"Bewegen"
		},

		"position": {
			"mode": {
				"create": "Neues Personal zuweisen",
				"update": "Organisationsposition {label} bearbeiten"
			},
			"create": {
				"title": "Personal der Position {label} zuweisen",
			},
			"copy": {
				"title": "Personal von Position {label} kopieren",
				"treeTitle": "Um organisatorisches Personal zu kopieren, wählen Sie bitte die Zielposition aus",
				"listTitle": "Liste des aktuellen Personals innerhalb der Quellposition",
				"errors": {
					"error1": "Bitte wählen Sie eine Position aus",
					"error2": "Die Zielposition ist gleich der Quelle und das Kopieren ist nicht möglich",
				}
			}
		},

		"move": {
			"errors": {
				"error1": "Das Amt sollte einer Planstelle zugeordnet werden.",
				"error2": "Die Einheit muss unter einer Position oder einem Büro platziert werden.",
				"error3": "Die Position sollte unter einer Einheit oder einer anderen Position platziert werden."
			}
		}

	},

	"person": {
		"changePassword": {
			"invalidCurrentPassword": "Das aktuelle Passwort ist falsch",
			"fcp": 'Lieber Benutzer! <br/><br/> Sie haben sich mit dem Standardpasswort im System angemeldet. <br/> Zum Schutz Ihres Kontos müssen Sie zuerst Ihr Passwort ändern.'
		},

		"createReal": "Echte Person",
		"createLegal": "Juristische Person",
		"createSystem": "Systembenutzer / Bot"
	}
}
