<template>
	<kaveh-content width="1100px" :is-loading="isLoading">
		<kaveh-form v-if="props" :key="ver" :props="props" :task="submit" />
	</kaveh-content>
</template>
<script>
	import { ENUMS, BaseEntity } from '@/core/data'
	import { Product } from '@/modules/store/data/product/create'
	import { ProductService, FeatureService } from '@/modules/store/services'
	export default {
		data()
		{
			return {
				ver: 0,
				isLoading: true,
				props: null
			}
		},


		async beforeMount()
		{
			this.props = await this.getProps();
			this.isLoading = false;
		},

		methods:
		{
			async submit(payload)
			{
				payload = this.buildPayload(payload)

				try
				{
					const service = new ProductService();
					const res = await service.create(payload);
					if (res.status == 200)
					{
						this.ver += 1
					}

				} catch (e) { console.log(e) }
			},

			buildPayload(data)
			{
				var payload = {}
				var features = [];

				const keys = Object.keys(data);
				for (var i = 0; i < keys.length; i++)
				{
					const key = keys[i];
					if (key.startsWith('featureGroup_'))
					{
						const gKeys =
							Object.keys(data[key]);

						for (var ii = 0; ii < gKeys.length; ii++)
						{
							const gKey = gKeys[ii];

							const featureId = gKey.split('_')[1];
							const featureValue = data[key][gKey];

							features.push({
								featureId: Number(featureId),
								value: String(featureValue)
							})
						}
					}
					else
					{
						payload[key] = data[key]
					}
				}

				payload.features = features;
				var payloadTranslations = [];

				if (payload.translations)
				{
					var translations =
						JSON.parse(payload.translations);

					for (var i = 0; i < translations.length; i++)
					{
						payloadTranslations.push(this.buildPayload(translations[i]))
					}

					payload.translations = JSON.stringify(translations)
				}

				return payload
			},

			async getProps()
			{
				var features = [];

				try
				{
					const res = await new FeatureService().list({
						start: 0, length: 10000
					});

					if (res.status == 200)
					{
						features = res.data.items || []
					}

				} catch (e) { console.log(e) }

				const getFeatureInputType = (featureInputTypeId) =>
				{
					switch (featureInputTypeId)
					{
						case 1: return 'text';
						case 2: return 'number';
						case 3: return 'color';
					}

					return 'text';
				}

				const buildFeatureGroupEntity = (featureGroupId) =>
				{
					var featureProps = {};
					for (var i = 0; i < features.length; i++)
					{
						const feature = features[i];
						if (feature.featureGroupId == featureGroupId)
						{
							const name = `feature_${feature.featureId}`;
							const label = feature.title;
							const inputType = getFeatureInputType(feature.featureInputTypeId)

							var prop = {
								name: name,
								label: label,
								display: {
									isVisible: true,
									direction: ENUMS.DIRECTION_TYPE.RTL,
								},
								setConfig: {
									inputType: inputType,
									canUseInForm: true,
									patterns: [],
								},
								errors: [],
								value: null
							}

							featureProps[name] = prop
						}
					}

					var entity = new BaseEntity();
					entity.props = featureProps;

					return entity
				};


				var props = {};
				var product = new Product();
				for (var i = 0; i < features.length; i++)
				{
					const feature = features[i];
					const name = `featureGroup_${feature.featureGroupId}`;
					const label = feature.featureGroup.title;

					if (!props[name])
					{
						const prop = {
							name: name,
							label: label,
							type: ENUMS.PROP_TYPE.OBJECT,
							entity: buildFeatureGroupEntity(feature.featureGroupId),
							display: {
								isVisible: true,
								displayType: ENUMS.DISPLAY_TYPE.POPUP,
								direction: ENUMS.DIRECTION_TYPE.RTL,
							},
							setConfig: {
								inputType: ENUMS.INPUT_TYPE.FORM,
								canUseInForm: true,
								patterns: [],
							},
							errors: [],
							value: null
						}

						props[name] = prop
					}
				}

				return {
					...product.props,
					...props
				};
			}
		}
	}
</script>
