//GeneratorFlag:[NoChange]
export default {
	path: 'activity',
	name: 'aut.activity.layout',
	redirect: to => {
		return {name: 'aut.activity.list'};
	},
	component: () => import('@/modules/aut/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/aut/activity"],
		resourceKey: 'aut.activityLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-activity',
		}
	},
	children: [
//GeneratorFlag:[ActivityDelete]"
		{
			path: 'delete',
			name: 'aut.activity.delete',
			component: () => import('@/modules/aut/views/activity/activity-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.activityDelete',
				clientAccessKeys:["/aut/activity/delete"],
				serverAccessKeys:["/aut/activity/delete","/aut/activity/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ActivityDetail]"
		{
			path: 'detail',
			name: 'aut.activity.detail',
			component: () => import('@/modules/aut/views/activity/activity-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.activityDetail',
				clientAccessKeys:["/aut/activity/detail"],
				serverAccessKeys:["/aut/activity/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ActivityCreate]"
		{
			path: 'create',
			name: 'aut.activity.create',
			component: () => import('@/modules/aut/views/activity/activity-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.activityCreate',
				clientAccessKeys:["/aut/activity/create"],
				serverAccessKeys:["/aut/activity/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ActivityList]"
		{
			path: 'list',
			name: 'aut.activity.list',
			component: () => import('@/modules/aut/views/activity/activity-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.activityList',
				clientAccessKeys:["/aut/activity/list"],
				serverAccessKeys:["/aut/activity/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ActivityAttachment]"
		{
			path: 'attachment',
			name: 'aut.activity.attachment',
			component: () => import('@/modules/aut/views/activity/activity-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.activityAttachment',
				clientAccessKeys:["/aut/activity/attachment"],
				serverAccessKeys:["/aut/activity/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ActivityState]"
		{
			path: 'state',
			name: 'aut.activity.state',
			component: () => import('@/modules/aut/views/activity/activity-state.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.activityState',
				clientAccessKeys:["/aut/activity/state"],
				serverAccessKeys:["/aut/activity/state"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}