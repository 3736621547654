<template>
	<div class="kaveh-overlay">
		<div v-if="isLoading" class="loading">
			<div class="spinner-border spinner-border-sm" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</div>
		<div v-else-if="isEmpty" class="empty">
			<template v-if="hasNoContentSlot">
				<slot name="noContent"></slot>
			</template>
			<template v-else>
				<div class=" text-warning">
					<kaveh-icon-bootstrap name="bi-info-circle" size="20px" />
				</div>
			</template>
		</div>
		<slot v-else></slot>
	</div>
</template>
<script>
	export default {
		props: {
			isLoading: {
				type: Boolean,
				default: false
			},

			isEmpty: {
				type: Boolean,
				default: false
			}
		},


		computed:
		{
			hasNoContentSlot()
			{
				return this.$slots['noContent'] != undefined
			}
		},
	}
</script>
<style>
	.kaveh-overlay {
		width:100%;
		position:relative;
		border-radius:5px;
	}

		.kaveh-overlay .loading,
		.kaveh-overlay .empty {
			background: inherit;
			text-align: center;
			padding: 10px 0;
		}

		.kaveh-overlay .empty {
			text-shadow: 0px 0px 2px #ddd;
		}

</style>
