export default {

	encript: (value) =>
	{
		if (value)
		{
			return encodeURIComponent(btoa(value))
		}
	},

	decript: (value) =>
	{
		if (value)
		{
			return atob(decodeURIComponent(value))
		}
	},
}
