import { ENUMS, BaseEntity } from '@/core/data'
export default class Branch extends BaseEntity
{
	constructor(arg)
	{
		super();

		if ((arg || {}).value)
		{
			var props = this.props;
			switch (arg.value.branchTypeId)
			{
				case 1:
					props.branchName.resourceKey = 'hub.ext.branch.create.officeName';
					break;

				case 2:
					props.phone.setConfig.canUseInForm = false;
					props.address.setConfig.canUseInForm = false;
					props.cityId.setConfig.canUseInForm = false;
					props.branchName.resourceKey = 'hub.ext.branch.create.unitName';
					break;

				case 3:
					props.phone.setConfig.canUseInForm = false;
					props.address.setConfig.canUseInForm = false;
					props.cityId.setConfig.canUseInForm = false;
					props.branchName.resourceKey = 'hub.ext.branch.create.positionName';
					break;

				default:
			}

			this.props = props
		}

		this.prepair(arg);
	}

	name = 'branch';
	schema = ENUMS.SCHEMA_TYPE.NONE;
	resourceKey = 'hub.branch';
	props = {
		branchId: {
			primarykey: true,
			name: 'branchId',
			resourceKey: 'hub.branchBranchId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,
				canUseInExcel: false,
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		branchName: {
			name: 'branchName',
			resourceKey: 'hub.branchBranchName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
				stringLength: 50,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		phone: {
			name: 'phone',
			resourceKey: 'hub.branchPhone',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
				stringLength: 20,
				isUnicode: false,
				isUnique: true,
				patterns: ['number'],
			},
			errors: [],
			value: null
		},
		address: {
			name: 'address',
			resourceKey: 'hub.branchAddress',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
				stringLength: 50,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		cityId: {
			foreignKey: true,
			name: 'cityId',
			resourceKey: 'hub.branchCityId',
			type: ENUMS.PROP_TYPE.INT16,
			serverAddress: '/hub/city/list',
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'nativeName',
				valueKey: 'cityId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		branchTypeId: {
			name: 'branchTypeId',
			resourceKey: 'hub.branchBranchTypeId',
			type: ENUMS.PROP_TYPE.BYTE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,
				canUseInExcel: true,
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		parentId: {
			name: 'parentId',
			resourceKey: 'hub.branchParentId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,
				canUseInExcel: true,
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}
