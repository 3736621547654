<template>
	<div class="row pt-2 pb-2" v-if="item.displayValue">
		<div class="col" vi-f="">
			<span>{{ getLabel() }}</span>
		</div>
		<div class="col" v-html="getValue()">
		</div>
	</div>
</template>

<script>
	export default {

		props: {

			'item': {
				type: Object
			},
		},

		methods:
		{
			getLabel()
			{
				if (this.item.label)
				{
					return this.item.label
				}

				return this.$loc.fromResource(this.item.resourceKey)
			},

			getValue()
			{
				const value =
					String(this.item.displayValue || this.item.value || "");

				if (value.startsWith('data:image'))
				{
					return `<img src="${value}" />`
				}

				return value;
			}
		}
	}
</script>
