//GeneratorFlag:[NoChange]
export default {
	path: 'activity',
	name: 'aut.report.activity.layout',
	redirect: to => {
		return {name: 'aut.activity.fullReport'};
	},
	component: () => import('@/modules/aut/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/aut/report/activity"],
		resourceKey: 'aut.activityLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-activity',
		}
	},
	children: [
//GeneratorFlag:[ActivityFullReport]"
		{
			path: 'full-report',
			name: 'aut.activity.fullReport',
			component: () => import('@/modules/aut/views/activity/activity-full-report.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.activityFullReport',
				clientAccessKeys:["/aut/report/activity/full-report"],
				serverAccessKeys:["/aut/activity/full-report"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}