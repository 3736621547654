<template>
    <ul v-bind:class="{'kaveh-form-tree':root}">
        <slot></slot>
    </ul>
</template>
<script>
export default {
  props: {

    root: {
      type: Boolean,
      default: true
    }
  }
}
</script>
