//GeneratorFlag:[NoChange]
import ext from './fa-ext.js'
export default {
	"name":"hub",
	"ext": ext,
	"layout":{
		"geographical": "اطلاعات جغرافیایی", //GeneratorFlag:[GeographicalLayout]
		"basis": "اطلاعات پایه", //GeneratorFlag:[BasisLayout]
//GeneratorFlag:[NewLayout]
	},
	'accessAgent':'مدیریت دسترسی ها',
	'accessAgentAccessKeys':'کلید ها',
	'accessAgentLayout':'مدیریت دسترسی ها',
	'accessAgentSave':'مدیریت دسترسی ها',
	'accessAgentSaveIsSuccess':'نتیجه',
	'accessAgentSavePersonId':'کاربر',
	'accessAgentSavePersonRoleId':'نقش کاربری',
	'accessToken':'دریافت توکن',
	'accessTokenGenerate':'دریافت توکن',
	'accessTokenGenerateExpirationDate':'تاریخ انقضا',
	'accessTokenGeneratePassword':'کلمه عبور',
	'accessTokenGenerateToken':'توکن',
	'accessTokenGenerateTokenType':'نوع توکن',
	'accessTokenGenerateUserName':'نام کاربری',
	'accessTokenLayout':'دریافت توکن',
	'branch':'شاخه سازمانی',
	'branchAddress':'ادرس دفتر',
	'branchBranchId':'شناسه',
	'branchBranchName':'نام شاخه',
	'branchBranchTrack':'اطلاعات رهگیری ثبت',
	'branchBranchType':'والد',
	'branchBranchTypeId':'والد',
	'branchCity':'شهر دفتر',
	'branchCityId':'شهر دفتر',
	'branchCreate':'ثبت شاخه سازمانی جدید',
	'branchCreateAddress':'ادرس',
	'branchCreateBranchId':'شناسه',
	'branchCreateBranchName':'عنوان شاخه سازمانی',
	'branchCreateBranchTypeId':'والد',
	'branchCreateCityId':'شهر',
	'branchCreateParentId':'والد',
	'branchCreatePhone':'تلفن',
	'branchDelete':'حذف شاخه سازمانی',
	'branchDetail':'جزئیات اطلاعات شاخه سازمانی',
	'branchLayout':'شاخه های سازمانی',
	'branchList':'فهرست ساخه های سازمانی',
	'branchMove':'انتقال شاخه های سازمانی',
	'branchParentId':'والد',
	'branchParentName':'عنوان والد',
	'branchPhone':'تلفن دفتر',
	'branchPositions':'position',
	'branchTenantId':'مرکز',
	'branchTree':'درخت',
	'branchTypeBranchTypeId':'شناسه',
	'branchTypeCaption':'توضیحات',
	'branchTypeTitle':'عنوان',
	'branchUpdate':'ویرایش شاخه سازمانی',
	'branchUpdateAddress':'ادرس',
	'branchUpdateBranchId':'شناسه',
	'branchUpdateBranchName':'عنوان سمت سازمانی',
	'branchUpdateBranchTypeId':'والد',
	'branchUpdateCityId':'شهر',
	'branchUpdateParentId':'والد',
	'branchUpdatePhone':'تلفن',
	'city':'شهر',
	'cityAttachment':'پیوست فایل',
	'cityCaption':'توضیحات',
	'cityCityId':'شناسه',
	'cityCityTrack':'اطلاعات رهگیری ثبت',
	'cityCreate':'ثبت شهر جدید',
	'cityCreateImport':'ثبت شهرها از طریق اکسل',
	'cityDelete':'حذف شهر',
	'cityDetail':'جزئیات اطلاعات شهر',
	'cityIdentifier':'کلید یکتا',
	'cityLayout':' شهرها',
	'cityList':'فهرست شهرها',
	'cityNativeName':'نام شهر',
	'cityProvince':'استان',
	'cityProvinceId':'استان',
	'cityUpdate':'ویرایش شهر',
	'country':'کشور',
	'countryAttachment':'پیوست فایل',
	'countryCapitalName':'نام پایتخت',
	'countryCaption':'توضیحات',
	'countryCode':'کد کشور',
	'countryCountryId':'شناسه',
	'countryCountryTrack':'اطلاعات رهگیری ثبت',
	'countryCreate':'ثبت کشور جدید',
	'countryCreateImport':'ثبت کشور ها از طریق اکسل',
	'countryDelete':'حذف کشور',
	'countryDetail':'جزئیات اطلاعات کشور',
	'countryIdentifier':'کلید یکتا',
	'countryLayout':' کشور ها',
	'countryList':'فهرست کشور ها',
	'countryNativeName':'نام کشور',
	'countryUpdate':'ویرایش کشور',
	'keyValue':'کلید',
	'keyValueAttachment':'پیوست فایل',
	'keyValueCreate':'ثبت کلید جدید',
	'keyValueCreateImport':'ثبت کلیدها از طریق اکسل',
	'keyValueDelete':'حذف کلید',
	'keyValueDetail':'جزئیات اطلاعات کلید',
	'keyValueIdentifier':'کلید یکتا',
	'keyValueIsPublic':'کلید عمومی است؟',
	'keyValueKeyValueId':'شناسه',
	'keyValueKeyValueTrack':'اطلاعات رهگیری ثبت',
	'keyValueLayout':' کلیدها',
	'keyValueList':'فهرست کلیدها',
	'keyValueTextKey':'کلید',
	'keyValueTextValue':'مقدار',
	'keyValueTitle':'عنوان',
	'keyValueUpdate':'ویرایش کلید',
	'person':'شخص',
	'personAccess':'اطلاعات دسترسی',
	'personAccessAccessIps':'آیپی های مجاز',
	'personAccessDetail':'جزئیات اطلاعات دسترسی',
	'personAccessForceChangePassword':'تغییر رمز در اولین ورود؟',
	'personAccessIsActive':'دسترسی فعال است؟',
	'personAccessPassword':'کلمه عبور',
	'personAccessPersonId':'شناسه شخص',
	'personAccessTwoStepLogin':'لاگین دومرحله ای؟',
	'personAccessUpdate':'ویرایش اطلاعات دسترسی',
	'personAccessUseHardwareToken':'توکن سخت افزاری؟',
	'personAccessUserName':'نام کاربری',
	'personAttachment':'پیوست فایل',
	'personChangePassword':'تغییر کلمه عبور',
	'personChangePasswordNewPassword':'کلمه عبور جدید',
	'personChangePasswordOldPassword':'کلمه عبور فعلی',
	'personCity':'شهر',
	'personCityId':'شهر',
	'personCreate':'ثبت شخص جدید',
	'personCreateImport':'ثبت اشخاص از طریق اکسل',
	'personDelete':'حذف شخص',
	'personDetail':'جزئیات اطلاعات شخص',
	'personEntityCaption':'توضیحات',
	'personEntityPersonEntityId':'شناسه',
	'personEntityTitle':'عنوان',
	'personGenderCaption':'توضیحات',
	'personGenderPersonGenderId':'شناسه',
	'personGenderTitle':'عنوان',
	'personGradeCaption':'توضیحات',
	'personGradePersonGradeId':'شناسه',
	'personGradeTitle':'عنوان',
	'personGroupCaption':'توضیحات',
	'personGroupPersonGroupId':'شناسه',
	'personGroupTitle':'عنوان',
	'personIdentifier':'کلید یکتا',
	'personLayout':'اشخاص',
	'personLegal':'اطلاعات حقوقی',
	'personLegalAddress':'آدرس',
	'personLegalDetail':'جزئیات اطلاعات حقوقی',
	'personLegalEconomicNumber':'شناسه اقتصادی',
	'personLegalEmail':'ایمیل',
	'personLegalFax':'فکس',
	'personLegalLegalName':'نام حقوقی',
	'personLegalNationalCode':'شناسه ملی',
	'personLegalPhoneNumber':'شماره تماس ثابت',
	'personLegalPostalCode':'کد پستی',
	'personLegalRegisterNumber':'شناسه ثبت',
	'personLegalUpdate':'ویرایش اطلاعات حقوقی',
	'personList':'فهرست اشخاص',
	'personMaritalCaption':'توضیحات',
	'personMaritalPersonMaritalId':'شناسه',
	'personMaritalTitle':'عنوان',
	'personMilitary':'وضعیت سربازی',
	'personMilitaryAttachment':'پیوست فایل',
	'personMilitaryCaption':'توضیحات',
	'personMilitaryCreate':'ثبت وضعیت سربازی جدید',
	'personMilitaryCreateImport':'ثبت وضعیت های سربازی از طریق اکسل',
	'personMilitaryDelete':'حذف وضعیت سربازی',
	'personMilitaryDetail':'جزئیات اطلاعات وضعیت سربازی',
	'personMilitaryIdentifier':'کلید یکتا',
	'personMilitaryLayout':' وضعیت های سربازی',
	'personMilitaryList':'فهرست وضعیت های سربازی',
	'personMilitaryPersonMilitaryId':'شناسه',
	'personMilitaryPersonMilitaryTrack':'اطلاعات رهگیری ثبت',
	'personMilitaryPersonReals':'person real',
	'personMilitaryTitle':'عنوان',
	'personMilitaryUpdate':'ویرایش وضعیت سربازی',
	'personNationality':'ملیت',
	'personNationalityAttachment':'پیوست فایل',
	'personNationalityCaption':'توضیحات',
	'personNationalityCreate':'ثبت ملیت جدید',
	'personNationalityCreateImport':'ثبت ملیت ها از طریق اکسل',
	'personNationalityDelete':'حذف ملیت',
	'personNationalityDetail':'جزئیات اطلاعات ملیت',
	'personNationalityIdentifier':'کلید یکتا',
	'personNationalityLayout':' ملیت ها',
	'personNationalityList':'فهرست ملیت ها',
	'personNationalityPersonNationalityId':'شناسه',
	'personNationalityPersonNationalityTrack':'اطلاعات رهگیری ثبت',
	'personNationalityPersonReals':'person real',
	'personNationalityTitle':'عنوان',
	'personNationalityUpdate':'ویرایش ملیت',
	'personPersonAccess':'اطلاعات دسترسی',
	'personPersonEntity':'نوع شخصیت',
	'personPersonEntityId':'نوع شخصیت',
	'personPersonGroup':'گروه',
	'personPersonGroupId':'گروه',
	'personPersonId':'شناسه',
	'personPersonLegal':'اطلاعات حقوقی',
	'personPersonName':'نام',
	'personPersonReal':'اطلاعات حقیقی',
	'personPersonRole':'نقش کاربری',
	'personPersonRoleId':'نقش کاربری',
	'personPersonTrack':'اطلاعات رهگیری ثبت',
	'personPersonVehicle':'اطلاعات وسیله نقلیه',
	'personReal':'اطلاعات حقیقی',
	'personRealBirthDate':'تاریخ تولد',
	'personRealDetail':'جزئیات اطلاعات حقیقی',
	'personRealEmail':'ایمیل',
	'personRealFatherName':'نام پدر',
	'personRealFirstName':'نام',
	'personRealFullName':'نام و نام خانوادگی',
	'personRealHomeAddress':'آدرس منزل',
	'personRealIdentification':'شماره شناسنامه',
	'personRealLastName':'نام خانوادگی',
	'personRealMobile':'موبایل',
	'personRealMotherName':'نام مادر',
	'personRealNationalCode':'کد ملی',
	'personRealPersonGender':'جنسیت',
	'personRealPersonGenderId':'جنسیت',
	'personRealPersonGrade':'مدرک تحصیلی',
	'personRealPersonGradeId':'مدرک تحصیلی',
	'personRealPersonId':'شناسه شخص',
	'personRealPersonMarital':'وضعیت تاهل',
	'personRealPersonMaritalId':'وضعیت تاهل',
	'personRealPersonMilitary':'وضعیت سربازی',
	'personRealPersonMilitaryId':'وضعیت سربازی',
	'personRealPersonNationality':'ملیت',
	'personRealPersonNationalityId':'ملیت',
	'personRealPhoneNumber':'شماره تماس ثابت',
	'personRealPlaceOfBirth':'محل تولد',
	'personRealPostalCode':'کد پستی',
	'personRealUpdate':'ویرایش اطلاعات حقیقی',
	'personRealWorkAddress':'آدرس محل کار',
	'personRole':'نقش کاربری',
	'personRoleAttachment':'پیوست فایل',
	'personRoleCaption':'توضیحات',
	'personRoleCreate':'ثبت نقش کاربری جدید',
	'personRoleCreateImport':'ثبت نقش های کاربری از طریق اکسل',
	'personRoleDelete':'حذف نقش کاربری',
	'personRoleDetail':'جزئیات اطلاعات نقش کاربری',
	'personRoleIdentifier':'کلید یکتا',
	'personRoleLayout':' نقش های کاربری',
	'personRoleList':'فهرست نقش های کاربری',
	'personRolePersonRoleId':'شناسه',
	'personRolePersonRoleTrack':'اطلاعات رهگیری ثبت',
	'personRoleTenant':'شناسه',
	'personRoleTenantId':'شناسه',
	'personRoleTitle':'عنوان',
	'personRoleUpdate':'ویرایش نقش کاربری',
	'personTenant':'مرکز',
	'personTenantId':'مرکز',
	'personUpdate':'ویرایش شخص',
	'personVehicle':'اطلاعات وسیله نقلیه',
	'personVehicleCertificateNumber':'شماره گواهینامه',
	'personVehicleDetail':'جزئیات اطلاعات وسیله نقلیه',
	'personVehiclePersonId':'شناسه',
	'personVehicleUpdate':'ویرایش اطلاعات وسیله نقلیه',
	'personVehicleVehicleColor':'رنگ',
	'personVehicleVehicleName':'نام وسیله نقلیه',
	'personVehicleVehiclePlaque':'پلاک',
	'position':'Position',
	'positionBranch':'سمت سازمانی',
	'positionBranchId':'سمت سازمانی',
	'positionCreate':'ثبت Position جدید',
	'positionCreateImport':'ثبت Position از طریق اکسل',
	'positionCreateTime':'زمان ثبت',
	'positionCreatorName':'کاربر ثبت',
	'positionDelete':'حذف Position',
	'positionDetail':'جزئیات اطلاعات Position',
	'positionEndDate':'تاریخ پایان سمت',
	'positionHasAccessExternalSign':'امکان امضای نامه صادره',
	'positionHasAccessFirstSign':'امکان امضای اول',
	'positionHasAccessInternalSign':'امکان امضای نامه داخلی',
	'positionHasAccessSecondSign':'امکان امضای دوم',
	'positionIsDefaultPosition':'سمت پیشفرض',
	'positionLayout':'Position',
	'positionList':'فهرست Position',
	'positionLocalCreateDate':'تاریخ ثبت',
	'positionPerson':'پرسنل',
	'positionPersonId':'پرسنل',
	'positionPersonName':'نام',
	'positionPositionId':'شناسه',
	'positionPositionTrack':'اطلاعات رهگیری ثبت',
	'positionStartDate':'تاریخ شروع سمت',
	'positionUpdate':'ویرایش Position',
	'province':'استان',
	'provinceAttachment':'پیوست فایل',
	'provinceCaption':'توضیحات',
	'provinceCountry':'کشور',
	'provinceCountryId':'کشور',
	'provinceCreate':'ثبت استان جدید',
	'provinceCreateImport':'ثبت استان ها از طریق اکسل',
	'provinceDelete':'حذف استان',
	'provinceDetail':'جزئیات اطلاعات استان',
	'provinceIdentifier':'کلید یکتا',
	'provinceLayout':' استان ها',
	'provinceList':'فهرست استان ها',
	'provinceNativeName':'نام استان',
	'provinceProvinceId':'شناسه',
	'provinceProvinceTrack':'اطلاعات رهگیری ثبت',
	'provinceUpdate':'ویرایش استان',
	'region':'منطقه',
	'regionAttachment':'پیوست فایل',
	'regionCaption':'توضیحات',
	'regionCity':'شهر',
	'regionCityId':'شهر',
	'regionCreate':'ثبت منطقه جدید',
	'regionCreateImport':'ثبت مناطق از طریق اکسل',
	'regionDelete':'حذف منطقه',
	'regionDetail':'جزئیات اطلاعات منطقه',
	'regionIdentifier':'کلید یکتا',
	'regionLayout':' مناطق',
	'regionList':'فهرست مناطق',
	'regionNativeName':'نام منطقه',
	'regionRegionId':'شناسه',
	'regionRegionTrack':'اطلاعات رهگیری ثبت',
	'regionUpdate':'ویرایش منطقه',
	'tenant':'مرکز',
	'tenantAddress':'آدرس',
	'tenantCaption':'توضیحات',
	'tenantCity':'شهر',
	'tenantCityId':'شهر',
	'tenantCreate':'ثبت مرکز جدید',
	'tenantCreateImport':'ثبت مراکز از طریق اکسل',
	'tenantDelete':'حذف مرکز',
	'tenantDetail':'جزئیات اطلاعات مرکز',
	'tenantInstagramAddress':'ادرس اینستاگرام',
	'tenantLayout':' مراکز',
	'tenantList':'فهرست مراکز',
	'tenantOtherSocialMedia':'آدرس شبکه اجتماعی غیره',
	'tenantParentId':'زیر مجموعه',
	'tenantSiteAddress':'آدرس وب سایت',
	'tenantSubDomain':'زیر دامنه',
	'tenantTenantId':'شناسه',
	'tenantTenantTrack':'اطلاعات رهگیری ثبت',
	'tenantTenantType':'نوع مرکز',
	'tenantTenantTypeId':'نوع مرکز',
	'tenantTitle':'نام',
	'tenantTypeCultureId':'زبان',
	'tenantTypeIsOrigin':'ریشه',
	'tenantTypeTenantTypeKey':'کلید',
	'tenantTypeTitle':'عنوان',
	'tenantUpdate':'ویرایش مرکز',

}