import store from '@/core/store'
import { Ws } from '@/core/server'
import { toast } from '@/plugins'
import { ActivityService } from '@/modules/aut/services';

const activityNotification = {

	info: {
		name: 'kaveh.aut.activity.notification',
		routeName: 'aut.activity.list',
		iconName: 'bi-journal',
		hasCounter: true
	},

	initHandler: async () =>
	{
		new Ws().getConnection('hub').then((connection) =>
		{
			const updater = async () =>
			{
				try
				{
					const name = activityNotification.info.name;
					const branchId = store.state.client.branchId || 0;
					const count = localStorage.getItem(name) || 0


					if (branchId > 0)
					{
						const service = new ActivityService();
						const res = await service.notification({}, { HSR: false, HER: false });

						if (res.status == 200)
						{
							await store.dispatch('app/setAlarm', {
								name: name,
								counter: res.data.total,
							});

							if (res.data.total > count)
							{
								toast.notification({
									destination: "/aut/activity/list",
									msg: 'New Activity'
								})
							}

							localStorage.setItem(name, res.data.total)
						}
					}

				} catch (e) { }
			}

			connection.handle(`AutActivityNotification`, data =>
			{
				updater();
			});

			updater();
		})
	}
}


export default activityNotification

