//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class StaticFileService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[StaticFile.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/asset/static-file/list', {'params':params});
	};

	//GeneratorFlag:[StaticFile.Download]
	async download(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/asset/static-file/download', {'params':params});
	};

	//GeneratorFlag:[StaticFile.Upload]
	async upload(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/asset/static-file/upload', params || { });
	};

	//GeneratorFlag:[StaticFile.ManagerList]
	async managerList(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/asset/static-file/manager-list', {'params':params});
	};

	//GeneratorFlag:[StaticFile.DeleteById]
	async deleteById(staticFileId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:staticFileId
		}
	
		return await _server.delete('/asset/static-file/delete-by-id', {'params':params})
	};

	//GeneratorFlag:[StaticFile.DeleteByName]
	async deleteByName(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;

		const _server =
			this.server(config);

		return await _server.post('/asset/static-file/delete-by-name', params || {});
	};

//GeneratorFlag:[NewAction]
}
