//GeneratorFlag:[NoChange]
import { defineAsyncComponent, markRaw } from "vue";

const letterCompose = {

	info: {
		name: 'kaveh.app.toolbar.aut.letterCompose',
		iconName: 'bi-envelope-plus',
		hasCounter: false,
	},

	getData: async () =>
	{
		return null;
	},

	getComponent: () => markRaw(defineAsyncComponent(() => import('./views/letter-compose.vue'))),
}


export default letterCompose
