<template>
	<input v-bind:id="prop.id"
		   v-bind:dir="direction"
		   v-on:change="onHandelChange"
		   v-on:keyup="onHandelKeyUp"
		   v-model="value"
		   type="text"
		   class="form-control"
		   autocomplete="off" />
</template>
<script>
	export default {
		emits: ['change', 'keyup', 'submit'],

		props: {
			'prop': {
				type: Object
			},

			'languageDirection': {
				type: String,
				default: undefined
			},
		},

		data()
		{
			return {
				value: null
			}
		},

		beforeMount()
		{
			this.value = this.prop.value
		},

		computed:
		{
			direction()
			{
				if (this.$store.state.app.direction == 'ltr' || this.languageDirection == 'ltr')
				{
					return 'ltr'
				}

				return this.prop.display.direction;
			}
		},

		methods: {

			onHandelChange()
			{
				this.$emit('change', this.value)
			},

			onHandelKeyUp(e) {
				if (e.key === 'Enter' || e.keyCode === 13) {
					this.$emit('submit')
				}
				else {
					this.$emit('keyup', this.value)
				}
			}
		}
	}
</script>
