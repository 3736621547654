//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { City, TenantType } from '@/modules/hub/data/tenant/list'; 
export default class TenantFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'tenantFilter';
	schema = ENUMS.SCHEMA_TYPE.BASIS;
	resourceKey = 'global.filters';
	props = {
		tenantId: {
			primarykey: true,
			name: 'tenantId',
			resourceKey: 'hub.tenantTenantId',
			type: ENUMS.PROP_TYPE.INT16,
			isSearchable: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		title: {
			name: 'title',
			resourceKey: 'hub.tenantTitle',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: true,
				patterns: [],
			},
			errors: [],
			value: null
		},
		caption: {
			name: 'caption',
			resourceKey: 'hub.tenantCaption',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		siteAddress: {
			name: 'siteAddress',
			resourceKey: 'hub.tenantSiteAddress',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		subDomain: {
			name: 'subDomain',
			resourceKey: 'hub.tenantSubDomain',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		instagramAddress: {
			name: 'instagramAddress',
			resourceKey: 'hub.tenantInstagramAddress',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		otherSocialMedia: {
			name: 'otherSocialMedia',
			resourceKey: 'hub.tenantOtherSocialMedia',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		address: {
			name: 'address',
			resourceKey: 'hub.tenantAddress',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		tenantTypeId: {
			foreignKey: true,
			name: 'tenantTypeId',
			resourceKey: 'hub.tenantTenantTypeId',
			type: ENUMS.PROP_TYPE.BYTE,
			isSearchable: true,
			serverAddress: '/hub/tenant-type/list',
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'tenantTypeId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		cityId: {
			foreignKey: true,
			name: 'cityId',
			resourceKey: 'hub.tenantCityId',
			type: ENUMS.PROP_TYPE.INT16,
			isSearchable: true,
			serverAddress: '/hub/city/list',
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'nativeName',
				valueKey: 'cityId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		translations: {
			name: 'translations',
			resourceKey: 'hub.tenantTranslations',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}