import ENUMS from '../enums'
import validator from '@/core/validator'
import Localization from '@/core/localization'
import store from '@/core/store'

const loc = new Localization()

export default class BaseEntity
{
	constructor(arg)
	{
		this.name = 'entity';
		this.resourceKey = 'data.baseEntityForm';
	}

	prepair(arg)
	{
		if ((arg || {}).props)
		{
			this.props = arg.props;
		}

		if ((arg || {}).value)
		{
			this.setValue(arg.value)
		}
	}

	setValue(value, entity)
	{
		entity = entity || this;
		entity.value = {};
		entity.displayValue = {};

		const translation =
			this.getTranslation(value)

		for (const key in entity.props)
		{
			let prop = entity.props[key];

			if (value[key] != null && value[key] != undefined)
			{
				if (prop.type == "object" && prop.entity)
				{
					prop.entity.setValue(value[key])

					entity.value[prop.name] = prop.entity.value;
					entity.props[prop.name].value = prop.entity.value;
				}
				else
				{
					var convertedValue = value[key];
					switch (prop.type)
					{
						case "string":
							convertedValue = String(value[key]).trim()
							if (convertedValue == '')
							{
								convertedValue = null
							}
							break;
						case "array":
							if (typeof (convertedValue) == 'string')
							{
								if (!convertedValue.startsWith('['))
								{
									convertedValue = `[${convertedValue}]`;
								}

								convertedValue = JSON.parse(convertedValue)
							}
							break;

						case "boolean":
							if (typeof (convertedValue) == 'string')
							{
								const lv = convertedValue.toLowerCase();
								if (lv == 'true' || lv == '1')
								{
									convertedValue = true;
								}

								if (lv == 'false' || lv == '0')
								{
									convertedValue = true;
								}
							}
							break;
						case "number":
						case "byte":
						case "int16":
						case "int32":
						case "int64":
						case "double":
						case "decimal":
							if (prop.setConfig.isMultiple != true)
							{
								convertedValue = Number(value[key])
							}
							break;
					}

					entity.value[prop.name] = convertedValue;
					entity.props[prop.name].value = convertedValue;
				}

				let displayValue =
					this.getDisplayValue(prop, translation);

				if (displayValue && prop.display.isVisible)
				{
					entity.displayValue[prop.name] = displayValue;
					entity.props[prop.name].displayValue = displayValue;
				}
			}
		}
	}

	getValue(entity)
	{
		var value = {};
		entity = entity || this;

		for (const key in entity.props)
		{
			var prop = entity.props[key];

			var _value =
				(prop.type == "object" && prop.entity) ?
					this.getValue(prop.entity) : prop.value;

			if (prop.type == "object" && Object.keys(_value || {}).length == 0)
				continue;

			if (validator.hasValue(_value))
			{
				value[key] = _value
			}
		}

		return value
	}

	getFormValue(entity)
	{
		var value = {};
		entity = entity || this;

		for (const key in entity.props)
		{
			var prop = entity.props[key];

			var _value =
				(prop.type == "object" && prop.entity) ?
					this.getFormValue(prop.entity) : prop.value;

			if (prop.type == "object" && Object.keys(_value || {}).length == 0)
				continue;

			if (validator.hasValue(_value) && prop.setConfig.canUseInForm == true)
			{
				value[key] = _value
			}
		}

		return value
	}

	getExcelValue(entity)
	{
		var value = {};
		entity = entity || this;

		for (const key in entity.props)
		{
			var prop = entity.props[key];

			var _value =
				(prop.type == "object" && prop.entity) ?
					this.getExcelValue(prop.entity) : prop.value;

			if (prop.type == "object" && Object.keys(_value || {}).length == 0)
				continue;

			if (validator.hasValue(_value) && prop.setConfig.canUseInExcel == true)
			{
				value[key] = _value
			}
		}

		return value
	}

	parseValue(entity)
	{
		entity = entity || this;
		entity.value = {};

		for (const key in entity.props)
		{
			let prop = entity.props[key];

			if (prop.type == "object" && prop.entity)
			{
				prop.value = this.parseValue(prop.entity);
			}

			if (prop.value)
			{
				if (prop.type == "object" && Object.keys(prop.value).length == 0)
					continue;

				entity.props[prop.name].value = prop.value;
				entity.value[prop.name] = prop.value
			}
		}

		return entity.value
	}

	parseAllError(entity, parent)
	{
		entity = entity || this;
		entity.error = {};
		entity.errors = [];

		for (const key in entity.props)
		{
			let errors = [];
			let prop = entity.props[key];

			if (prop.setConfig.canUseInForm == true || prop.setConfig.canUseInExcel == true)
			{
				if (prop.type == "object" && prop.entity)
				{
					var value = this.getFormValue(prop.entity);
					if (Object.keys(value).length > 0)
					{
						this.parseAllError(prop.entity);
						errors = prop.entity.errors
					}
				}
				else
				{
					errors = validator.getPropErrors(prop)
				}

				if (errors && errors.length > 0)
				{
					entity.props[prop.name].errors = errors;
					entity.error[prop.name] = errors
					entity.errors = [...entity.errors, ...errors]
				}
			}
		}
	}

	parseFormError(entity, justMultiLanguages)
	{
		entity = entity || this;
		entity.error = {};

		for (const key in entity.props)
		{
			let prop = entity.props[key];

			let useThisProp = true;
			if (justMultiLanguages)
			{
				useThisProp = (prop.isMultiLanguages == true);
			}

			if (useThisProp && prop.setConfig.canUseInForm == true)
			{
				if (prop.type == "object" && prop.entity)
				{
					var value = this.getFormValue(prop.entity);
					if (Object.keys(value).length > 0)
					{
						this.parseFormError(prop.entity);
					}
				}
				else
				{
					let errors = validator.getPropErrors(prop)
					if (errors && errors.length > 0)
					{
						entity.props[prop.name].errors = errors;
						entity.error[prop.name] = errors
					}
				}
			}
		}
	}

	getFormError(entity, justMultiLanguages)
	{
		var error = {};
		entity = entity || this;

		for (const key in entity.props)
		{
			let prop = entity.props[key];

			let useThisProp = true;
			if (justMultiLanguages)
			{
				useThisProp = (prop.isMultiLanguages == true);
			}

			if (useThisProp && prop.setConfig.canUseInForm == true)
			{
				if (prop.type == "object" && prop.entity)
				{
					let _error =
						this.getFormError(prop.entity);

					if (Object.keys(_error).length > 0)
					{
						error[key] = _error
					}
				}
				else
				{
					let _errors = prop.errors

					if (_errors && _errors.length > 0)
					{
						error[key] = _errors
					}
				}
			}
		}

		return error
	}

	getFormErrorLength(entity)
	{
		return Object.keys(this.getFormError(entity)).length
	}

	parseExcelError(entity)
	{
		entity = entity || this;
		entity.error = {};

		for (const key in entity.props)
		{
			let prop = entity.props[key];
			if (prop.setConfig.canUseInExcel == true)
			{
				if (prop.type == "object" && prop.entity)
				{
					var value = this.getFormValue(prop.entity);
					if (Object.keys(value).length > 0)
					{
						this.parseExcelError(prop.entity);
					}
				}
				else
				{
					let errors = validator.getPropErrors(prop)
					if (errors && errors.length > 0)
					{
						entity.props[prop.name].errors = errors;
						entity.error[prop.name] = errors
					}
				}

			}
		}
	}

	getExcelError(entity)
	{
		var error = {};
		entity = entity || this;

		for (const key in entity.props)
		{
			let prop = entity.props[key];
			if (prop.setConfig.canUseInExcel == true)
			{
				if (prop.type == "object" && prop.entity)
				{
					let _error =
						this.getExcelError(prop.entity);

					if (Object.keys(_error).length > 0)
					{
						error[key] = _error
					}
				}
				else
				{
					let _errors = prop.errors

					if (_errors && _errors.length > 0)
					{
						error[key] = _errors
					}
				}
			}
		}

		return error
	}

	getExcelErrorLength(entity)
	{
		return Object.keys(this.getExcelError(entity)).length
	}

	getCleanProps()
	{
		var props = {};
		var biProps = {};
		var bsProps = {};
		var etcProps = {};

		const keys = Object.keys(this.props || {});
		for (var i = 0; i < keys.length; i++)
		{
			const prop = this.props[keys[i]];
			if (prop.type == 'object' && prop.entity)
			{
				if (prop.entity.schema == 'baseInfo')
				{
					biProps[prop.name] = prop
				}

				else if (prop.entity.schema == 'baseSys')
				{
					bsProps[prop.name] = prop
				}

				else
				{
					etcProps[prop.name] = prop
				}
			}
			else
			{
				props[prop.name] = prop
			}
		}


		return { ...props, ...bsProps, ...biProps, ...etcProps };
	}


	getFlatProps(entity)
	{
		var result = {};
		entity = entity || this;

		var getProps = (entity, parentName) =>
		{
			for (const item in entity.props)
			{
				const prop = { ...entity.props[item] };

				if (prop.setConfig.canUseInExcel)
				{
					if (prop.type != 'object')
					{
						var key = prop.name;
						if (parentName)
						{
							key = `${parentName}.${prop.name}`;
						}
						prop.data = [];
						result[key] = prop;
					}
					else
					{
						getProps(prop.entity, prop.name);
					}
				}
			}
		}

		getProps(entity);

		return result;
	}

	cleanError(entity)
	{
		entity = entity || this;
		for (const key in entity.props)
		{
			let prop = entity.props[key];
			if (prop.type == "object" && prop.entity)
			{
				this.cleanError(prop.entity);
			}
			else
			{
				prop.errors = undefined
			}
		}
	}

	cleanValue(entity)
	{
		entity = entity || this;
		for (const key in entity.props)
		{
			let prop = entity.props[key];
			if (prop.type == "object" && prop.entity)
			{
				this.cleanValue(prop.entity);

			}

			entity.props[key].value = null
			entity.props[key].displayValue = null
		}

		entity.value = null
		entity.displayValue = null
	}

	getFormProps()
	{
		var props = []
		for (var key in this.props)
		{
			var prop = this.props[key];
			if (prop.setConfig.canUseInForm == true)
			{
				props.push(this.props[key])
			}
		}

		return props
	}

	getExcelProps()
	{
		var props = []
		for (var key in this.props)
		{
			var prop = this.props[key];
			if (prop.setConfig.canUseInExcel == true)
			{
				props.push(this.props[key])
			}
		}

		return props
	}

	getTranslationProps()
	{
		var props = []
		for (var key in this.props)
		{
			var prop = this.props[key];
			if (prop.isMultiLanguages && prop.setConfig.canUseInForm == true)
			{
				props.push(this.props[key])
			}
		}

		return props
	}

	propHasRequired(prop)
	{
		return prop.setConfig.patterns.includes('required')
	}

	getTranslation(value)
	{
		var translation = {};
		var lang = store.state.app.language

		if (value && value["translations"] )
		{
			try
			{
				const translations =
					JSON.parse(value["translations"]) || [];

				const index = translations.findIndex((x) =>
				{
					return x.lang == lang
				});

				if (index > -1)
				{
					translation = translations[index]
				}

			} catch (e) { }
		}

		return translation
	}

	getDisplayValue(prop, translation)
	{
		var name = prop.name;
		var value = translation[name] || prop.value;

		if (prop.type == 'object')
		{
			if (prop.value && prop.value["translations"])
			{
				const translationValue =
					this.getTranslation(prop.value);

				if (Object.keys(translationValue).length > 0)
				{
					value = translationValue;
				}
				else
				{
					value = prop.value;
				}
			}
		}

		if (prop != undefined && prop.display != undefined)
		{
			var defaultValue = prop.display.defaultValue;

			if (defaultValue)
			{
				return loc.fromResource(defaultValue, value)
			}

			if ((prop.setConfig || {}).inputType == 'select' && Array.isArray(prop.data) && value)
			{
				const valueKey = prop.setConfig.valueKey;
				const textKey = prop.setConfig.textKey;
				const item = prop.data.find((x) =>
				{
					return x[valueKey] == value
				});

				if (item)
				{
					return item[textKey]
				}
			}
		}

		return value
	}
}
