import { defineAsyncComponent, markRaw } from "vue";

const branchChoose = {

	info: {
		name: 'kaveh.app.toolbar.hub.branchChoose',
		iconName: 'bi-person-vcard',
		hasCounter: false,
	},

	getData: async () =>
	{
		return null;
	},

	getComponent: () => markRaw(defineAsyncComponent(() => import('./views/branch-choose.vue'))),
}


export default branchChoose
