//GeneratorFlag:[NoChange]
import noticePatternRoute from './notice-pattern-route'; //GeneratorFlag:[NoticePattern]
//GeneratorFlag:[NewImport]
const routes = [{
	path: 'notification',
	name: 'notification',
	component: () => import('@/modules/notification/views/layout.vue'),
	meta: {
		requiresAuth: true,
		clientAccessKeys:['/notification'],
		serverAccessKeys:[],
		resourceKey: 'service.notification',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-envelope-at',
		}
	},
	children: [
noticePatternRoute, //GeneratorFlag:[NewChild]
//GeneratorFlag:[NewLayout]
	]
}]
export default routes
export{
noticePatternRoute, //GeneratorFlag:[NewExport]
}
