<template>
	<excel-parser v-if="state == 'parser'"
				  v-on:complete="parseComplete"
				  :entity="localEntity" />

	<excel-preview v-if="state == 'preview'"
				  :entity="localEntity"
				  :data="items" />

	<task-manager v-if="state == 'manager'"
				  v-on:complete="tasksComplete"
				  v-on:cancel="tasksCancel"
				  v-on:close="tasksClose"
				  :stream-address="streamAddress"
				  :entity="localEntity"
				  :task="task"
				  :data="items"
				  :label="label" />
</template>
<script>
	import { BaseEntity } from '@/core/data/entities'

	import taskManager from '@/components/task/task-manager'
	import excelParser from '@/components/excel/parser/excel-parser'
	import excelPreview from '@/components/excel/preview/excel-preview'
	var props = null;

	export default {
		emits: ['complete','cancel','close'],

		components: {
			'task-manager': taskManager,
			'excel-parser': excelParser,
			'excel-preview': excelPreview,
		},

		props: {

			'props': {
				type: Object
			},

			'data': {
				type: Array,
				default: null
			},

			'entity': {
				type: Function
			},

			'task': {
				Function
			},

			'streamAddress': {
				type: String
			},
		},

		data()
		{
			return {
				state: '',
				items: null,
				label: this.$loc.fromResource('action.importFromExcel')
			}
		},

		beforeMount()
		{
			if (this.props == undefined)
			{
				this.localEntity = this.entity;
			}
			else
			{
				props = this.props
				this.localEntity = function (arg)
				{
					arg = arg || {};
					arg.props = props

					return new BaseEntity(arg);
				}
			}

			this.state = 'parser'
		},

		methods: {

			parseComplete(data)
			{
				this.items = data
				this.state = this.task ? 'manager' : 'preview'
			},

			tasksComplete(data)
			{
				this.$emit('complete', data)
			},

			tasksCancel()
			{
				this.$emit('cancel')
			},

			tasksClose()
			{
				this.$emit('close')
			},

			getValue()
			{
				return JSON.parse(JSON.stringify(this.items))
			}
		}
	}
</script>
