//GeneratorFlag:[NoChange]
import BranchService from './branch-service'; //GeneratorFlag:[Branch]
import PersonService from './person-service'; //GeneratorFlag:[Person]
import PositionService from './position-service'; //GeneratorFlag:[Position]
import CityService from './city-service'; //GeneratorFlag:[City]
import CountryService from './country-service'; //GeneratorFlag:[Country]
import PersonMilitaryService from './person-military-service'; //GeneratorFlag:[PersonMilitary]
import PersonNationalityService from './person-nationality-service'; //GeneratorFlag:[PersonNationality]
import PersonRoleService from './person-role-service'; //GeneratorFlag:[PersonRole]
import ProvinceService from './province-service'; //GeneratorFlag:[Province]
import RegionService from './region-service'; //GeneratorFlag:[Region]
import PersonAccessService from './person-access-service'; //GeneratorFlag:[PersonAccess]
import PersonLegalService from './person-legal-service'; //GeneratorFlag:[PersonLegal]
import PersonRealService from './person-real-service'; //GeneratorFlag:[PersonReal]
import PersonVehicleService from './person-vehicle-service'; //GeneratorFlag:[PersonVehicle]
import BranchRelationTypeService from './branch-relation-type-service'; //GeneratorFlag:[BranchRelationType]
import BranchTypeService from './branch-type-service'; //GeneratorFlag:[BranchType]
import NoticeStatusService from './notice-status-service'; //GeneratorFlag:[NoticeStatus]
import PersonEntityService from './person-entity-service'; //GeneratorFlag:[PersonEntity]
import PersonGenderService from './person-gender-service'; //GeneratorFlag:[PersonGender]
import PersonGradeService from './person-grade-service'; //GeneratorFlag:[PersonGrade]
import PersonGroupService from './person-group-service'; //GeneratorFlag:[PersonGroup]
import PersonMaritalService from './person-marital-service'; //GeneratorFlag:[PersonMarital]
import AccessAgentService from './access-agent-service'; //GeneratorFlag:[AccessAgent]
import AccessTokenService from './access-token-service'; //GeneratorFlag:[AccessToken]
import NoticeService from './notice-service'; //GeneratorFlag:[Notice]
import OtpService from './otp-service'; //GeneratorFlag:[Otp]
import TenantService from './tenant-service'; //GeneratorFlag:[Tenant]
import TenantTypeService from './tenant-type-service'; //GeneratorFlag:[TenantType]
import ConfigService from './config-service'; //GeneratorFlag:[Config]
import KeyValueService from './key-value-service'; //GeneratorFlag:[KeyValue]
//GeneratorFlag:[NewImport]
export default {
BranchService, //GeneratorFlag:[Branch]
PersonService, //GeneratorFlag:[Person]
PositionService, //GeneratorFlag:[Position]
CityService, //GeneratorFlag:[City]
CountryService, //GeneratorFlag:[Country]
PersonMilitaryService, //GeneratorFlag:[PersonMilitary]
PersonNationalityService, //GeneratorFlag:[PersonNationality]
PersonRoleService, //GeneratorFlag:[PersonRole]
ProvinceService, //GeneratorFlag:[Province]
RegionService, //GeneratorFlag:[Region]
PersonAccessService, //GeneratorFlag:[PersonAccess]
PersonLegalService, //GeneratorFlag:[PersonLegal]
PersonRealService, //GeneratorFlag:[PersonReal]
PersonVehicleService, //GeneratorFlag:[PersonVehicle]
BranchRelationTypeService, //GeneratorFlag:[BranchRelationType]
BranchTypeService, //GeneratorFlag:[BranchType]
NoticeStatusService, //GeneratorFlag:[NoticeStatus]
PersonEntityService, //GeneratorFlag:[PersonEntity]
PersonGenderService, //GeneratorFlag:[PersonGender]
PersonGradeService, //GeneratorFlag:[PersonGrade]
PersonGroupService, //GeneratorFlag:[PersonGroup]
PersonMaritalService, //GeneratorFlag:[PersonMarital]
AccessAgentService, //GeneratorFlag:[AccessAgent]
AccessTokenService, //GeneratorFlag:[AccessToken]
NoticeService, //GeneratorFlag:[Notice]
OtpService, //GeneratorFlag:[Otp]
TenantService, //GeneratorFlag:[Tenant]
TenantTypeService, //GeneratorFlag:[TenantType]
ConfigService, //GeneratorFlag:[Config]
KeyValueService, //GeneratorFlag:[KeyValue]
//GeneratorFlag:[NewExport]
}
export{
BranchService, //GeneratorFlag:[Branch]
PersonService, //GeneratorFlag:[Person]
PositionService, //GeneratorFlag:[Position]
CityService, //GeneratorFlag:[City]
CountryService, //GeneratorFlag:[Country]
PersonMilitaryService, //GeneratorFlag:[PersonMilitary]
PersonNationalityService, //GeneratorFlag:[PersonNationality]
PersonRoleService, //GeneratorFlag:[PersonRole]
ProvinceService, //GeneratorFlag:[Province]
RegionService, //GeneratorFlag:[Region]
PersonAccessService, //GeneratorFlag:[PersonAccess]
PersonLegalService, //GeneratorFlag:[PersonLegal]
PersonRealService, //GeneratorFlag:[PersonReal]
PersonVehicleService, //GeneratorFlag:[PersonVehicle]
BranchRelationTypeService, //GeneratorFlag:[BranchRelationType]
BranchTypeService, //GeneratorFlag:[BranchType]
NoticeStatusService, //GeneratorFlag:[NoticeStatus]
PersonEntityService, //GeneratorFlag:[PersonEntity]
PersonGenderService, //GeneratorFlag:[PersonGender]
PersonGradeService, //GeneratorFlag:[PersonGrade]
PersonGroupService, //GeneratorFlag:[PersonGroup]
PersonMaritalService, //GeneratorFlag:[PersonMarital]
AccessAgentService, //GeneratorFlag:[AccessAgent]
AccessTokenService, //GeneratorFlag:[AccessToken]
NoticeService, //GeneratorFlag:[Notice]
OtpService, //GeneratorFlag:[Otp]
TenantService, //GeneratorFlag:[Tenant]
TenantTypeService, //GeneratorFlag:[TenantType]
ConfigService, //GeneratorFlag:[Config]
KeyValueService, //GeneratorFlag:[KeyValue]
//GeneratorFlag:[NewExport]
}