//GeneratorFlag:[NoChange]
export default {
	path: 'person-nationality',
	name: 'hub.basis.personNationality.layout',
	redirect: to => {
		return {name: 'hub.personNationality.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/basis/person-nationality"],
		resourceKey: 'hub.personNationalityLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonNationalityCreate]"
		{
			path: 'create',
			name: 'hub.personNationality.create',
			component: () => import('@/modules/hub/views/person-nationality/person-nationality-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.personNationalityCreate',
				clientAccessKeys:["/hub/basis/person-nationality/create"],
				serverAccessKeys:["/hub/person-nationality/create","/hub/person-nationality/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonNationalityCreateImport]"
		{
			path: 'create-import',
			name: 'hub.personNationality.createImport',
			component: () => import('@/modules/hub/views/person-nationality/person-nationality-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.personNationalityCreateImport',
				clientAccessKeys:["/hub/basis/person-nationality/create-import"],
				serverAccessKeys:["/hub/person-nationality/create","/hub/person-nationality/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonNationalityDelete]"
		{
			path: 'delete',
			name: 'hub.personNationality.delete',
			component: () => import('@/modules/hub/views/person-nationality/person-nationality-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.personNationalityDelete',
				clientAccessKeys:["/hub/basis/person-nationality/delete"],
				serverAccessKeys:["/hub/person-nationality/delete","/hub/person-nationality/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonNationalityDetail]"
		{
			path: 'detail',
			name: 'hub.personNationality.detail',
			component: () => import('@/modules/hub/views/person-nationality/person-nationality-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.personNationalityDetail',
				clientAccessKeys:["/hub/basis/person-nationality/detail"],
				serverAccessKeys:["/hub/person-nationality/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonNationalityList]"
		{
			path: 'list',
			name: 'hub.personNationality.list',
			component: () => import('@/modules/hub/views/person-nationality/person-nationality-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.personNationalityList',
				clientAccessKeys:["/hub/basis/person-nationality/list"],
				serverAccessKeys:["/hub/person-nationality/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonNationalityAttachment]"
		{
			path: 'attachment',
			name: 'hub.personNationality.attachment',
			component: () => import('@/modules/hub/views/person-nationality/person-nationality-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.personNationalityAttachment',
				clientAccessKeys:["/hub/basis/person-nationality/attachment"],
				serverAccessKeys:["/hub/person-nationality/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonNationalityUpdate]"
		{
			path: 'update',
			name: 'hub.personNationality.update',
			component: () => import('@/modules/hub/views/person-nationality/person-nationality-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.personNationalityUpdate',
				clientAccessKeys:["/hub/basis/person-nationality/update"],
				serverAccessKeys:["/hub/person-nationality/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}