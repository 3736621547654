<template>
	<div class="swiper activity-slider">
		<div class="swiper-wrapper">
			<div class="swiper-slide activity-slide"
				 v-for="(item, nodeIndex) in node.nodes" :key="nodeIndex" v-bind:class="{'acted': item.activityActionId}">
				<div class="activity-slide-icon">
					<kaveh-icon-bootstrap v-if="item.activityActionId <= 2" name="bi-check2" color="green" size="20px" />
					<kaveh-icon-bootstrap v-else-if="item.activityActionId == 3" name="bi-arrow-clockwise" color="orange" size="20px" />
					<kaveh-icon-bootstrap v-else-if="item.activityActionId == 4" name="bi-check2-all" color="green" size="20px" />
					<kaveh-icon-bootstrap v-else-if="item.activityActionId == 5" name="bi-x-lg" color="red" size="20px" />
					<kaveh-icon-bootstrap v-else-if="node.activityStatusId <= 2" name="bi-hourglass-split" color="gray" size="20px" />
					<kaveh-icon-bootstrap v-else name="bi-dash" color="gray" size="20px" />
				</div>
				<div class="activity-slide-title">{{item.processNodeTitle}}</div>
				<div class="activity-slide-caption text-muted mt-2">{{getActivityCaption(item)}}</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Swiper from 'swiper';
	export default {

		props: {
			node: {
				type:Object
			}
		},

		mounted()
		{
			new Swiper('.swiper', {
				slidesPerView: 4.1,
				spaceBetween: 20
			});
		},

		methods: {
			getActivityCaption(node)
			{
				if (node.activityActionId)
				{
					var action = "";
					switch (node.activityActionId)
					{
						case 1: action = "new"; break;
						case 2: action = "continue"; break;
						case 3: action = "return"; break;
						case 4: action = "complete"; break;
						case 5: action = "stop"; break;
					}

					var action =
						node[`${action}Title`] ||
						this.$loc.fromResource(`aut.ext.activity.list.${action}Title`);


					return this.$loc.fromResource(`aut.ext.activity.list.caption`, {
						action, activist: node.activistName
					})
				}

				return "";
			}
		}
	}
</script>
<style>
	.activity-slider {
		width: 100%;
	}

		.activity-slider .activity-slide {
			float: right;
			background: #f8f8f8;
			border-radius: 10px;
			margin-left: 20px;
			height: 120px;
			padding: 10px;
			font-size: 11px;
			position: relative;
			text-align: center;
		}

			.activity-slider .activity-slide::after {
				content: "";
				position: absolute;
				width: 22px;
				height: 1px;
				border-bottom: #ddd dashed 2px;
				right: -21px;
				top: 60px;
			}

			.activity-slider .activity-slide.acted::after {
				border-bottom: green solid 2px;
			}

			.activity-slider .activity-slide:first-child::after {
				display: none;
			}
</style>
