//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { Node } from '@/modules/aut/data/activity/list'; 
export default class Activity extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'activity';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'aut.activityList';
	props = {
		query: {
			name: 'query',
			resourceKey: 'aut.activityListQuery',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		pageName: {
			name: 'pageName',
			resourceKey: 'aut.activityListPageName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		processId: {
			name: 'processId',
			resourceKey: 'aut.activityListProcessId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		processTitle: {
			name: 'processTitle',
			resourceKey: 'aut.activityListProcessTitle',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		processCaption: {
			name: 'processCaption',
			resourceKey: 'aut.activityListProcessCaption',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		activityId: {
			primarykey: true,
			name: 'activityId',
			resourceKey: 'aut.activityListActivityId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		deadlineHour: {
			name: 'deadlineHour',
			resourceKey: 'aut.activityListDeadlineHour',
			type: ENUMS.PROP_TYPE.INT16,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		activityPriorityId: {
			name: 'activityPriorityId',
			resourceKey: 'aut.activityListActivityPriorityId',
			type: ENUMS.PROP_TYPE.BYTE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		activityPriorityTitle: {
			name: 'activityPriorityTitle',
			resourceKey: 'aut.activityListActivityPriorityTitle',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		activityStatusId: {
			name: 'activityStatusId',
			resourceKey: 'aut.activityListActivityStatusId',
			type: ENUMS.PROP_TYPE.BYTE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		activityStatusTitle: {
			name: 'activityStatusTitle',
			resourceKey: 'aut.activityListActivityStatusTitle',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		activityStatusHexColor: {
			name: 'activityStatusHexColor',
			resourceKey: 'aut.activityListActivityStatusHexColor',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.COLOR,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.COLOR,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		localCreateDate: {
			name: 'localCreateDate',
			resourceKey: 'aut.activityListLocalCreateDate',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		createTime: {
			name: 'createTime',
			resourceKey: 'aut.activityListCreateTime',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TIME_PICKER,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		nodes: {
			name: 'nodes',
			resourceKey: 'aut.activityListNodes',
			type: ENUMS.PROP_TYPE.ARRAY,
			entity: new Node(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{formValue}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				collectionMinLength: 0,
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: []
		},
		identifier: {
			name: 'identifier',
			resourceKey: 'aut.activityListIdentifier',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}