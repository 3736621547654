<template>
	<kaveh-overlay :is-empty="$store.state.client.branches.length == 0" style="min-height:70px;">
		<template v-slot:noContent>
			<div class="card bg-light justify-content-center mb-2 pt-3 pb-3">
				<div class="card-body">
					<div class="d-flex align-items-center">
						<kaveh-icon-bootstrap color="red" name="bi-info-circle me-3" />
						<span>{{ $loc.fromResource('hub.ext.branch.choose.noFound')}}</span>
					</div>
				</div>
			</div>
		</template>
		<div class="row branch-choose">
			<div class="col-12">
				<div class="alert alert-light mb-1">
					<strong>{{ $loc.fromResource('hub.ext.branch.choose.head')}}</strong>
				</div>
				<div class="alert alert-warning">
					<p>{{ $loc.fromResource('hub.ext.branch.choose.warning')}}</p>
				</div>
			</div>
			<div class="col col-lg-12 mb-2" v-for="(branch, index) in $store.state.client.branches" :key="index">
				<div class="item" @click="onHandleSelect(branch)" v-bind:class="{'selected':branchId == branch.branchId}">
					<strong>{{ branch.branchName }}</strong><br /><br />
					<small>{{ branch.officeName }} > {{ branch.unitName }} > {{ branch.branchName }}</small>
					<span class="check" v-if="branchId == branch.branchId">	&#10003; {{ $loc.fromResource('hub.ext.branch.choose.selected') }}</span>
				</div>
			</div>
		</div>
	</kaveh-overlay>
</template>
<script>
	export default {
		emits: ['complete'],

		computed:
		{
			branchId()
			{
				const state =
					this.$store.state.client;

				if (state.branch !== null)
				{
					return state.branch.branchId
				}

				return 0;
			}
		},

		methods: {

			async onHandleSelect(branch)
			{
				await this.$store.dispatch('client/setBranch', branch);
				this.$emit('complete')
			}
		}
	}
</script>
<style>
	.branch-choose .item {
		cursor: pointer;
		background: #f8f8f8;
		text-align: center;
		padding-top: 20px;
		height: 130px;
		transform: scale(1);
		position: relative;
		border: transparent solid 1px;
		border-radius: 10px;
		border: #ddd solid 1px;
	}

		.branch-choose .item.selected {
			color: green;
			border-color:green;
			border-width:2px;
			font-weight:bold;
		}

		.branch-choose .item:hover {
			transform: scale(1.02);
		}

		.branch-choose .item .check {
			position: absolute;
			bottom: 10px;
			right: 10px;
		}
</style>
