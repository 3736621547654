<template>
	<div class="letter-forward-step-3 pt-2">
		<div class="mb-3 row">
			<label for="letterPatternId" class="col-4 col-md-3">
				<span>{{$loc.fromResource(form.letterPatternId.resourceKey)}}</span>
			</label>
			<div class="col-8 col-md-9">
				<select class="form-select" @change="onHandleChangePattern" v-bind:class="{'is-loading': letterPatternListIsLoading}" v-model="form.letterPatternId.value" id="letterPatternId">
					<option v-for="(item,index) in letterPatternList" :value="item.letterPatternId" :key="index">
						{{item.title}}
					</option>
				</select>
			</div>
		</div>
		<div class="row">
			<div class="col pt-3">
				<kaveh-text-editor ref="Editor" />
			</div>
		</div>
	</div>
</template>
<script>
	import { ENUMS } from '@/core/data'
	import { LetterPatternService } from '@/modules/aut/services';

	export default {

		data()
		{
			return {
				letterPatternList: [],
				letterPatternListIsLoading: true,
				form: {
					letterPatternId: {
						type: ENUMS.PROP_TYPE.INT16,
						resourceKey: 'aut.letterCreate.letterPatternId',
						value: null,
					},
				}
			}
		},

		async beforeMount()
		{
			this.letterPatternList = await this.getLetterPatternList();
		},

		methods:
		{
			async getLetterPatternList()
			{
				var items = [];
				var service = new LetterPatternService();

				try
				{
					const res = await service.list({
						start: 0,
						length: 1000
					}, { HER: true });

					if (res.status == 200)
					{
						items = res.data.items || [];
					}

				} catch (e) { }

				this.letterPatternListIsLoading = false;
				return items;
			},

			onHandleChangePattern()
			{
				const pattern = this.letterPatternList.find((x) =>
				{
					return x.letterPatternId == this.form.letterPatternId.value
				})

				this.$refs.Editor.setValue(pattern.patternBody)
			},

			getValue()
			{
				return this.$refs.Editor.getValue()
			}
		}
	}
</script>
