<template>
	<kaveh-table :data="data" :entity="entity" :selectable="false" :show-foreign-key="true" />
</template>
<script>
	export default {
		props: {

			'entity': {
				type: Function
			},

			'data': {
				type: Array
			},

		},
	}
</script>
