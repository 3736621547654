export default {
	path: 'file-manager',
	name: 'asset.fileManager.layout',
	component: () => import('@/modules/asset/views/file-manager/file-manager-layout.vue'),
	meta: {
		requiresAuth: true,
		clientAccessKeys: ["/asset/file-manager"],
		serverAccessKeys: [
			'/asset/static-file/manager-list',
			'/asset/static-file/upload',
			'/asset/static-file/download',
			'/asset/static-file/delete',
			'/asset/virtual-folder/create',
			'/asset/virtual-folder/delete',
			'/asset/virtual-folder/detail',
			'/asset/virtual-folder/list',
			'/asset/virtual-folder/update'
		],
		resourceKey: 'asset.ext.views.fileManager.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-envelope-fill',
		}
	},
}
