//GeneratorFlag:[NoChange]
import ext from './fa-ext.js'
export default {
	"name":"store",
	"ext": ext,
	"layout":{
		"basis": "اطلاعات پایه", //GeneratorFlag:[BasisLayout]
//GeneratorFlag:[NewLayout]
	},
	'deliveryDateType':'زمان تحویل محصول',
	'deliveryDateTypeAttachment':'پیوست فایل',
	'deliveryDateTypeCaption':'توضیحات',
	'deliveryDateTypeCreate':'ثبت زمان تحویل محصول جدید',
	'deliveryDateTypeCreateImport':'ثبت زمان های تحویل محصول از طریق اکسل',
	'deliveryDateTypeDelete':'حذف زمان تحویل محصول',
	'deliveryDateTypeDeliveryDateTypeId':'شناسه',
	'deliveryDateTypeDeliveryDateTypeTrack':'اطلاعات رهگیری ثبت',
	'deliveryDateTypeDetail':'جزئیات اطلاعات زمان تحویل محصول',
	'deliveryDateTypeIdentifier':'کلید یکتا',
	'deliveryDateTypeLayout':' زمان های تحویل محصول',
	'deliveryDateTypeList':'فهرست زمان های تحویل محصول',
	'deliveryDateTypeTitle':'عنوان',
	'deliveryDateTypeUpdate':'ویرایش زمان تحویل محصول',
	'discount':'تخفیف',
	'discountAttachment':'پیوست فایل',
	'discountCouponCode':'کد تخفیف',
	'discountCreate':'ثبت تخفیف جدید',
	'discountCreateImport':'ثبت تخفیفات از طریق اکسل',
	'discountDelete':'حذف تخفیف',
	'discountDetail':'جزئیات اطلاعات تخفیف',
	'discountDiscountAmount':'مبلغ تخفیف',
	'discountDiscountId':'شناسه',
	'discountDiscountPercentage':'درصد تحفیف',
	'discountDiscountTrack':'اطلاعات رهگیری ثبت',
	'discountEndDate':'تاریخ پایان',
	'discountIdentifier':'کلید یکتا',
	'discountLayout':'تخفیفات',
	'discountList':'فهرست تخفیفات',
	'discountStartDate':'تاریخ شروع',
	'discountTitle':'عنوان',
	'discountUpdate':'ویرایش تخفیف',
	'feature':'ویژگی محصول',
	'featureAttachment':'پیوست فایل',
	'featureCreate':'ثبت ویژگی محصول جدید',
	'featureCreateImport':'ثبت ویژگی های محصول از طریق اکسل',
	'featureDefaultValue':'مقدار پیشفرض',
	'featureDelete':'حذف ویژگی محصول',
	'featureDetail':'جزئیات اطلاعات ویژگی محصول',
	'featureFeatureGroup':'گروه ویژگی',
	'featureFeatureGroupId':'گروه ویژگی',
	'featureFeatureId':'شناسه',
	'featureFeatureInputType':'نوع کنترل',
	'featureFeatureInputTypeId':'نوع کنترل',
	'featureFeatureTrack':'اطلاعات رهگیری ثبت',
	'featureGroup':'گروه ویژگی محصول',
	'featureGroupAttachment':'پیوست فایل',
	'featureGroupCaption':'توضیحات',
	'featureGroupCreate':'ثبت گروه ویژگی محصول جدید',
	'featureGroupCreateImport':'ثبت گروه ویژگی های محصول از طریق اکسل',
	'featureGroupDelete':'حذف گروه ویژگی محصول',
	'featureGroupDetail':'جزئیات اطلاعات گروه ویژگی محصول',
	'featureGroupFeatureGroupId':'شناسه',
	'featureGroupFeatureGroupTrack':'اطلاعات رهگیری ثبت',
	'featureGroupIdentifier':'کلید یکتا',
	'featureGroupLayout':' گروه ویژگی های محصول',
	'featureGroupList':'فهرست گروه ویژگی های محصول',
	'featureGroupParentId':'والد',
	'featureGroupTitle':'عنوان',
	'featureGroupUpdate':'ویرایش گروه ویژگی محصول',
	'featureIdentifier':'کلید یکتا',
	'featureInputTypeCaption':'توضیحات',
	'featureInputTypeFeatureInputTypeId':'شناسه',
	'featureInputTypeTitle':'عنوان',
	'featureIsPricingFeature':'شاخص قیمت گذاری',
	'featureIsProductFeature':'ویژگی عمومی محصول',
	'featureLayout':'ویژگی های محصول',
	'featureList':'فهرست ویژگی های محصول',
	'featureTitle':'عنوان',
	'featureUpdate':'ویرایش ویژگی محصول',
	'pricing':'قیمت محصول',
	'pricingCartMaxLength':'حداکثر تعداد سبد خرید',
	'pricingCartMinLength':'حداقل تعداد سبد خرید',
	'pricingCreate':'ثبت قیمت محصول جدید',
	'pricingCreateImport':'ثبت قیمت های محصولات از طریق اکسل',
	'pricingDelete':'حذف قیمت محصول',
	'pricingDeliveryDateType':'زمان تحویل',
	'pricingDeliveryDateTypeId':'زمان تحویل',
	'pricingDetail':'جزئیات اطلاعات قیمت محصول',
	'pricingFeature':'شاخص قیمت',
	'pricingFeatureId':'شاخص قیمت',
	'pricingFeatureValue':'مقدار شاخص',
	'pricingIsPrimary':'بروشور اصلی',
	'pricingIsTaxExempt':'معاف از مالیات؟',
	'pricingLayout':'قیمت های محصولات',
	'pricingList':'فهرست قیمت های محصولات',
	'pricingOldPrice':'قیمت قدیم',
	'pricingPackingCost':'هزینه بسته بندی',
	'pricingPrice':'قیمت محصول',
	'pricingPricingId':'شناسه',
	'pricingPricingTrack':'اطلاعات رهگیری ثبت',
	'pricingProduct':'محصول',
	'pricingProductId':'محصول',
	'pricingShippingCost':'هزینه ی حمل جداگانه',
	'pricingStockQuantity':'تعداد موجودی',
	'pricingTaxAmount':'هزینه ی مالیات',
	'pricingTaxPercentage':'درصد مالیات',
	'pricingUpdate':'ویرایش قیمت محصول',
	'pricingWarehouseCode':'شناسه کالا در انبار',
	'producer':'تولید کننده',
	'producerAttachment':'پیوست فایل',
	'producerCaption':'توضیحات',
	'producerCreate':'ثبت تولید کننده جدید',
	'producerCreateImport':'ثبت تولید کنندگان از طریق اکسل',
	'producerDelete':'حذف تولید کننده',
	'producerDetail':'جزئیات اطلاعات تولید کننده',
	'producerIdentifier':'کلید یکتا',
	'producerLayout':' تولید کنندگان',
	'producerList':'فهرست تولید کنندگان',
	'producerProducerId':'شناسه',
	'producerProducerTrack':'اطلاعات رهگیری ثبت',
	'producerTitle':'عنوان',
	'producerUpdate':'ویرایش تولید کننده',
	'product':'محصول',
	'productAttachment':'پیوست فایل',
	'productCallForPrice':'تماس برای قیمت؟',
	'productCaption':'توضیح کوتاه',
	'productCreate':'ثبت محصول جدید',
	'productCreateImport':'ثبت محصولات از طریق اکسل',
	'productDelete':'حذف محصول',
	'productDetail':'جزئیات اطلاعات محصول',
	'productDisableBuyButton':'دکمه خرید غیرفعال شود؟',
	'productDiscount':'تخفیف',
	'productDiscountId':'تخفیف',
	'productEndSaleDate':'تاریخ پایان فروش',
	'productFeatures':'feature',
	'productGroup':'گروه محصولات',
	'productGroupAttachment':'پیوست فایل',
	'productGroupCaption':'توضیحات',
	'productGroupCreate':'ثبت گروه محصولات جدید',
	'productGroupCreateImport':'ثبت گروه محصولات از طریق اکسل',
	'productGroupDelete':'حذف گروه محصولات',
	'productGroupDetail':'جزئیات اطلاعات گروه محصولات',
	'productGroupIdentifier':'کلید یکتا',
	'productGroupImageAttachment':'پیوست تصویر',
	'productGroupLayout':' گروه محصولات',
	'productGroupList':'فهرست گروه محصولات',
	'productGroupParentId':'والد',
	'productGroupProductGroupId':'شناسه',
	'productGroupProductGroupTrack':'اطلاعات رهگیری ثبت',
	'productGroupTitle':'عنوان',
	'productGroupUpdate':'ویرایش گروه محصولات',
	'productGroupViewOrder':'ترتیب نمایش',
	'productIdentifier':'کلید یکتا',
	'productImageAttachment':'پیوست تصویر',
	'productIsRelease':'منتشر شود؟',
	'productLayout':'محصولات',
	'productList':'فهرست محصولات',
	'productProducer':'تولید کننده',
	'productProducerId':'تولید کننده',
	'productProductGroup':'گروه محصول',
	'productProductGroupId':'گروه محصول',
	'productProductId':'شناسه',
	'productProductSeo':'اطلاعات سئو محصول',
	'productProductTrack':'اطلاعات رهگیری ثبت',
	'productSeo':'اطلاعات سئو محصول',
	'productSeoDescriptionTag':'تگ توضیحات',
	'productSeoDetail':'جزئیات اطلاعات سئو محصول',
	'productSeoKeywordsTag':'تگ برچسب ها',
	'productSeoPageTitle':'عنوان بالای صفحه',
	'productSeoProductId':'شناسه',
	'productSeoTitleTag':'تگ عنوان',
	'productSeoUpdate':'ویرایش اطلاعات سئو محصول',
	'productShowInHomePage':'نمایش در صفحه اصلی؟',
	'productStartSaleDate':'تاریخ شروع فروش',
	'productStringContent':'توضیحات کامل',
	'productTitle':'عنوان',
	'productUpdate':'ویرایش محصول',
	'productViewOrder':'ترتیب نمایش',
	'productWarehouse':'انبار',
	'productWarehouseId':'انبار',
	'warehouse':'انبار',
	'warehouseAttachment':'پیوست فایل',
	'warehouseCaption':'توضیحات',
	'warehouseCreate':'ثبت انبار جدید',
	'warehouseCreateImport':'ثبت انبارها از طریق اکسل',
	'warehouseDelete':'حذف انبار',
	'warehouseDetail':'جزئیات اطلاعات انبار',
	'warehouseIdentifier':'کلید یکتا',
	'warehouseLayout':' انبارها',
	'warehouseList':'فهرست انبارها',
	'warehouseTitle':'عنوان',
	'warehouseUpdate':'ویرایش انبار',
	'warehouseWarehouseId':'شناسه',
	'warehouseWarehouseTrack':'اطلاعات رهگیری ثبت',

}