<template>
	<div class="row mb-3">
		<div class="col">
			<router-link :to="{'name':'aut.activity.create'}" class="btn rounded-pill text-light border-0 btn-base-color w-100">
				<kaveh-icon-bootstrap name="bi-plus" size="18px" />
				<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.activity.views.compose')}}</span>
			</router-link>
		</div>
	</div>
	<div class="row mb-3">
		<div class="col">
			<div class="dropdown">
				<button class="btn btn-light dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
					<span v-if="pageName == 'currents'">{{$loc.fromResource('aut.ext.activity.views.currents')}}</span>
					<span v-if="pageName == 'my-activities'">{{$loc.fromResource('aut.ext.activity.views.myActivities')}}</span>
					<span v-if="pageName == 'completed'">{{$loc.fromResource('aut.ext.activity.views.completed')}}</span>
					<span v-if="pageName == 'stoped'">{{$loc.fromResource('aut.ext.activity.views.stoped')}}</span>
				</button>
				<ul class="dropdown-menu">
					<li class="dropdown-item" @click="handleChangeFilter('pageName','currents')">
						<div v-bind:class="{'text-bold': pageName == 'currents'  }">
							<kaveh-icon-bootstrap name="bi-lightning-charge" size="18px" />
							<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.activity.views.currents')}}</span>
						</div>
					</li>
					<li class="dropdown-item" @click="handleChangeFilter('pageName','my-activities')">
						<div v-bind:class="{'text-bold': pageName == 'my-activities' }">
							<kaveh-icon-bootstrap name="bi-person" size="18px" />
							<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.activity.views.myActivities')}}</span>
						</div>
					</li>
					<li class="dropdown-item" @click="handleChangeFilter('pageName','completed')">
						<div v-bind:class="{'text-bold': pageName == 'completed' }">
							<kaveh-icon-bootstrap name="bi-check2-all" size="18px" />
							<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.activity.views.completed')}}</span>
						</div>
					</li>
					<li class="dropdown-item" @click="handleChangeFilter('pageName','stoped')">
						<div v-bind:class="{'text-bold': pageName == 'stoped' }">
							<kaveh-icon-bootstrap name="bi-stop-circle" size="18px" />
							<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.activity.views.stoped')}}</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="col">
			<div class="dropdown">
				<button class="btn btn-light dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
					<span v-if="activityPriorityId == null">{{ $loc.fromResource('aut.ext.activity.priority.allPriority')}}</span>
					<span v-for="(priority,index) in priorities" :key="index">
						<span v-if="activityPriorityId == priority.activityPriorityId">{{priority.title}}</span>
					</span>
				</button>
				<ul class="dropdown-menu">
					<li class="dropdown-item" @click="handleChangeFilter('activityPriorityId',null)">
						<div class="priority" v-bind:class="{'text-bold': activityPriorityId == null }">
							<span class="color" style="background:#f8f8f8;"></span>
							<span class="ms-2 me-2">{{ $loc.fromResource('aut.ext.activity.priority.allPriority')}}</span>
						</div>
					</li>
					<li class="dropdown-item" v-for="(priority,index) in priorities" :key="index" @click="handleChangeFilter('activityPriorityId',priority.activityPriorityId)">
						<div class="priority" v-bind:class="{'text-bold': activityPriorityId == priority.activityPriorityId }">
							<span class="color" :style="{'background':`${priority.hexColor}`}"></span>
							<span class="ms-2 me-2">{{priority.title}}</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="col">
			<div class="dropdown">
				<button class="btn btn-light dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
					<span>{{$loc.fromResource('aut.ext.enums.activityCategory.public')}}</span>
				</button>
				<ul class="dropdown-menu">
					<li class="dropdown-item" @click="handleChangeFilter('processCategoryId',null)">
						<div>
							<kaveh-icon-bootstrap name="bi-file-earmark-text" size="18px" />
							<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.enums.activityCategory.public')}}</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<kaveh-overlay :is-loading="activitiesState == 'loading'" :is-empty="activitiesState != 'loading' && activities.length == 0">
				<template v-slot:noContent>
					{{$loc.fromResource('aut.ext.activity.list.empty')}}
				</template>
				<div class="bg-light rounded mb-2 p-3" v-for="(item, index) in activities" :key="index">
					<router-link :to="generateLink(item)">
						<div>{{item.activityNumber}}</div>
						<div>{{ item.processTitle }}</div>
						<div>{{ item.activityStatusTitle }}</div>
						<div>
							<span>{{item.nodes[0].activistName}} <small class="text-muted"><i>[ {{ item.nodes[0].activistRoleName }} ]</i></small></span>
						</div>
						<div>{{ item.activityPriorityTitle }}</div>
						<div>
							<small dir="ltr">{{ item.localCreateDate }} - {{item.createTime}}</small>
						</div>
					</router-link>
				</div>
			</kaveh-overlay>
		</div>
	</div>
</template>

<script>
	import { url } from '@/plugins'
	import { ActivityPriorityService, ActivityService } from '../../../services'

	export default {

		data()
		{
			return {
				priorities: [],
				activities: [],
				prioritiesState: "loading",
				activitiesState: "loading",
				activityPage: "currents",
				activityIcons: ['', 'bi-envelope', 'bi-reply', 'bi-forward'],
			}
		},


		beforeMount()
		{
			if (this.branchId == 0)
			{
				this.$router.push({ name: 'hub.branch.accessDenied' })
			}
			else
			{
				this.initActivityPriorities();
				this.initActivities();
			}
		},

		computed:
		{
			branchId()
			{
				return this.$store.state.client.branchId
			},

			pageName()
			{
				return this.$route.query.pageName || "currents";
			},

			activityPriorityId()
			{
				return this.$route.query.activityPriorityId || null;
			},
		},

		methods:
		{
			generateLink(item)
			{
				return {
					name: 'aut.activity.state', query: {
						'key': url.encript(item.activityId)
					}
				}
			},

			async handleChangeFilter(key, value)
			{
				var query =
					Object.assign({},
						this.$route.query
					);

				query[key] = value
				await this.$router.replace({ query });

				await this.initActivities();
			},

			async initActivityPriorities()
			{
				var items = [];

				try
				{
					const res = await new ActivityPriorityService().list({
						start: 0,
						length: 1000
					});

					if (res.status == 200)
					{
						items = this.$loc.fromList(res.data.items || []);
					}

				} catch (e) { console.log(e); }

				this.priorities = items;
				this.prioritiesState = items.length > 0 ? "ready" : "empty"
			},

			async initActivities()
			{
				var items = [];

				try
				{
					this.activitiesState = "loading"
					const res = await new ActivityService().list({
						start: 0,
						length: 1000,
						filter: {
							activityPriorityId: this.activityPriorityId,
							pageName: this.pageName
						}
					}, { HSR: false });

					if (res.status == 200)
					{
						items = this.$loc.fromList(res.data.items || []);
					}

				} catch (e) { console.log(e); }

				this.activities = items;
				this.activitiesState = items.length > 0 ? "ready" : "empty"
			}
		}
	}

</script>
<style>

	.activity-list .page-items,
	.activity-list .priority-items {
		list-style: none;
		padding: 20px 10px;
	}

	.activity-list .page,
	.activity-list .priority {
		margin: 10px 0;
		cursor: pointer;
		transition: all .2s;
	}

		.activity-list .page:hover,
		.activity-list .priority:hover {
			padding-right: 5px;
		}

			.activity-list .page:hover > i,
			.activity-list .priority:hover > i {
				color: var(--kaveh-base-color) !important;
			}

		.activity-list .priority .color {
			width: 15px;
			height: 15px;
			border-radius: 100%;
			display: inline-block;
			border: #ddd solid 1px;
		}
</style>
