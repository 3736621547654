<template>
	<input v-bind:id="prop.id"
		   v-bind:dir="prop.display.direction"
		   v-model="value"
		   type="hidden"
		   class="form-control"
		   autocomplete="off" />
</template>
<script>
	export default {
		props: {
			prop: {
				type: Object
			}
		},

		data()
		{
			return {
				value: null
			}
		},

		beforeMount()
		{
			this.value = this.prop.value
		},
	}
</script>
