<template>
	<common-loading v-if="isLoading" />
	<div v-else class="bg-light p-3">
		<div class="d-block" v-html="$loc.fromResource('com.common.cd.warning')"></div>
		<div class="mt-3">
			<button type="button" @click="onHandleOk" class="btn btn-light border me-2">
				<kaveh-icon-bootstrap name="bi-check2-circle" size="18px" class="me-2" />
				<span>{{ $loc.fromResource('com.common.cd.ok') }}</span>
			</button>
			<button type="button" @click="onHandleCancel" class="btn btn-light border">
				<kaveh-icon-bootstrap name="bi-x-circle" size="18px" class="me-2" />
				<span>{{ $loc.fromResource('com.common.cd.cancel') }}</span>
			</button>
		</div>
	</div>
</template>
<script>
	import  CommonLoading from './common-loading'
	export default {
		emits: ['ok', 'cancel'],

		components: {
			'common-loading': CommonLoading
		},

		props: {
			isLoading: {
				type: Boolean,
				default: false
			}
		},

		methods:
		{
			onHandleOk()
			{
				this.$emit('ok');
			},

			onHandleCancel()
			{
				this.$emit('cancel');
			}
		}
	}
</script>
