<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-task-manager v-if="temp" :entity="entity" :task="delete" stream-address="hub/region/stream-delete" :data="temp" :label="label" @close="reset" @cancel="reset" />
</template>
<script>
	import { Region } from '@/modules/hub/data/region/list'
	import { RegionService } from '@/modules/hub/services'
	export default {
		data()
		{
			return {
				temp: null
			}
		},
		computed:
		{
			entity()
			{
				return Region;
			},

			service()
			{
				return new RegionService();
			},

			label()
			{
				return this.$loc.fromResource('action.delete');
			}
		},
		async beforeMount()
		{
			await this.init();
		},
		methods:
		{
			async init()
			{
				const store = this.$store;
				const router = this.$route;
				var temp = await store.dispatch('temp/findByQuery', router.query);
				if (temp)
				{
					this.temp = temp.value;
				}
				else this.$router.go(-1);
			},

			async delete(payload)
			{
				return await this.service.delete(payload['regionId'], {HSR:false, HER:false});
			},
			
			reset()
			{
				this.$router.go(-1);
			}
		}
	}
</script>