//GeneratorFlag:[NoChange]
export default {
	path: 'letter',
	name: 'aut.letter.layout',
	redirect: to => {
		return {name: 'aut.letter.list'};
	},
	component: () => import('@/modules/aut/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/aut/letter"],
		resourceKey: 'aut.letterLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-envelope-fill',
		}
	},
	children: [
//GeneratorFlag:[LetterCreate]"
		{
			path: 'create',
			name: 'aut.letter.create',
			component: () => import('@/modules/aut/views/letter/letter-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterCreate',
				clientAccessKeys:["/aut/letter/create"],
				serverAccessKeys:["/aut/letter/create","/aut/letter/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterCreateImport]"
		{
			path: 'create-import',
			name: 'aut.letter.createImport',
			component: () => import('@/modules/aut/views/letter/letter-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterCreateImport',
				clientAccessKeys:["/aut/letter/create-import"],
				serverAccessKeys:["/aut/letter/create","/aut/letter/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterDelete]"
		{
			path: 'delete',
			name: 'aut.letter.delete',
			component: () => import('@/modules/aut/views/letter/letter-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterDelete',
				clientAccessKeys:["/aut/letter/delete"],
				serverAccessKeys:["/aut/letter/delete","/aut/letter/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterDetail]"
		{
			path: 'detail',
			name: 'aut.letter.detail',
			component: () => import('@/modules/aut/views/letter/letter-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterDetail',
				clientAccessKeys:["/aut/letter/detail"],
				serverAccessKeys:["/aut/letter/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterList]"
		{
			path: 'list',
			name: 'aut.letter.list',
			component: () => import('@/modules/aut/views/letter/letter-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterList',
				clientAccessKeys:["/aut/letter/list"],
				serverAccessKeys:["/aut/letter/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterAttachment]"
		{
			path: 'attachment',
			name: 'aut.letter.attachment',
			component: () => import('@/modules/aut/views/letter/letter-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterAttachment',
				clientAccessKeys:["/aut/letter/attachment"],
				serverAccessKeys:["/aut/letter/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}