<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" />
</template>
<script>
	import { Country, CountryFilter } from '@/modules/hub/data/country/list'
	import { CountryService } from '@/modules/hub/services'
	export default {
		computed:
		{
			entity()
			{
				return Country;
			},
			
			filterEntity()
			{
				return CountryFilter;
			},

			service()
			{
				return new CountryService();
			},

			filterData()
			{
				var filter = {};
				const keys = Object.keys(this.$route.query);

				for (var i = 0; i < keys.length; i++)
				{
					const key = keys[i];
					if (["start", "length", "pageSize", "currentPage"].indexOf() == -1)
					{
						filter[key] = this.$route.query[key]
					}
				}

				return filter;
			},

			actions()
			{
				return [
					{
						routeName: 'hub.country.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'hub.country.createImport',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						routeName: 'hub.country.update',
						resourceKey: 'action.update',
						iconName: 'bi-pen',
						activeMode: 'single',
						dataTransferMode: 'store',
					},
					{
						routeName: 'hub.country.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [
							{
								routeName: 'hub.province.list',
								resourceKey: 'hub.views.province.list',
								iconName: 'bi-list-columns',
								activeMode: 'single',
								dataTransferMode: 'query',
								dataTransferKeys: ['countryId'],
							},

						]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>
