//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class ConfigService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[Config.SyncDistributeData]
	async syncDistributeData(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/config/sync-distribute-data', params || { });
	};

//GeneratorFlag:[NewAction]
}