<template>
	<input v-bind:id="prop.id"
		   v-bind:dir="prop.display.direction"
		   v-on:change="onHandelChange"
		   v-on:keyup="onHandelKeyUp"
		   v-model="value"
		   type="text"
		   class="form-control"
		   autocomplete="off" />

	<div v-for="(item, index) in values" :key="index" class="p-2 bg-white rounded mt-2">
		<span>{{item}}</span> <kaveh-icon-bootstrap class="float-end" cursor="pointer" @click="values.splice(index,1)" name="bi-trash" size="18" color="red" />
	</div>
</template>
<script>
	export default {
		emits: ['change', 'keyup', 'submit'],

		props: {
			prop: {
				type: Object
			}
		},

		data()
		{
			return {
				values: [],
				value: null,
			}
		},

		beforeMount()
		{
			if (this.prop.value)
			{
				this.values = this.prop.value.split(",")
			}
		},

		methods: {

			onHandelChange()
			{
				this.values.push(this.value);
				this.value = null;

				this.$emit('change', this.values.join())
			},

			onHandelKeyUp(e)
			{
				if (e.key === 'Enter' || e.keyCode === 13)
				{
					this.$emit('keyup', this.values.join())
				}
				else
				{
					this.$emit('keyup', this.values.length > 0 ? this.values.join() : this.value)
				}
			}
		}
	}
</script>
