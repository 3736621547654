//GeneratorFlag:[NoChange]
export default {
	path: 'letter-tag',
	name: 'aut.basis.letterTag.layout',
	redirect: to => {
		return {name: 'aut.letterTag.list'};
	},
	component: () => import('@/modules/aut/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/aut/basis/letter-tag"],
		resourceKey: 'aut.letterTagLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[LetterTagCreate]"
		{
			path: 'create',
			name: 'aut.letterTag.create',
			component: () => import('@/modules/aut/views/letter-tag/letter-tag-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterTagCreate',
				clientAccessKeys:["/aut/basis/letter-tag/create"],
				serverAccessKeys:["/aut/letter-tag/create","/aut/letter-tag/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterTagCreateImport]"
		{
			path: 'create-import',
			name: 'aut.letterTag.createImport',
			component: () => import('@/modules/aut/views/letter-tag/letter-tag-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterTagCreateImport',
				clientAccessKeys:["/aut/basis/letter-tag/create-import"],
				serverAccessKeys:["/aut/letter-tag/create","/aut/letter-tag/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterTagDelete]"
		{
			path: 'delete',
			name: 'aut.letterTag.delete',
			component: () => import('@/modules/aut/views/letter-tag/letter-tag-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterTagDelete',
				clientAccessKeys:["/aut/basis/letter-tag/delete"],
				serverAccessKeys:["/aut/letter-tag/delete","/aut/letter-tag/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterTagDetail]"
		{
			path: 'detail',
			name: 'aut.letterTag.detail',
			component: () => import('@/modules/aut/views/letter-tag/letter-tag-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterTagDetail',
				clientAccessKeys:["/aut/basis/letter-tag/detail"],
				serverAccessKeys:["/aut/letter-tag/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterTagList]"
		{
			path: 'list',
			name: 'aut.letterTag.list',
			component: () => import('@/modules/aut/views/letter-tag/letter-tag-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterTagList',
				clientAccessKeys:["/aut/basis/letter-tag/list"],
				serverAccessKeys:["/aut/letter-tag/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterTagAttachment]"
		{
			path: 'attachment',
			name: 'aut.letterTag.attachment',
			component: () => import('@/modules/aut/views/letter-tag/letter-tag-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterTagAttachment',
				clientAccessKeys:["/aut/basis/letter-tag/attachment"],
				serverAccessKeys:["/aut/letter-tag/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterTagUpdate]"
		{
			path: 'update',
			name: 'aut.letterTag.update',
			component: () => import('@/modules/aut/views/letter-tag/letter-tag-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterTagUpdate',
				clientAccessKeys:["/aut/basis/letter-tag/update"],
				serverAccessKeys:["/aut/letter-tag/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}