<template>
	<content-loading v-if="loading || isLoading" />
	<div v-else class="kaveh-content p-2 p-lg-4" v-bind:class="{'bg-light':useBg}" :style="{'max-width': width, 'border': border}">
		<slot></slot>
	</div>
</template>
<script>
	import contentLoading from './content-loading'
	export default {

		components: {
			'content-loading': contentLoading,
		},

		props: {
			width: {
				type: String,
				default: '100%'
			},
			border: {
				type: String,
				default: 'none'
			},
			loading: {
				type: Boolean,
				default: false
			},
			isLoading: {
				type: Boolean,
				default: false
			},
			useBg: {
				type: Boolean,
				default: true
			}
		}
	}
</script>
<style>
	.kaveh-content {
		margin: 0 auto;
		border-radius: 10px;
	}
</style>
