//GeneratorFlag:[NoChange]
import routes from './routes'
import services from './services'
import resources from './resources'
import toolbar from './toolbar'

const store = {
	name: 'store',
	foldreName: 'store',
	routes,
	services,
	resources,
	toolbar
}

export default store
export { routes, services, resources, toolbar }