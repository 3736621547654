//GeneratorFlag:[NoChange]
import ActivityService from './activity-service'; //GeneratorFlag:[Activity]
import LetterService from './letter-service'; //GeneratorFlag:[Letter]
import ProcessService from './process-service'; //GeneratorFlag:[Process]
import ProcessNodeService from './process-node-service'; //GeneratorFlag:[ProcessNode]
import LetterPatternService from './letter-pattern-service'; //GeneratorFlag:[LetterPattern]
import LetterTagService from './letter-tag-service'; //GeneratorFlag:[LetterTag]
import LetterTopicService from './letter-topic-service'; //GeneratorFlag:[LetterTopic]
import ProcessGroupService from './process-group-service'; //GeneratorFlag:[ProcessGroup]
import ActivityActionService from './activity-action-service'; //GeneratorFlag:[ActivityAction]
import ActivityPriorityService from './activity-priority-service'; //GeneratorFlag:[ActivityPriority]
import ActivityStatusService from './activity-status-service'; //GeneratorFlag:[ActivityStatus]
import LetterBoxService from './letter-box-service'; //GeneratorFlag:[LetterBox]
import LetterCategoryService from './letter-category-service'; //GeneratorFlag:[LetterCategory]
import LetterClassificationService from './letter-classification-service'; //GeneratorFlag:[LetterClassification]
import LetterDocumentTypeService from './letter-document-type-service'; //GeneratorFlag:[LetterDocumentType]
import LetterNodeTypeService from './letter-node-type-service'; //GeneratorFlag:[LetterNodeType]
import LetterPriorityService from './letter-priority-service'; //GeneratorFlag:[LetterPriority]
import LetterTypeService from './letter-type-service'; //GeneratorFlag:[LetterType]
import WorkerService from './worker-service'; //GeneratorFlag:[Worker]
import ActivityNodeService from './activity-node-service'; //GeneratorFlag:[ActivityNode]
//GeneratorFlag:[NewImport]
export default {
ActivityService, //GeneratorFlag:[Activity]
LetterService, //GeneratorFlag:[Letter]
ProcessService, //GeneratorFlag:[Process]
ProcessNodeService, //GeneratorFlag:[ProcessNode]
LetterPatternService, //GeneratorFlag:[LetterPattern]
LetterTagService, //GeneratorFlag:[LetterTag]
LetterTopicService, //GeneratorFlag:[LetterTopic]
ProcessGroupService, //GeneratorFlag:[ProcessGroup]
ActivityActionService, //GeneratorFlag:[ActivityAction]
ActivityPriorityService, //GeneratorFlag:[ActivityPriority]
ActivityStatusService, //GeneratorFlag:[ActivityStatus]
LetterBoxService, //GeneratorFlag:[LetterBox]
LetterCategoryService, //GeneratorFlag:[LetterCategory]
LetterClassificationService, //GeneratorFlag:[LetterClassification]
LetterDocumentTypeService, //GeneratorFlag:[LetterDocumentType]
LetterNodeTypeService, //GeneratorFlag:[LetterNodeType]
LetterPriorityService, //GeneratorFlag:[LetterPriority]
LetterTypeService, //GeneratorFlag:[LetterType]
WorkerService, //GeneratorFlag:[Worker]
ActivityNodeService, //GeneratorFlag:[ActivityNode]
//GeneratorFlag:[NewExport]
}
export{
ActivityService, //GeneratorFlag:[Activity]
LetterService, //GeneratorFlag:[Letter]
ProcessService, //GeneratorFlag:[Process]
ProcessNodeService, //GeneratorFlag:[ProcessNode]
LetterPatternService, //GeneratorFlag:[LetterPattern]
LetterTagService, //GeneratorFlag:[LetterTag]
LetterTopicService, //GeneratorFlag:[LetterTopic]
ProcessGroupService, //GeneratorFlag:[ProcessGroup]
ActivityActionService, //GeneratorFlag:[ActivityAction]
ActivityPriorityService, //GeneratorFlag:[ActivityPriority]
ActivityStatusService, //GeneratorFlag:[ActivityStatus]
LetterBoxService, //GeneratorFlag:[LetterBox]
LetterCategoryService, //GeneratorFlag:[LetterCategory]
LetterClassificationService, //GeneratorFlag:[LetterClassification]
LetterDocumentTypeService, //GeneratorFlag:[LetterDocumentType]
LetterNodeTypeService, //GeneratorFlag:[LetterNodeType]
LetterPriorityService, //GeneratorFlag:[LetterPriority]
LetterTypeService, //GeneratorFlag:[LetterType]
WorkerService, //GeneratorFlag:[Worker]
ActivityNodeService, //GeneratorFlag:[ActivityNode]
//GeneratorFlag:[NewExport]
}