<template>
    <div class="card bg-light justify-content-center pt-3 pb-3">
        <div class="card-body">
            <div class="d-flex align-items-center">
                <div class="spinner-grow spinner-grow-sm me-3"></div>
                <span>{{ $loc.fromResource('system.downloading')}}</span>
            </div>
        </div>
    </div>
</template>
