//GeneratorFlag:[NoChange]
export default {
	path: 'letter-pattern',
	name: 'aut.basis.letterPattern.layout',
	redirect: to => {
		return {name: 'aut.letterPattern.list'};
	},
	component: () => import('@/modules/aut/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/aut/basis/letter-pattern"],
		resourceKey: 'aut.letterPatternLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[LetterPatternCreate]"
		{
			path: 'create',
			name: 'aut.letterPattern.create',
			component: () => import('@/modules/aut/views/letter-pattern/letter-pattern-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterPatternCreate',
				clientAccessKeys:["/aut/basis/letter-pattern/create"],
				serverAccessKeys:["/aut/letter-pattern/create","/aut/letter-pattern/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterPatternCreateImport]"
		{
			path: 'create-import',
			name: 'aut.letterPattern.createImport',
			component: () => import('@/modules/aut/views/letter-pattern/letter-pattern-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterPatternCreateImport',
				clientAccessKeys:["/aut/basis/letter-pattern/create-import"],
				serverAccessKeys:["/aut/letter-pattern/create","/aut/letter-pattern/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterPatternDelete]"
		{
			path: 'delete',
			name: 'aut.letterPattern.delete',
			component: () => import('@/modules/aut/views/letter-pattern/letter-pattern-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterPatternDelete',
				clientAccessKeys:["/aut/basis/letter-pattern/delete"],
				serverAccessKeys:["/aut/letter-pattern/delete","/aut/letter-pattern/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterPatternDetail]"
		{
			path: 'detail',
			name: 'aut.letterPattern.detail',
			component: () => import('@/modules/aut/views/letter-pattern/letter-pattern-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterPatternDetail',
				clientAccessKeys:["/aut/basis/letter-pattern/detail"],
				serverAccessKeys:["/aut/letter-pattern/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterPatternList]"
		{
			path: 'list',
			name: 'aut.letterPattern.list',
			component: () => import('@/modules/aut/views/letter-pattern/letter-pattern-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterPatternList',
				clientAccessKeys:["/aut/basis/letter-pattern/list"],
				serverAccessKeys:["/aut/letter-pattern/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterPatternAttachment]"
		{
			path: 'attachment',
			name: 'aut.letterPattern.attachment',
			component: () => import('@/modules/aut/views/letter-pattern/letter-pattern-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterPatternAttachment',
				clientAccessKeys:["/aut/basis/letter-pattern/attachment"],
				serverAccessKeys:["/aut/letter-pattern/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterPatternUpdate]"
		{
			path: 'update',
			name: 'aut.letterPattern.update',
			component: () => import('@/modules/aut/views/letter-pattern/letter-pattern-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterPatternUpdate',
				clientAccessKeys:["/aut/basis/letter-pattern/update"],
				serverAccessKeys:["/aut/letter-pattern/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}