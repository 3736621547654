//GeneratorFlag:[NoChange]
export default {
	path: 'country',
	name: 'hub.geographical.country.layout',
	redirect: to => {
		return {name: 'hub.country.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/geographical/country"],
		resourceKey: 'hub.countryLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-geo-fill',
		}
	},
	children: [
//GeneratorFlag:[CountryCreate]"
		{
			path: 'create',
			name: 'hub.country.create',
			component: () => import('@/modules/hub/views/country/country-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.countryCreate',
				clientAccessKeys:["/hub/geographical/country/create"],
				serverAccessKeys:["/hub/country/create","/hub/country/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CountryCreateImport]"
		{
			path: 'create-import',
			name: 'hub.country.createImport',
			component: () => import('@/modules/hub/views/country/country-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.countryCreateImport',
				clientAccessKeys:["/hub/geographical/country/create-import"],
				serverAccessKeys:["/hub/country/create","/hub/country/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CountryDelete]"
		{
			path: 'delete',
			name: 'hub.country.delete',
			component: () => import('@/modules/hub/views/country/country-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.countryDelete',
				clientAccessKeys:["/hub/geographical/country/delete"],
				serverAccessKeys:["/hub/country/delete","/hub/country/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CountryDetail]"
		{
			path: 'detail',
			name: 'hub.country.detail',
			component: () => import('@/modules/hub/views/country/country-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.countryDetail',
				clientAccessKeys:["/hub/geographical/country/detail"],
				serverAccessKeys:["/hub/country/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CountryList]"
		{
			path: 'list',
			name: 'hub.country.list',
			component: () => import('@/modules/hub/views/country/country-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.countryList',
				clientAccessKeys:["/hub/geographical/country/list"],
				serverAccessKeys:["/hub/country/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CountryAttachment]"
		{
			path: 'attachment',
			name: 'hub.country.attachment',
			component: () => import('@/modules/hub/views/country/country-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.countryAttachment',
				clientAccessKeys:["/hub/geographical/country/attachment"],
				serverAccessKeys:["/hub/country/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CountryUpdate]"
		{
			path: 'update',
			name: 'hub.country.update',
			component: () => import('@/modules/hub/views/country/country-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.countryUpdate',
				clientAccessKeys:["/hub/geographical/country/update"],
				serverAccessKeys:["/hub/country/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}