//GeneratorFlag:[NoChange]
import Person from './person';
import City from './city';
import PersonAccess from './person-access';
import PersonEntity from './person-entity';
import PersonGroup from './person-group';
import PersonLegal from './person-legal';
import PersonReal from './person-real';
import PersonRole from './person-role';
import PersonVehicle from './person-vehicle';
import Tenant from './tenant';

export default {Person, City, PersonAccess, PersonEntity, PersonGroup, PersonLegal, PersonReal, PersonRole, PersonVehicle, Tenant}
export{Person, City, PersonAccess, PersonEntity, PersonGroup, PersonLegal, PersonReal, PersonRole, PersonVehicle, Tenant}