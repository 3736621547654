<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" />
</template>
<script>
	import { KeyValue, KeyValueFilter } from '@/modules/hub/data/key-value/list'
	import { KeyValueService } from '@/modules/hub/services'
	export default {
		computed:
		{
			entity()
			{
				return KeyValue;
			},
			
			filterEntity()
			{
				return KeyValueFilter;
			},

			service()
			{
				return new KeyValueService();
			},

			actions()
			{
				return [
					{
						routeName: 'hub.keyValue.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'hub.keyValue.createImport',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						routeName: 'hub.keyValue.attachment',
						resourceKey: 'action.fileAttachment',
						iconName: 'bi-cloud-arrow-up',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'hub.keyValue.update',
						resourceKey: 'action.update',
						iconName: 'bi-pen',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'hub.keyValue.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [{
							routeName: 'hub.keyValue.detail',
							resourceKey: 'hub.keyValueDetail',
							iconName: 'bi-list-columns',
							activeMode: 'single',
							dataTransferMode: 'store',
						}]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>