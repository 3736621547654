
<template>
	<div v-html="$loc.fromResource('com.excel.warning')"></div>
	<div class="mt-3 mb-3 tex-center">
		<kaveh-button :is-loading="isLoading" type="button" @click="getSample" class="btn btn-light border me-2">
			{{ $loc.fromResource('com.excel.downloadSampleFile') }}
		</kaveh-button>
		<div class="btn btn-dark ps-5 pe-5 text-light border position-relative">
			<span>{{ $loc.fromResource('com.excel.chooseFile') }}</span>
			<input class="form-control" type="file" v-on:change="selectFile" accept=".xlsx" />
		</div>
	</div>
</template>
<script>
	import { utils, writeFile } from 'xlsx'
	import { persian } from '@/plugins'
	import BasicService from '@/core/server/basic-service'

	export default {
		emits: ['select'],

		props: {
			'entity': {
				type: Function
			}
		},

		data()
		{
			return {
				isLoading: false
				}
		},

		methods: {

			selectFile(e)
			{

				const file = e.target.files[0];
				const allowType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

				if (file.type == allowType)
				{
					this.$emit('select', file)
				}
			},

			getPropName(prop)
			{

				const name = prop.label || this.$loc.fromResource(prop.resourceKey);
				const star = prop.setConfig.patterns.includes('required') ? '*' : ''

				return name + star;
			},

			getSheets(entity, masterPropety)
			{
				return new Promise(async (resolve, reject) =>
				{

					var sheets = [], item = {
						localName: this.$loc.fromResource(entity.resourceKey),
						name: entity.name,
						headers: {},
						keys: {}
					};


					for (const key in entity.props)
					{
						var prop = entity.props[key];

						if (prop.setConfig.canUseInExcel)
						{
							if (prop.type != 'object')
							{

								const key = `${(masterPropety ? masterPropety.name : entity.name)}.${prop.name}`;

								item.keys[key] = key;
								item.headers[key] = this.getPropName(prop);

								continue;
							}

							var relationSheets = await this.getSheets(prop.entity, prop);
							sheets.push(relationSheets[0])
						}
					}

					sheets.push(item);
					resolve(sheets);

				});
			},


			getSingleSheet(entity)
			{
				return new Promise(async (resolve, reject) =>
				{
					var sheet = {
						localName: this.$loc.fromResource(entity.resourceKey),
						name: entity.name,
						headers: {},
						comments: {},
						keys: {}
					};

					var getProps = async (entity, parentName) =>
					{
						for (const item in entity.props)
						{
							const prop = entity.props[item];

							if (prop.setConfig.canUseInExcel)
							{
								if (prop.type != 'object')
								{

									var key = prop.name;
									if (parentName)
									{
										key = `${parentName}.${prop.name}`;
									}

									sheet.keys[key] = key;
									sheet.headers[key] = this.getPropName(prop);
									sheet.comments[key] = await this.getComment(prop);

									continue;
								}
								else
								{
									await getProps(prop.entity, prop.name);
								}
							}
						}
					}

					await getProps(entity);
					resolve(sheet);
				});
			},


			async getSample()
			{

				this.isLoading = true;
				const sheet = await this.getSingleSheet(new this.entity());

				var wb = utils.book_new()
				utils.book_append_sheet(
					wb, utils.json_to_sheet([sheet.keys, sheet.headers], {
						skipHeader: true
					}),
					sheet.localName
				)

				var sheetName = wb.SheetNames[0]
				var entityKeys = Object.keys(sheet.keys);
				var headerKeys = Object.keys(sheet.headers);
				var sheetKeys = Object.keys(wb.Sheets[sheetName]).filter((x) => { return x.endsWith("1")});


				var wscols = [];
				for (var i = 0; i < headerKeys.length; i++)
				{
					wscols.push({ wch: sheet.headers[headerKeys[i]].length})
				}



				for (var i = 0; i < entityKeys.length; i++)
				{
					const key = entityKeys[i];

					if (sheet.comments[key] != "")
					{
						for (var ii = 0; ii < sheetKeys.length; ii++)
						{
							if (wb.Sheets[sheetName][sheetKeys[ii]].v == key)
							{
								wb.Sheets[sheetName][sheetKeys[ii]].c = []
								wb.Sheets[sheetName][sheetKeys[ii]].c.push({
									a: "SheetJS",
									t: sheet.comments[key]
								});
								wb.Sheets[sheetName][sheetKeys[ii]].c.hidden = true;
							}
						}
					}
				}


				wb.Sheets[sheetName]['!cols'] = wscols;
				wb.Workbook = {
					Views: [{ RTL: true }]
				}

				const fileName =
					this.$loc.fromResource('com.excel.sampleFileName', {
						'sheetName': sheet.localName,
						'id': persian.cleanDateTime()
					});

				writeFile(wb, `${fileName}` + '.xlsx')
				this.isLoading = false;
			},


			async getComment(prop)
			{
				if (prop.name.indexOf('cityId') > -1)
					return "";


				var comment = "";

				const foreignKey = prop.foreignKey;
				const textKey = prop.setConfig.textKey;
				const valueKey = prop.setConfig.valueKey;
				const serverAddress = prop.serverAddress;

				if (foreignKey && serverAddress)
				{
					try
					{
						const payload = {
							start: 0,
							length: 100
						};

						const service = new BasicService();
						const res = await service.get(payload, serverAddress);
						const items = res.data.items || [];

						for (var i = 0; i < items.length; i++)
						{
							const item = items[i];
							comment += `${item[textKey]} => ${item[valueKey]} \n`
						}

					} catch (e) { }
				}

				return comment
			}
		}
	}
</script>
<style>
	.kaveh-excel-parser input[type='file'] {
		position: absolute;
		opacity: 0;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
</style>
