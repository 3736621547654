<template>
	<small class="text-danger">
		{{ getFirstError() }}
	</small>
</template>
<script>
	export default {
		props:
		{
			prop:
			{
				type: Object
			}
		},

		methods:
		{
			getFirstError() {
				if (this.getErrorsLen() > 0) {

					const controlLabel =
						this.prop.label || this.$loc.fromResource(this.prop.resourceKey);

					return this.$loc.fromResource(this.getErrors()[0].resourceKey, { label: controlLabel })
				}
				return ''
			},

			getErrorsLen() {
				return this.getErrors().length
			},

			getErrors() {
				return (this.prop || {}).errors || []
			}
		}
	}
</script>
