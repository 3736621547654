//GeneratorFlag:[NoChange]
import NoticePatternService from './notice-pattern-service'; //GeneratorFlag:[NoticePattern]
import NoticeProviderService from './notice-provider-service'; //GeneratorFlag:[NoticeProvider]
import NoticeStatusService from './notice-status-service'; //GeneratorFlag:[NoticeStatus]
import NoticeTypeService from './notice-type-service'; //GeneratorFlag:[NoticeType]
import NoticeService from './notice-service'; //GeneratorFlag:[Notice]
import OtpService from './otp-service'; //GeneratorFlag:[Otp]
//GeneratorFlag:[NewImport]
export default {
NoticePatternService, //GeneratorFlag:[NoticePattern]
NoticeProviderService, //GeneratorFlag:[NoticeProvider]
NoticeStatusService, //GeneratorFlag:[NoticeStatus]
NoticeTypeService, //GeneratorFlag:[NoticeType]
NoticeService, //GeneratorFlag:[Notice]
OtpService, //GeneratorFlag:[Otp]
//GeneratorFlag:[NewExport]
}
export{
NoticePatternService, //GeneratorFlag:[NoticePattern]
NoticeProviderService, //GeneratorFlag:[NoticeProvider]
NoticeStatusService, //GeneratorFlag:[NoticeStatus]
NoticeTypeService, //GeneratorFlag:[NoticeType]
NoticeService, //GeneratorFlag:[Notice]
OtpService, //GeneratorFlag:[Otp]
//GeneratorFlag:[NewExport]
}