import store from '@/core/store'
import { Ws } from '@/core/server'
import { toast } from '@/plugins'
import { LetterService } from '@/modules/aut/services';

const letterNotification = {

	info: {
		name: 'kaveh.aut.letter.notification',
		routeName: 'aut.letter.list',
		iconName: 'bi-envelope',
		hasCounter: true
	},

	initHandler: async () =>
	{
		new Ws().getConnection('hub').then((connection) =>
		{
			const updater = async () =>
			{
				try
				{
					const name = letterNotification.info.name;
					const branchId = store.state.client.branchId || 0;
					const count = localStorage.getItem(name) || 0


					if (branchId > 0)
					{
						const service = new LetterService();
						const res = await service.notification({}, { HSR: false, HER: false });

						if (res.status == 200)
						{
							await store.dispatch('app/setAlarm', {
								name: name,
								counter: res.data.total,
							});

							if (res.data.total > count)
							{
								toast.notification({
									destination: "/aut/letter/list",
									msg: 'New Letter'
								})
							}

							localStorage.setItem(name, res.data.total)
						}
					}

				} catch (e) { }
			}

			connection.handle(`AutLetterNotification`, data =>
			{
				updater();
			});

			updater();

		})
	}
}


export default letterNotification
