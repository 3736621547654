export default {
	"views": {
		"fileManager": {
			"layout": "مدیریت فایل ها"
		}
	},

	"fileManager": {
		"createFolder":"ایجاد پوشه جدید",
		"uploadFile": "اپلود فایل جدید",
		"accessTitle": "وضعیت دسترسی به فایل",
		"accessPrivateTitle":"فایل خصوصی است و فقط با توکن امنیتی قابل دانلود است",
		"accessPublicTitle":"فایل عمومی است و بدون توکن قابل دانلود است",
		"uploadAction": "آپلود فایل های انتخاب شده",
		"info": {
			"fileName": "نام فایل",
			"extensionName":"نوع فایل",
			"contentType":"نوع محتوا",
			"key":"کلید فایل",
			"download":"دانلود فایل",
			"clickToDownload":"جهت دانلود کلیک فرمایید",
			"externalLink":"لینک دانلود خارجی",
			"tokenWarning": "دقت فرمایید لینک فوق فقط با جایگذاری توکن کاربری به جای {token} قابل استفاده است",
			"createDateTime": "تاریخ و زمان ایجاد",
			"modifyDateTime": "تاریخ و زمان ویرایش"
		}
	}
}
