<template>
	<input v-bind:id="prop.id"
		   v-on:change="onHandelChange"
		   v-on:keyup="onHandelChange"
		   v-model="value"
		   type="checkbox"
		   class="form-check-input"
		   autocomplete="off" />
</template>
<script>
	export default {
		emits: ['change'],

		props: {
			prop: {
				type: Object
			}
		},

		data() {
			return {
				value: null
			}
		},

		beforeMount() {
			this.value = this.prop.value
		},

		methods: {

			onHandelChange() {
				this.$emit('change', this.value)
			}
		}
	}
</script>
