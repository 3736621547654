<template>
	<div class="alert alert-warning pt-4 pb-4">
		<div>{{$loc.fromResource('hub.ext.branch.choose.accessDenied')}}</div>
		<div class="btn btn-light mt-3">
			<router-link :to="{name:'hub.branch.choose'}">
				{{$loc.fromResource('hub.ext.branch.views.choose')}}
			</router-link>
		</div>
	</div>
</template>
