<template>
	<div class="activity-compose" v-if="branchId > 0">

		<div class="steps bg-light pt-4 pb-4 ps-3 pe-3 mb-2">
			<div class="row">
				<div class="col step" v-bind:class="{'active': step >= 1}">
					<div class="circle-wrapper">
						<div class="circle">
							<kaveh-icon-bootstrap cursor="default" name="bi-signpost-split-fill" size="20px" />
						</div>
					</div>
					<div class="d-none d-lg-block">{{$loc.fromResource('aut.ext.activity.compose.step1')}}</div>
				</div>
				<div class="col step" v-bind:class="{'active': step >= 2}">
					<div class="circle-wrapper">
						<div class="circle">
							<kaveh-icon-bootstrap cursor="default" name="bi-info-lg" size="20px" />
						</div>
					</div>
					<div class="d-none d-lg-block">{{$loc.fromResource('aut.ext.activity.compose.step2')}}</div>
				</div>
				<div class="col step" v-bind:class="{'active': step >= 3}">
					<div class="circle-wrapper">
						<div class="circle">
							<kaveh-icon-bootstrap cursor="default" name="bi-paperclip" size="20px" />
						</div>
					</div>
					<div class="d-none d-lg-block">{{$loc.fromResource('aut.ext.activity.compose.step3')}}</div>
				</div>
				<div class="col step" v-bind:class="{'active': step >= 4}">
					<div class="circle-wrapper">
						<div class="circle">
							<kaveh-icon-bootstrap cursor="default" name="bi-check-all" size="20px" />
						</div>
					</div>
					<div class="d-none d-lg-block">{{$loc.fromResource('aut.ext.activity.compose.step4')}}</div>
				</div>
			</div>
		</div>



		<div class="content bg-light p-3">

			<activity-compose-step1 :class="{'d-none': step != 1}" ref="ProcessRef" />
			<activity-compose-step2 :class="{'d-none': step != 2}" ref="FormRef" />
			<activity-compose-step3 :class="{'d-none': step != 3}" ref="AttachmentRef" />
			<activity-compose-step4 :class="{'d-none': step != 4}" ref="ConfirmRef" v-if="step == 4" />

			<div class="mt-3 p-3 text-center bg-white" v-if="loading && step == 1">
				<div class="spinner-border spinner-border-sm me-2" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
				<span>{{$loc.fromResource('system.pleaseWaite')}}</span>
			</div>
			<div class="mt-3 p-3 text-center bg-white" v-else-if="loading">
				<div class="spinner-border spinner-border-sm me-2" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
				<span>{{$loc.fromResource('aut.ext.activity.compose.loading')}}</span>
			</div>
			<div class="mt-3 p-3 text-center bg-white" v-else>
				<div class="text-danger pb-4" v-if="error != ''">
					<small>{{ error }}</small>
				</div>
				<button @click="prev" class="btn btn-light border me-3 ps-5 pe-5" v-bind:disabled="step == 1">
					{{$loc.fromResource('aut.ext.activity.compose.prevStep')}}
				</button>
				<button @click="next" class="btn btn-light border me-3 ps-5 pe-5">
					<span v-if="step < 4">{{$loc.fromResource('aut.ext.activity.compose.nextStep')}}</span>
					<span v-else>{{$loc.fromResource('aut.ext.activity.compose.finalStep')}}</span>
				</button>
			</div>

		</div>

	</div>
</template>

<script>

	import ActivityComposeStep1 from './activity-compose-step1'
	import ActivityComposeStep2 from './activity-compose-step2'
	import ActivityComposeStep3 from './activity-compose-step3'
	import ActivityComposeStep4 from './activity-compose-step4'

	import { ActivityService, ProcessNodeService } from '@/modules/aut/services';

	export default {
		emits: ['complete'],
		components: {
			'activity-compose-step1': ActivityComposeStep1,
			'activity-compose-step2': ActivityComposeStep2,
			'activity-compose-step3': ActivityComposeStep3,
			'activity-compose-step4': ActivityComposeStep4,
		},

		data() {
			return {
				step: 1,
				error: "",
				loading: false,
				processId: 0,
				processNodeId: 0,
				process: null,
				formPattern: null,
				isArrayFormValue: false,
				basicFormValue: null,
				customFormValue: null,
				attachments: []
			}
		},

		computed:
		{
			branchId() {
				return this.$store.state.client.branchId
			}
		},

		async beforeMount() {
			if (this.branchId == 0) {
				this.$router.push({ name: 'hub.branch.accessDenied' })
			}
		},

		methods:
		{

			async setFormPattern() {

				const service =
					new ProcessNodeService();

				try {
					this.loading = true;
					const res = await service.formPattern({
						orderNumber: 1,
						processId: this.processId
					}, { HER: false });

					if (res.status == 200) {
						if (res.data.formPattern)
						{
							this.isArrayFormValue = res.data.isArrayFormValue
							this.formPattern = JSON.parse(res.data.formPattern);
						}
					}

				} catch (e) { }

				this.loading = false;
			},


			prev(e) {
				if (this.step > 1) {
					this.error = "";
					this.step = this.step - 1;
				}

				e.target.blur()
			},

			next(e) {
				this.error = "";
				this[`handelStep${this.step}`]();

				e.target.blur()
			},

			async handelStep1() {
				const value =
					this.$refs.ProcessRef.getValue();

				if (value) {
					this.process = value;
					this.processId = value.processId;
					await this.setFormPattern();

					this.step = 2;
				}
				else {
					this.error =
						this.$loc.fromResource(
							'aut.ext.activity.compose.step1Error');
				}
			},

			handelStep2() {
				const value =
					this.$refs.FormRef.getValue();

				if (value != undefined) {
					this.basicFormValue = value.basicValue;
					this.customFormValue = value.customValue;
					this.step = 3;
				}
				else {
					this.error =
						this.$loc.fromResource(
							'aut.ext.activity.compose.step2Error');
				}
			},

			handelStep3() {
				const value =
					this.$refs.AttachmentRef.getValue();

				if (value) {
					this.attachments = value
				}

				this.step = 4;
			},

			async handelStep4() {
				this.loading = true;

				const process = this.process;
				const formPattern = this.formPattern;
				const isArrayFormValue = this.isArrayFormValue;
				const basicFormValue = this.basicFormValue;
				const customFormValue = this.customFormValue;
				const attachments = this.attachments;




				var payload = {
					processId: process.processId,
					activityPriorityId: basicFormValue.activityPriorityId,
					deadlineHour: basicFormValue.deadlineHour || 0,
					comment: basicFormValue.comment,
					formValue: JSON.stringify(customFormValue),
					branchId: this.branchId,
					staticFiles: []
				}

				for (var i = 0; i < attachments.length; i++) {
					payload.staticFiles.push({
						fileName: attachments[i].name,
						dataUrl: attachments[i].dataUrl
					})
				}

				try {
					const res =
						await new ActivityService().create(payload);

					if (res && res.status == 200) {
						this.$emit('complete')
					}

				} catch (e) {
					console.log(e)
				}

				this.loading = false;
			},
		}
	}
</script>

<style>

	.activity-compose .steps,
	.activity-compose .content {
		border-radius: var(--kaveh-radius);
	}

		.activity-compose .steps .step {
			text-align: center;
			transition: all .5s;
			padding: 0;
		}

			.activity-compose .steps .step.active .circle {
				color: var(--kaveh-base-color);
			}

			.activity-compose .steps .step.active .circle-wrapper::before,
			.activity-compose .steps .step.active .circle-wrapper::after {
				border-top-style: solid;
			}


		.activity-compose .steps .circle-wrapper {
			position: relative;
		}

		.activity-compose .steps .step .circle-wrapper::before,
		.activity-compose .steps .step .circle-wrapper::after {
			content: "";
			position: absolute;
			top: 50%;
			width: calc(50% - 10px);
			height: 2px;
			border-top: #ddd dashed 2px;
			transition: all .5s;
		}

		.activity-compose .steps .step .circle-wrapper::before {
			left: 5px;
		}

		.activity-compose .steps .step .circle-wrapper::after {
			right: 5px;
		}

		.activity-compose .steps .circle {
			width: 40px;
			height: 40px;
			text-align: center;
			padding-top: 5px;
			font-size: 15px;
			display: block;
			border: #E7EAED solid 1px;
			border-radius: 100%;
			margin: 0 auto 10px auto;
			background: #ffffff;
			position: relative;
			z-index: 1;
		}
</style>
