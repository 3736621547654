//GeneratorFlag:[NoChange]
import ext from './de-ext.js'
export default {
	"name":"messenger",
	"ext": ext,
	"layout":{
//GeneratorFlag:[NewLayout]
	},
	'room':'room',
	'roomCreate':'Neues Zimmer hinzugefügt',
	'roomLayout':'room',

}