export default {
	path: 'branch',
	name: 'hub.branch.layout',
	redirect: to => {
		return {name: 'hub.branch.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/branch"],
		resourceKey: 'hub.branchLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-diagram-3-fill',
		}
	},
	children: [

		{
			path: 'access-denied',
			name: 'hub.branch.accessDenied',
			component: () => import('@/modules/hub/views/branch/branch-access-denied.vue'),
			meta: {
				requiresAuth: false,
				resourceKey: 'hub.ext.branch.views.accessDenied',
				clientAccessKeys: [],
				serverAccessKeys: [],
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'branch-choose',
			name: 'hub.branch.choose',
			component: () => import('@/modules/hub/views/branch/branch-choose.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.ext.branch.views.choose',
				clientAccessKeys: ["/hub/branch/branch-choose"],
				serverAccessKeys: ["/hub/branch/my-list"],
				menuConfig: {
					isVisible: false
				}
			}
		},

		{
			path: 'branch-position',
			name: 'hub.branch.position',
			component: () => import('@/modules/hub/views/branch/branch-position.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.ext.branch.views.position',
				clientAccessKeys: ["/hub/branch/branch-position"],
				serverAccessKeys: ["/hub/position/list", "/hub/position/create", "/hub/person/employee-list"],
				menuConfig: {
					isVisible: false
				}
			}
		},

		{
			path: 'branch-copy-position',
			name: 'hub.branch.copyPosition',
			component: () => import('@/modules/hub/views/branch/branch-copy-position.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.ext.branch.views.copyPosition',
				clientAccessKeys: ["/hub/branch/branch-copy-position"],
				serverAccessKeys: ["/hub/position/list", "/hub/position/create", "/hub/person/employee-list"],
				menuConfig: {
					isVisible: false
				}
			}
		},


//GeneratorFlag:[BranchDelete]"
		{
			path: 'delete',
			name: 'hub.branch.delete',
			component: () => import('@/modules/hub/views/branch/branch-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.branchDelete',
				clientAccessKeys:["/hub/branch/delete"],
				serverAccessKeys:["/hub/branch/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BranchDetail]"
		{
			path: 'detail',
			name: 'hub.branch.detail',
			component: () => import('@/modules/hub/views/branch/branch-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.branchDetail',
				clientAccessKeys:["/hub/branch/detail"],
				serverAccessKeys:["/hub/branch/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BranchList]"
		{
			path: 'list',
			name: 'hub.branch.list',
			component: () => import('@/modules/hub/views/branch/branch-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.branchList',
				clientAccessKeys:["/hub/branch/list"],
				serverAccessKeys:["/hub/branch/list","/hub/branch-type/list","/hub/employee/list","/hub/branch/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},

//GeneratorFlag:[BranchCreate]"
		{
			path: 'create',
			name: 'hub.branch.create',
			component: () => import('@/modules/hub/views/branch/branch-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.branchCreate',
				clientAccessKeys:["/hub/branch/create"],
				serverAccessKeys:["/hub/branch/create","/hub/person/employee-list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BranchUpdate]"
		{
			path: 'update',
			name: 'hub.branch.update',
			component: () => import('@/modules/hub/views/branch/branch-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.branchUpdate',
				clientAccessKeys:["/hub/branch/update"],
				serverAccessKeys:["/hub/branch/update","/hub/person/employee-list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BranchMove]"
		{
			path: 'move',
			name: 'hub.branch.move',
			component: () => import('@/modules/hub/views/branch/branch-move.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.branchMove',
				clientAccessKeys:["/hub/branch/move"],
				serverAccessKeys:["/hub/branch/move"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]



	]
}
