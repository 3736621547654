<template>
	<div class="letter-forward-step-4 pt-2">
		<div class="row">
			<div class="col pt-3">
				<kaveh-uploader ref="uploader" />
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		methods:
		{
			getValue()
			{
				return this.$refs.uploader.getValue() 
			}
		}
	}
</script>
