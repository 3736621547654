<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" :show-filter="true" />
</template>
<script>
	import { Activity, ActivityFilter } from '@/modules/aut/data/activity/full-report'
	import { ActivityService } from '@/modules/aut/services'
	export default {
		computed:
		{
			entity()
			{
				return Activity;
			},
			
			filterEntity()
			{
				return ActivityFilter;
			},

			service()
			{
				return new ActivityService();
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.fullReport(payload);
			}
		}
	}
</script>