//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class LetterTopicService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[LetterTopic.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/aut/letter-topic/create', params || { });
	};

	//GeneratorFlag:[LetterTopic.Delete]
	async delete(letterTopicId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:letterTopicId
		}
	
		return await _server.delete('/aut/letter-topic/delete', {'params':params})
	};

	//GeneratorFlag:[LetterTopic.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/aut/letter-topic/detail', {'params':params});
	};

	//GeneratorFlag:[LetterTopic.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/aut/letter-topic/list', {'params':params});
	};

	//GeneratorFlag:[LetterTopic.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/aut/letter-topic/update', params || { });
	};

//GeneratorFlag:[NewAction]
}