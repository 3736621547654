<template>
	<div class="row">
		<div class="col">
			<div class="bg-light rounded p-3 mb-3">
				<div class="mb-2">
					<input type="text"
						   v-model="filter"
						   @keyup="filterChanged"
						   class="form-control border-0"
						   :placeholder="$loc.fromResource('action.searchPoint')" />
				</div>
				<kaveh-overlay :is-loading="state == 'loading'" :is-empty="branches.length == 0" class="col">
					<branch-editable-tree-node :nodes="branches" />
				</kaveh-overlay>
			</div>
		</div>
	</div>
</template>
<script>
	import { array } from '@/plugins'
	import { BranchService } from '@/modules/hub/services'
	import BranchEditableTreeNode from './branch-editable-tree-node'

	export default {
		emits: ['change'],
		components: {
			'branch-editable-tree-node': BranchEditableTreeNode
		},

		props: {
			value: {
				type: Array
			},
		},

		data()
		{
			return {
				filter: "",
				state: "loading",
				branches: [],
				flatBranches: [],
				flatHistoryBranches: [],
			}
		},

		async beforeMount()
		{
			await this.init();
		},

		methods: {

			async init()
			{
				try
				{
					const payload = {
						start: 0,
						length:10000
					}

					var response =
						await new BranchService().list(payload , { HSR: false });

					if (response.status == 200)
					{
						this.bindData(response.data.items)
						this.flatBranches = response.data.items;
						this.flatHistoryBranches = response.data.items;
					}

				} catch (e)
				{
					this.state = "error";
				}
			},

			bindData(items)
			{
				this.state = items.length == 0 ? "empty" : "ready";
				this.branches = array.toNested(items, 'branchId', 'parentId')
			},


			filterChanged(e)
			{
				const value = e.target.value
				const keys = ['branchName', 'officeName', 'unitName', 'personName'];

				this.bindData(array.filter(this.flatHistoryBranches, value, keys))
			},


			reset()
			{
				this.bindData(this.flatBranches)
				this.flatHistoryBranches = this.flatBranches
			}
		}
	}
</script>
