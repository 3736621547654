//GeneratorFlag:[NoChange]
export default {
	path: 'person-role',
	name: 'hub.basis.personRole.layout',
	redirect: to => {
		return {name: 'hub.personRole.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/basis/person-role"],
		resourceKey: 'hub.personRoleLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonRoleCreate]"
		{
			path: 'create',
			name: 'hub.personRole.create',
			component: () => import('@/modules/hub/views/person-role/person-role-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.personRoleCreate',
				clientAccessKeys:["/hub/basis/person-role/create"],
				serverAccessKeys:["/hub/person-role/create","/hub/person-role/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRoleCreateImport]"
		{
			path: 'create-import',
			name: 'hub.personRole.createImport',
			component: () => import('@/modules/hub/views/person-role/person-role-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.personRoleCreateImport',
				clientAccessKeys:["/hub/basis/person-role/create-import"],
				serverAccessKeys:["/hub/person-role/create","/hub/person-role/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRoleDelete]"
		{
			path: 'delete',
			name: 'hub.personRole.delete',
			component: () => import('@/modules/hub/views/person-role/person-role-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.personRoleDelete',
				clientAccessKeys:["/hub/basis/person-role/delete"],
				serverAccessKeys:["/hub/person-role/delete","/hub/person-role/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRoleDetail]"
		{
			path: 'detail',
			name: 'hub.personRole.detail',
			component: () => import('@/modules/hub/views/person-role/person-role-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.personRoleDetail',
				clientAccessKeys:["/hub/basis/person-role/detail"],
				serverAccessKeys:["/hub/person-role/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRoleList]"
		{
			path: 'list',
			name: 'hub.personRole.list',
			component: () => import('@/modules/hub/views/person-role/person-role-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.personRoleList',
				clientAccessKeys:["/hub/basis/person-role/list"],
				serverAccessKeys:["/hub/person-role/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRoleAttachment]"
		{
			path: 'attachment',
			name: 'hub.personRole.attachment',
			component: () => import('@/modules/hub/views/person-role/person-role-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.personRoleAttachment',
				clientAccessKeys:["/hub/basis/person-role/attachment"],
				serverAccessKeys:["/hub/person-role/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRoleUpdate]"
		{
			path: 'update',
			name: 'hub.personRole.update',
			component: () => import('@/modules/hub/views/person-role/person-role-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.personRoleUpdate',
				clientAccessKeys:["/hub/basis/person-role/update"],
				serverAccessKeys:["/hub/person-role/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}