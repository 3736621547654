//GeneratorFlag:[NoChange]
import ext from './fa-ext.js'
export default {
	"name":"aut",
	"ext": ext,
	"layout":{
		"basis": "اطلاعات پایه", //GeneratorFlag:[BasisLayout]
		"report": "گزارشات", //GeneratorFlag:[ReportLayout]
//GeneratorFlag:[NewLayout]
	},
	'activity':'فعالیت',
	'activityActivityId':'شناسه',
	'activityActivityPriority':'اولویت',
	'activityActivityPriorityId':'اولویت',
	'activityActivityPriorityTitle':'اولویت',
	'activityActivityStatus':'وضعیت',
	'activityActivityStatusId':'وضعیت',
	'activityActivityStatusTitle':'وضعیت',
	'activityActivityTrack':'اطلاعات رهگیری ثبت',
	'activityAttachment':'پیوست فایل',
	'activityCreate':'ثبت فعالیت جدید',
	'activityCreateActivityId':'شناسه',
	'activityCreateActivityPriorityId':'اولویت',
	'activityCreateBranchId':'شناسه',
	'activityCreateComment':'متن درخواست',
	'activityCreateDeadlineHour':'ددلاین فرآیند/ساعت',
	'activityCreateFormValue':'اطلاعات فرم',
	'activityCreateProcessId':'شناسه',
	'activityCustomerCreate':'activity',
	'activityDeadlineHour':'ددلاین فرآیند/ساعت',
	'activityDelete':'حذف فعالیت',
	'activityDetail':'جزئیات اطلاعات فعالیت',
	'activityFileLengths':'تعداد فایل های  چرخه فرآیند',
	'activityFirstActivistName':'آغازگر',
	'activityFirstActivistRoleName':'جایگاه سازمانی آغازگر',
	'activityFirstActivityComment':'توضیحات آغازگر',
	'activityFirstActivityCreateTime':'ساعت آغاز',
	'activityFirstActivityFileLength':'تعداد فایل پیوست',
	'activityFirstActivityLocalDate':'تاریخ آغاز',
	'activityFormActivists':'اقدامگرهای  چرخه فرآیند',
	'activityFormComments':'توضیحات چرخه فرآیند',
	'activityFormValues':'فرم های  چرخه فرآیند',
	'activityFullReport':'گزارش فعالیت ها',
	'activityLastActivistName':'آخرین اقدامگر',
	'activityLastActivistRoleName':'جایگاه سازمانی آخرین اقدامگر',
	'activityLastProcessNodeTitle':'آخرین اقدام',
	'activityLayout':'فعالیت ها',
	'activityList':'فهرست فعالیت ها',
	'activityLocalDateTimes':'زمان های چرخه فرآیند',
	'activityNextNode':'next node',
	'activityNextProcessNodeId':'سطح بعدی',
	'activityNode':'node',
	'activityPriorityActivityPriorityId':'شناسه',
	'activityPriorityCaption':'توضیحات',
	'activityPriorityHexColor':'رنگ',
	'activityPriorityTitle':'عنوان',
	'activityProcess':'شناسه',
	'activityProcessId':'شناسه',
	'activityProcessTitle':'نام فرآیند',
	'activityState':'فعالیت',
	'activityStaticFile':'static file',
	'activityStatusActivityStatusId':'شناسه',
	'activityStatusCaption':'توضیحات',
	'activityStatusHexColor':'رنگ',
	'activityStatusTitle':'عنوان',
	'letter':'نامه',
	'letterAttachment':'پیوست فایل',
	'letterBody':'بدنه نامه',
	'letterBranch':'branch',
	'letterCategory':'category',
	'letterClassification':'classification',
	'letterCreate':'ثبت نامه جدید',
	'letterCreateImport':'ثبت نامه ها از طریق اکسل',
	'letterDeadlineHour':'مهلت اقدام به ساعت',
	'letterDelete':'حذف نامه',
	'letterDetail':'جزئیات اطلاعات نامه',
	'letterDocumentType':'document type',
	'letterIndicator':'اندیکاتور',
	'letterLayout':'نامه ها',
	'letterLetterCategoryId':'دسته بندی',
	'letterLetterClassificationId':'طبقه بندی',
	'letterLetterDocumentTypeId':'نوع سند',
	'letterLetterId':'شناسه',
	'letterLetterPatternId':'الگوی نامه',
	'letterLetterPriorityId':'اولویت',
	'letterLetterTopicId':'سرفصل بایگانی',
	'letterLetterTypeId':'نوع نامه',
	'letterList':'فهرست نامه ها',
	'letterNode':'node',
	'letterPattern':'الگوی نامه',
	'letterPatternAttachment':'پیوست فایل',
	'letterPatternCaption':'توضیحات',
	'letterPatternCreate':'ثبت الگوی نامه جدید',
	'letterPatternCreateImport':'ثبت الگوهای نامه از طریق اکسل',
	'letterPatternDelete':'حذف الگوی نامه',
	'letterPatternDetail':'جزئیات اطلاعات الگوی نامه',
	'letterPatternIdentifier':'کلید اصلی',
	'letterPatternLayout':' الگوهای نامه',
	'letterPatternLetterPatternId':'شناسه',
	'letterPatternLetterPatternTrack':'اطلاعات رهگیری ثبت',
	'letterPatternList':'فهرست الگوهای نامه',
	'letterPatternPatternBody':'بدنه الگو',
	'letterPatternTitle':'نام الگو',
	'letterPatternUpdate':'ویرایش الگوی نامه',
	'letterPriority':'priority',
	'letterStaticFile':'static file',
	'letterTag':'برچسب نامه',
	'letterTagAttachment':'پیوست فایل',
	'letterTagCaption':'توضیحات',
	'letterTagCreate':'ثبت برچسب نامه جدید',
	'letterTagCreateImport':'ثبت برچسب های نامه از طریق اکسل',
	'letterTagDelete':'حذف برچسب نامه',
	'letterTagDetail':'جزئیات اطلاعات برچسب نامه',
	'letterTagHexColor':'رنگ',
	'letterTagIdentifier':'کلید اصلی',
	'letterTagLayout':' برچسب های نامه',
	'letterTagLetterTagId':'شناسه',
	'letterTagLetterTagTrack':'اطلاعات رهگیری ثبت',
	'letterTagList':'فهرست برچسب های نامه',
	'letterTagTitle':'عنوان پرچسب',
	'letterTagUpdate':'ویرایش برچسب نامه',
	'letterTitle':'عنوان نامه',
	'letterTopic':'سرفصل بایگانی',
	'letterTopicAttachment':'پیوست فایل',
	'letterTopicCaption':'توضیحات',
	'letterTopicCreate':'ثبت سرفصل بایگانی جدید',
	'letterTopicCreateImport':'ثبت سرفصل های بایگانی از طریق اکسل',
	'letterTopicDelete':'حذف سرفصل بایگانی',
	'letterTopicDetail':'جزئیات اطلاعات سرفصل بایگانی',
	'letterTopicIdentifier':'کلید اصلی',
	'letterTopicLayout':' سرفصل های بایگانی',
	'letterTopicLetterTopicId':'شناسه',
	'letterTopicLetterTopicTrack':'اطلاعات رهگیری ثبت',
	'letterTopicList':'فهرست سرفصل های بایگانی',
	'letterTopicTitle':'عنوان',
	'letterTopicUpdate':'ویرایش سرفصل بایگانی',
	'letterType':'type',
	'nextNodeStateCompleteTitle':'عنوان دکمه تکمیل',
	'nextNodeStateContinueTitle':'عنوان دکمه ادامه',
	'nextNodeStateReturnTitle':'عنوان دکمه بازگشت',
	'nextNodeStateStopTitle':'عنوان دکمه توقف',
	'nodeListCompleteTitle':'عنوان دکمه تکمیل',
	'nodeListContinueTitle':'عنوان دکمه ادامه',
	'nodeListReturnTitle':'عنوان دکمه بازگشت',
	'nodeListStopTitle':'عنوان دکمه توقف',
	'nodeStateCompleteTitle':'عنوان دکمه تکمیل',
	'nodeStateContinueTitle':'عنوان دکمه ادامه',
	'nodeStateReturnTitle':'عنوان دکمه بازگشت',
	'nodeStateStopTitle':'عنوان دکمه توقف',
	'process':'فرآیند',
	'processAttachment':'پیوست فایل',
	'processCaption':'توضیحات',
	'processCreate':'ثبت فرآیند جدید',
	'processCreateImport':'ثبت فرآیند ها از طریق اکسل',
	'processDelete':'حذف فرآیند',
	'processDetail':'جزئیات اطلاعات فرآیند',
	'processGroup':'گروه فرآیند',
	'processGroupAttachment':'پیوست فایل',
	'processGroupCaption':'توضیحات',
	'processGroupCreate':'ثبت گروه فرآیند جدید',
	'processGroupCreateImport':'ثبت گروه های فرآیند از طریق اکسل',
	'processGroupDelete':'حذف گروه فرآیند',
	'processGroupDetail':'جزئیات اطلاعات گروه فرآیند',
	'processGroupHexColor':'رنگ',
	'processGroupIdentifier':'کلید اصلی',
	'processGroupLayout':' گروه های فرآیند',
	'processGroupList':'فهرست گروه های فرآیند',
	'processGroupProcessGroupId':'شناسه',
	'processGroupProcessGroupTrack':'اطلاعات رهگیری ثبت',
	'processGroupTitle':'عنوان',
	'processGroupUpdate':'ویرایش گروه فرآیند',
	'processIdentifier':'کلید یکتا',
	'processLayout':'فرآیند ها',
	'processList':'فهرست فرآیند ها',
	'processNode':'وظیفه',
	'processNodeLayout':'وظایف',
	'processNodeManagement':'وظیفه',
	'processNodeManagementCaption':'توضیحات',
	'processNodeManagementCompleteTitle':'عنوان دکمه تکمیل',
	'processNodeManagementContinueTitle':'عنوان دکمه ادامه',
	'processNodeManagementEventPattern':'رویداد',
	'processNodeManagementFormPattern':'فرم',
	'processNodeManagementHasAccessToComplete':'امکان تکمیل / پایان موفق',
	'processNodeManagementHasAccessToContinue':'امکان ادامه',
	'processNodeManagementHasAccessToReturn':'امکان بازگشت',
	'processNodeManagementHasAccessToStop':'امکان توقف / پایان ناموفق',
	'processNodeManagementIsArrayFormValue':'ورود اطلاعات از اکسل',
	'processNodeManagementIsLimitedToUnit':'محدود به واحد سازمانی',
	'processNodeManagementMessagePatternName':'نام الگوی پیامک',
	'processNodeManagementOrderNumber':'اولویت',
	'processNodeManagementProcessBranchAgents':'اقدامگرها',
	'processNodeManagementProcessId':'فرآیند',
	'processNodeManagementProcessNodeId':'شناسه',
	'processNodeManagementProcessRoleAgents':'نقش های کاربری',
	'processNodeManagementReturnNodes':'اقدام های بازگشتی',
	'processNodeManagementReturnTitle':'عنوان دکمه بازگشت',
	'processNodeManagementStopTitle':'عنوان دکمه توقف',
	'processNodeManagementProcessWorkerAgent': 'سرویس اقدامگر',
	'processNodeManagementTitle':'عنوان',
	'processNodeWorkerAgent':'سرویس اقدامگر',
	'processProcessGroup':'گروه',
	'processProcessGroupId':'گروه',
	'processProcessId':'شناسه',
	'processProcessTrack':'اطلاعات رهگیری ثبت',
	'processTitle':'عنوان',
	'processUpdate':'ویرایش فرآیند',

}
