<template>
	<kaveh-overlay :is-loading="state.letter == 'loading'" class="letter-detail" :data-root="isRoot">
		<div class="wrapper" v-if="letter">
			<div class="content">
				<div class="mb-2" v-if="showActions">
					<button class="btn border btn-light me-1" @click="handleAction('reply')" v-if="!isSelf">
						<kaveh-icon-bootstrap name="bi-reply" size="18px" />
						<span class="ps-3">{{$loc.fromResource('aut.ext.letter.actions.reply')}}</span>
					</button>
					<button class="btn border btn-light me-1" @click="handleAction('forward')" v-if="!isSelf">
						<kaveh-icon-bootstrap name="bi-forward" size="18px" />
						<span class="ps-3">{{$loc.fromResource('aut.ext.letter.actions.forward')}}</span>
					</button>
					<kaveh-button class="btn border btn-light me-1" :is-loading="state.archive == 'loading'" @click="handelArchive" v-if="letter.letterBoxId != 4">
						<kaveh-icon-bootstrap name="bi-archive" size="18px" />
						<span class="ps-3">{{$loc.fromResource('aut.ext.letter.actions.archive')}}</span>
					</kaveh-button>
					<kaveh-button class="btn border btn-light me-1" :is-loading="state.pin == 'loading'" @click="handelPin">
						<kaveh-icon-bootstrap name="bi-pin" size="18px" />
						<span v-if="letter.isPin" class="ps-3">{{$loc.fromResource('aut.ext.letter.actions.unpin')}}</span>
						<span v-else class="ps-3">{{$loc.fromResource('aut.ext.letter.actions.pin')}}</span>
					</kaveh-button>
					<kaveh-button class="btn border btn-light me-1" :is-loading="state.markAsUnread == 'loading'" @click="handelMarkAsUnread">
						<kaveh-icon-bootstrap name="bi-eye-slash" size="18px" />
						<span class="ps-3">{{$loc.fromResource('aut.ext.letter.actions.markAsUnread')}}</span>
					</kaveh-button>
				</div>
				<div v-if="!isRoot" class="mb-2">
					<small dir="ltr">{{ persianWord(letter.localCreateDate) }} - {{letter.createTime}}</small>
				</div>
				<div v-if="isRoot" class="mb-0 p-3 bg-light">
					<strong class="float-start">{{letter.title}}</strong>
					<small class="float-end" dir="ltr">{{ persianWord(letter.localCreateDate) }} - {{letter.createTime}}</small>
				</div>
				<div class="p-3 bg-light">
					<div class="d-block mb-2" v-html="senderLabel"></div>
					<label v-for="(item,index) in primaryList" :key="index" class="d-inline-block pe-2">
						<small>{{item.personFullName}}&nbsp;</small>
						<small class="text-muted">{{item.branchName}}</small>
						<small v-if="index < (primaryList.length - 1)">,</small>
					</label>
				</div>
				<div class="p-3 bg-white">
					<div class="mt-3 mb-3" v-html="letter.body"></div>
				</div>
				<div class="p-3 bg-light" v-if="ccList.length > 0">
					<span class="d-block mb-2">
						{{$loc.fromResource('aut.ext.letter.compose.preview.ccList')}}
					</span>
					<label v-for="(item,index) in ccList" :key="index" class="d-inline-block pe-2">
						<small>{{item.personFullName}}&nbsp;</small>
						<small class="text-muted">{{item.branchName}}</small>
						<small v-if="index < (ccList.length - 1)">,</small>
					</label>
				</div>
				<kaveh-overlay v-if="showAttachments && attachments.length > 0" :is-loading="state.attachments == 'loading'" class="mb-1 p-3 bg-light">
					<div class="mb-2">
						{{$loc.fromResource('aut.ext.letter.compose.preview.attachments')}}
					</div>
					<div class="mt-3">
						<kaveh-button :is-loading="item.isLoading" class="btn p-2 me-2 mb-2 rounded bg-white pointer hover-shadow d-inline-block"
									  v-for="(item, index) in attachments" :key="index" @click="downloadFile(item)">
							{{ item.fileName }}
						</kaveh-button>
					</div>
				</kaveh-overlay>
			</div>
			<letter-detail v-if="showMore" :id="letter.parentId" :show-actions="false" :is-root="false" />
			<div class="text-start mt-2" v-if="!showMore && letter && letter.parentId">
				<button class="btn btn-light" @click="showMore = true">
					{{$loc.fromResource('aut.ext.letter.compose.preview.showOlder')}}
				</button>
			</div>
		</div>
	</kaveh-overlay>

</template>

<script>
	import { url, dataUrl, persian } from '@/plugins'
	import { LetterService } from '../../services'
	import { StaticFileService } from '@/modules/asset/services'

	export default {

		props: {
			showActions: {
				type: Boolean,
				default: true
			},
			showAttachments: {
				type: Boolean,
				default: true
			},
			id: {
				type: Number,
				default: null,
			},
			nodeId: {
				type: Number,
				default: null,
			},
			isRoot: {
				type: Boolean,
				default: true,
			}
		},

		data()
		{
			return {
				letter: null,
				attachments: [],
				state: {
					letter: "loading",
					attachments: "loading",
					pin: "ready",
					archive: "ready",
					markAsUnread: "ready",
				},
				showMore: false
			}
		},

		async beforeMount()
		{
			if (this.branchId == 0)
			{
				this.$router.push({ name: 'hub.branch.accessDenied' });
				return;
			}

			if (!this.letterId || !this.letterNodeId)
			{
				this.$router.push({ name: 'notFound' });
				return;
			}

			await this.initLetter();
			await this.handelRead();
		},

		computed:
		{
			branchId()
			{
				return this.$store.state.client.branchId;
			},

			letterId()
			{
				return Number(this.id || url.decript(this.$route.query.key));
			},

			letterNodeId()
			{
				return Number(this.nodeId || url.decript(this.$route.query.node));
			},

			senderLabel()
			{
				if (this.letter)
				{
					const letter = this.letter
					const sender = this.getNodes(1)[0]

					return this.$loc.fromResource('aut.ext.letter.reader.sender', {
						...sender, 'label': letter.type.title
					})
				}

				return '';
			},

			isSelf()
			{
				if (this.letter)
				{
					const sender =
						this.getNodes(1)[0];

					return sender.branchId === this.branchId;
				}

				return true;
			},

			primaryList()
			{
				return this.getNodes(2);
			},

			ccList()
			{
				return this.getNodes(3);
			}
		},

		methods:
		{
			async initLetter()
			{
				var letter = null;

				try
				{
					this.state.letter = "loading"
					const res = await new LetterService().detail({
						letterId: this.letterId,
						letterNodeId: this.letterNodeId,
						branchId: this.branchId
					}, { HSR: false });

					if (res.status == 200)
					{
						letter = res.data;
						this.initFiles(letter.identifier);
					}

				} catch (e) { console.log(e); }

				this.letter = letter;
				this.state.letter = letter != null ? "ready" : "empty";
			},

			async initFiles(identifier)
			{
				if (this.showAttachments)
				{
					var items = [];

					try
					{
						const res = await new StaticFileService().list({
							start: 0,
							length: 1000,
							filter: {
								identifier: identifier
							}
						}, { HSR: false });

						if (res.status == 200)
							items = res.data.items || [];

					} catch (e) { console.log(e); }

					this.attachments = items;
					this.state.attachments = items.length > 0 ? "ready" : "empty";
				}
			},

			async downloadFile(item)
			{
				item.isLoading = true;
				this.$assetDownload(item.systemName, item.fileName).then(() =>
				{
					item.isLoading = false;
				})
			},

			async handelArchive()
			{
				try
				{
					this.state.archive = "loading"
					const res = await new LetterService().archive({
						letterId: this.letterId,
						letterNodeId: this.letterNodeId,
						branchId: this.branchId
					});

					if (res.status == 200)
					{
						this.initLetter()
					}

				} catch (e) { console.log(e); }

				this.state.archive = "ready"
			},

			async handelPin()
			{
				try
				{
					this.state.pin = "loading"
					const res = await new LetterService().pin({
						letterId: this.letterId,
						letterNodeId: this.letterNodeId,
						branchId: this.branchId,
						isPin: !this.letter.isPin
					});

					if (res.status == 200)
					{
						this.initLetter()
					}

				} catch (e) { console.log(e); }

				this.state.pin = "ready"
			},

			async handelMarkAsUnread()
			{
				try
				{
					this.state.markAsUnread = "loading"
					const res = await new LetterService().markAsUnread({
						letterId: this.letterId,
						letterNodeId: this.letterNodeId,
						branchId: this.branchId
					});

					if (res.status == 200)
					{
						this.$router.go(-1)
					}

				} catch (e) { console.log(e); }

				this.state.markAsUnread = "ready"
			},

			async handelRead()
			{
				try
				{
					if (this.showActions == true && this.letter)
					{
						await new LetterService().read({
							letterId: Number(this.letterId),
							letterNodeId: this.letterNodeId,
							branchId: this.branchId
						}, { HSR: false });
					}

				} catch (e) { console.log(e); }
			},

			getNodes(id)
			{
				const items = [];
				const letter = this.letter;

				if (letter != null)
				{
					for (var i = 0; i < letter.nodes.length; i++)
					{
						const node = letter.nodes[i];
						if (node.letterNodeTypeId === id)
						{
							items.push(node)
						}
					}
				}

				return items;
			},

			handleAction(action)
			{
				this.$router.push({
					name: `aut.letter.create`,
					query: {
						action: action,
						key: url.encript(this.letterId),
						node: url.encript(this.letterNodeId)
					}
				});
			},

			getData()
			{
				return this.letter;
			},

			persianWord(persianDate)
			{
				return persian.word(persianDate);
			}
		}
	}

</script>
<style>
	.letter-detail {
	}

		.letter-detail[data-root="false"] {
			padding-top: 10px;
			margin-top: 10px;
		}
</style>
