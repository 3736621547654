<!--GeneratorFlag:[NoChange]-->
<template>
	<p>This is a action page.</p>
</template>
<script>
	export default {
		data()
		{
			return {
				test: 'test-prop-value'
			}
		}
	}
</script>