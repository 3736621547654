//GeneratorFlag:[NoChange]
import { defineAsyncComponent, markRaw } from "vue";

const notification = {

	info: {
		name: 'kaveh.app.toolbar.cms.notification',
		iconName: 'bi-chat',
		hasCounter: true,
	},

	getData: async () =>
	{
		const localStorageKey = 'kaveh.app.toolbar.cms.notification';
		const strData = localStorage.getItem(localStorageKey);
		const data = JSON.parse(strData) || { isLoading: false, counter: 0, content: null }

		try
		{
			if (data.isLoading == false)
			{
				// get data from service
			}

		} catch (e){ console.log(e); }

		return null;
	},

	getComponent: () => markRaw(defineAsyncComponent(() => import('./views/notification.vue'))),
}


export default notification