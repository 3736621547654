//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { Discount, Producer, ProductGroup, ProductSeo, Warehouse } from '@/modules/store/data/product/list'; 
export default class ProductFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'productFilter';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'global.filters';
	props = {
		productId: {
			primarykey: true,
			name: 'productId',
			resourceKey: 'store.productProductId',
			type: ENUMS.PROP_TYPE.INT32,
			isSearchable: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		title: {
			name: 'title',
			resourceKey: 'store.productTitle',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: true,
				patterns: [],
			},
			errors: [],
			value: null
		},
		caption: {
			name: 'caption',
			resourceKey: 'store.productCaption',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 100,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		stringContent: {
			name: 'stringContent',
			resourceKey: 'store.productStringContent',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		showInHomePage: {
			name: 'showInHomePage',
			resourceKey: 'store.productShowInHomePage',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			isSearchable: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		viewOrder: {
			name: 'viewOrder',
			resourceKey: 'store.productViewOrder',
			type: ENUMS.PROP_TYPE.INT32,
			isSearchable: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		startSaleDate: {
			name: 'startSaleDate',
			resourceKey: 'store.productStartSaleDate',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATA_PICKER,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 10,
				isUnique: false,
				patterns: ['date'],
			},
			errors: [],
			value: null
		},
		endSaleDate: {
			name: 'endSaleDate',
			resourceKey: 'store.productEndSaleDate',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATA_PICKER,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 10,
				isUnique: false,
				patterns: ['date'],
			},
			errors: [],
			value: null
		},
		disableBuyButton: {
			name: 'disableBuyButton',
			resourceKey: 'store.productDisableBuyButton',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			isSearchable: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		isRelease: {
			name: 'isRelease',
			resourceKey: 'store.productIsRelease',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			isSearchable: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		callForPrice: {
			name: 'callForPrice',
			resourceKey: 'store.productCallForPrice',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			isSearchable: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		productGroupId: {
			foreignKey: true,
			name: 'productGroupId',
			resourceKey: 'store.productProductGroupId',
			type: ENUMS.PROP_TYPE.INT32,
			isSearchable: true,
			serverAddress: '/store/product-group/list',
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'productGroupId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		producerId: {
			foreignKey: true,
			name: 'producerId',
			resourceKey: 'store.productProducerId',
			type: ENUMS.PROP_TYPE.INT32,
			isSearchable: true,
			serverAddress: '/store/producer/list',
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'producerId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		warehouseId: {
			foreignKey: true,
			name: 'warehouseId',
			resourceKey: 'store.productWarehouseId',
			type: ENUMS.PROP_TYPE.INT32,
			isSearchable: true,
			serverAddress: '/store/warehouse/list',
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'warehouseId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		translations: {
			name: 'translations',
			resourceKey: 'store.productTranslations',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}