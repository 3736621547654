//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { WorkerAgent } from '@/modules/aut/data/process-node/management'; 
export default class ProcessNode extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'processNode';
	schema = ENUMS.SCHEMA_TYPE.NONE;
	resourceKey = 'aut.processNodeManagement';
	props = {
		processNodeId: {
			primarykey: true,
			name: 'processNodeId',
			resourceKey: 'aut.processNodeManagementProcessNodeId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		title: {
			name: 'title',
			resourceKey: 'aut.processNodeManagementTitle',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		caption: {
			name: 'caption',
			resourceKey: 'aut.processNodeManagementCaption',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXTAREA,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 100,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		orderNumber: {
			name: 'orderNumber',
			resourceKey: 'aut.processNodeManagementOrderNumber',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: ['number','required'],
			},
			errors: [],
			value: null
		},
		hasAccessToReturn: {
			name: 'hasAccessToReturn',
			resourceKey: 'aut.processNodeManagementHasAccessToReturn',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		hasAccessToContinue: {
			name: 'hasAccessToContinue',
			resourceKey: 'aut.processNodeManagementHasAccessToContinue',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		hasAccessToComplete: {
			name: 'hasAccessToComplete',
			resourceKey: 'aut.processNodeManagementHasAccessToComplete',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		hasAccessToStop: {
			name: 'hasAccessToStop',
			resourceKey: 'aut.processNodeManagementHasAccessToStop',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		returnTitle: {
			name: 'returnTitle',
			resourceKey: 'aut.processNodeManagementReturnTitle',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		continueTitle: {
			name: 'continueTitle',
			resourceKey: 'aut.processNodeManagementContinueTitle',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		stopTitle: {
			name: 'stopTitle',
			resourceKey: 'aut.processNodeManagementStopTitle',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		completeTitle: {
			name: 'completeTitle',
			resourceKey: 'aut.processNodeManagementCompleteTitle',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		isLimitedToUnit: {
			name: 'isLimitedToUnit',
			resourceKey: 'aut.processNodeManagementIsLimitedToUnit',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		isArrayFormValue: {
			name: 'isArrayFormValue',
			resourceKey: 'aut.processNodeManagementIsArrayFormValue',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		processId: {
			foreignKey: true,
			name: 'processId',
			resourceKey: 'aut.processNodeManagementProcessId',
			type: ENUMS.PROP_TYPE.INT16,
			serverAddress: '/aut/process/list',
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'processId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		processBranchAgents: {
			name: 'processBranchAgents',
			resourceKey: 'aut.processNodeManagementProcessBranchAgents',
			type: ENUMS.PROP_TYPE.ARRAY,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				collectionMinLength: 0,
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: []
		},
		processRoleAgents: {
			name: 'processRoleAgents',
			resourceKey: 'aut.processNodeManagementProcessRoleAgents',
			type: ENUMS.PROP_TYPE.ARRAY,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				collectionMinLength: 0,
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: []
		},
		processWorkerAgent: {
			name: 'processWorkerAgent',
			resourceKey: 'aut.processNodeManagementProcessWorkerAgent',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new WorkerAgent(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{workerPattern}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		returnNodes: {
			name: 'returnNodes',
			resourceKey: 'aut.processNodeManagementReturnNodes',
			type: ENUMS.PROP_TYPE.ARRAY,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				collectionMinLength: 0,
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: []
		},
		formPattern: {
			name: 'formPattern',
			resourceKey: 'aut.processNodeManagementFormPattern',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		eventPattern: {
			name: 'eventPattern',
			resourceKey: 'aut.processNodeManagementEventPattern',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		messagePatternName: {
			name: 'messagePatternName',
			resourceKey: 'aut.processNodeManagementMessagePatternName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}