export default {
	"views": {
		"fileManager": {
			"layout": "Flie Explorer"
		}
	},

	"fileManager": {
		"createFolder": "Create new Folder",
		"uploadFile": "Upload new File",
		"accessTitle": "File access status",
		"accessPrivateTitle": "The file is private and can only be downloaded with a security token",
		"accessPublicTitle": "The file is public and can be downloaded without a token",
		"uploadAction": "Upload selected files",
		"info": {
			"fileName": "File Name",
			"extensionName": "Extension Name",
			"contentType": "Content Type",
			"key": "Key",
			"download": "Download",
			"clickToDownload": "Click to download",
			"externalLink": "External download link",
			"tokenWarning": "The above link can only be used by using the security token in the {token} section",
			"createDateTime": "Create Date Time",
			"modifyDateTime": "Modify Date Time"
		}
	}
}
