<template>
	<span v-if="hasValue" v-bind:dir="direction" :class="{ 'truncate': $parent.isTrancate }">
		{{ data.displayValue }}
	</span>
</template>
<script>
	export default {
		props:
		{
			data: Object
		},

		computed:
		{
			direction() {
				return (this.data.display || {}).direction || document.body.dir
			},

			hasValue()
			{
				return this.data.value != null && this.data.value != undefined
			}
		}
	}
</script>
