//GeneratorFlag:[NoChange]
import ext from './en-ext.js'
export default {
	"name":"store",
	"ext": ext,
	"layout":{
		"basis": "Basic Information", //GeneratorFlag:[BasisLayout]
//GeneratorFlag:[NewLayout]
	},
	'deliveryDateType':'DeliveryDateType',
	'deliveryDateTypeAttachment':'Attach files',
	'deliveryDateTypeCaption':'caption',
	'deliveryDateTypeCreate':'Create New DeliveryDateType',
	'deliveryDateTypeCreateImport':'Imports DeliveryDateType From Excel',
	'deliveryDateTypeDelete':'Delete DeliveryDateType',
	'deliveryDateTypeDeliveryDateTypeId':'delivery date type id',
	'deliveryDateTypeDeliveryDateTypeTrack':'delivery date type track',
	'deliveryDateTypeDetail':'Detail of DeliveryDateType',
	'deliveryDateTypeIdentifier':'identifier',
	'deliveryDateTypeLayout':'Create DeliveryDateType',
	'deliveryDateTypeList':'List of DeliveryDateType',
	'deliveryDateTypeTitle':'title',
	'deliveryDateTypeUpdate':'Edit DeliveryDateType',
	'discount':'Discount',
	'discountAttachment':'Attach files',
	'discountCouponCode':'coupon code',
	'discountCreate':'Create New Discount',
	'discountCreateImport':'Imports Discount From Excel',
	'discountDelete':'Delete Discount',
	'discountDetail':'Detail of Discount',
	'discountDiscountAmount':'discount amount',
	'discountDiscountId':'discount id',
	'discountDiscountPercentage':'discount percentage',
	'discountDiscountTrack':'discount track',
	'discountEndDate':'end date',
	'discountIdentifier':'identifier',
	'discountLayout':'Discount',
	'discountList':'List of Discount',
	'discountStartDate':'start date',
	'discountTitle':'title',
	'discountUpdate':'Edit Discount',
	'feature':'Feature',
	'featureAttachment':'Attach files',
	'featureCreate':'Create New Feature',
	'featureCreateImport':'Imports Feature From Excel',
	'featureDefaultValue':'default value',
	'featureDelete':'Delete Feature',
	'featureDetail':'Detail of Feature',
	'featureFeatureGroup':'feature group',
	'featureFeatureGroupId':'feature group',
	'featureFeatureId':'feature id',
	'featureFeatureInputType':'feature input type',
	'featureFeatureInputTypeId':'feature input type',
	'featureFeatureTrack':'feature track',
	'featureGroup':'FeatureGroup',
	'featureGroupAttachment':'Attach files',
	'featureGroupCaption':'caption',
	'featureGroupCreate':'Create New FeatureGroup',
	'featureGroupCreateImport':'Imports FeatureGroup From Excel',
	'featureGroupDelete':'Delete FeatureGroup',
	'featureGroupDetail':'Detail of FeatureGroup',
	'featureGroupFeatureGroupId':'feature group id',
	'featureGroupFeatureGroupTrack':'feature group track',
	'featureGroupIdentifier':'identifier',
	'featureGroupLayout':'Create FeatureGroup',
	'featureGroupList':'List of FeatureGroup',
	'featureGroupParentId':'parent',
	'featureGroupTitle':'title',
	'featureGroupUpdate':'Edit FeatureGroup',
	'featureIdentifier':'identifier',
	'featureInputTypeCaption':'caption',
	'featureInputTypeFeatureInputTypeId':'feature input type id',
	'featureInputTypeTitle':'title',
	'featureIsPricingFeature':'is pricing feature',
	'featureIsProductFeature':'is product feature',
	'featureLayout':'Feature',
	'featureList':'List of Feature',
	'featureTitle':'title',
	'featureUpdate':'Edit Feature',
	'pricing':'Pricing',
	'pricingCartMaxLength':'cart max length',
	'pricingCartMinLength':'cart min length',
	'pricingCreate':'Create New Pricing',
	'pricingCreateImport':'Imports Pricing From Excel',
	'pricingDelete':'Delete Pricing',
	'pricingDeliveryDateType':'delivery date type',
	'pricingDeliveryDateTypeId':'delivery date type',
	'pricingDetail':'Detail of Pricing',
	'pricingFeature':'feature',
	'pricingFeatureId':'feature',
	'pricingFeatureValue':'feature value',
	'pricingIsPrimary':'is primary',
	'pricingIsTaxExempt':'is tax exempt',
	'pricingLayout':'Pricing',
	'pricingList':'List of Pricing',
	'pricingOldPrice':'old price',
	'pricingPackingCost':'packing cost',
	'pricingPrice':'price',
	'pricingPricingId':'pricing id',
	'pricingPricingTrack':'pricing track',
	'pricingProduct':'product',
	'pricingProductId':'product',
	'pricingShippingCost':'shipping cost',
	'pricingStockQuantity':'stock quantity',
	'pricingTaxAmount':'tax amount',
	'pricingTaxPercentage':'tax percentage',
	'pricingUpdate':'Edit Pricing',
	'pricingWarehouseCode':'warehouse code',
	'producer':'Producer',
	'producerAttachment':'Attach files',
	'producerCaption':'caption',
	'producerCreate':'Create New Producer',
	'producerCreateImport':'Imports Producer From Excel',
	'producerDelete':'Delete Producer',
	'producerDetail':'Detail of Producer',
	'producerIdentifier':'identifier',
	'producerLayout':'Create Producer',
	'producerList':'List of Producer',
	'producerProducerId':'producer id',
	'producerProducerTrack':'producer track',
	'producerTitle':'title',
	'producerUpdate':'Edit Producer',
	'product':'Product',
	'productAttachment':'Attach files',
	'productCallForPrice':'call for price',
	'productCaption':'caption',
	'productCreate':'Create New Product',
	'productCreateImport':'Imports Product From Excel',
	'productDelete':'Delete Product',
	'productDetail':'Detail of Product',
	'productDisableBuyButton':'disable buy button',
	'productDiscount':'discount',
	'productDiscountId':'discount',
	'productEndSaleDate':'end sale date',
	'productFeatures':'feature',
	'productGroup':'ProductGroup',
	'productGroupAttachment':'Attach files',
	'productGroupCaption':'caption',
	'productGroupCreate':'Create New ProductGroup',
	'productGroupCreateImport':'Imports ProductGroup From Excel',
	'productGroupDelete':'Delete ProductGroup',
	'productGroupDetail':'Detail of ProductGroup',
	'productGroupIdentifier':'identifier',
	'productGroupImageAttachment':'Attach images',
	'productGroupLayout':'Create ProductGroup',
	'productGroupList':'List of ProductGroup',
	'productGroupParentId':'parent',
	'productGroupProductGroupId':'product group id',
	'productGroupProductGroupTrack':'product group track',
	'productGroupTitle':'title',
	'productGroupUpdate':'Edit ProductGroup',
	'productGroupViewOrder':'view order',
	'productIdentifier':'identifier',
	'productImageAttachment':'Attach images',
	'productIsRelease':'is release',
	'productLayout':'Product',
	'productList':'List of Product',
	'productProducer':'producer',
	'productProducerId':'producer',
	'productProductGroup':'product group',
	'productProductGroupId':'product group',
	'productProductId':'product id',
	'productProductSeo':'product seo',
	'productProductTrack':'product track',
	'productSeo':'ProductSeo',
	'productSeoDescriptionTag':'description tag',
	'productSeoDetail':'Detail of ProductSeo',
	'productSeoKeywordsTag':'keywords tag',
	'productSeoPageTitle':'page title',
	'productSeoProductId':'product id',
	'productSeoTitleTag':'title tag',
	'productSeoUpdate':'Edit ProductSeo',
	'productShowInHomePage':'show in home page',
	'productStartSaleDate':'start sale date',
	'productStringContent':'string content',
	'productTitle':'title',
	'productUpdate':'Edit Product',
	'productViewOrder':'view order',
	'productWarehouse':'warehouse',
	'productWarehouseId':'warehouse',
	'warehouse':'Warehouse',
	'warehouseAttachment':'Attach files',
	'warehouseCaption':'caption',
	'warehouseCreate':'Create New Warehouse',
	'warehouseCreateImport':'Imports Warehouse From Excel',
	'warehouseDelete':'Delete Warehouse',
	'warehouseDetail':'Detail of Warehouse',
	'warehouseIdentifier':'identifier',
	'warehouseLayout':'Create Warehouse',
	'warehouseList':'List of Warehouse',
	'warehouseTitle':'title',
	'warehouseUpdate':'Edit Warehouse',
	'warehouseWarehouseId':'warehouse id',
	'warehouseWarehouseTrack':'warehouse track',

}