<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table v-if="temp" :data="[temp]" :entity="entity" :selectable="false" :show-row-number="false" :show-pagination="false" />
	<kaveh-overlay :is-loading="isLoading" :is-empty="attachments.length == 0" class="mb-1 p-3 bg-light">
		<template #noContent>
			<span>
				{{ $loc.fromResource('message.emptyResult') }}
			</span>
		</template>
		<div class="mb-3">
			{{ $loc.fromResource('title.attachmentsList') }}
		</div>
		<table class="table table-backgroundless text-center">
			<thead>
				<tr>
					<th>{{$loc.fromResource('global.row')}}</th>
					<th>{{$loc.fromResource('global.name')}}</th>
					<th>{{$loc.fromResource('action.download')}}</th>
					<th>{{$loc.fromResource('action.delete')}}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item, index) in attachments" :key="index">
					<td>{{ index + 1 }}</td>
					<td dir="ltr">{{ item.fileName }}</td>
					<td>
						<div v-if="item.isPrivate">
							<a rel="nofollow" class="btn btn-light border btn-sm" :href="$assetPrivateLink(item.systemName, false, true)">
								<kaveh-icon-bootstrap name="bi-download" size="12px" />
								<span class="ms-2">
									{{ $loc.fromResource('action.download') }}
								</span>
							</a>
						</div>
						<div v-else>
							<a rel="nofollow" class="btn btn-light border btn-sm" :href="$assetPublicLink(item.systemPath)">
								<kaveh-icon-bootstrap name="bi-download" size="12px" />
								<span class="ms-2">
									{{ $loc.fromResource('action.download') }}
								</span>
							</a>
						</div>
					</td>
					<td>
						<kaveh-button :is-loading="item.isLoadingDelete" class="btn btn-danger btn-sm" @click="deleteFile(item)">
							<kaveh-icon-bootstrap name="bi-trash" size="12px" />
							<span class="ms-2">
								{{ $loc.fromResource('action.delete') }}
							</span>
						</kaveh-button>
					</td>
				</tr>
			</tbody>
		</table>
	</kaveh-overlay>

	<div :key="uploaderKey">
		<kaveh-uploader ref="UploaderRef" />
		<div class="upload-action">
			<kaveh-button :is-loading="isLoadingUpload" class="btn btn-dark mt-3" @click="uploadFile">
				<kaveh-icon-bootstrap name="bi-cloud-arrow-up" />
				<span class="ms-2">
					{{ $loc.fromResource('action.uploadSelectedFiles') }}
				</span>
			</kaveh-button>
		</div>
	</div>

</template>
<script>
	import { Activity } from '@/modules/aut/data/activity/list'
	import { StaticFileService } from '@/modules/asset/services'
	export default {
		data()
		{
			return {
				temp: null,
				identifier: null,
				isLoading: false,
				isLoadingUpload: false,
				uploaderKey: 1,
				attachments: []
			}
		},

		computed:
		{
			entity()
			{
				return Activity;
			},
		},

		async beforeMount()
		{
			await this.init()
		},

		methods:
		{
			async init()
			{
				const temp = await this.getDataFromTemp();

				if (temp != undefined && temp.identifier != undefined && temp.identifier != null)
				{
					this.temp = temp;
					this.identifier = temp.identifier;

					await this.initAttachments();
				}
				else
				{
					this.$router.go(-1)
				}
			},

			async initAttachments()
			{
				try
				{
					this.isLoading = true;
					const payload = {
						start: 0,
						length: 10000,
						filter: {
							identifier: this.identifier,
						}
					};

					const service = new StaticFileService();
					const res = await service.list(payload, { HSR: false, HER: true });

					if (res.status == 200)
					{
						this.attachments = res.data.items || [];
					}

				} catch (e) { console.log(e) }

				this.isLoading = false;
			},

			async getDataFromTemp()
			{
				const store = this.$store;
				const router = this.$route;
				const temp =
					await store.dispatch(
						'temp/findByQuery', router.query
					);

				return temp ? temp.value : undefined;
			},

			async deleteFile(item)
			{
				const label =
					this.$loc.fromResource('message.deleteConfirm');

				if (confirm(label))
				{
					try
					{
						item.isLoadingDelete = true;
						const payload = {
							systemName: item.systemName
						}
						const service = new StaticFileService();
						const res = await service.deleteByName(payload, { HSR: true, HER: true });
						if (res.status == 200)
						{
							await this.initAttachments()
						}

					} catch (e) { console.log(e) }

					item.isLoadingDelete = false;
				}
			},

			async uploadFile()
			{
				const files =
					this.$refs.UploaderRef.getValue();

				if (files.length > 0)
				{
					this.isLoading = true;
					this.isLoadingUpload = true;

					setTimeout(async () =>
					{
						for (var i = 0; i < files.length; i++)
						{
							try
							{
								const payload = {
									fileName: files[i].name,
									dataUrl: files[i].dataUrl,
									isPrivate: true,
									identifier: this.identifier,
									virtualFolderId: null,
									tagName: 'aut.activity.attachments'
								}

								const service = new StaticFileService();
								const res = await service.upload(payload, { HSR: false, HER: true, useHttp: true });

							} catch (e) { console.log(e) }
						}

						this.isLoadingUpload = false;
						this.uploaderKey = this.uploaderKey + 1;

						await this.initAttachments()
						this.isLoading = false;

					}, 50);
				}
			}
		}
	}
</script>