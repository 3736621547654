<template>
	<div class="mb-2 mt-1 row" v-if="$parent.filterProp">
		<div class="col">
			<div class="bg-light p-3 rounded">
				<div class="row">
					<div class="col-1 text-center pt-3">
						<kaveh-icon-bootstrap name="bi-search" size="25px" color="var(--kaveh-base-color)" />
					</div>
					<div class="col-11">
						<label for="inputFilterProp" class="form-label">{{filterLabel}}</label>
						<input type="search"
							   class="form-control border"
							   id="inputFilterProp"
							   :placeholder="$loc.fromResource('com.table.searchPlaceholder')"
							   v-model="$parent.filterPropValue" @keyup="filterChange" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	export default {
		emits: ['flterPropChange'],

		props:
		{
			'entity': Function,
		},

		data()
		{
			return {
				search: ''
			}
		},

		computed:
		{
			filterLabel()
			{
				if (this.$parent.filterProp)
				{
					const prop = this.$parent.filterProp;
					const label = prop.label || this.$loc.fromResource(prop.resourceKey);

					return this.$loc.fromResource('com.table.headerSearch', { label });
				}

				return ""
			}
		},

		methods: {

			filterChange(e)
			{
				if (e.key === 'Enter' || e.keyCode === 13)
				{
					this.$emit('flterPropChange')
				}
			},
		}
	}
</script>
<style>
	.page-item > .page-link {
		color: var(--kaveh-dark);
		user-select: none;
		cursor: pointer;
	}

	.page-item.active > .page-link {
		background: var(--kaveh-base-color) !important;
		border-color: transparent;
	}
</style>
