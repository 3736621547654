<template>
	<div class="w-100 mb-2">
		<button class="btn btn-light border me-1" @click="changeDisplayType('tree')">
			<kaveh-icon-bootstrap class="me-2" name="bi-list-nested" />
			<span>{{$loc.fromResource('hub.ext.branch.list.showInTree')}}</span>
		</button>
		<button class="btn btn-light border me-1" @click="changeDisplayType('ord')">
			<kaveh-icon-bootstrap class="me-2" name="bi-diagram-3-fill" />
			<span>{{$loc.fromResource('hub.ext.branch.list.showInOrd')}}</span>
		</button>
	</div>

	<branch-ord v-if="displayType == 'ord'" />
	<branch-editable-tree v-if="displayType == 'tree'" />


</template>
<script>

	import BranchOrd from './ord/branch-ord.vue'
	import BranchEditableTree from './editable-tree/branch-editable-tree.vue'

	export default {

		components: {
			'branch-ord': BranchOrd,
			'branch-editable-tree': BranchEditableTree
		},

		data()
		{
			return {
				displayType: "ord",
			}
		},

		beforeMount()
		{
			this.displayType =
				localStorage.getItem('kaveh.hub.branch.list.displayType') || 'ord'
		},

		methods: {
			changeDisplayType(displayType)
			{
				this.displayType = displayType

				const key = 'kaveh.hub.branch.list.displayType'
				localStorage.setItem(key, displayType)
			}
		}
	}
</script>
