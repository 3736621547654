export default {
	"enums": {
		"letterCategory": {
			"all": "Alle Briefe",
			"public": "Öffentlich",
			"mailroom": "Poststelle",
			"toAct": "Zum Handeln",
			"toRemind": "Zum Erinnern",
		},
		"activityCategory": {
			"public": "Öffentlich",
		}
	},

	"letter": {

		"compose": {
			"step1": "Empfänger auswählen",
			"step2": "Erste Informationen",
			"step3": "Briefinhalt",
			"step4": "Anhangsdateien",
			"step5": "Bestätigung und Versenden",
			"prevStep": "Vorheriger Schritt",
			"nextStep": "Nächster Schritt",
			"finalStep": "Endgültige Bestätigung und Versenden des Briefs",
			"loading": "Bitte warten, das System versendet den Brief...",

			"step1Error": "Keine Empfänger für den Brief ausgewählt!",
			"step2Error": "Bitte korrigieren Sie zuerst Formularfehler.",
			"step3Error": "Bitte Briefinhalt eingeben.",
			"duplicateError": "{label} wurde bereits ausgewählt.",

			"receivers": {
				"primaryList": "Hauptempfänger",
				"ccList": "CC-Empfänger",
				"bccList": "BCC-Empfänger",
				"emptyList": "Kein Empfänger ausgewählt.",
			},

			"preview": {
				"primaryList": "Dieser Brief wird von Ihnen an folgende Empfänger gesendet",
				"ccList": "CC an",
				"bccList": "BCC an",
				"attachments": "Anhänge",
				"showOlder" : "Ältere anzeigen"
			}
		},


		"reply": {
			"step1": "Vorschau",
			"step2": "Antwortinhalt",
			"step3": "Anhangsdateien",
			"step4": "Bestätigung und Versenden",
			"prevStep": "Vorheriger Schritt",
			"nextStep": "Nächster Schritt",
			"finalStep": "Endgültige Bestätigung und Versenden der Antwort",
			"loading": "Bitte warten, das System versendet die Antwort...",
			"to": "Antwort an",

			"step2Error": "Bitte Antwortinhalt eingeben.",

			"preview": {
				"primaryList": "Diese Antwort wird von Ihnen an folgende Empfänger gesendet",
				"attachments": "Anhänge",
			}
		},

		"forward": {
			"step1": "Vorschau",
			"step2": "Empfänger auswählen",
			"step3": "Absatz",
			"step4": "Anhangsdateien",
			"step5": "Bestätigung und Weiterleitung",
			"prevStep": "Vorheriger Schritt",
			"nextStep": "Nächster Schritt",
			"finalStep": "Endgültige Bestätigung und Weiterleitung",
			"loading": "Bitte warten, das System leitet den Brief weiter...",
			"step2Error": "Kein Empfänger für die Weiterleitung des Briefs ausgewählt!",
			"step3Error": "Bitte Weiterleitungsabsatz eingeben.",

			"receivers": {
				"primary": "Weiterleitungs-Empfänger",
				"emptyList": "Kein Empfänger ausgewählt.",
			},

			"preview": {
				"primaryList": "Dieser Brief wird von Ihnen an folgende Empfänger weitergeleitet",
				"attachments": "Anhänge",
			}
		},

		"reader": {
			"sender": "<span>Dieser {label} wurde von {personFullName} <small class='text-muted'>{branchName}</small> an</span>",
			"attachments": "Anhänge"
		},

		"list": {
			"empty": "Keine Briefe gefunden!"
		},

		"tag": {
			"self": "Tags",
			"noTag": "Alle Tags"
		},

		"letterCategory": {
			"self": "Kategorien",
		},

		"actions": {
			"reply": "Antworten",
			"forward": "Weiterleiten",
			"archive": "Archivieren",
			"pin": "Anheften",
			"unpin": "Lösen",
			"markAsUnread": "Als ungelesen markieren",
		},

		"views": {
			"self": "Seiten",
			"layout": "Briefverwaltung",
			"list": "Briefliste",
			"management": "Briefliste",
			"inbox": "Eingehende Briefe",
			"outbox": "Ausgehende Briefe",
			"drafts": "Entwürfe",
			"archived": "Archivierte Briefe",
			"compose": "Neuen Brief verfassen",
			"reply": "Antwort senden",
			"forward": "Brief weiterleiten",
			"reader": "Brief lesen"
		}
	},

	"process": {
		"nodeManagement": "Prozessverwaltung",
		"noFound": "Bisher kein Prozess definiert. Sie müssen zuerst Systemprozesse definieren, um Aufgaben zu definieren."
	},

	"processNode": {
		"management": {
			"info": "Um Aufgaben in Prozessen zu verwalten, wählen Sie bitte Ihren gewünschten Prozess aus. Wenn Ihr gewünschter Prozess hier nicht verfügbar ist, können Sie ihn im Bereich Prozessverwaltung definieren.",
		},
		"event": {
			"serviceInfo": "Service-Informationen",
			"loginServiceInfo": "Login-Service-Informationen",
			"address": "Service-Adresse",
			"method": "Methodentyp",
			"contentType": "Inhaltstyp",
			"properties": "Felder",
			"tokenPropertyName": "Token-Rückgabefeld"
		},
		"worker": {
			"emailDestination": "E-Mail-Adresse des Mitarbeiters",
			"properties": "Felder des Arbeitsdienstes"
		},
		"returnPh": "Titel der Zurück-Schaltfläche",
		"continuePh": "Titel der Weiter-Schaltfläche",
		"stopPh": "Titel der Stopp-Schaltfläche",
		"completePh": "Titel der Abschluss-Schaltfläche",
		"formInfo": "Formularinformationen",
		"eventInfo": "Ereignisinformationen",
		"formPreview": "Formularvorschau",
		"formManagement": "Formularverwaltung",
		"eventManagement": "Ereignisverwaltung",
		"levelName": "Level {label}",
		"createLevel": "Neues Level erstellen",
		"selectEventAlert": "Bitte wählen Sie Ihr gewünschtes Ereignis aus.",
		"noFound": "Bisher kein Prozess definiert. Sie müssen zuerst Systemprozesse definieren, um Aufgaben zu definieren."
	},


	"activity": {

		"compose": {
			"step1": "Prozess auswählen",
			"step2": "Anforderungsinformationen",
			"step3": "Anhangsdateien",
			"step4": "Bestätigung und Starten",
			"prevStep": "Vorheriger Schritt",
			"nextStep": "Nächster Schritt",
			"finalStep": "Endgültige Bestätigung und Prozessstart",
			"loading": "Bitte warten, das System startet den Prozess...",

			"step1Error": "Kein Prozess ausgewählt!",
			"step2Error": "Bitte korrigieren Sie zuerst Formularfehler.",

			"preview": {
				"processName": "Prozessname",
				"customForm": "Formularinformationen",
				"comment": "Anforderungskommentare",
				"attachments": "Anhänge",
				"deadlineHour": "Frist {label} Stunde"
			}
		},

		"views": {
			"self": "Seiten",
			"compose": "Neuen Prozess starten",
			"ongoing": "Laufende Aktivitäten",
			"currents": "Aktuelle Aktivitäten",
			"myActivities": "Meine Aktivitäten",
			"completed": "Abgeschlossene Aktivitäten",
			"stopped": "Gestoppte Aktivitäten"
		},

		"priority": {
			"self": "Prioritäten",
			"allPriority": "Alle Prioritäten"
		},


		"category": {
			"self": "Kategorien",
		},


		"list": {
			"empty": "Keine Aktivität gefunden!",

			"newTitle": "Starten",
			"returnTitle": "Zurückgeben",
			"continueTitle": "Weitermachen",
			"stopTitle": "Stoppen",
			"completeTitle": "Abschließen",

			"caption": "{action} von {activist}",
			"showDetail": "Details anzeigen"
		},

		"node": {
			"localCreateDate": "Datum",
			"createTime": "Uhrzeit",
			"dateTime": "Datum und Uhrzeit",
			"processNodeTitle": "Aktionstitel",
			"processNodeCaption": "Zusätzliche Aktionsbeschreibung",
			"comment": "Kommentare",
			"employee": "Aktivist",
			"attachments": "Anhänge",

			"continue": "Prozess fortsetzen",
			"complete": "Prozess abschließen",
			"return": "Zurückkehren",
			"returnTo": "Zurückkehren zu {label}",
			"stop": "Prozess stoppen",
			"pleaseWait": "Bitte warten, das System registriert die Aktion..."
		}
	}
}
