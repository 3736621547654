<template>
	<div class="kaveh-task">

		<task-progress v-if="state == 'progress'"
					   :timeTasks="timeTasks"
					   :totalTasksLength="totalTasksLength"
					   :currentTasksLength="currentTaskIndex"
					   :succeedTasksLength="succeedTasks.length"
					   :failedTasksLength="failedTasks.length"
					   :remainingMilliseconds="remainingMilliseconds" />

		<task-complete v-if="state == 'complete'"
					   :failedTasks="failedTasks"
					   :succeedTasks="succeedTasks"
					   v-on:close="close" />

		<task-preview v-if="state == 'preview'"
					  v-bind:entity="entity"
					  v-bind:data="data"
					  v-bind:label="label"
					  v-on:doTasks="taskRun"
					  v-on:cancel="$emit('cancel')" />

	</div>
</template>
<script>

	import taskPreview from './common/task-preview'
	import taskComplete from './common/task-complete'
	import taskProgress from './common/task-progress'
	export default {
		emits: ['complete', 'cancel', 'close'],

		props:
		{
			'entity': {
				type: Function
			},

			'task': {
				type: Function
			},

			'streamAddress': {
				type: String
			},

			'data': {
				type: Array
			},

			'label': {
				type: String
			}
		},

		data()
		{
			return {
				state: 'preview',
				failedTasks: [],
				succeedTasks: [],
				timeTasks: [],
				remainingMilliseconds: 0,
				currentTaskIndex: 0,
				totalTasksLength: (this.data || []).length,
			}
		},

		components: {
			'task-preview': taskPreview,
			'task-complete': taskComplete,
			'task-progress': taskProgress,
		},

		methods: {

			async taskRun()
			{
				var timeDiffs = [];
				this.state = "progress";
				const data = this.data || [];
				const run = (payload) => new Promise(async (resolve, reject) =>
				{
					var dt = Date.now();
					var diff = 0;

					try
					{
						const res = await this.task(payload);
						diff = Date.now() - dt;
						this.timeTasks.push(diff)
						if (res.status == 200)
						{
							resolve(res)
						}
						else
						{
							reject(res)
						}

					} catch (e)
					{
						diff = Date.now() - dt;
						this.timeTasks.push(diff)

						reject(e)
					}

					this.currentTaskIndex += 1;
					timeDiffs.push(diff);

					if (this.currentTaskIndex == 1 || this.currentTaskIndex % 10 == 0)
					{
						const average = timeDiffs.reduce((a, b) => a + b, 0) / timeDiffs.length;
						const remainingTasksLength = (data.length - this.currentTaskIndex);
						this.remainingMilliseconds = (remainingTasksLength * average);

						timeDiffs = []
					}
				})

				for (var i = 0; i < data.length; i++)
				{
					await run(data[i]).then((res) =>
					{
						this.succeedTasks.push(res);

					}).catch((error) =>
					{
						this.failedTasks.push({ error: error, index: i });
					})
				}

				this.completed()
			},

			completed()
			{
				setTimeout(() =>
				{
					this.state = 'complete';
					this.$emit('complete', {
						succeed: this.succeedTasks.length,
						failed: this.failedTasks.length
					});

				}, 1000);
			},

			close()
			{
				this.$emit('close')
			}
		}
	}
</script>
