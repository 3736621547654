<template>
	<div class="row">
		<div class="col">
			<div class="bg-light rounded p-3 mb-3">
				<div class="mb-2">
					<input type="text"
						   v-model="filter"
						   @keyup="filterChanged"
						   class="form-control border-0"
						   :placeholder="$loc.fromResource('action.searchPoint')" />
				</div>
				<kaveh-overlay :is-loading="state == 'loading'" :is-empty="branches.length == 0" class="col">
					<branch-employee-tree-node :is-multiple="isMultiple" :items="branches" @change="handleChange" />
				</kaveh-overlay>
			</div>
		</div>
	</div>
</template>
<script>
	import { array } from '@/plugins'
	import { BranchService } from '@/modules/hub/services'
	import BranchEmployeeTreeNode from './branch-employee-tree-node'

	export default {
		emits: ['change'],
		components: {
			'branch-employee-tree-node': BranchEmployeeTreeNode
		},

		props: {
			isMultiple: {
				type: Boolean,
				default: true,
			},

			value: {
				type:Array
			},
		},

		data()
		{
			return {
				filter: "",
				state: "loading",
				branches: [],
				flatBranches: [],
				flatHistoryBranches: [],
			}
		},

		async beforeMount()
		{
			await this.init();
			this.bindChecked(this.value || [])
		},

		methods: {

			async init()
			{
				try
				{
					const payload = {
						start: 0,
						length: 1000
					}

					var items = [];
					var response = await new BranchService().positionList(payload, { HSR: false });

					if (response.status == 200)
					{
						var items = response.data.items;
						this.bindData(items)
						this.flatBranches = items;
						this.flatHistoryBranches = items;
					}

				} catch (e)
				{
					this.state = "error";
				}
			},

			bindData(items)
			{
				this.state = items.length == 0 ? "empty" : "ready";
				this.branches = array.toNested(items, 'branchId', 'parentId')
			},

			bindChecked(items)
			{
				this.reset();

				items = items || []

				var branches =
					JSON.parse(JSON.stringify(this.flatHistoryBranches));

				if (items.length > 0 && branches.length > 0)
				{
					items.map((x) =>
					{
						const index = branches.findIndex((f) =>
						{
							return f.branchId == x.branchId
						});

						branches[index].checked = true;
					});

					this.flatHistoryBranches = branches;
					this.bindData(branches)
				}
			},

			bindCheckedChildren(item, checked, items)
			{
				items.map((x) =>
				{
					if (x.parentId == item.branchId)
					{
						if (!item.personId)
						{
							x.checked = checked;
							this.bindCheckedChildren(x, checked, items)
						}
					}

					if (x.branchId == item.branchId && x.positionId == item.positionId)
					{
						x.checked = checked;
					}
				});
			},

			bindCheckedParent(item, checked, items)
			{
				if (checked == false)
				{
					items.map((x) =>
					{
						if (x.branchId == item.parentId)
						{
							x.checked = checked;
							this.bindCheckedParent(x, checked, items)
						}
					});
				}
			},

			filterChanged(e)
			{
				const value = e.target.value
				const keys = ['branchName', 'personName'];

				this.bindData(array.filter(this.flatHistoryBranches, value, keys))
			},

			handleChange(node)
			{
				if (this.isMultiple)
				{
					var branches =
						JSON.parse(JSON.stringify(this.flatHistoryBranches));

					this.bindCheckedChildren(node, node.checked, branches);
					this.bindCheckedParent(node, node.checked, branches);
					this.bindData(branches)

					var checkeds = branches.filter((x) =>
					{
						return x.checked == true && x.branchTypeId == 3
					});

					this.flatHistoryBranches = branches;

					this.$emit('change', checkeds)
				}
				else
				{
					this.$emit('change', {
						branchId: node.branchId,
						branchName: node.branchName,
						tree: node.tree
					})
				}
			},

			reset()
			{
				this.bindData(this.flatBranches)
				this.flatHistoryBranches = this.flatBranches
			}
		}
	}
</script>
