//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseTrack } from '@/core/data'
import { FeatureGroup, FeatureInputType } from '@/modules/store/data/feature/list'; 
export default class Feature extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'feature';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'store.feature';
	props = {
		featureId: {
			primarykey: true,
			name: 'featureId',
			resourceKey: 'store.featureFeatureId',
			type: ENUMS.PROP_TYPE.INT32,
			isSearchable: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		title: {
			name: 'title',
			resourceKey: 'store.featureTitle',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: true,
				patterns: [],
			},
			errors: [],
			value: null
		},
		isPricingFeature: {
			name: 'isPricingFeature',
			resourceKey: 'store.featureIsPricingFeature',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			isSearchable: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		isProductFeature: {
			name: 'isProductFeature',
			resourceKey: 'store.featureIsProductFeature',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			isSearchable: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		defaultValue: {
			name: 'defaultValue',
			resourceKey: 'store.featureDefaultValue',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 250,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		featureInputTypeId: {
			foreignKey: true,
			name: 'featureInputTypeId',
			resourceKey: 'store.featureFeatureInputTypeId',
			type: ENUMS.PROP_TYPE.BYTE,
			isSearchable: true,
			serverAddress: '/store/feature-input-type/list',
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'featureInputTypeId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		featureGroupId: {
			foreignKey: true,
			name: 'featureGroupId',
			resourceKey: 'store.featureFeatureGroupId',
			type: ENUMS.PROP_TYPE.INT32,
			isSearchable: true,
			serverAddress: '/store/feature-group/list',
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'featureGroupId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		translations: {
			name: 'translations',
			resourceKey: 'store.featureTranslations',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		featureGroup: {
			name: 'featureGroup',
			resourceKey: 'store.featureFeatureGroup',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new FeatureGroup(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		featureInputType: {
			name: 'featureInputType',
			resourceKey: 'store.featureFeatureInputType',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new FeatureInputType(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		featureTrack: {
			name: 'featureTrack',
			resourceKey: 'store.featureFeatureTrack',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BaseTrack(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: 'data.track.summary',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		identifier: {
			name: 'identifier',
			resourceKey: 'store.featureIdentifier',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}