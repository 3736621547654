<template>
	<button v-if="isLoading" disabled>
		<span v-if="isLoadingType == 'message'">
			{{ $loc.fromResource('system.pleaseWaite') }}
		</span>
		<span v-else class="spinner-border text-dark" role="status">
			<span class="visually-hidden">Loading...</span>
		</span>
	</button>
	<button v-else :disabled="disabled">
		<slot></slot>
	</button>
</template>
<script>
	export default {
		props: {
			isLoading:
			{
				type: Boolean,
				default: false
			},

			isLoadingType:
			{
				type: String,
				default: 'message'
			},

			disabled:
			{
				type: Boolean,
				default: false
			},
		}
	}
</script>
