export default {
	path: 'process-node',
	name: 'aut.processNode.layout',
	redirect: to => {
		return {name: 'aut.process.list'};
	},
	component: () => import('@/modules/aut/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/aut/process-node"],
		resourceKey: 'aut.processLayout',
		menuConfig: {
			isVisible: false,
			iconName: 'bi-clipboard-pulse',
		}
	},
	children: [
//GeneratorFlag:[ProcessNodeManagement]"
		{
			path: 'management',
			name: 'aut.processNode.management',
			component: () => import('@/modules/aut/views/process-node/process-node-management.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.processNodeManagement',
				clientAccessKeys:["/aut/process-node/management"],
				serverAccessKeys: ["/aut/process-node/list","/aut/process-node/management","/aut/process/list","/aut/process-agent-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]

	]
}
