<template>
	<component :is="`letter-${action}`" @complete="complete" />
</template>
<script>
	import LetterCompose from './compose/letter-compose.vue'
	import LetterForward from './forward/letter-forward.vue'
	import LetterReply from './reply/letter-reply.vue'
	export default {

		components: {
			'letter-compose': LetterCompose,
			'letter-forward': LetterForward,
			'letter-reply': LetterReply,
		},

		data()
		{
			return {
				action: 'compose'
			}
		},

		beforeMount() {
			if (this.$route.query.action) {
				this.action = this.$route.query.action
			}
		},

		methods: {
			complete(payload) {
				this.$router.push({
					name: 'aut.letter.list',
					query: {
						letterBoxId: 2,
						letterCategoryId: payload.letterCategoryId
					}
				});
			}
		}
	}
</script>
