<template>

	<div class="kaveh-form-generator">
		<div class="panel-palette">
			<div class="head">
				{{$loc.fromResource('com.form.generator.head.controls')}}
				<kaveh-icon-bootstrap @click="getControlsFromJson" class="float-end" cursor="pointer" name="bi bi-upload" size="15px" />
			</div>
			<div class="body">
				<div class="palette-item" @click="previewAdd(item)" v-for="(item,index) in paletteItems" :key="index">
					<kaveh-icon-bootstrap class="me-4" :name="item.icon" size="22px" />
					<span>{{ $loc.fromResource(item.resourceKey) }}</span>
				</div>
				<div class="palette-item-submit text-center">
					<button class="btn bg-white border mt-4" @click="submit">
						{{$loc.fromResource('action.submitChange')}}
					</button>
				</div>
			</div>
		</div>
		<div class="panel-preview">
			<div class="head">
				{{$loc.fromResource('com.form.generator.head.preview')}}
			</div>
			<div class="body">
				<form-collapse mode="generator"
							   :entity="entity"
							   display-type="block"
							   :use-validation="false"
							   v-on:generator-up="previewUp"
							   v-on:generator-down="previewDown"
							   v-on:generator-remove="previewRemove"
							   v-on:change="handleConfigChange"
							   v-on:generator-select="previewSelect" />
			</div>
		</div>
		<div class="panel-config">
			<div class="head">
				{{$loc.fromResource('com.form.generator.head.config')}}
			</div>
			<div class="body" v-if="selectedName != ''">
				<div dir="ltr" class="mb-1 mt-4 row">
					<label class="col-12">
						ID: {{selectedName}}
					</label>
				</div>
				<div class="mb-3 row">
					<label class="col-12">
						<small>{{$loc.fromResource('com.form.generator.config.label')}}</small>
					</label>
					<div class="col-12">
						<span v-if="selectedType == 'lable'">
							<textarea @keyup="handleConfigChange" v-model="selectedConfig.label" type="text" class="form-control form-control-sm" autocomplete="off"></textarea>

						</span>
						<span v-else>

							<input @keyup="handleConfigChange" v-model="selectedConfig.label" type="text" class="form-control form-control-sm" autocomplete="off">
						</span>
					</div>
				</div>

				<div class="mb-1 row">
					<div class="col-12">
						<div class="form-check">
							<input class="form-check-input" type="checkbox" v-model="selectedConfig.useRequiredPattern" @change="handleConfigChange" id="requiredPattern" />
							<label class="form-check-label" for="requiredPattern">
								<small>{{$loc.fromResource('com.form.generator.config.patterns.required')}}</small>
							</label>
						</div>
					</div>
				</div>

				<div class="mb-1 row" v-if="selectedType == 'text' || selectedType == 'textarea' || selectedType == 'text-editor'">
					<div class="col-12">
						<div class="form-check">
							<input class="form-check-input" type="checkbox" v-model="selectedConfig.isUnicode" @change="handleConfigChange" id="isUnicode" />
							<label class="form-check-label" for="isUnicode">
								<small>{{$loc.fromResource('com.form.generator.config.isUnicode')}}</small>
							</label>
						</div>
					</div>
				</div>
				<div class="mb-1 row">
					<div class="col-12">
						<div class="form-check">
							<input class="form-check-input" type="checkbox" v-model="selectedConfig.useMobilePattern" @change="handleConfigChange" id="mobilePattern" />
							<label class="form-check-label" for="mobilePattern">
								<small>{{$loc.fromResource('com.form.generator.config.patterns.mobile')}}</small>
							</label>
						</div>
					</div>
				</div>
				<div class="mb-1 row">
					<div class="col-12">
						<div class="form-check">
							<input class="form-check-input" type="checkbox" v-model="selectedConfig.useNationalcodePattern" @change="handleConfigChange" id="nationalCodePattern" />
							<label class="form-check-label" for="nationalCodePattern">
								<small>{{$loc.fromResource('com.form.generator.config.patterns.nationalCode')}}</small>
							</label>
						</div>
					</div>
				</div>
				<div class="mb-1 row">
					<div class="col-12">
						<div class="form-check">
							<input class="form-check-input" type="checkbox" v-model="selectedConfig.useEmailPattern" @change="handleConfigChange" id="emailPattern" />
							<label class="form-check-label" for="emailPattern">
								<small>{{$loc.fromResource('com.form.generator.config.patterns.email')}}</small>
							</label>
						</div>
					</div>
				</div>
				<div class="mb-1 row" v-if="selectedType == 'select'">
					<label class="col-12">
						<small>{{$loc.fromResource('com.form.generator.config.select.serverAddress')}}</small>
					</label>
					<div class="col-12">
						<input dir="ltr" @keyup="handleConfigChange" v-model="selectedConfig.serverAddress" type="text" class="form-control form-control-sm" autocomplete="off">
					</div>
				</div>
				<div class="mb-1 row" v-if="selectedType == 'select'">

					<div class="col-12">
						<div class="border p-2" style="max-height:300px; overflow-y:auto;">
							<div class="mb-1">
								<label>{{$loc.fromResource('com.form.generator.config.select.head')}}</label>
							</div>
							<template v-for="(item, index) in selectedConfig.data" :key="index">
								<div class="mb-1 row">
									<div class="col-12">
										<input @keyup="handleConfigChange" v-model="selectedConfig.data[index].text" type="text" class="form-control form-control-sm" autocomplete="off" :placeholder="$loc.fromResource('com.form.generator.config.select.text')">
									</div>
								</div>
								<div class="mb-3 row">
									<div class="col-9">
										<input @keyup="handleConfigChange" v-model="selectedConfig.data[index].value" type="number" class="form-control form-control-sm" autocomplete="off" :placeholder="$loc.fromResource('com.form.generator.config.select.value')">
									</div>
									<div class="col-3">
										<button class="btn btn-sm bg-white border" @click="removeDropItem(index)">
											<kaveh-icon-bootstrap color="red" name="bi-trash" size="12px" />
										</button>
									</div>
								</div>
							</template>
							<div class="mb-1 row">
								<div class="col-12">
									<button class="btn btn-sm bg-white border" @click="addDropItem">
										<kaveh-icon-bootstrap name="bi-node-plus" size="14px" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { array } from '@/plugins'
	import { ENUMS } from '@/core/data'
	import { BaseEntity } from '@/core/data/entities'
	import { numberToWords } from "@persian-tools/persian-tools";
	import formCollapse from './form-collapse'

	export default {
		emits: ['submit'],
		components:
		{
			'form-collapse': formCollapse
		},

		props: {
			'props': {
				type: Object,
			}
		},

		data()
		{

			return {
				selectedName: '',
				selectedType: '',
				selectedConfig: {
					useEmailPattern: false,
					useRequiredPattern: false,
					useMobilePattern: false,
					useNationalcodePattern: false,
					isMultiple: false,
					data: [],
					serverAddress: '',
					isUnicode: false,
					label: '',
				},
				paletteItems: [
					{
						icon: 'bi-input-cursor-text',
						type: ENUMS.PROP_TYPE.STRING,
						inputType: ENUMS.INPUT_TYPE.TEXT,
						resourceKey: 'com.form.generator.controls.text',
					},
					{
						icon: 'bi-123',
						type: ENUMS.PROP_TYPE.INT32,
						inputType: ENUMS.INPUT_TYPE.NUMBER,
						resourceKey: 'com.form.generator.controls.number',
					},
					{
						icon: 'bi-textarea-resize',
						type: ENUMS.PROP_TYPE.STRING,
						inputType: ENUMS.INPUT_TYPE.TEXTAREA,
						resourceKey: 'com.form.generator.controls.textarea',
					},
					{
						icon: 'bi-key',
						type: ENUMS.PROP_TYPE.STRING,
						resourceKey: 'com.form.generator.controls.password',
						inputType: ENUMS.INPUT_TYPE.PASSWORD,
					},
					{
						icon: 'bi-arrow-bar-down',
						type: ENUMS.PROP_TYPE.INT32,
						inputType: ENUMS.INPUT_TYPE.SELECT,
						resourceKey: 'com.form.generator.controls.select',
					},
					{
						icon: 'bi-ui-radios',
						type: ENUMS.PROP_TYPE.BOOLEAN,
						inputType: ENUMS.INPUT_TYPE.CHECKDROP,
						resourceKey: 'com.form.generator.controls.checkdrop',
					},
					{
						icon: 'bi-palette',
						type: ENUMS.PROP_TYPE.STRING,
						inputType: ENUMS.INPUT_TYPE.COLOR,
						resourceKey: 'com.form.generator.controls.color',
					},
					{
						icon: 'bi-calendar-date',
						type: ENUMS.PROP_TYPE.STRING,
						inputType: ENUMS.INPUT_TYPE.DATA_PICKER,
						resourceKey: 'com.form.generator.controls.datePicker',
					},
					{
						icon: 'bi-clock',
						type: ENUMS.PROP_TYPE.STRING,
						inputType: ENUMS.INPUT_TYPE.TIME_PICKER,
						resourceKey: 'com.form.generator.controls.timePicker',
					},
					{
						icon: 'bi-pen',
						type: ENUMS.PROP_TYPE.STRING,
						inputType: ENUMS.INPUT_TYPE.SIGNATURE_PAD,
						resourceKey: 'com.form.generator.controls.signaturePad',
					},
					{

						icon: 'bi bi-textarea-t',
						type: ENUMS.PROP_TYPE.STRING,
						inputType: ENUMS.INPUT_TYPE.TEXT_EDITOR,
						resourceKey: 'com.form.generator.controls.textEditor',
					},
					{
						icon: 'bi bi-grip-horizontal',
						type: ENUMS.PROP_TYPE.STRING,
						inputType: ENUMS.INPUT_TYPE.HR,
						resourceKey: 'com.form.generator.controls.hr',
					},
					{
						icon: 'bi bi-type-h3',
						type: ENUMS.PROP_TYPE.STRING,
						inputType: ENUMS.INPUT_TYPE.HEADING,
						resourceKey: 'com.form.generator.controls.h3',
					}
					,
					{
						icon: 'bi bi-tag-fill',
						type: ENUMS.PROP_TYPE.STRING,
						inputType: ENUMS.INPUT_TYPE.LABLE,
						resourceKey: 'com.form.generator.controls.lable',
					}
				],
				entity: null,
			}
		},

		async beforeMount()
		{

			this.entity = new BaseEntity();
			this.entity.resourceKey = "com.form.generator.entityName"
			this.entity.props = this.props || {}
		},

		methods:
		{

			handleConfigChange(x)
			{
				this.selectedConfig.value = x.value;
				var name = this.selectedName;
				var prop = this.entity.props[name];

				prop.label = this.selectedConfig.label;
				prop.setConfig.patterns = [];

				if (this.selectedConfig.useEmailPattern) { prop.setConfig.patterns.push('email'); }
				if (this.selectedConfig.useMobilePattern) { prop.setConfig.patterns.push('mobile'); }
				if (this.selectedConfig.useRequiredPattern) { prop.setConfig.patterns.push('required'); }
				if (this.selectedConfig.useNationalcodePattern) { prop.setConfig.patterns.push('nationalCode'); }

				if (prop.setConfig.inputType == 'number') { prop.setConfig.patterns.push('number') }
				if (prop.setConfig.inputType == 'time-picker') { prop.setConfig.patterns.push('time'); }
				if (prop.setConfig.inputType == 'date-picker') { prop.setConfig.patterns.push('date'); }
				if (this.selectedConfig.value)
				{
					prop.value = this.selectedConfig.value;
				}


				prop.setConfig.isUnicode =
					this.selectedConfig.isUnicode;

				if (prop.setConfig.inputType == 'select')
				{
					prop.data = this.selectedConfig.data
					prop.setConfig.isMultiple = this.selectedConfig.isMultiple;
					prop.serverAddress = this.selectedConfig.serverAddress
				}
				prop.value = x.value;
				this.entity.props[name] = prop;
			},

			addDropItem()
			{
				var name = this.selectedName;
				var item = this.getNewDropItem();

				this.selectedConfig.data.push(item)
				this.entity.props[name].data = this.selectedConfig.data
			},

			removeDropItem(index)
			{

				var name = this.selectedName;

				this.selectedConfig.data.splice(index, 1)
				this.entity.props[name].data = this.selectedConfig.data
			},

			getNewDropItem()
			{
				const length =
					this.selectedConfig.data.length + 1;

				return {
					text: this.$loc.fromResource(
						'com.form.generator.config.select.textNumber', {
						label: numberToWords(length)
					}),
					value: length
				};
			},

			addPattern(pattern)
			{
				this.selectedConfig.value = null;
				const name = this.selectedName;
				var patterns = this.entity.props[name].setConfig.patterns;

				const index = patterns.findIndex((x) =>
				{
					return x == pattern
				});

				if (index == -1)
				{
					patterns.push(pattern);
					this.entity.props[name].setConfig.patterns = patterns
				}
			},

			removePattern(pattern)
			{
				const name = this.selectedName;
				var patterns = this.entity.props[name].setConfig.patterns;

				const index = patterns.findIndex((x) =>
				{
					return x == pattern
				});

				if (index > -1)
				{
					patterns.splice(index, 1);
					this.entity.props[name].setConfig.patterns = patterns
				}
			},

			previewAdd(item)
			{
				const propertyType = item.type;
				const inputType = item.inputType;
				const name = Date.now().toString();
				const label = this.$loc.fromResource(item.resourceKey);

				const prop = {
					name: name,
					label: label,
					type: propertyType,
					display: {
						isVisible: true,
						direction: 'rtl',
					},
					setConfig: {
						inputType: inputType,
						canUseInForm: true,
						canUseInExcel: true,
						isUnicode: false,
						patterns: [],
					},
					generatorConfig: {
						selected: false,
						isFirst: false,
						isLast: false
					}
				}

				if (inputType == 'select')
				{
					prop.data = []
					prop.setConfig.textKey = 'text';
					prop.setConfig.valueKey = 'value';
				}

				if (inputType == 'text' || inputType == 'textarea' || inputType == 'text-editor')
				{
					prop.setConfig.isUnicode = true;
				}

				this.entity.props[name] = prop
				this.bindOrder()
			},

			previewRemove(name)
			{
				if (confirm(this.$loc.fromResource('com.form.generator.controls.removeConfirm')))
				{
					var keys = Object.keys(this.entity.props);
					keys.splice(keys.findIndex((x) =>
					{
						return x == name;
					}), 1);

					this.reBindProps(keys)
				}
			},

			previewUp(name)
			{
				var keys = Object.keys(this.entity.props);
				var currentIndex = keys.findIndex((x) => { return x == name });
				var newKeys = array.move(keys, currentIndex, currentIndex - 1);

				this.reBindProps(newKeys)
			},

			previewDown(name)
			{
				var keys = Object.keys(this.entity.props);
				var currentIndex = keys.findIndex((x) => { return x == name });
				var newKeys = array.move(keys, currentIndex, currentIndex + 1);

				this.reBindProps(newKeys)
			},

			async previewSelect(name)
			{

				if (this.selectedName != name)
				{
					this.selectedConfig.value = null;
					var prop =
						this.entity.props[name];
					this.selectedConfig.value = prop.value;

					this.selectedConfig.label = prop.label;
					this.selectedConfig.useRequiredPattern =
						prop.setConfig.patterns.findIndex((x) => { return x == 'required' }) > -1

					this.selectedConfig.useMobilePattern =
						prop.setConfig.patterns.findIndex((x) => { return x == 'mobile' }) > -1

					this.selectedConfig.useNationalcodePattern =
						prop.setConfig.patterns.findIndex((x) => { return x == 'nationalCode' }) > -1

					this.selectedConfig.useEmailPattern =
						prop.setConfig.patterns.findIndex((x) => { return x == 'email' }) > -1

					if (prop.setConfig.inputType == 'select')
					{
						this.selectedConfig.data = prop.data;
						this.selectedConfig.serverAddress = prop.serverAddress;
					}

					this.selectedConfig.isUnicode =
						prop.setConfig.isUnicode;

					this.deselectProps();
					this.selectedName = name;
					this.selectedType = prop.setConfig.inputType;
					this.entity.props[name].generatorConfig.selected = true
				}
			},

			deselectProps()
			{
				const keys =
					Object.keys(this.entity.props);

				for (var i = 0; i < keys.length; i++)
				{
					const key = keys[i];
					this.entity.props[key].generatorConfig.selected = false
				}

				this.selectedName = "";
				this.selectedType = "";

			},

			reBindProps(keys)
			{
				var props = {};
				for (var i = 0; i < keys.length; i++)
				{
					const key = keys[i];
					props[key] = this.entity.props[key]
					props[key].generatorConfig = props[key].generatorConfig || {};
				}

				this.entity.props = props
				this.bindOrder()
			},

			bindOrder()
			{
				const keys = Object.keys(this.entity.props)
				for (var i = 0; i < keys.length; i++)
				{
					this.entity.props[keys[i]].generatorConfig.isFirst = i == 0
					this.entity.props[keys[i]].generatorConfig.isLast = i == (keys.length - 1)
				}
			},

			async getControlsFromJson()
			{
				var props = {};
				var value = window.prompt('please paste form template');

				if (value)
				{
					try
					{
						var jsonVale = JSON.parse(value)
						var keys = Object.keys(jsonVale)
						for (var i = 0; i < keys.length; i++)
						{
							var newProp = await new Promise((resolve) =>
							{
								setTimeout(() =>
								{
									const item = jsonVale[keys[i]]
									const name = Date.now().toString();
									const label = this.$loc.fromResource(item.resourceKey);

									var prop = {
										name: name,
										label: label,
										type: item.type,
										display: {
											isVisible: true,
											direction: item.display.direction,
										},
										setConfig: {
											inputType: item.setConfig.inputType,
											canUseInForm: true,
											canUseInExcel: true,
											isUnicode: item.setConfig.isUnicode,
											patterns: item.setConfig.patterns,
										},
										generatorConfig: {
											selected: false,
											isFirst: false,
											isLast: false
										},

									};

									if (item.setConfig.inputType == 'select')
									{
										prop.data = item.data
										prop.serverAddress = item.serverAddress
										prop.setConfig.textKey = item.setConfig.textKey;
										prop.setConfig.valueKey = item.setConfig.valueKey;
									}

									resolve(prop)
								}, 10)
							})

							props[newProp.name] = newProp
						}
						this.entity.props = props;
						this.bindOrder()

					} catch (e)
					{
						alert("The format is invalid")
					}
				}
			},

			submit()
			{

				this.$emit('submit', this.entity.props);
			}
		}
	}
</script>
<style>
	.kaveh-form-generator {
		width: 100%;
		background: #fff;
	}

		.kaveh-form-generator,
		.kaveh-form-generator .panel-palette,
		.kaveh-form-generator .panel-config,
		.kaveh-form-generator .panel-preview {
			width: 100%;
			height: 100%;
			float: right;
		}

			.kaveh-form-generator .panel-palette .head,
			.kaveh-form-generator .panel-config .head,
			.kaveh-form-generator .panel-preview .head {
				padding: 10px;
				background: #fff;
				border-bottom: #ddd solid 1px;
				color: var(--kaveh-base-color);
				text-align: center;
			}

			.kaveh-form-generator .panel-palette .body,
			.kaveh-form-generator .panel-config .body,
			.kaveh-form-generator .panel-preview .body {
				height: 100%;
				overflow-y: auto;
				overflow-x: hidden;
			}

			.kaveh-form-generator .panel-config,
			.kaveh-form-generator .panel-palette {
				background: #f8f8f8;
			}


			.kaveh-form-generator .panel-palette {
				width: 200px;
			}

				.kaveh-form-generator .panel-palette .palette-item {
					width: 100%;
					padding: 5px 20px;
					border-bottom: #ddd solid 1px;
					cursor: pointer;
				}


					.kaveh-form-generator .panel-palette .palette-item:hover {
						background: #eaeaea;
					}



			.kaveh-form-generator .panel-preview {
				width: calc(100% - 500px);
				border-right: #ddd solid 1px;
				border-left: #ddd solid 1px;
			}

				.kaveh-form-generator .panel-preview .body {
					padding: 0 10px;
				}

			.kaveh-form-generator .panel-config {
				width: 300px;
			}

				.kaveh-form-generator .panel-config .body {
					padding: 0 10px;
				}
</style>
