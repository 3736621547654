<template>
	<kaveh-content width="800px" :is-loading="temp == null">
		<kaveh-form :key="ver" :entity="entity" :task="submit" :value="temp"/>
	</kaveh-content>
</template>
<script>
	import  Branch  from './data/branch.js'
	import { BranchService } from '@/modules/hub/services'
	export default {
		data()
		{
			return {
				ver: 0,
				temp: null,
			}
		},
		computed: 
		{
			entity()
			{
				return Branch
			},
			service()
			{
				return new BranchService()
			},
		},

		async beforeMount()
		{
			await this.init()
		},

		methods:
		{
			async init()
			{
				this.temp = await this.getDataFromTemp();

				if (this.temp == undefined)
				{
					this.$router.push({
						name: 'hub.branch.list'
					})
				}
			},

			async getDataFromTemp()
			{
				const store = this.$store;
				const router = this.$route;
				const temp =
					await store.dispatch(
						'temp/findByQuery', router.query
					);

				return temp ? temp.value : undefined;
			},

			async submit(payload)
			{
				try
				{
					const res = await this.service.create(payload);
					if (res.status == 200)
					{
						this.$router.push({
							name: 'hub.branch.list'
						})
					}

				} catch (e) { console.log(e) }
			},
		}
	}
</script>
