<template>
	<textarea v-bind:id="prop.id"
			  v-on:change="onHandelChange"
			  v-on:keyup="onHandelKeyUp"
			  v-bind:dir="direction"
			  v-model="value"
			  ref="input"
			  rows="3"
			  class="form-control" />

	<div class="row row-cols-auto mt-2" v-for="(word,i) in words" :key="i">
		<div class="col-12">
			<small>{{ $loc.fromResource(word.res) }}</small>
		</div>
		<div class="col-12">
			<button dir="ltr" class="bt btn-sm btn-light border mb-1 me-1 ps-3 pe-3"
					v-for="(item,i2) in word.items"
					v-on:click="addWord(item)"
					v-bind:key="i2">
				{{ $loc.fromResource(item.res) }}
			</button>
		</div>
	</div>
</template>
<script>
	export default {
		emits: ['change', 'keyup', 'submit'],

		props: {
			'prop': {
				type: Object
			},
			
			'languageDirection': {
				type: String,
				default: undefined
			},
		},

		data()
		{
			return {
				value: null,
				words: []
			}
		},

		beforeMount()
		{
			this.value = this.prop.value

			if (this.prop.data) { this.words = this.prop.data }
		},


		computed:
		{
			direction()
			{
				if (this.$store.state.app.direction == 'ltr' || this.languageDirection == 'ltr')
				{
					return 'ltr'
				}

				return this.prop.display.direction;
			}
		},

		methods: {

			addWord(item)
			{
				var startPos = this.$refs.input.selectionStart
				var endPos = this.$refs.input.selectionEnd
				var value = this.value || ''

				this.$refs.input.focus()
				this.$refs.input.selectionStart = startPos

				this.value =
					value.substring(0, startPos) + item.value +
					value.substring(endPos, value.length)
			},

			onHandelChange()
			{
				this.$emit('change', this.value)
			},

			onHandelKeyUp(e) {
				if (e.key === 'Enter' || e.keyCode === 13) {
					this.$emit('submit')
				}
				else {
					this.$emit('keyup', this.value)
				}
			}
		}
	}
</script>
