<template>
	<kaveh-popup width="1200px" v-if="isShow" :close="() => {isShow = false}" :confirm-when-close="true">
		<activity-compose v-if="branchId > 0"  @complete="complete" />
		<branch-choose v-else />
	</kaveh-popup>
</template>
<script>
	import ActivityCompose from '@/modules/aut/views/activity/compose/activity-compose.vue'
	import BranchChoose from '@/modules/hub/views/branch/branch-choose.vue'
	export default {
		components: {
			'activity-compose': ActivityCompose,
			'branch-choose': BranchChoose
		},

		data() {
			return {
				isShow: true,
			}
		},

		computed: {
			branchId()
			{
				return this.$store.state.client.branchId || 0
			}
		},

		methods: {
			complete() {
				this.isShow = false;
			}
		}
	}
</script>
