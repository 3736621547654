<template>
	<ul class="kaveh-tree-ul" v-bind:class="{'kaveh-tree': isRoot }" v-bind:style="style">
		<slot></slot>
	</ul>
</template>
<script>
	export default {
		props: {
			isRoot: {
				type: Boolean,
				default: false
			},
			height: {
				type: String,
				default: "200px"
			},
		},

		computed: {
			style()
			{
				var style = {
					height: this.height,
					overflow: 'unset'
				}

				if (this.height == 'unset')
				{
					style.overflow = 'unset'
				}
				else
				{
					style.overflow = 'auto'
				}

				if (!this.isRoot)
				{
					style.height = 'unset'
					style.overflow = 'unset'

				}

				return style;
			}
		}

	}
</script>
<style>
	.kaveh-tree {
		list-style: none;
		user-select: none;
		padding: 0 30px 0 0;
		padding-right: 20px;
	}

		.kaveh-tree .kaveh-tree-ul {
			list-style: none;
			position: relative;
			padding-right: 20px;
		}

			.kaveh-tree .kaveh-tree-ul::before {
				content: "";
				position: absolute;
				top: -5px;
				right: 5px;
				width: 1px;
				height: calc(100% + 10px);
				border-right: var(--kaveh-base-color) solid 1px;
			}


			.kaveh-tree .kaveh-tree-ul .kaveh-tree-li {
				min-height: 30px;
				position: relative;
			}

				.kaveh-tree .kaveh-tree-ul .kaveh-tree-li::before {
					content: "";
					position: absolute;
					top: 12px;
					right: -15px;
					height: 1px;
					width: 15px;
					border-bottom: var(--kaveh-base-color) solid 1px;
				}


		.kaveh-tree .node .meta {
			width: 100%;
			padding: 5px 0px;
			position: relative;
		}

			.kaveh-tree .node .meta .arrow {
				width: 14px;
				height: 14px;
				line-height: 14px;
				right: -20px;
				top: 5px;
				position: absolute;
				background: #ddd;
				border-radius: 100%;
				z-index: 1;
			}



	[dir="ltr"] .kaveh-tree {
		padding-right: unset;
		padding-left: 20px;
	}

		[dir="ltr"] .kaveh-tree .kaveh-tree-ul {
			padding-right: unset;
			padding-left: 20px;
		}

			[dir="ltr"] .kaveh-tree .kaveh-tree-ul::before {
				right: unset;
				left: 5px;
			}


			[dir="ltr"] .kaveh-tree .kaveh-tree-ul .kaveh-tree-li::before {
				right: unset;
				left: -15px;
			}

		[dir="ltr"] .kaveh-tree .node .meta .arrow {
			right: unset;
			left: -20px;
		}
</style>
