export default {

	download: (obj) =>
	{
		fetch(obj.dataUrl)
			.then(response => response.blob())
			.then(blob =>
			{
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = obj.fileName;
				link.click();
			}).catch(console.error);
	}
}
