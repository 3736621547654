<template>
	<tr :class="{'checked':data.checked}">
		<td v-if="selectable">
			<input type="checkbox"
				   class="form-check-input"
				   v-on:change="check"
				   v-model="data.checked"
				   v-bind:checked="data.checked" />
		</td>
		<td>
			<span v-if="entityHasParent" v-on="getChildren">ch</span>
		</td>
		<td v-if="showRowNumber">
			<span>{{ ((currentPage - 1) * pageSize) + (index + 1) }}</span>
		</td>
		<td v-for="(prop) in getProps(data)" :key="prop.name">
			<table-cell :data="prop" :showError="showError" />
		</td>
	</tr>

	<template v-if="data.showChildren">
		<row-tree v-for="(item,index) in children"
				   v-on:checked="check"
				   :key="index"
				   :selectable="selectable"
				   :showRowNumber="showRowNumber"
				   :currentPage="currentPage"
				   :pageSize="pageSize"
				   :columns="columns"
				   :task="task"
				   :index="index"
				   :data="item" />
	</template>
</template>
<script>

	import tableCell from '../cells/table-cell'
	export default {
		emits: ['checked'],
		components:
		{
			'table-cell': tableCell,
		},

		props:
		{
			'data': Object,
			'entity': Function,
			'currentPage': Number,
			'selectable': Boolean,
			'pageSize': Number,
			'columns': Array,
			'index': Number,
			'task':Function,
			'level': {
				type: Number,
				default:0
			},

			'showRowNumber': {
				type: Boolean,
				default: true
			},

			'showError': {
				type: Boolean,
				default: false
			},

			'showForeignKey': {
				type: Boolean,
				default: false
			}
		},

		data()
		{
			return {
				children:[]
			}
		},

		computed:
		{
			entityHasParent()
			{
				for (var key in this.data.props)
				{
					if (key == "parentId")
					{
						return true;
					}
				}

				return false;
			}
		},

		methods:
		{

			getProps(entity)
			{
				var props = []
				entity = entity || new this.entity({})
				for (var key in entity.props)
				{
					const prop = entity.props[key];

					if (prop.foreignKey && this.showForeignKey == false)
						continue;

					if (!prop.display.isVisible || (this.columns || []).indexOf(key) == -1)
						continue;

					props.push(entity.props[key])
				}

				return props
			},

			async getChildren()
			{
				const parentId =
					this.data.props['parentId'].value

				payload = {
					start: 0,
					length: 1000,
					filter: {
						parentId: parentId
					}
				}

				var items = [];
				var data = await this.task(payload);

				if (data)
				{
					for (var item of data.items || [])
					{
						items.push(new this.entity({ value: item }))
					}

					this.children = items;
				}
			},

			check()
			{
				this.$emit('checked')
			},

		},
	}
</script>
