//GeneratorFlag:[NoChange]
export default {
	path: 'static-page',
	name: 'cms.staticPage.layout',
	redirect: to => {
		return {name: 'cms.staticPage.list'};
	},
	component: () => import('@/modules/cms/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/cms/static-page"],
		resourceKey: 'cms.staticPageLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[StaticPageCreate]"
		{
			path: 'create',
			name: 'cms.staticPage.create',
			component: () => import('@/modules/cms/views/static-page/static-page-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.staticPageCreate',
				clientAccessKeys:["/cms/static-page/create"],
				serverAccessKeys:["/cms/static-page/create","/cms/static-page/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StaticPageCreateImport]"
		{
			path: 'create-import',
			name: 'cms.staticPage.createImport',
			component: () => import('@/modules/cms/views/static-page/static-page-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.staticPageCreateImport',
				clientAccessKeys:["/cms/static-page/create-import"],
				serverAccessKeys:["/cms/static-page/create","/cms/static-page/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StaticPageDelete]"
		{
			path: 'delete',
			name: 'cms.staticPage.delete',
			component: () => import('@/modules/cms/views/static-page/static-page-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.staticPageDelete',
				clientAccessKeys:["/cms/static-page/delete"],
				serverAccessKeys:["/cms/static-page/delete","/cms/static-page/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StaticPageDetail]"
		{
			path: 'detail',
			name: 'cms.staticPage.detail',
			component: () => import('@/modules/cms/views/static-page/static-page-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.staticPageDetail',
				clientAccessKeys:["/cms/static-page/detail"],
				serverAccessKeys:["/cms/static-page/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StaticPageList]"
		{
			path: 'list',
			name: 'cms.staticPage.list',
			component: () => import('@/modules/cms/views/static-page/static-page-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.staticPageList',
				clientAccessKeys:["/cms/static-page/list"],
				serverAccessKeys:["/cms/static-page/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StaticPageUpdate]"
		{
			path: 'update',
			name: 'cms.staticPage.update',
			component: () => import('@/modules/cms/views/static-page/static-page-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.staticPageUpdate',
				clientAccessKeys:["/cms/static-page/update"],
				serverAccessKeys:["/cms/static-page/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StaticPageImageAttachment]"
		{
			path: 'image-attachment',
			name: 'cms.staticPage.imageAttachment',
			component: () => import('@/modules/cms/views/static-page/static-page-image-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.staticPageImageAttachment',
				clientAccessKeys:["/cms/static-page/image-attachment"],
				serverAccessKeys:["/cms/static-page/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}