//GeneratorFlag:[NoChange]
export default {
	path: 'province',
	name: 'hub.geographical.province.layout',
	redirect: to => {
		return {name: 'hub.province.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/geographical/province"],
		resourceKey: 'hub.provinceLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-geo-fill',
		}
	},
	children: [
//GeneratorFlag:[ProvinceCreate]"
		{
			path: 'create',
			name: 'hub.province.create',
			component: () => import('@/modules/hub/views/province/province-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.provinceCreate',
				clientAccessKeys:["/hub/geographical/province/create"],
				serverAccessKeys:["/hub/province/create","/hub/province/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProvinceCreateImport]"
		{
			path: 'create-import',
			name: 'hub.province.createImport',
			component: () => import('@/modules/hub/views/province/province-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.provinceCreateImport',
				clientAccessKeys:["/hub/geographical/province/create-import"],
				serverAccessKeys:["/hub/province/create","/hub/province/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProvinceDelete]"
		{
			path: 'delete',
			name: 'hub.province.delete',
			component: () => import('@/modules/hub/views/province/province-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.provinceDelete',
				clientAccessKeys:["/hub/geographical/province/delete"],
				serverAccessKeys:["/hub/province/delete","/hub/province/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProvinceDetail]"
		{
			path: 'detail',
			name: 'hub.province.detail',
			component: () => import('@/modules/hub/views/province/province-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.provinceDetail',
				clientAccessKeys:["/hub/geographical/province/detail"],
				serverAccessKeys:["/hub/province/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProvinceList]"
		{
			path: 'list',
			name: 'hub.province.list',
			component: () => import('@/modules/hub/views/province/province-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.provinceList',
				clientAccessKeys:["/hub/geographical/province/list"],
				serverAccessKeys:["/hub/province/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProvinceAttachment]"
		{
			path: 'attachment',
			name: 'hub.province.attachment',
			component: () => import('@/modules/hub/views/province/province-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.provinceAttachment',
				clientAccessKeys:["/hub/geographical/province/attachment"],
				serverAccessKeys:["/hub/province/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProvinceUpdate]"
		{
			path: 'update',
			name: 'hub.province.update',
			component: () => import('@/modules/hub/views/province/province-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.provinceUpdate',
				clientAccessKeys:["/hub/geographical/province/update"],
				serverAccessKeys:["/hub/province/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}