//GeneratorFlag:[NoChange]
import branchRoute from './branch-route'; //GeneratorFlag:[Branch]
import personRoute from './person-route'; //GeneratorFlag:[Person]
import geographicalCityRoute from './geographical/city-route'; //GeneratorFlag:[GeographicalCity]
import geographicalCountryRoute from './geographical/country-route'; //GeneratorFlag:[GeographicalCountry]
import basisKeyValueRoute from './basis/key-value-route'; //GeneratorFlag:[BasisKeyValue]
import basisPersonMilitaryRoute from './basis/person-military-route'; //GeneratorFlag:[BasisPersonMilitary]
import basisPersonNationalityRoute from './basis/person-nationality-route'; //GeneratorFlag:[BasisPersonNationality]
import basisPersonRoleRoute from './basis/person-role-route'; //GeneratorFlag:[BasisPersonRole]
import geographicalProvinceRoute from './geographical/province-route'; //GeneratorFlag:[GeographicalProvince]
import geographicalRegionRoute from './geographical/region-route'; //GeneratorFlag:[GeographicalRegion]
import basisTenantRoute from './basis/tenant-route'; //GeneratorFlag:[BasisTenant]
import accessAgentRoute from './access-agent-route'; //GeneratorFlag:[AccessAgent]
//GeneratorFlag:[NewImport]
const routes = [{
	path: 'hub',
	name: 'hub',
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		clientAccessKeys:['/hub'],
		serverAccessKeys:[],
		resourceKey: 'service.hub',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-intersect',
		}
	},
	children: [
branchRoute, personRoute, accessAgentRoute, //GeneratorFlag:[NewChild]
		//GeneratorFlag:[GeographicalLayout]
		{
			path: 'geographical',
			name: 'hub.geographical',
			component: () => import('@/modules/hub/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.layout.geographical',
				clientAccessKeys:['/hub/geographical'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-geo-alt-fill',
				}
			},
			children: [
			geographicalCityRoute, geographicalCountryRoute, geographicalProvinceRoute, geographicalRegionRoute, //GeneratorFlag:[NewGeographicalChild]
			]
		},
		//GeneratorFlag:[BasisLayout]
		{
			path: 'basis',
			name: 'hub.basis',
			component: () => import('@/modules/hub/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.layout.basis',
				clientAccessKeys:['/hub/basis'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-database-fill-gear',
				}
			},
			children: [
			basisKeyValueRoute, basisPersonMilitaryRoute, basisPersonNationalityRoute, basisPersonRoleRoute, basisTenantRoute, //GeneratorFlag:[NewBasisChild]
			]
		},
//GeneratorFlag:[NewLayout]
	]
}]
export default routes
export{
branchRoute, personRoute, geographicalCityRoute, geographicalCountryRoute, basisKeyValueRoute, basisPersonMilitaryRoute, basisPersonNationalityRoute, basisPersonRoleRoute, geographicalProvinceRoute, geographicalRegionRoute, basisTenantRoute, accessAgentRoute, //GeneratorFlag:[NewExport]
}