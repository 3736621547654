<template>
	<kaveh-tree :isRoot="isRoot" height="unset">
		<kaveh-tree-node v-for="(node,index) in nodes" :key="index" :is-expand="isRoot">
			<div class="border-base-color rounded pt-2 pb-2 ps-3 pe-3 bg-gray d-inline-block">

				<span class="me-2">
					<kaveh-icon-bootstrap :name="getIcon(node)" size="15px" />
				</span>

				<span v-if="node.personId" class="me-2">
					<span> {{node.branchName}}</span>&nbsp;<span class="text-muted">- {{node.personName}}</span>
					<div v-if="node.positions" class="position-absolute bottom-0 start-0 p-1">
						<div class="btn-group">
							<button type="button" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
								<kaveh-icon-bootstrap name="bi-people-fill" size="18px" />
								<span class="badge rounded-pill text-bg-light ms-2">{{node.positions.length}}</span>
							</button>
							<ul class="dropdown-menu dropdown-menu-end">
								<li class="cursor-pointer" v-for="(position, positionIndex) in node.positions" :key="positionIndex">
									<div class="dropdown-item">
										<span class="me-2">
											<kaveh-icon-bootstrap name="bi-person" size="18px" />
										</span>
										<span>
											{{position.personName}}
										</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</span>
				<span v-else class="me-2">
					<span> {{node.branchName}}</span>
				</span>

				<div class="btn-group">
					<button type="button" class="btn p-0" data-bs-toggle="dropdown" aria-expanded="false">
						<kaveh-icon-bootstrap name="bi-three-dots-vertical" size="15px" />
					</button>
					<ul class="dropdown-menu dropdown-menu-end">
						<li v-if="isVisible(node,'office')" class="cursor-pointer" @click="create(node.branchId,1)">
							<div class="dropdown-item">
								<span class="me-2">
									<kaveh-icon-bootstrap name="bi-building" color="darkslategrey" size="15px" />
								</span>
								<span>
									{{$loc.fromResource('hub.ext.branch.create.officeNode')}}
								</span>
							</div>
						</li>
						<li v-if="isVisible(node,'workspace')" class="cursor-pointer" @click="create(node.branchId,2)">
							<div class="dropdown-item">
								<span class="me-2">
									<kaveh-icon-bootstrap name="bi-layers" color="darkslategrey" size="15px" />
								</span>
								<span>
									{{$loc.fromResource('hub.ext.branch.create.unitNode')}}
								</span>
							</div>
						</li>
						<li v-if="isVisible(node,'position')" class="cursor-pointer" @click="create(node.branchId,3)">
							<div class="dropdown-item">
								<span class="me-2">
									<kaveh-icon-bootstrap name="bi-person-bounding-box" color="darkslategrey" size="15px" />
								</span>
								<span>
									{{$loc.fromResource('hub.ext.branch.create.positionNode')}}
								</span>
							</div>
						</li>

						<li v-if="isVisible(node,'position')" class="cursor-pointer" @click="addPerson(node)">
							<div class="dropdown-item">
								<span class="me-2">
									<kaveh-icon-bootstrap name="bi-person-vcard" color="darkslategrey" size="15px" />
								</span>
								<span>
									{{$loc.fromResource('hub.ext.branch.create.personNode')}}
								</span>
							</div>
						</li>

						<li v-if="node.branchTypeId == 3" class="cursor-pointer" @click="copyPosition(node)">
							<div class="dropdown-item">
								<span class="me-2">
									<kaveh-icon-bootstrap name="bi-copy" color="darkslategrey" size="15px" />
								</span>
								<span>
									{{$loc.fromResource('hub.ext.branch.views.copyPosition')}}
								</span>
							</div>
						</li>

						<li v-if="node.parentId" class="cursor-pointer" @click="move(node)">
							<div class="dropdown-item">
								<span class="me-2">
									<kaveh-icon-bootstrap name="bi-scissors" color="darkslategrey" size="15px" />
								</span>
								<span>
									{{$loc.fromResource('hub.ext.branch.views.move')}}
								</span>
							</div>
						</li>

						<li><hr class="dropdown-divider"></li>
						<li @click="remove(node)" class="cursor-pointer">
							<div class="dropdown-item">
								<span class="me-2">
									<kaveh-icon-bootstrap name="bi-trash" color="red" size="15px" />
								</span>
								<span>
									{{$loc.fromResource('action.delete')}}
								</span>
							</div>
						</li>

						<li @click="update(node)" class="cursor-pointer">
							<div class="dropdown-item">
								<span class="me-2">
									<kaveh-icon-bootstrap name="bi-pen" color="darkorange" size="15px" />
								</span>
								<span>
									{{$loc.fromResource('action.update')}}
								</span>
							</div>
						</li>
					</ul>

				</div>
			</div>
			<template v-slot:children v-if="hasChildren(node)">
				<branch-editable-tree-node :nodes="node.children" :is-root="false" />
			</template>
		</kaveh-tree-node>
	</kaveh-tree>
</template>
<script>

	export default {
		emits: ['change'],
		props: {

			nodes: {
				type: Array
			},
		},

		data()
		{
			return {
				isRoot: true
			}
		},

		methods:
		{
			async create(branchId, branchTypeId)
			{
				const temp =
					await this.$store.dispatch('temp/add', {
						parentId: branchId,
						branchTypeId: branchTypeId
					});

				this.$router.push({
					name: 'hub.branch.create',
					query: {
						temp: temp
					}
				})
			},


			async update(node)
			{
				const temp =
					await this.$store.dispatch('temp/add', node);

				this.$router.push({
					name: `hub.branch.update`,
					query: {
						temp: temp
					}
				})
			},

			async remove(node)
			{
				const temp =
					await this.$store.dispatch('temp/add', [node]);

				this.$router.push({
					name: 'hub.branch.delete',
					query: {
						temp: temp
					}
				})
			},


			async move(node)
			{
				const temp =
					await this.$store.dispatch('temp/add', node);

				this.$router.push({
					name: 'hub.branch.move',
					query: {
						temp: temp
					}
				})
			},

			async copyPosition(node)
			{
				const temp =
					await this.$store.dispatch('temp/add', node);

				this.$router.push({
					name: 'hub.branch.copyPosition',
					query: {
						temp: temp
					}
				})
			},

			hasChildren(node)
			{
				return (node.children || []).length > 0
			},

			getIcon(node)
			{
				switch (node.branchTypeId)
				{
					case 1:
						return "bi-building";
					case 2:
						return "bi-layers";
					case 3:
						return "bi-person-fill";
				}
			},


			isVisible(node, name)
			{
				const typeId =
					node.branchTypeId;

				switch (name)
				{
					case "position":
						return (typeId == 2 || typeId == 3)
					case "workspace":
						return (typeId == 1 || typeId == 3)
					case "office":
						return typeId == 3
					default:
				}
			}
		}
	}
</script>
