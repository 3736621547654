export default {
	"room": {
		"create": "ایجاد گفتگوی جدید",
		"filter": "جستجوی گفتگو ... ",
		"delete": "حذف گفتگو",
		"createTitle": "شخص و یا اشخاص مورد نظر خود را جهت آغاز گفتگو انخاب نمایید.",
		"errors": {
			"error1": "لطفا عنوان گروه را وارد نمایید"
		}
	},
	"chat": {
		"delete": "حذف پیام",
		"deleteForAll": "حذف پیام برای همه",
		"reply": "پاسخ",
		"copy": "کپی متن پیام",
		"showOldChats":"مشاهده پیام های قبل"
	}
}
