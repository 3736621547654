//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseTrack } from '@/core/data'
import { Discount, Producer, ProductGroup, ProductSeo, Warehouse, Feature } from '@/modules/store/data/product/detail'; 
export default class Product extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'product';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'store.product';
	props = {
		productId: {
			primarykey: true,
			name: 'productId',
			resourceKey: 'store.productProductId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		title: {
			name: 'title',
			resourceKey: 'store.productTitle',
			type: ENUMS.PROP_TYPE.STRING,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 50,
				isUnique: true,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		caption: {
			name: 'caption',
			resourceKey: 'store.productCaption',
			type: ENUMS.PROP_TYPE.STRING,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXTAREA,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 100,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		stringContent: {
			name: 'stringContent',
			resourceKey: 'store.productStringContent',
			type: ENUMS.PROP_TYPE.STRING,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		showInHomePage: {
			name: 'showInHomePage',
			resourceKey: 'store.productShowInHomePage',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		viewOrder: {
			name: 'viewOrder',
			resourceKey: 'store.productViewOrder',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		startSaleDate: {
			name: 'startSaleDate',
			resourceKey: 'store.productStartSaleDate',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATA_PICKER,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 10,
				isUnique: false,
				patterns: ['date','required'],
			},
			errors: [],
			value: null
		},
		endSaleDate: {
			name: 'endSaleDate',
			resourceKey: 'store.productEndSaleDate',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATA_PICKER,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 10,
				isUnique: false,
				patterns: ['date'],
			},
			errors: [],
			value: null
		},
		disableBuyButton: {
			name: 'disableBuyButton',
			resourceKey: 'store.productDisableBuyButton',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		isRelease: {
			name: 'isRelease',
			resourceKey: 'store.productIsRelease',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		callForPrice: {
			name: 'callForPrice',
			resourceKey: 'store.productCallForPrice',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		productGroupId: {
			foreignKey: true,
			name: 'productGroupId',
			resourceKey: 'store.productProductGroupId',
			type: ENUMS.PROP_TYPE.INT32,
			serverAddress: '/store/product-group/list',
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'title',
				valueKey: 'productGroupId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		discountId: {
			foreignKey: true,
			name: 'discountId',
			resourceKey: 'store.productDiscountId',
			type: ENUMS.PROP_TYPE.INT32,
			serverAddress: '/store/discount/list',
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'title',
				valueKey: 'discountId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		producerId: {
			foreignKey: true,
			name: 'producerId',
			resourceKey: 'store.productProducerId',
			type: ENUMS.PROP_TYPE.INT32,
			serverAddress: '/store/producer/list',
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'title',
				valueKey: 'producerId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		warehouseId: {
			foreignKey: true,
			name: 'warehouseId',
			resourceKey: 'store.productWarehouseId',
			type: ENUMS.PROP_TYPE.INT32,
			serverAddress: '/store/warehouse/list',
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'title',
				valueKey: 'warehouseId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		translations: {
			name: 'translations',
			resourceKey: 'store.productTranslations',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		discount: {
			name: 'discount',
			resourceKey: 'store.productDiscount',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Discount(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		producer: {
			name: 'producer',
			resourceKey: 'store.productProducer',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Producer(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		productGroup: {
			name: 'productGroup',
			resourceKey: 'store.productProductGroup',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new ProductGroup(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		productSeo: {
			name: 'productSeo',
			resourceKey: 'store.productProductSeo',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new ProductSeo(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{pageTitle}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		warehouse: {
			name: 'warehouse',
			resourceKey: 'store.productWarehouse',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Warehouse(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		productTrack: {
			name: 'productTrack',
			resourceKey: 'store.productProductTrack',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BaseTrack(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: 'data.track.summary',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		features: {
			name: 'features',
			resourceKey: 'store.productFeatures',
			type: ENUMS.PROP_TYPE.ARRAY,
			entity: new Feature(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{value}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				collectionMinLength: 0,
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: []
		},
	};
}