//GeneratorFlag:[NoChange]
import Product from './product';
import Discount from './discount';
import Producer from './producer';
import ProductGroup from './product-group';
import ProductSeo from './product-seo';
import Warehouse from './warehouse';
import Feature from './feature';

export default {Product, Discount, Producer, ProductGroup, ProductSeo, Warehouse, Feature}
export{Product, Discount, Producer, ProductGroup, ProductSeo, Warehouse, Feature}