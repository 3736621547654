<template>
	<popper v-if="data.value != null" class="popper-light" arrow hover>
		<span class="pointer" v-bind:class="{ 'truncate': $parent.isTrancate }">
			{{value}}
		</span>
		<template #content>
			{{word}} 
		</template>
	</popper>
</template>
<script>
	import { numberToWords , addCommas } from "@persian-tools/persian-tools";
	export default {
		props:
		{
			data: Object
		},

		computed:
		{
			value()
			{
				const value = this.data.value;
				if (value)
				{
					return addCommas(value)
				}

				return this.data.value;
			},


			word()
			{
				const value = this.data.value;
				if (value && String(value).indexOf(".") == -1)
				{
					return numberToWords(value)
				}

				return this.data.value;
			},
		}
	}
</script>
