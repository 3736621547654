//GeneratorFlag:[NoChange]
export default {
	path: 'room',
	name: 'messenger.room.layout',
	redirect: to => {
		return {name: 'messenger.room.list'};
	},
	component: () => import('@/modules/messenger/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/messenger/room"],
		resourceKey: 'messenger.roomLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[RoomCreate]"
		{
			path: 'create',
			name: 'messenger.room.create',
			component: () => import('@/modules/messenger/views/room/room-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'messenger.roomCreate',
				clientAccessKeys:["/messenger/room/create"],
				serverAccessKeys:["/messenger/room/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}