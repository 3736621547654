import ENUMS from '../enums'
import BaseEntity from './base-entity'

export default class BaseSubInfo extends BaseEntity
{
	constructor(arg)
	{
		super()
		this.prepair(arg)
	}

	prepair(arg)
	{
		if ((arg || {}).value)
		{
			if (arg.value.creatorId == arg.value.modifierId &&
				arg.value.creatorIp == arg.value.modifierIp &&
				arg.value.creatorName == arg.value.modifierName &&
				arg.value.createDate == arg.value.modifyDate &&
				arg.value.localCreateDate == arg.value.displayModifyDate &&
				arg.value.createTime == arg.value.modifyTime)
			{
				arg.value.modifierId = null;
				arg.value.modifierIp = null;
				arg.value.modifierName = null;
				arg.value.modifyDate = null;
				arg.value.displayModifyDate = null;
				arg.value.modifyTime = null;
			}

			this.setValue(this, arg.value)
		}
	}

	name = 'subInfo';
	schema = ENUMS.SCHEMA_TYPE.SUB_INFO;
	resourceKey = 'data.subInfo.self';
	props = {
		creatorId: {
			name: 'creatorId',
			resourceKey: 'data.subInfo.creatorId',

			type: ENUMS.PROP_TYPE.NUMBER,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.LTR
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				patterns: []
			}
		},
		creatorIp: {
			name: 'creatorIp',
			resourceKey: 'data.subInfo.creatorIp',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				patterns: []
			}
		},
		creatorName: {
			name: 'creatorName',
			resourceKey: 'data.subInfo.creatorName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				patterns: []
			}
		},

		createDate: {
			name: 'createDate',
			resourceKey: 'data.subInfo.createDate',
			type: ENUMS.PROP_TYPE.DATE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.DATE,
				patterns: []
			}
		},
		localCreateDate: {
			name: 'localCreateDate',
			resourceKey: 'data.subInfo.localCreateDate',
			type: ENUMS.PROP_TYPE.PDATE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.PDATE,
				patterns: []
			}
		},

		createTime: {
			name: 'createTime',
			resourceKey: 'data.subInfo.createTime',
			type: ENUMS.PROP_TYPE.TIME,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TIME,
				patterns: []
			}
		},

		modifierId: {
			name: 'modifierId',
			resourceKey: 'data.subInfo.modifierId',
			type: ENUMS.PROP_TYPE.NUMBER,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				patterns: []
			}
		},

		modifierIp: {
			name: 'modifierIp',
			resourceKey: 'data.subInfo.modifierIp',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				patterns: []
			}
		},

		modifierName: {
			name: 'modifierName',
			resourceKey: 'data.subInfo.modifierName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				patterns: []
			}
		},
		modifyDate: {
			name: 'modifyDate',
			resourceKey: 'data.subInfo.modifyDate',
			type: ENUMS.PROP_TYPE.DATE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.DATE,
				patterns: []
			}
		},
		displayModifyDate: {
			name: 'displayModifyDate',
			resourceKey: 'data.subInfo.displayModifyDate',
			type: ENUMS.PROP_TYPE.PDATE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.PDATE,
				patterns: []
			}
		},
		modifyTime: {
			name: 'modifyTime',
			resourceKey: 'data.subInfo.modifyTime',
			type: ENUMS.PROP_TYPE.time,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TIME,
				patterns: []
			}
		}
	}
}
