import { Server } from '@/core/server'
export default class SelectService
{
	constructor() { }

	server(config)
	{
		return new Server(config)
	}

	async get(params, address)
	{
		const _server =
			this.server({ HSR: false, HER: true });

		return await _server.get(address, { 'params': params });
	};
}
