<template>
	<div class="kaveh-collapse">
		<div class="collapse-header" @click="onHandelClick" v-if="$slots['header']">
			<kaveh-icon-bootstrap size="14px" v-bind:name="getIconName()" class="me-2" />
			<slot name="header"></slot>
		</div>
		<Transition name="collapse">
			<div class="collapse-body" v-if="open">
				<slot name="body"></slot>
			</div>
		</Transition>
	</div>
</template>
<script>

	export default {
		props: {
			collapsed: {
				type: Boolean,
				default: false
			}
		},

		data()
		{
			return {
				open: this.collapsed
			}
		},

		computed:
		{
			direction()
			{
				return this.$store.state.app.direction
			},
		},

		methods:
		{
			getIconName()
			{
				if (this.direction == 'rtl')
				{
					return this.open ? 'bi-arrow-down' : 'bi-arrow-left'
				}

				return this.open ? 'bi-arrow-down' : 'bi-arrow-right'
			},

			onHandelClick()
			{
				if (!this.collapsed) { this.open = !this.open }
				//this.open = !this.open
			}
		}

	}
</script>

<style scoped>

	.kaveh-collapse .collapse-header,
	.kaveh-collapse .collapse-body {
	}

	.kaveh-collapse .collapse-header {
		width: 100%;
		height: 40px;
		cursor: pointer;
		user-select: none;
		margin-top: 3px;
		font-size: 15px;
		transition: all .3s;
		border: #ddd solid 1px;
		padding: 10px;
		background: #fff;
		border-radius: 4px;
		min-height: 40px;
	}

		.kaveh-collapse .collapse-header * {
			margin-left: 10px;
		}

	.kaveh-collapse .collapse-body {
		margin-top: 20px;
		margin-bottom: 20px;
		padding: 10px;
	}

		.kaveh-collapse .collapse-body .collapse-body {
			width: calc(100% - 30px);
			margin-right: 10px;
			border-right: #ddd solid 1px;
		}

			.collapse-enter-active {
				animation: collapse reverse 250ms linear;
			}

	.collapse-leave-active {
		animation: collapse 250ms linear;
	}

	@keyframes collapse {
		from {
			opacity: 1;
		}

		to {
			opacity: 0;
		}
	}



	[dir="ltr"] .kaveh-collapse .collapse-body .collapse-body {
		margin-right: unset;
		border-right: unset;
		margin-left: 10px;
		border-left: #ddd solid 1px;
	}
</style>
