//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class FeatureGroupService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[FeatureGroup.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/store/feature-group/create', params || { });
	};

	//GeneratorFlag:[FeatureGroup.Delete]
	async delete(featureGroupId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:featureGroupId
		}
	
		return await _server.delete('/store/feature-group/delete', {'params':params})
	};

	//GeneratorFlag:[FeatureGroup.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/store/feature-group/detail', {'params':params});
	};

	//GeneratorFlag:[FeatureGroup.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/store/feature-group/list', {'params':params});
	};

	//GeneratorFlag:[FeatureGroup.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/store/feature-group/update', params || { });
	};

//GeneratorFlag:[NewAction]
}