//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { Position } from '@/modules/hub/data/branch/list'; 
export default class BranchFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'branchFilter';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'global.filters';
	props = {
	};
}