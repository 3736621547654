export default {
	"views": {
		"fileManager": {
			"layout": "File management"
		}
	},

	"fileManager": {
		"createFolder": "Neuen Ordner erstellen",
		"uploadFile": "Neue Datei hochladen",
		"accessTitle": "Dateizugriffsstatus",
		"accessPrivateTitle": "Die Datei ist privat und kann nur mit einem Sicherheitstoken heruntergeladen werden",
		"accessPublicTitle": "Die Datei ist öffentlich und kann ohne Token heruntergeladen werden",
		"uploadAction": "Ausgewählte Dateien hochladen",
		"info": {
			"fileName": "Dateiname",
			"extensionName": "Erweiterungsname",
			"contentType": "Inhaltstyp",
			"key": "Schlüssel",
			"download": "herunterladen",
			"clickToDownload": "Klicken Sie hier zum Herunterladen",
			"externalLink": "Externer Download-Link",
			"tokenWarning": "Der obige Link kann nur unter Verwendung des Sicherheitstokens im Abschnitt {token} verwendet werden",
			"createDateTime": "Create Date Time",
			"modifyDateTime": "Modify Date Time"
		}
	}
}
