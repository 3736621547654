<template>

	<div class="table-responsive rounded-2 pb-2 mb-2" :style="{'max-height': layoutHeight}">
		<table class="table table-striped table-kaveh mb-0" v-bind:class="{'kaveh-loading':loading}">
			<thead>
				<tr>
					<th v-if="selectable">
						<div class="line"></div>
						<div class="form-check form-switch">
							<input type="checkbox" class="form-check-input" v-bind:role="'switch'"
								   v-on:change="checkAll" v-bind:checked="checkedLen() == items.length" />
						</div>
					</th>
					<th v-if="showRowNumber">
						<div class="line"></div>
						<div>#</div>
					</th>
					<th v-for="prop in getProps()" v-bind:key="prop.name" class="order-column">
						<div class="truncate">
							<span>{{ (prop.label || $loc.fromResource(prop.resourceKey)) }}</span>
							<span v-if="prop.type != 'object'" class="order-key" @click="changeOrder(prop)">
								<span v-bind:class="{'text-base-color': (orderPropertyName == prop.name && orderDescending == true) }">&#8593;</span>
								<span v-bind:class="{'text-base-color': (orderPropertyName == prop.name && orderDescending == false) }">&#8595;</span>
							</span>
							<span v-if="prop.isSearchable" class="search-key" @click="$emit('selectFilterProp',prop)">
								<kaveh-icon-bootstrap name="bi-search" size="12px" />
							</span>
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<table-row v-for="(item,index) in items"
						   v-on:checked="check"
						   :key="index"
						   :selectable="selectable"
						   :showError="showError"
						   :showRowNumber="showRowNumber"
						   :showForeignKey="showForeignKey"
						   :currentPage="currentPage"
						   :pageSize="pageSize"
						   :columns="columns"
						   :index="index"
						   :data="item" />
			</tbody>
		</table>
	</div>

	<table-pagination v-if="showPagination"
					  :total="totalRows"
					  :pageSize="pageSize"
					  :current="currentPage"
					  :totalfilters="totalfilters"
					  v-on:change="(obj)=> $emit('changePage', obj )" />
</template>
<script>

	import tableRowGrid from './rows/row-grid'
	import tablePagination from './common/table-pagination'

	export default {
		emits: ['changePage', 'changeOrder', 'checked', 'selectFilterProp'],
		components:
		{
			'table-row': tableRowGrid,
			'table-pagination': tablePagination
		},

		props:
		{
			'items': Array,
			'actions': Array,
			'entity': Function,
			'totalRows': Number,
			'totalfilters': Number,
			'currentPage': Number,
			'selectable': Boolean,
			'pageSize': Number,
			'showRowNumber': Boolean,
			'showError': Boolean,
			'showPagination': Boolean,
			'showForeignKey': Boolean,
			'loading': Boolean
		},

		data()
		{
			return {
				columns: [],
				orderPropertyName: '',
				orderDescending: false
			}
		},

		beforeMount()
		{
			this.init()
			this.initColumns()
		},

		computed:
		{
			layoutHeight() {
				return `${window.outerHeight - 300}px`;
			},
		},

		methods:
		{
			init() {
				const orderPropertyName = this.$route.query['orderPropertyName']
				const orderDescending = this.$route.query['orderDescending']

				if (orderPropertyName)
				{
					this.orderPropertyName = orderPropertyName
					this.orderDescending = orderDescending == 'true' ? true : false
				}
			},

			initColumns()
			{
				var columns = {}
				for (var item of this.items)
				{
					for (const key of Object.keys(item.value || {}).concat(this.showError ? Object.keys(item.error || {}) : []))
					{
						columns[key] = true
					}
				}

				this.columns = Object.keys(columns)
			},

			getProps(entity)
			{
				var props = []
				entity = entity || new this.entity({})
				for (var key in entity.props)
				{
					const prop = entity.props[key];

					if (prop.foreignKey && this.showForeignKey == false)
						continue;

					if (!prop.display.isVisible || this.columns.indexOf(key) == -1)
						continue;

					props.push(entity.props[key])
				}

				return props
			},

			check()
			{
				this.$emit('checked', this.getChecked())
			},

			checkAll(e)
			{
				for (const item of this.items)
				{
					item.checked = e.target.checked
				}

				this.$emit('checked', this.getChecked())
			},

			getChecked()
			{
				var items = []
				for (const item of this.items)
				{
					if (item.checked == true) { items.push(item) }
				}

				return items
			},

			checkedLen()
			{
				var len = 0
				for (const item of this.items) { len += item.checked ? 1 : 0 }

				return len
			},

			async changeOrder(prop)
			{
				var orderPropertyName = prop.name;
				var orderDescending = false;

				if (this.orderPropertyName == prop.name)
				{
					orderDescending = !this.orderDescending;
				}

				const query = {
					...this.$route.query,
					orderPropertyName, orderDescending
				};

				await this.$router.replace({ query });
				this.$emit('changeOrder');

				this.orderPropertyName = orderPropertyName
				this.orderDescending = orderDescending
			}
		},
	}
</script>
<style>
	.table-kaveh th.order-column {
		padding-left: 70px;
	}
	.table-kaveh th.order-column .order-key {
		position: absolute;
		left: 0;
		width: 25px;
		top: 8px;
		text-align: center;
		cursor: pointer;
		font-size: 12px;
	}

	.table-kaveh th.order-column .search-key {
		position: absolute;
		left: 25px;
		width: 15px;
		top: 8px;
		text-align: center;
		cursor: pointer;
		font-size: 12px;
	}

	.table-kaveh thead th {
		position: sticky;
		top: 0px;
		background: #fff;
	}



	[dir="ltr"] .table-kaveh th.order-column {
		padding-left: 5px;
		padding-right: 70px;
	}

		[dir="ltr"] .table-kaveh th.order-column .order-key {
			left: unset;
			right: 0;
		}

		[dir="ltr"] .table-kaveh th.order-column .search-key {
			left: unset;
			right: 25px;
		}


</style>
