<template>
	<div class="d-inline-block me-1 mb-2">
		<popper class="popper-light" arrow>
			<button class="btn bg-base-color text-base-color border-0">
				<kaveh-icon-bootstrap name="bi-download" size="15px" />
			</button>
			<template #content="{ close }">
				<div class="cursor-pointer" @click="emitAndClose('exportExcel',close)">
					<kaveh-icon-bootstrap name="bi-file-earmark-excel" size="15px" />
					<small class="ps-2 pe-5">
						{{ $loc.fromResource('com.table.action.exportCurentPageAsExcel') }}
					</small>
				</div>
				<div class="cursor-pointer" @click="emitAndClose('createReport',close)">
					<kaveh-icon-bootstrap name="bi-file-earmark-excel" size="15px" />
					<small class="ps-2 pe-5">
						{{ $loc.fromResource('com.table.action.createReport') }}
					</small>
				</div>
			</template>
		</popper>
	</div>
</template>
<script>
	export default {
		methods: {
			emitAndClose(emmitValue, closeFn)
			{
				this.$emit(emmitValue)
				closeFn()
			}
		}
	}
</script>
