//GeneratorFlag:[NoChange]
export default {
	path: 'static-content',
	name: 'cms.staticContent.layout',
	redirect: to => {
		return {name: 'cms.staticContent.list'};
	},
	component: () => import('@/modules/cms/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/cms/static-content"],
		resourceKey: 'cms.staticContentLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[StaticContentCreate]"
		{
			path: 'create',
			name: 'cms.staticContent.create',
			component: () => import('@/modules/cms/views/static-content/static-content-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.staticContentCreate',
				clientAccessKeys:["/cms/static-content/create"],
				serverAccessKeys:["/cms/static-content/create","/cms/static-content/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StaticContentCreateImport]"
		{
			path: 'create-import',
			name: 'cms.staticContent.createImport',
			component: () => import('@/modules/cms/views/static-content/static-content-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.staticContentCreateImport',
				clientAccessKeys:["/cms/static-content/create-import"],
				serverAccessKeys:["/cms/static-content/create","/cms/static-content/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StaticContentDelete]"
		{
			path: 'delete',
			name: 'cms.staticContent.delete',
			component: () => import('@/modules/cms/views/static-content/static-content-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.staticContentDelete',
				clientAccessKeys:["/cms/static-content/delete"],
				serverAccessKeys:["/cms/static-content/delete","/cms/static-content/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StaticContentDetail]"
		{
			path: 'detail',
			name: 'cms.staticContent.detail',
			component: () => import('@/modules/cms/views/static-content/static-content-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.staticContentDetail',
				clientAccessKeys:["/cms/static-content/detail"],
				serverAccessKeys:["/cms/static-content/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StaticContentList]"
		{
			path: 'list',
			name: 'cms.staticContent.list',
			component: () => import('@/modules/cms/views/static-content/static-content-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.staticContentList',
				clientAccessKeys:["/cms/static-content/list"],
				serverAccessKeys:["/cms/static-content/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StaticContentAttachment]"
		{
			path: 'attachment',
			name: 'cms.staticContent.attachment',
			component: () => import('@/modules/cms/views/static-content/static-content-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.staticContentAttachment',
				clientAccessKeys:["/cms/static-content/attachment"],
				serverAccessKeys:["/cms/static-content/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StaticContentUpdate]"
		{
			path: 'update',
			name: 'cms.staticContent.update',
			component: () => import('@/modules/cms/views/static-content/static-content-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.staticContentUpdate',
				clientAccessKeys:["/cms/static-content/update"],
				serverAccessKeys:["/cms/static-content/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}