export default {
	"accessAgent": {
		"personRoleTab": "Assign Access to User Roles",
		"personTab": "Assign Access to Users",
	},

	"branch": {

		"list": {
			"showInOrd": "Show as Chart",
			"showInTree": "Show as Subordinate"
		},

		"create": {
			"officeName": "Organization or Agency Title",
			"unitName": "Organizational Unit Title",
			"positionName": "Organizational Position Title",

			"officeNode": "Add Organization/Agency",
			"unitNode": "Add Organizational Unit",
			"positionNode": "Add Organizational Position",
			"personNode": "Assign Personnel"
		},

		"tree": {
			"positionSelect": "Select Personnel",
			"officeSelect": "Select Organization / Unit",
		},

		"choose": {
			"head": "To participate in automation, please select your desired organizational position.",
			"empty": "No organizational positions found for you.",
			"warning": "Please note that after selecting or changing the organizational position, all your organizational letters and actions will be registered with the new position.",
			"selected": "Active",
			"accessDenied": "You do not have access to this section, you must first specify your organizational position. Please select your organizational position using the button below.",
			"noFound": "No organizational positions have been defined for you. Please coordinate with your organization's automation officer."
		},

		"views": {
			"choose": "Select Organizational Position",
			"accessDenied": "Access Denied",
			"position": "Assign Position to Personnel",
			"copyPosition": "Copy Active Positions to Another Position",
			"move": "Move"

		},

		"position": {
			"mode": {
				"create": "Assign New Personnel",
				"update": "Edit Personnel Position {label}"
			},
			"create": {
				"title": "Assign Personnel to Position {label}",
			},
			"copy": {
				"title": "Copy Personnel from Position {label}",
				"treeTitle": "To copy organizational personnel, please select the destination position",
				"listTitle": "List of current personnel within the source position",
				"errors": {
					"error1": "Please select a position",
					"error2": "The destination position is equal to the source and copying is not possible",
				}
			}
		},

		"move": {
			"errors": {
				"error1": "The office should be placed under a position.",
				"error2": "The unit must be placed under a position or office.",
				"error3": "The position should be placed under a unit or another position."
			}
		}

	},

	"person": {
		"changePassword": {
			"invalidCurrentPassword": "Current password is incorrect",
			"fcp": 'Dear user! <br/><br/> You have logged into the system with the default password.<br/> For the security of your account, you must first change your password.'
		},

		"createReal": "Real Person",
		"createLegal": "Legal Person",
		"createSystem": "System User / Bot"
	}
}
