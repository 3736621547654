<template>
	<kaveh-code-editor ref="EditorRef" v-model="value" @change="onHandelChange" />
</template>
<script>
	export default {
		emits: ['change', 'keyup'],

		props: {
			prop: {
				type: Object
			}
		},

		data()
		{
			return {
				value: null,
			}
		},

		mounted()
		{
			if (this.prop.value)
			{
				this.$refs.EditorRef.setValue(this.prop.value)
			}
		},

		methods: {

			onHandelChange()
			{
				this.$emit('change', this.value)
				this.$emit('keyup', this.value)
			},
		}
	}
</script>
