<template>
	<component v-bind:mode="mode"
			   v-bind:col="col"
			   v-bind:prop="localProp"
			   v-bind:is="`form-${displayTypeComputed}`"
			   v-bind:use-validation="useValidation"
			   v-on:generator-up="(x) => {$emit('generator-up', x)}"
			   v-on:generator-down="(x) => {$emit('generator-down', x)}"
			   v-on:generator-remove="(x) => {$emit('generator-remove', x)}"
			   v-on:generator-select="(x) => {$emit('generator-select', x)}">
		<component v-bind:is="`form-${prop.setConfig.inputType}`"
				   v-bind:prop="localProp"
				   v-bind:language-direction="languageDirection"
				   v-on:change="onHandelChange"
				   v-on:keyup="onHandelKeyup"
				   v-on:submit="$emit('submit')" />
	</component>
</template>

<script>
	import validator from '@/core/validator'
	import formBlock from './common/form-block'
	import formInline from './common/form-inline'
	import formCheckbox from './checkbox/form-checkbox'
	import formCheckdrop from './checkdrop/form-checkdrop'
	import formNumber from './number/form-number'
	import formFloat from './float/form-float'
	import formPassword from './password/form-password'
	import formSelect from './select/form-select'
	import formText from './text/form-text'
	import formTextarray from './textarray/form-textarray'
	import formHidden from './hidden/form-hidden'
	import formDate from './date/form-date'
	import formTime from './time/form-time'
	import formPersianDate from './persian-date/form-persian-date'
	import formEmail from './email/form-email'
	import formTextarea from './textarea/form-textarea'
	import formTree from './tree/form-tree'
	import formColor from './color/form-color'
	import formTextEditor from './text-editor/form-text-editor'
	import formCodeEditor from './code-editor/form-code-editor'
	import formUploader from './uploader/form-uploader'
	import formDatePicker from './date-picker/form-date-picker'
	import formTimePicker from './time-picker/form-time-picker'
	import formIranPlaque from './iran-plaque/form-iran-plaque'
	import formSignaturePad from './signature-pad/form-signature-pad'
	import formHr from './hr/form-hr'
	import formHeading from './heading/form-heading'
	import formLable from './lable/form-lable'
	import { deviceType } from '@/plugins'

	export default {
		emits: ['submit', 'change', 'generator-remove', 'generator-up', 'generator-down', 'generator-select'],

		components: {
			'form-block': formBlock,
			'form-inline': formInline,
			'form-checkbox': formCheckbox,
			'form-checkdrop': formCheckdrop,
			'form-number': formNumber,
			'form-float': formFloat,
			'form-password': formPassword,
			'form-select': formSelect,
			'form-iran-plaque': formIranPlaque,
			'form-text': formText,
			'form-textarray': formTextarray,
			'form-hidden': formHidden,
			'form-date': formDate,
			'form-time': formTime,
			'form-persian-date': formPersianDate,
			'form-email': formEmail,
			'form-textarea': formTextarea,
			'form-tree': formTree,
			'form-color': formColor,
			'form-text-editor': formTextEditor,
			'form-code-editor': formCodeEditor,
			'form-uploader': formUploader,
			'form-date-picker': formDatePicker,
			'form-time-picker': formTimePicker,
			'form-signature-pad': formSignaturePad,
			'form-hr': formHr,
			'form-heading': formHeading,
			'form-lable': formLable
		},

		props: {

			'prop': {
				type: Object
			},

			'mode': {
				type: String
			},

			'displayType': {
				type: String
			},

			'col': {
				type: String
			},

			'useValidation': {
				type: Boolean
			},

			'languageDirection': {
				type: String,
				default: undefined
			},
		},

		data()
		{
			return {
				localProp: null
			}
		},

		beforeMount()
		{
			this.localProp = this.prop;
			this.localProp.id = `${this.prop.name}_${(new Date().valueOf())}`;
		},

		computed: {

			displayTypeComputed()
			{
				if (this.displayType)
				{
					return this.displayType
				}

				return deviceType() == 'desktop' ? 'inline' : 'block'
			},

			inputTypeComputed()
			{
				return deviceType() == 'desktop' ? 'inline' : 'block'
			}
		},

		methods:
		{
			onHandelChange(value, reset)
			{
				if (!this.localProp.changed) { this.localProp.changed = true }

				if (reset)
				{
					this.bideReset()
				} else this.bindChange(value)
			},

			onHandelKeyup(value)
			{
				if (typeof (value) != 'object' && this.localProp.changed)
				{
					this.bindChange(value)
				}
			},

			bideReset()
			{
				this.localProp.value = null
				this.localProp.errors = []
			},

			bindChange(value)
			{
				this.localProp.value =
					value !== "" ? value : undefined

				if (this.useValidation)
				{
					this.localProp.errors =
						validator.getPropErrors(this.localProp)
				}

				this.$emit('change', this.localProp)
			},
		}
	}
</script>
