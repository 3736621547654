//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class BlogContentService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[BlogContent.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/cms/blog-content/create', params || { });
	};

	//GeneratorFlag:[BlogContent.Delete]
	async delete(blogContentId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:blogContentId
		}
	
		return await _server.delete('/cms/blog-content/delete', {'params':params})
	};

	//GeneratorFlag:[BlogContent.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/cms/blog-content/detail', {'params':params});
	};

	//GeneratorFlag:[BlogContent.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/cms/blog-content/list', {'params':params});
	};

	//GeneratorFlag:[BlogContent.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/cms/blog-content/update', params || { });
	};

//GeneratorFlag:[NewAction]
}