//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class NoticeService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[Notice.Send]
	async send(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/notification/notice/send', params || { });
	};

//GeneratorFlag:[NewAction]
}