<template>
	<kaveh-popup v-if="isShow" :close="() => {isShow = false}">
		<branch-choose @complete="complete" />
	</kaveh-popup>
</template>
<script>
	import branchChoose from '@/modules/hub/views/branch/branch-choose.vue'
	export default {
		components: {
			'branch-choose': branchChoose
		},

		data() {
			return {
				isShow: true,
			}
		},

		methods: {
			complete() {
				this.isShow = false;
			}
		}
	}
</script>
