<template>
	<div class="kaveh-excel-parser">
		<excel-parser-loading v-if="state == 'loading'" />
		<excel-parser-epmty v-if="state == 'empty'" v-on:changeState="changeState" />
		<excel-parser-select v-if="state == 'select'" :entity="entity" v-on:select="selectFile" />

		<excel-parser-error v-if="state == 'error'"
							:entity="entity"
							:data="items"
							:errorIndexes="errorIndexes"
							:reviewLength="reviewLength"
							:isLoadingReview="isLoadingReview"
							v-on:changeState="changeState"
							v-on:review="review" />
	</div>
</template>
<script>

	import { utils, read } from 'xlsx'
	import excelParserEmpty from './excel-parser-epmty'
	import excelParserError from './excel-parser-error'
	import excelParserLoading from './excel-parser-loading'
	import excelParserSelect from './excel-parser-select'

	export default {
		emits: ['complete'],
		props: {
			'entity': {
				type: Function
			}
		},

		data()
		{
			return {
				items: [],
				errorIndexes: [],
				reviewLength: 0,
				state: 'select',
				isLoadingReview: false,
			}
		},

		components: {
			'excel-parser-epmty': excelParserEmpty,
			'excel-parser-error': excelParserError,
			'excel-parser-select': excelParserSelect,
			'excel-parser-loading': excelParserLoading
		},

		methods: {

			selectFile(file)
			{
				this.state = 'loading';
				this.readFileAsBinary(file).then((binaryFile) =>
				{
					this.binaryFileToSheet(binaryFile).then((data) =>
					{
						if (data.isEmpty)
						{
							this.state = 'empty'; return;
						}

						this.decodeSheetData(data.rows).then((data) =>
						{
							if (data.isEmpty)
							{
								this.state = 'empty'; return;
							}

							this.convertDataToEntities(data.rows).then((result) =>
							{
								if (result.errorLength == 0)
								{
									this.state = 'complete';
									this.$emit('complete', result.items); return;
								}

								this.state = 'error';
								this.items = result.items;
								this.errorIndexes = result.errorIndexes;
							});
						});
					});
				});
			},

			changeState(state)
			{
				this.state = state;
			},

			review(data)
			{
				this.state = 'loading';
				this.convertDataToEntities(data).then((result) =>
				{
					if (result.errorLength == 0)
					{
						this.state = 'complete';
						this.$emit('complete', data); return;
					}

					this.state = 'error';
					this.reviewLength = this.reviewLength + 1
					this.items = data;
				});
			},


			readFileAsBinary(file)
			{
				return new Promise((resolve, reject) =>
				{
					setTimeout(() =>
					{
						var reader = new FileReader();
						reader.onload = () =>
						{
							resolve(reader.result)
						};
						reader.onerror = reject;
						reader.readAsBinaryString(file);
					}, 1)
				});
			},

			binaryFileToSheet(bfile)
			{
				return new Promise((resolve, reject) =>
				{
					setTimeout(() =>
					{
						var wb = read(bfile, { type: 'binary' });
						var rows = utils.sheet_to_row_object_array(Object.values(wb.Sheets)[0], {
							skipHeader: true
						});

						resolve({
							rows: rows,
							isEmpty: rows.length == 0
						})
					}, 1)
				});
			},

			decodeSheetData(rows)
			{
				return new Promise((resolve, reject) =>
				{
					setTimeout(() =>
					{

						var result = []
						if (rows.length > 0)
						{
							for (var i = 0; i < rows.length; i++)
							{
								if (i > 0)
								{
									var data = {};
									for (var key of Object.keys(rows[i]))
									{

										if (key.includes("."))
										{

											const names =
												key.split('.')

											data[names[0]] = data[names[0]] || {}
											data[names[0]][names[1]] = String(rows[i][key])
										}
										else data[key] = String(rows[i][key])
									}

									result.push(data)
								}
							}
						}

						resolve({
							rows: result,
							isEmpty: result.length == 0
						})
					}, 1)
				});
			},

			convertDataToEntities(rows)
			{
				return new Promise((resolve, reject) =>
				{
					setTimeout(() =>
					{

						var result = {
							items: [],
							clearItems: [],
							errorLength: 0,
							errorIndexes: [],
						};

						for (var i = 0; i < rows.length; i++)
						{
							var row = rows[i];
							var entity = new this.entity({
								value: row
							});

							entity.parseExcelError();
							var errorLength = entity.getExcelErrorLength()

							result.items.push(entity.value);
							result.errorLength += errorLength;

							if (errorLength > 0)
							{
								result.errorIndexes.push(i)
							}
						}


						resolve(result)
					}, 1)
				});
			}
		}
	}
</script>
