<template>
	<canvas class="kaveh-signature-pad" @dblclick="clear" ref="SignaturePadRef"></canvas>
</template>
<script>
	import SignaturePad from 'signature_pad'
	export default {
		emit: ['change', 'update:modelValue'],
		data() {
			return {
				signaturePad: null,
			}
		},

		mounted()
		{
			this.init()
		},

		methods: {
			init() {
				var canvas = this.$refs.SignaturePadRef
				this.signaturePad = new SignaturePad(canvas, {
					minWidth: 1,
					maxWidth: 1,
					penColor: "blue"
				});

				this.signaturePad.addEventListener('endStroke', (x) =>
				{
					const value = this.signaturePad.toDataURL("image/png", 0.5);

					this.$emit('update:modelValue', value);
					this.$emit('change', value)
				})

			},

			clear()
			{
				this.signaturePad.clear()
			},

			getValue()
			{
				return this.signaturePad.toDataURL("image/png", 0.5);
			}
		}
	}
</script>
<style>
	.kaveh-signature-pad{
		background:#fff;
		border:#ddd solid 1px;
		border-radius:4px;
	}
</style>
