import store from '@/core/store'
import { halfSpace } from "@persian-tools/persian-tools";

export default class localization
{
	constructor() {
	}

	fromList(items)
	{
		const translateFunction = (value) =>
		{
			var translation = {};
			var lang = store.state.app.language

			if (value && value["translations"])
			{
				try
				{
					const translations = JSON.parse(value["translations"]) || [];

					const index = translations.findIndex((x) =>
					{
						return x.lang == lang
					});

					if (index > -1)
					{
						translation = translations[index]
					}

				} catch (e) { }
			}

			return translation
		}


		const result = [];
		items = items || [];
		for (var i = 0; i < items.length; i++)
		{
			const itemResult = {};
			const item = items[i];
			const keys = Object.keys(item)

			const translation = translateFunction(item)

			for (var ii = 0; ii < keys.length; ii++)
			{
				const key = keys[ii];

				itemResult[key] = translation[key] || item[key]
			}

			result.push(itemResult);
		}

		return result;
	}

	fromResource(path, value) {

		if (!path)
		{
			return '';
		}

		var lang = store.state.app.language;
		var current = store.state.app.overrideResource[lang];

		if (current)
		{
			path.split('.').forEach(function (p)
			{
				current = (current[p] != undefined) ? current[p] : path
			})

			if (current === path)
			{
				current = store.state.app.resource[lang];
				path.split('.').forEach(function (p)
				{
					current = (current[p] != undefined) ? current[p] : path
				})
			}

			if (current && typeof value === 'object' && !Array.isArray(value))
			{
				for (const param in value)
				{
					current = current.replace(new RegExp('{' + param + '}', 'g'), value[param])
				}
			}
		}

		if (typeof current === 'string')
		{
			if (lang == 'fa')
			{
				return halfSpace(current)
			}

			return current
		}

		return path
	}
}
