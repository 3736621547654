<template>
	<kaveh-ord :state="state">
		<branch-ord-node :nodes="nodes" />
		<template v-slot:noContent>
			<div class="card bg-light justify-content-center mb-2 pt-3 pb-3">
				<div class="card-body">
					<div class="d-flex align-items-center">
						<kaveh-icon-bootstrap color="red" name="bi-info-circle me-3" />
						<span>{{ $loc.fromResource('system.notfound')}}</span>
					</div>
					<div class="d-flex align-items-center mt-5">
						<button class="btn btn-light border me-2" @click="$router.push({name: 'hub.branch.createUnit'})">
							{{ $loc.fromResource('hub.views.branch.createUnit')}}
						</button>
						<button class="btn btn-light border me-2" @click="$router.push({name: 'hub.branch.createEmployee'})">
							{{ $loc.fromResource('hub.views.branch.createEmployee')}}
						</button>
					</div>
				</div>
			</div>
		</template>
	</kaveh-ord>
</template>
<script>

	import { array } from '@/plugins'
	import { BranchService } from '@/modules/hub/services'
	import BranchOrdNode from './branch-ord-node.vue'

	export default {

		components: {
			'branch-ord-node': BranchOrdNode
		},

		data()
		{
			return {
				nodes: [],
				state: "loading",
			}
		},

		beforeMount()
		{
			this.init();
		},

		computed:
		{
			service()
			{
				return new BranchService();
			}
		},

		methods:
		{
			async init()
			{
				try
				{
					const payload = {
						start: 0,
						length: 1000
					}

					var nodes = [];
					const res = await this.service.list(payload, { HSR: false });
					if (res.status == 200)
					{
						nodes = array.toNested(res.data.items || [], 'branchId', 'parentId')

						this.nodes = nodes;
						this.state = nodes.length == 0 ? "empty" : "";
					}
					else
					{
						this.nodes = nodes;
						this.state = "error";
					}

				} catch (e)
				{
					this.nodes = nodes;
					this.state = "error";
				}
			}
		}
	}
</script>
