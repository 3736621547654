import ext from './en-ext.js'
export default {
	"name":"asset",
	"ext": ext,
	"layout":{

	},
	'virtualFolder':'Folder',
	'virtualFolderAttachment':'File Attachment',
	'virtualFolderCreate':'Create New Folder',
	'virtualFolderCreateImport':'Register Folders via Excel',
	'virtualFolderDelete':'Delete Folder',
	'virtualFolderDetail':'Folder Information Details',
	'virtualFolderIdentifier':'Unique Key',
	'virtualFolderLayout':'Folders',
	'virtualFolderList':'Folder List',
	'virtualFolderParentId':'Parent',
	'virtualFolderTenantId':'Center',
	'virtualFolderTitle':'Folder Title',
	'virtualFolderTree':'Tree',
	'virtualFolderUpdate':'Update Folder',
	'virtualFolderVirtualFolderId':'ID',
}