//GeneratorFlag:[NoChange]
export default {
	path: 'letter-topic',
	name: 'aut.basis.letterTopic.layout',
	redirect: to => {
		return {name: 'aut.letterTopic.list'};
	},
	component: () => import('@/modules/aut/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/aut/basis/letter-topic"],
		resourceKey: 'aut.letterTopicLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[LetterTopicCreate]"
		{
			path: 'create',
			name: 'aut.letterTopic.create',
			component: () => import('@/modules/aut/views/letter-topic/letter-topic-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterTopicCreate',
				clientAccessKeys:["/aut/basis/letter-topic/create"],
				serverAccessKeys:["/aut/letter-topic/create","/aut/letter-topic/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterTopicCreateImport]"
		{
			path: 'create-import',
			name: 'aut.letterTopic.createImport',
			component: () => import('@/modules/aut/views/letter-topic/letter-topic-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterTopicCreateImport',
				clientAccessKeys:["/aut/basis/letter-topic/create-import"],
				serverAccessKeys:["/aut/letter-topic/create","/aut/letter-topic/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterTopicDelete]"
		{
			path: 'delete',
			name: 'aut.letterTopic.delete',
			component: () => import('@/modules/aut/views/letter-topic/letter-topic-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterTopicDelete',
				clientAccessKeys:["/aut/basis/letter-topic/delete"],
				serverAccessKeys:["/aut/letter-topic/delete","/aut/letter-topic/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterTopicDetail]"
		{
			path: 'detail',
			name: 'aut.letterTopic.detail',
			component: () => import('@/modules/aut/views/letter-topic/letter-topic-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterTopicDetail',
				clientAccessKeys:["/aut/basis/letter-topic/detail"],
				serverAccessKeys:["/aut/letter-topic/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterTopicList]"
		{
			path: 'list',
			name: 'aut.letterTopic.list',
			component: () => import('@/modules/aut/views/letter-topic/letter-topic-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterTopicList',
				clientAccessKeys:["/aut/basis/letter-topic/list"],
				serverAccessKeys:["/aut/letter-topic/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterTopicAttachment]"
		{
			path: 'attachment',
			name: 'aut.letterTopic.attachment',
			component: () => import('@/modules/aut/views/letter-topic/letter-topic-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterTopicAttachment',
				clientAccessKeys:["/aut/basis/letter-topic/attachment"],
				serverAccessKeys:["/aut/letter-topic/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[LetterTopicUpdate]"
		{
			path: 'update',
			name: 'aut.letterTopic.update',
			component: () => import('@/modules/aut/views/letter-topic/letter-topic-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.letterTopicUpdate',
				clientAccessKeys:["/aut/basis/letter-topic/update"],
				serverAccessKeys:["/aut/letter-topic/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}