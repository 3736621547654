//GeneratorFlag:[NoChange]
import Product from './product';
import Discount from './discount';
import Producer from './producer';
import ProductGroup from './product-group';
import ProductSeo from './product-seo';
import Warehouse from './warehouse';
import ProductFilter from './product-filter';

export default {Product, Discount, Producer, ProductGroup, ProductSeo, Warehouse, ProductFilter}
export{Product, Discount, Producer, ProductGroup, ProductSeo, Warehouse, ProductFilter}