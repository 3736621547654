import ext from './de-ext.js'
export default {
	"name":"vermögen",
	"ext": ext,
	"layout":{

	},
	'virtualFolder':'Ordner',
	'virtualFolderAttachment':'Dateianhang',
	'virtualFolderCreate':'Neuen Ordner erstellen',
	'virtualFolderCreateImport':'Ordner über Excel registrieren',
	'virtualFolderDelete':'Ordner löschen',
	'virtualFolderDetail':'Ordnerinformationen im Detail',
	'virtualFolderIdentifier':'Eindeutiger Schlüssel',
	'virtualFolderLayout':'Ordner',
	'virtualFolderList':'Ordnerliste',
	'virtualFolderParentId':'Eltern',
	'virtualFolderTenantId':'Zentrum',
	'virtualFolderTitle':'Ordner-Titel',
	'virtualFolderTree':'Baum',
	'virtualFolderUpdate':'Ordner aktualisieren',
	'virtualFolderVirtualFolderId':'ID',
}
