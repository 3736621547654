<template>
	<div></div>
</template>
<script>
	export default {

		props: {

			nodes: {
				type: Array,
			}
		},
	}
</script>
