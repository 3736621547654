import { Server } from '@/core/server'
export default class ApiService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}


	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/general/api/list', {'params':params});
	};
}
