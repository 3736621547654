<template>
	<div class="letter-compose-step-5" v-if="payload != null">

		<div class="mb-2 p-3 bg-white">
			<strong class="d-block mb-2">
				{{$loc.fromResource('aut.ext.letter.compose.preview.primaryList')}}
			</strong>
			<label v-for="(item,index) in payload.receivers.primaryList" :key="index" class="d-inline-block pe-2">
				<small>{{item.personName}}&nbsp;</small>
				<small class="text-muted">{{item.branchName}}</small>
				<small v-if="index < (payload.receivers.primaryList.length - 1)">,</small>
			</label>
		</div>

		<div class="mb-4 p-3 bg-white">
			<strong class="mb-5 d-block">{{payload.basicInfo.title}}</strong>
			<div v-html="payload.body"></div>
		</div>

		<div class="mb-2 p-3 bg-white" v-if="payload.receivers.ccList.length > 0">
			<strong class="d-block mb-2">
				{{$loc.fromResource('aut.ext.letter.compose.preview.ccList')}}
			</strong>
			<label v-for="(item,index) in payload.receivers.ccList" :key="index" class="d-inline-block pe-2">
				<small>{{item.personName}}&nbsp;</small>
				<small class="text-muted">{{item.branchName}}</small>
				<small v-if="index < (payload.receivers.ccList.length - 1)">,</small>
			</label>
		</div>

		<div class="mb-2 p-3 bg-white" v-if="payload.receivers.bccList.length > 0">
			<strong class="d-block mb-2">
				{{$loc.fromResource('aut.ext.letter.compose.preview.bccList')}}
			</strong>
			<label v-for="(item,index) in payload.receivers.bccList" :key="index" class="d-inline-block pe-2">
				<small>{{item.personName}}&nbsp;</small>
				<small class="text-muted">{{item.branchName}}</small>
				<small v-if="index < (payload.receivers.bccList.length - 1)">,</small>
			</label>
		</div>

		<div class="mb-5 p-3 bg-white" v-if=" payload.attachments.length > 0">
			<strong class="d-block mb-4">
				{{$loc.fromResource('aut.ext.letter.compose.preview.attachments')}}
			</strong>
			<div class="file" v-for="(item, index) in payload.attachments" :key="index">
				<span dir="ltr">{{ item.name }}</span>&nbsp;
			</div>
		</div>
	</div>
</template>
<script>
	export default {

		data()
		{
			return {
				payload: null
			}
		},

		methods:
		{
			init(payload)
			{
				this.payload = payload
			}
		}
	}
</script>
<style>
	.letter-compose-step-5 {
		width: 100%;
		height: auto;
		margin: 20px auto 0 auto;
		max-width:800px;
	}
</style>
