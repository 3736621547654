<template>
	<popper v-if="data.value" class="popper-light m-0 border-0" arrow>
		<span class="pointer" @click="isOpen = true">
			<kaveh-icon-bootstrap name="bi-search" color="gray" class="ms-1" size="13px" />
		</span>
		<template #content>
			<div v-html="data.value"></div>
		</template>
	</popper>
</template>
<script>
	export default {
		props:
		{
			data: Object
		},

		data()
		{
			return {
				isOpen: false
			}
		},
	}
</script>

