import ENUMS from '@/core/data/enums'
import store from '@/core/store'
export default {

	parseObjectError: function (obj)
	{
		obj = obj || {};
		const keys = Object.keys(obj);

		for (var i = 0; i < keys.length; i++)
		{
			obj[keys[i]].errors =
				this.getPropErrors(obj[keys[i]])
		}
	},

	getObjectError: function (obj)
	{
		var error = {}
		const keys = Object.keys(obj);

		this.parseObjectError(obj);
		for (var i = 0; i < keys.length; i++)
		{
			const key = keys[i];

			if (obj[key].errors.length > 0)
			{
				error[key] = obj[key].errors
			}
		}

		return error
	},

	getObjectErrorLength(obj)
	{
		return Object.keys(this.getObjectError(obj || {})).length
	},

	getPropErrors: function (prop)
	{
		if (prop.setConfig && prop.setConfig.canUseInForm == false)
		{
			return []
		}

		var errors = [];
		var value = prop.value;

		var patterns = [];
		var stringLength = null;
		var collectionMinLength = null;
		var stringIsUnicode = false;
		if (prop.setConfig)
		{
			patterns = prop.setConfig.patterns || [];
			stringLength = prop.setConfig.stringLength || null;
			stringIsUnicode = prop.setConfig.isUnicode;
			collectionMinLength = prop.setConfig.collectionMinLength || 0;
		}
		else
		{
			patterns = prop.patterns || [];
			stringLength = prop.stringLength || null;
			stringIsUnicode = prop.isUnicode;
			collectionMinLength = prop.collectionMinLength || 0;
		}

		if (prop.name && prop.name.toLowerCase().indexOf("date") > -1 && (`${value || ""}`).indexOf("|") > -1)
		{
			return [];
		}

		if (prop.type == ENUMS.PROP_TYPE.BYTE ||
			prop.type == ENUMS.PROP_TYPE.INT16 ||
			prop.type == ENUMS.PROP_TYPE.UINT16 ||
			prop.type == ENUMS.PROP_TYPE.INT32 ||
			prop.type == ENUMS.PROP_TYPE.UINT32 ||
			prop.type == ENUMS.PROP_TYPE.INT64 ||
			prop.type == ENUMS.PROP_TYPE.UINT64 ||
			prop.type == ENUMS.PROP_TYPE.SINGLE)
		{
			if (value && !Number.isInteger(value))
			{
				if (prop.setConfig.isMultiple != true)
				{
					errors.push({ resourceKey: "error.number", model: { resourceKey: prop.resourceKey } });
				}
			}
		}


		if (prop.type == ENUMS.PROP_TYPE.DOUBLE ||
			prop.type == ENUMS.PROP_TYPE.DECIMAL)
		{
			if (value && !Number.isFinite(parseFloat(value)))
				errors.push({ resourceKey: "error.number", model: { resourceKey: prop.resourceKey } });
		}


		if (prop.type == ENUMS.PROP_TYPE.BYTE)
		{
			if (Number(value) > 255)
				errors.push({ resourceKey: "error.byteOutOfRange", model: { resourceKey: prop.resourceKey } });
		}

		if (prop.type == ENUMS.PROP_TYPE.INT16)
		{
			if (Number(value) > 32767)
				errors.push({ resourceKey: "error.int16OutOfRange", model: { resourceKey: prop.resourceKey } });
		}

		if (prop.type == ENUMS.PROP_TYPE.UINT16)
		{
			if (Number(value) > 65535)
				errors.push({ resourceKey: "error.uint16OutOfRange", model: { resourceKey: prop.resourceKey } });
		}

		if (prop.type == ENUMS.PROP_TYPE.INT32)
		{
			if (Number(value) > 2147483647)
				errors.push({ resourceKey: "error.int32OutOfRange", model: { resourceKey: prop.resourceKey } });
		}

		if (prop.type == ENUMS.PROP_TYPE.UINT32)
		{
			if (Number(value) > 4294967295)
				errors.push({ resourceKey: "error.uint32OutOfRange", model: { resourceKey: prop.resourceKey } });
		}

		if (prop.type == ENUMS.PROP_TYPE.INT64)
		{
			if (Number(value) > 9223372036854775807)
				errors.push({ resourceKey: "error.int64OutOfRange", model: { resourceKey: prop.resourceKey } });
		}

		if (prop.type == ENUMS.PROP_TYPE.UINT64)
		{
			if (Number(value) > 18446744073709551615)
				errors.push({ resourceKey: "error.uint64OutOfRange", model: { resourceKey: prop.resourceKey } });
		}

		if (prop.type == 'string' && stringIsUnicode == false)
		{
			if (this.isUnicode(value))
				errors.push({ resourceKey: "error.noUnicodeString", model: { resourceKey: prop.resourceKey } });
		}

		if (stringLength != null)
		{
			if (!this.isValidStringLength(value, stringLength))
				errors.push({ resourceKey: "error.stringLength", model: { resourceKey: prop.resourceKey } });
		}

		if (patterns.findIndex((x) => x == "required") > -1)
		{
			if (this.isEmpty(value, collectionMinLength))
				errors.push({ resourceKey: "error.required", model: { resourceKey: prop.resourceKey } });
		}

		if (patterns.findIndex((x) => x == "postalCode") > -1)
		{
			if (!this.isEmpty(value, collectionMinLength) && value.length != 10)
				errors.push({ resourceKey: "error.invalid", model: { resourceKey: prop.resourceKey } });
		}

		if (patterns.findIndex((x) => x == "nationalCode") > -1)
		{
			if (!this.isEmpty(value, collectionMinLength) && !this.isValidNationalCode(value))
				errors.push({ resourceKey: "error.invalid", model: { resourceKey: prop.resourceKey } });
		}

		if (patterns.findIndex((x) => x == "mobile") > -1)
		{
			if (!this.isEmpty(value, collectionMinLength) && !this.isValidMobile(value))
				errors.push({ resourceKey: "error.invalid", model: { resourceKey: prop.resourceKey } });
		}

		if (patterns.findIndex((x) => x == "date") > -1)
		{
			if (!this.isEmpty(value, collectionMinLength))
			{
				var hasError = false;
				if (store.state.app.language == 'fa')
				{
					hasError = !this.isValidPersianDate(value);
				}
				else
				{

					hasError = !this.isValidDate(value);
				}

				if (hasError)
				{
					errors.push({ resourceKey: "error.invalid", model: { resourceKey: prop.resourceKey } });
				}
			}
		}

		if (patterns.findIndex((x) => x == "time") > -1)
		{
			if (!this.isEmpty(value, collectionMinLength) && !this.isValidTime(value))
				errors.push({ resourceKey: "error.invalid", model: { resourceKey: prop.resourceKey } });
		}

		if (patterns.findIndex((x) => x == "timeDuration") > -1)
		{
			if (!this.isEmpty(value, collectionMinLength) && !this.isValidTimeDuration(value))
				errors.push({ resourceKey: "error.invalid", model: { resourceKey: prop.resourceKey } });
		}


		if (patterns.findIndex((x) => x == "digit") > -1)
		{
			if (!this.isEmpty(value, collectionMinLength) && !this.isValidDigit(value))
				errors.push({ resourceKey: "error.digit", model: { resourceKey: prop.resourceKey } });
		}

		if (patterns.findIndex((x) => x == "number") > -1)
		{
			if (!this.isEmpty(value, collectionMinLength) && !this.isValidNumber(value))
				errors.push({ resourceKey: "error.number", model: { resourceKey: prop.resourceKey } });
		}

		if (patterns.findIndex((x) => x == "password") > -1)
		{
			if (!this.isEmpty(value, collectionMinLength) && !this.isValidPassword(value))
				errors.push({ resourceKey: "error.password", model: { resourceKey: prop.resourceKey } });
		}

		return errors;
	},

	hasValue: function (value)
	{
		return !this.isEmpty(value)
	},

	isEmpty: function (value, collectionMinLength)
	{
		if (Array.isArray(value))
		{
			return (value || []).length == 0;
		}

		return (
			value === undefined ||
			value === null ||
			value === "" ||
			value.toString().trim() === ""
		);
	},

	isValidNationalCode: function (value)
	{
		if (
			!/^\d{10}$/.test(value) ||
			value === "0000000000" ||
			value === "1111111111" ||
			value === "2222222222" ||
			value === "3333333333" ||
			value === "4444444444" ||
			value === "5555555555" ||
			value === "6666666666" ||
			value === "7777777777" ||
			value === "8888888888" ||
			value === "9999999999"
		)
		{
			return false;
		}

		var check = parseInt(value[9], 10);
		var sum = 0;
		var i;
		for (i = 0; i < 9; i++)
		{
			sum += parseInt(value[i], 10) * (10 - i);
		}
		sum %= 11;

		return (sum < 2 && check === sum) || (sum >= 2 && check + sum === 11);
	},

	isValidMobile: function (value)
	{
		return /09\d{9}$/.test(value);
	},

	isValidPersianDate: function (value)
	{
		return /^[1-4]\d{3}\/((0[1-6]\/((3[0-1])|([1-2][0-9])|(0[1-9])))|((1[0-2]|(0[7-9]))\/(30|([1-2][0-9])|(0[1-9]))))$/.test(
			value
		);
	},

	isValidDate: function (value)
	{
		return /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(
			value
		);
	},


	isValidTime: function (value)
	{
		return /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
	},

	isValidTimeDuration: function (value)
	{
		return /^\d{2}:[0-5][0-9]$/.test(value);
	},

	isValidDigit: function (value)
	{
		return /^\d+$/.test(value);
	},

	isValidEmail: function (value)
	{
		return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			value
		);
	},

	isValidNumber: function (value)
	{
		return /^\d+$/.test(value);
	},

	isValidStringLength: function (value, stringLength)
	{
		return (value || "").toString().length <= stringLength;
	},

	isValidPassword(value)
	{
		return (value || "").toString().length >= 8;
	},

	isUnicode: function (value)
	{
		for (var i = 0, n = (value || "").length; i < n; i++)
		{
			if (value.charCodeAt(i) > 255) { return true; }
		}
		return false;
	}

}
