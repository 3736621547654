//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class ActivityService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[Activity.Delete]
	async delete(activityId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:activityId
		}
	
		return await _server.delete('/aut/activity/delete', {'params':params})
	};

	//GeneratorFlag:[Activity.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/aut/activity/detail', {'params':params});
	};

	//GeneratorFlag:[Activity.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/aut/activity/create', params || { });
	};

	//GeneratorFlag:[Activity.CustomerCreate]
	async customerCreate(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/aut/activity/customer-create', params || { });
	};

	//GeneratorFlag:[Activity.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/aut/activity/list', {'params':params});
	};

	//GeneratorFlag:[Activity.Notification]
	async notification(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/aut/activity/notification', {'params':params});
	};

	//GeneratorFlag:[Activity.State]
	async state(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/aut/activity/state', {'params':params});
	};

	//GeneratorFlag:[Activity.FullReport]
	async fullReport(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/aut/activity/full-report', {'params':params});
	};

//GeneratorFlag:[NewAction]
}