//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class KeyValueService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[KeyValue.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/key-value/create', params || { });
	};

	//GeneratorFlag:[KeyValue.Delete]
	async delete(keyValueId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:keyValueId
		}
	
		return await _server.delete('/hub/key-value/delete', {'params':params})
	};

	//GeneratorFlag:[KeyValue.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/hub/key-value/detail', {'params':params});
	};

	//GeneratorFlag:[KeyValue.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/hub/key-value/list', {'params':params});
	};

	//GeneratorFlag:[KeyValue.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/key-value/update', params || { });
	};

	//GeneratorFlag:[KeyValue.FindByKey]
	async findByKey(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/hub/key-value/find-by-key', {'params':params});
	};

//GeneratorFlag:[NewAction]
}