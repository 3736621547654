<template>
	<div class="mb-2 p-2 bg-light row-grid-list" @dblclick="handleCellClick" v-bind:class="{'checked':data.checked}">
		<div class="row" v-if="showRowNumber">
			<div class="col mb-3">
				<div class="form-check form-switch" v-if="selectable">
					<input type="checkbox"
						   class="form-check-input"
						   v-bind:role="'switch'"
						   v-on:change="check"
						   v-model="data.checked"
						   v-bind:checked="data.checked" />
				</div>
			</div>
			<div class="col mb-3 text-end">
				<div class="badge text-dark border bg-light">
					{{ ((currentPage - 1) * pageSize) + (index + 1) }}
				</div>
			</div>
		</div>
		<div class="row p-1 item" @dblclick="handleCellClick" v-bind:class="{'text-bold':data.checked}" v-for="(prop) in getProps(data)" :key="prop.name">
			<span class="col pb-2">
				<strong class="pe-2 pb-1 base">
					{{ $loc.fromResource(prop.resourceKey) }} :
				</strong>
				<table-cell :data="prop" :is-trancate="false" :showError="showError" />
			</span>
		</div>
	</div>
</template>
<style>
	.row-grid-list {
		border: transparent solid 1px;
		border-radius: var(--kaveh-radius);
	}
		.row-grid-list.checked {
			border: #ff6a00 solid 1px;
		}
</style>
<script>

	import tableCell from '../cells/table-cell'
	export default {
		emits: ['checked'],
		components:
		{
			'table-cell': tableCell,
		},

		props:
		{
			'data': Object,
			'entity': Function,
			'currentPage': Number,
			'selectable': Boolean,
			'pageSize': Number,
			'columns': Array,
			'index': Number,

			'showRowNumber': {
				type: Boolean,
				default: true
			},

			'showError': {
				type: Boolean,
				default: false
			},

			'showForeignKey': {
				type: Boolean,
				default: false
			}
		},


		methods:
		{

			getProps(entity)
			{
				var props = []
				entity = entity || new this.entity({})
				for (var key in entity.props)
				{
					const prop = entity.props[key];

					if (prop.foreignKey && this.showForeignKey == false)
						continue;

					if (!prop.display.isVisible || (this.columns || []).indexOf(key) == -1)
						continue;

					props.push(entity.props[key])
				}

				return props
			},

			check()
			{
				this.$emit('checked')
			},

			handleCellClick(e)
			{
				console.log(111)
				console.log(e.target.classList)
				//row-grid-list
				if (e.target.classList == "TD")
				{
					this.data.checked = !this.data.checked
					this.check()
				}
			}

		},
	}
</script>
