<template>
	<letter-list-full v-if="deviceType == 'desktop'" />
	<letter-list-tiny v-else />
</template>

<script>
	import { deviceType } from '@/plugins'
	import LetterListFull from './letter-list/letter-list-full.vue'
	import LetterListTiny from './letter-list/letter-list-tiny.vue'

	export default {

		components: {
			'letter-list-full': LetterListFull,
			'letter-list-tiny': LetterListTiny
		},

		data()
		{
			return {
				deviceType: deviceType()
			}
		},
	}
</script>
