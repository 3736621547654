<template>
	<div class="kaveh-code-editor">
		<div class="action">
			<select v-model="language" class="form-select form-select-sm" @change="onHandleChangeLanguage">
				<option value="json">json</option>
				<option value="html">HTML</option>
				<option value="plain">Text</option>
				<option value="javascript">javascript</option>
			</select>
			<div class="text-danger" v-if="compilerHasError">
				<small>{{compilerError}}</small>
			</div>
		</div>
		<div dir="ltr" ref="CodeEditor"></div>
	</div>
</template>
<script>
	import '@/assets/fonts/consolas/consolas.css'
	import { indentUnit } from "@codemirror/language"
	import { basicSetup, EditorView } from "codemirror"
	import { keymap } from "@codemirror/view"
	import { indentWithTab } from "@codemirror/commands"
	import { EditorState, StateEffect, Compartment } from "@codemirror/state"
	import { javascript } from "@codemirror/lang-javascript"
	import { json } from "@codemirror/lang-json"
	import { html } from "@codemirror/lang-html"
	import { guid } from "@/plugins"

	let codeBlocks = {};
	export default {

		emit: ['change', 'update:modelValue'],
		props: {
			prop: {
				type: Object
			},
			lang: {
				typeof: String,
				default: 'plain'
			}
		},


		data()
		{
			return {
				value: null,
				language: this.lang,
				blockId: guid.newGuid(),
				compilerHasError:false,
				compilerError: ''
			}
		},

		async mounted()
		{
			this.setState()
		},

		methods: {
			onHandleChangeLanguage()
			{
				codeBlocks[this.blockId].dispatch({
					effects: StateEffect.reconfigure.of([])
				})

				this.setState(codeBlocks[this.blockId].state.doc.toString())
			},

			onHandelChange()
			{
				const value =
					codeBlocks[this.blockId].state.doc.toString();

				try
				{
					if (this.language == 'javascript')
					{
						eval(value)
					}

					if (this.language == 'json')
					{
						JSON.parse(value)
					}

					this.compilerHasError = false;
					this.compilerError = '';

				} catch (e)
				{
					this.compilerHasError = true;
					this.compilerError = e.toString();
				}


				if ((value || '') != (this.value || ''))
				{
					this.value = value;
					this.$emit('update:modelValue', value);
					this.$emit('change', value)
				}
			},

			setValue(value)
			{
				if (codeBlocks[this.blockId])
				{
					this.setState(value)
					this.$emit('update:modelValue', value);
				}
			},

			setState(value)
			{
				if (!codeBlocks[this.blockId])
				{
					codeBlocks[this.blockId] = new EditorView({
						parent: this.$refs.CodeEditor,
						tabMode:'indent'
					})
				}

				codeBlocks[this.blockId].setState(EditorState.create({
					doc: value || this.value || '',
					extensions: this.getExtensions()
				}))
			},

			getExtensions()
			{
				var language = new Compartment();

				switch (this.language)
				{
					case 'javascript':

						return [
							basicSetup,
							indentUnit.of("	"),
							language.of(javascript()),
							keymap.of([indentWithTab]),
							EditorView.updateListener.of((e) =>
							{
								this.onHandelChange()
							})
						]
					case 'json':

						return [
							basicSetup,
							indentUnit.of("	"),
							language.of(json()),
							keymap.of([indentWithTab]),
							EditorView.updateListener.of((e) =>
							{
								this.onHandelChange()
							})
						]

					case 'html':

						return [
							basicSetup,
							indentUnit.of("	"),
							language.of(html()),
							keymap.of([indentWithTab]),
							EditorView.updateListener.of((e) =>
							{
								this.onHandelChange()
							})
						]


					default:
						return [
							basicSetup,
							indentUnit.of("	"),
							keymap.of([indentWithTab]),
							EditorView.updateListener.of((e) =>
							{
								this.onHandelChange()
							})
						]
				}
			}


		}
	}
</script>
<style>
	.kaveh-code-editor {
		width: 100%;
		min-height: 100px;
		background: #fff;
		border-radius: 4px;
		border-radius: 4px;
		border: #ddd solid 1px;
	}

		.kaveh-code-editor .action {
			width: 100%;
			padding: 10px;
			direction: ltr;
			display: flex;
			flex-direction: row;
			gap: 10px;
		}

			.kaveh-code-editor .action select {
				max-width: 120px;
			}

		.kaveh-code-editor .cm-scroller {
			font-family: Consolas,IRANSansX;
			font-size: 13px;
		}
</style>
