<template>
	<div class="mb-2 mt-1 row" v-if="$parent.filterEntity">
		<div class="col">
			<div class="bg-gray ps-3 pe-3 pb-3 rounded">
				<div class="row">
					<div class="col">
						<kaveh-form :entity="$parent.filterEntity"
									:use-validation="false"
									:use-multilanguage="false"
									:value="$parent.filterValue"
									:task="$parent.filterChange"
									display-type="block"
									col="col-12 col-md-4 col-lg-3"
									mode="filter"
									submit-resource-key="action.ApplyFilters" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	export default {
		emits: ['flterPropChange'],

		data()
		{
			return {
				search: ''
			}
		},

		computed:
		{
			filterLabel()
			{
				if (this.$parent.filterProp)
				{
					const prop = this.$parent.filterProp;
					const label = prop.label || this.$loc.fromResource(prop.resourceKey);

					return this.$loc.fromResource('com.table.headerSearch', { label });
				}

				return ""
			}
		},

		methods: {

			filterChange(e)
			{
				if (e.key === 'Enter' || e.keyCode === 13)
				{
					this.$emit('flterPropChange')
				}
			},
		}
	}
</script>
<style>
	.page-item > .page-link {
		color: var(--kaveh-dark);
		user-select: none;
		cursor: pointer;
	}

	.page-item.active > .page-link {
		background: var(--kaveh-base-color) !important;
		border-color: transparent;
	}
</style>
