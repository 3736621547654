<template>
	<kaveh-overlay :is-loading="state == 'loading'" :is-empty="state == 'empty'">
		<div class="row">
			<div class="col-12 col-md-3 col-lg-3 mb-3" v-for="(item,i) in processes" :key="i">
				<div @click="handleSelect(item)"
					 class="card pt-2 pb-2 hover-shadow"
					 style="min-height:135px;"
					 v-bind:class="{'bg-base-color': processId == item.processId}">
					<div class="card-body">
						<strong class="card-title d-block">{{ item.title }}</strong>
						<p class="card-text">{{ item.caption }}</p>
					</div>
				</div>
			</div>
		</div>
	</kaveh-overlay>
</template>
<script>
	import { ProcessService } from '@/modules/aut/services'
	export default {
		data() {
			return {
				state: 'loading',
				process: null,
				processId: 0,
				processes: [],
			}
		},

		beforeMount()
		{
			if (this.branchId == 0)
			{
				this.$router.push({ name: 'hub.branch.accessDenied' })
			}
			else
			{
				this.getProcessList();
			}
		},

		computed:
		{
			branchId()
			{
				return this.$store.state.client.branchId
			},
		},

		methods:
		{
			async getProcessList() {
				var items = [];

				try {
					const res = await new ProcessService().accessList({
						branchId: this.branchId,
					});

					if (res.status == 200) {
						items = res.data.items || []
						this.state = items.length > 0 ? "ready" : "empty";
					}

				} catch (e) {
					console.log(e)
					this.state = "error";
				}

				this.processes = items;
			},

			handleSelect(item) {
				this.process = item;
				this.processId = item.processId
			},

			getValue() {
				return this.process
			}
		}
	}
</script>
