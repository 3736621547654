//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class ProcessGroupService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[ProcessGroup.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/aut/process-group/create', params || { });
	};

	//GeneratorFlag:[ProcessGroup.Delete]
	async delete(processGroupId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:processGroupId
		}
	
		return await _server.delete('/aut/process-group/delete', {'params':params})
	};

	//GeneratorFlag:[ProcessGroup.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/aut/process-group/detail', {'params':params});
	};

	//GeneratorFlag:[ProcessGroup.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/aut/process-group/list', {'params':params});
	};

	//GeneratorFlag:[ProcessGroup.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/aut/process-group/update', params || { });
	};

//GeneratorFlag:[NewAction]
}