import store from '@/core/store'
import { toast } from '@/plugins'
import { Ws } from '@/core/server'
import { ChatService } from '@/modules/messenger/services';

const chatNotification = {

	info: {
		name: 'kaveh.messenger.chat.notification',
		routeName: 'messenger',
		iconName: 'bi-chat-dots',
		hasCounter: true
	},

	initHandler: async () =>
	{
		new Ws().getConnection('hub').then((connection) =>
		{
			const updater = async () =>
			{
				try
				{
					const name = chatNotification.info.name;
					const count = localStorage.getItem(name) || 0
					const service = new ChatService();
					const res = await service.notification({}, { HSR: false, HER: false });

					if (res.status == 200)
					{
						await store.dispatch('app/setAlarm', {
							name: name,
							counter: res.data.total,
						});

						if (res.data.total > count)
						{
							toast.notification({
								destination: "/messenger",
								msg: 'New Message'
							})
						}

						localStorage.setItem(name, res.data.total)
					}

				} catch (e)
				{
					console.log(e)
				}
			}


			connection.handle(`MessengerChatNotification`, data =>
			{
				console.log(1111)
				updater();
			});

			updater();

		})
	}
}


export default chatNotification

