//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
export default class VirtualFolderFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'virtualFolderFilter';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'global.filters';
	props = {
		virtualFolderId: {
			primarykey: true,
			name: 'virtualFolderId',
			resourceKey: 'asset.virtualFolderVirtualFolderId',
			type: ENUMS.PROP_TYPE.INT32,
			isSearchable: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		title: {
			name: 'title',
			resourceKey: 'asset.virtualFolderTitle',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: true,
				patterns: [],
			},
			errors: [],
			value: null
		},
		tree: {
			name: 'tree',
			resourceKey: 'asset.virtualFolderTree',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		tenantId: {
			name: 'tenantId',
			resourceKey: 'asset.virtualFolderTenantId',
			type: ENUMS.PROP_TYPE.INT16,
			isSearchable: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		translations: {
			name: 'translations',
			resourceKey: 'asset.virtualFolderTranslations',
			type: ENUMS.PROP_TYPE.STRING,
			isSearchable: true,
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}