//GeneratorFlag:[NoChange]
export default {
	path: 'process',
	name: 'aut.process.layout',
	redirect: to => {
		return {name: 'aut.process.list'};
	},
	component: () => import('@/modules/aut/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/aut/process"],
		resourceKey: 'aut.processLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-signpost-split-fill',
		}
	},
	children: [
//GeneratorFlag:[ProcessCreate]"
		{
			path: 'create',
			name: 'aut.process.create',
			component: () => import('@/modules/aut/views/process/process-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.processCreate',
				clientAccessKeys:["/aut/process/create"],
				serverAccessKeys:["/aut/process/create","/aut/process/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProcessCreateImport]"
		{
			path: 'create-import',
			name: 'aut.process.createImport',
			component: () => import('@/modules/aut/views/process/process-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.processCreateImport',
				clientAccessKeys:["/aut/process/create-import"],
				serverAccessKeys:["/aut/process/create","/aut/process/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProcessDelete]"
		{
			path: 'delete',
			name: 'aut.process.delete',
			component: () => import('@/modules/aut/views/process/process-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.processDelete',
				clientAccessKeys:["/aut/process/delete"],
				serverAccessKeys:["/aut/process/delete","/aut/process/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProcessDetail]"
		{
			path: 'detail',
			name: 'aut.process.detail',
			component: () => import('@/modules/aut/views/process/process-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.processDetail',
				clientAccessKeys:["/aut/process/detail"],
				serverAccessKeys:["/aut/process/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProcessList]"
		{
			path: 'list',
			name: 'aut.process.list',
			component: () => import('@/modules/aut/views/process/process-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.processList',
				clientAccessKeys:["/aut/process/list"],
				serverAccessKeys:["/aut/process/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProcessAttachment]"
		{
			path: 'attachment',
			name: 'aut.process.attachment',
			component: () => import('@/modules/aut/views/process/process-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.processAttachment',
				clientAccessKeys:["/aut/process/attachment"],
				serverAccessKeys:["/aut/process/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProcessUpdate]"
		{
			path: 'update',
			name: 'aut.process.update',
			component: () => import('@/modules/aut/views/process/process-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'aut.processUpdate',
				clientAccessKeys:["/aut/process/update"],
				serverAccessKeys:["/aut/process/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}