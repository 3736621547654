<template>
	<date-picker v-if="language == 'fa'"
				 :auto-submit="true"
				 :range="useRange"
				 :simple="!useRange"
				 v-bind:id="prop.id"
				 v-model="value"
				 v-on:change="onHandelChange"
				 v-on:keyup="onHandelKeyUp" />

	<input v-else
		   v-bind:id="prop.id"
		   v-bind:dir="prop.display.direction"
		   v-on:change="onHandelChange"
		   v-on:keyup="onHandelKeyUp"
		   v-model="value"
		   value="2017-06-01"
		   type="date"
		   class="form-control"
		   autocomplete="off" />
</template>
<script>
	export default {
		emits: ['change', 'keyup', 'submit'],

		props: {
			prop: {
				type: Object
			}
		},

		data()
		{
			return {
				value: null
			}
		},

		computed:
		{
			language()
			{
				return this.$store.state.app.language;
			},

			useRange()
			{
				return this.$parent.mode == "filter"
			}
		},

		beforeMount()
		{
			this.value = this.prop.value
		},

		methods: {

			getValue()
			{
				var value = this.value
				if (Array.isArray(value))
				{
					value = `${value[0]}|${value[1]}`
				}

				return value
			},

			onHandelChange()
			{
				this.$emit('change', this.getValue())
			},

			onHandelKeyUp(e)
			{
				if (e.key === 'Enter' || e.keyCode === 13)
				{
					this.$emit('submit')
				}
				else
				{
					this.$emit('keyup', this.getValue())
				}
			}
		}
	}
</script>
