//GeneratorFlag:[NoChange]
import ChatService from './chat-service'; //GeneratorFlag:[Chat]
import RoomService from './room-service'; //GeneratorFlag:[Room]
//GeneratorFlag:[NewImport]
export default {
ChatService, //GeneratorFlag:[Chat]
RoomService, //GeneratorFlag:[Room]
//GeneratorFlag:[NewExport]
}
export{
ChatService, //GeneratorFlag:[Chat]
RoomService, //GeneratorFlag:[Room]
//GeneratorFlag:[NewExport]
}