export default {

	isUnicode: (value) =>
	{
		for (var i = 0, n = (value || "").length; i < n; i++)
		{
			if (value.charCodeAt(i) > 255) { return true; }
		}

		return false;
	}
}
