<template>
	<div class="letter-reply-step1">
		<letter-detail ref="ReaderRef" :show-actions="false" :show-attachments="false" />
	</div>
</template>
<script>
	import LetterDetail from '../letter-detail.vue'
	export default {

		components: {
			'letter-detail': LetterDetail
		},

		methods:
		{
			getData()
			{
				return this.$refs.ReaderRef.getData()
			}
		}
	}
</script>
<style>
	.letter-reply-step-1 {
		width: 100%;
		height: auto;
		margin: 20px auto 0 auto;
		max-width: 800px;
	}
</style>
