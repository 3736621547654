// initial state
const state = () => ({
	socketIsConnect: false,
	socketError: null,
	servers: [],
	socket: {}
})

// getters
const getters = {
}

// actions
const actions = {
	setServers({ commit }, servers)
	{
		commit('setServers', servers);
	},

	setSocket({ commit }, arg)
	{
		commit('setSocket', arg);
	},

	clearSocket({ commit })
	{
		commit('clearSocket');
	}
}

// mutations
const mutations =
{
	setServers(state, servers)
	{
		state.servers = servers;
	},

	setSocket(state, arg)
	{
		state.socket[arg.serverName] = arg.connection;
	},

	clearSocket(state)
	{
		const keys =
			Object.keys(state.socket);

		for (var i = 0; i < keys.length; i++)
		{
			state.socket[keys[i]].stop()
		}

		state.socket = {};
		window.$keys.ws = {}
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
