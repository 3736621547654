<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" />
</template>
<script>
	import { Product, ProductFilter } from '@/modules/store/data/product/list'
	import { ProductService } from '@/modules/store/services'
	export default {
		computed:
		{
			entity()
			{
				return Product;
			},
			
			filterEntity()
			{
				return ProductFilter;
			},

			service()
			{
				return new ProductService();
			},

			actions()
			{
				return [
					{
						routeName: 'store.product.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'store.product.createImport',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						routeName: 'store.product.attachment',
						resourceKey: 'action.fileAttachment',
						iconName: 'bi-cloud-arrow-up',
						activeMode: 'single',
						dataTransferMode: 'store',
					},
					{
						routeName: 'store.product.imageAttachment',
						resourceKey: 'action.imageAttachment',
						iconName: 'bi-images',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						resourceKey: 'action.update',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [
							{
								routeName: 'store.product.productSeoUpdate',
								resourceKey: 'store.productSeoUpdate',
								iconName: 'bi-pen',
								activeMode: 'single',
								dataTransferMode: 'store',
							},
							{
								routeName: 'store.product.update',
								resourceKey: 'store.productUpdate',
								iconName: 'bi-pen',
								activeMode: 'single',
								dataTransferMode: 'store',
							}
						],
					},

					{
						routeName: 'store.product.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [
							{
								routeName: 'store.pricing.list',
								resourceKey: 'store.pricingList',
								iconName: 'bi-list-columns',
								activeMode: 'single',
								dataTransferMode: 'query',
								dataTransferKeys: ['productId'],
							},
							{
								routeName: 'store.product.productSeoDetail',
								resourceKey: 'store.productSeoDetail',
								iconName: 'bi-list-columns',
								activeMode: 'single',
								dataTransferMode: 'store',
							},

						]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>