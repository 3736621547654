//GeneratorFlag:[NoChange]
import DiscountService from './discount-service'; //GeneratorFlag:[Discount]
import FeatureService from './feature-service'; //GeneratorFlag:[Feature]
import PricingService from './pricing-service'; //GeneratorFlag:[Pricing]
import ProductService from './product-service'; //GeneratorFlag:[Product]
import DeliveryDateTypeService from './delivery-date-type-service'; //GeneratorFlag:[DeliveryDateType]
import FeatureGroupService from './feature-group-service'; //GeneratorFlag:[FeatureGroup]
import ProducerService from './producer-service'; //GeneratorFlag:[Producer]
import ProductGroupService from './product-group-service'; //GeneratorFlag:[ProductGroup]
import WarehouseService from './warehouse-service'; //GeneratorFlag:[Warehouse]
import ProductSeoService from './product-seo-service'; //GeneratorFlag:[ProductSeo]
import FeatureInputTypeService from './feature-input-type-service'; //GeneratorFlag:[FeatureInputType]
import InvoiceStatusService from './invoice-status-service'; //GeneratorFlag:[InvoiceStatus]
//GeneratorFlag:[NewImport]
export default {
DiscountService, //GeneratorFlag:[Discount]
FeatureService, //GeneratorFlag:[Feature]
PricingService, //GeneratorFlag:[Pricing]
ProductService, //GeneratorFlag:[Product]
DeliveryDateTypeService, //GeneratorFlag:[DeliveryDateType]
FeatureGroupService, //GeneratorFlag:[FeatureGroup]
ProducerService, //GeneratorFlag:[Producer]
ProductGroupService, //GeneratorFlag:[ProductGroup]
WarehouseService, //GeneratorFlag:[Warehouse]
ProductSeoService, //GeneratorFlag:[ProductSeo]
FeatureInputTypeService, //GeneratorFlag:[FeatureInputType]
InvoiceStatusService, //GeneratorFlag:[InvoiceStatus]
//GeneratorFlag:[NewExport]
}
export{
DiscountService, //GeneratorFlag:[Discount]
FeatureService, //GeneratorFlag:[Feature]
PricingService, //GeneratorFlag:[Pricing]
ProductService, //GeneratorFlag:[Product]
DeliveryDateTypeService, //GeneratorFlag:[DeliveryDateType]
FeatureGroupService, //GeneratorFlag:[FeatureGroup]
ProducerService, //GeneratorFlag:[Producer]
ProductGroupService, //GeneratorFlag:[ProductGroup]
WarehouseService, //GeneratorFlag:[Warehouse]
ProductSeoService, //GeneratorFlag:[ProductSeo]
FeatureInputTypeService, //GeneratorFlag:[FeatureInputType]
InvoiceStatusService, //GeneratorFlag:[InvoiceStatus]
//GeneratorFlag:[NewExport]
}