<template>
	<div class="row activity-list">
		<div class="col-3">
			<div class="bg-light" style="border-radius: var(--kaveh-radius);">
				<ul class="page-items">
					<li>
						<div class="mb-4">
							<router-link :to="{'name':'aut.activity.create'}" class="btn rounded-pill text-light border-0 btn-base-color">
								<kaveh-icon-bootstrap name="bi-plus" size="18px" />
								<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.activity.views.compose')}}</span>
							</router-link>
						</div>
					</li>
					<li @click="handleChangeFilter('pageName','currents')">
						<div class="page" v-bind:class="{'text-bold': pageName == 'currents'  }">
							<kaveh-icon-bootstrap name="bi-lightning-charge" size="18px" />
							<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.activity.views.currents')}}</span>
						</div>
					</li>
					<li @click="handleChangeFilter('pageName','my-activities')">
						<div class="page" v-bind:class="{'text-bold': pageName == 'my-activities' }">
							<kaveh-icon-bootstrap name="bi-person" size="18px" />
							<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.activity.views.myActivities')}}</span>
						</div>
					</li>
					<li @click="handleChangeFilter('pageName','completed')">
						<div class="page" v-bind:class="{'text-bold': pageName == 'completed' }">
							<kaveh-icon-bootstrap name="bi-check2-all" size="18px" />
							<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.activity.views.completed')}}</span>
						</div>
					</li>
					<li @click="handleChangeFilter('pageName','stoped')">
						<div class="page" v-bind:class="{'text-bold': pageName == 'stoped' }">
							<kaveh-icon-bootstrap name="bi-stop-circle" size="18px" />
							<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.activity.views.stoped')}}</span>
						</div>
					</li>
				</ul>
			</div>
			<kaveh-overlay class="bg-light" style="border-radius: var(--kaveh-radius);" :is-loading="prioritiesState == 'loading'" :is-empty="priorities.length == 0">
				<ul class="priority-items">
					<li @click="handleChangeFilter('activityPriorityId',null)">
						<div class="priority" v-bind:class="{'text-bold': activityPriorityId == null }">
							<span class="color" style="background:#f8f8f8;"></span>
							<span class="ms-2 me-2">{{  $loc.fromResource('aut.ext.activity.priority.allPriority')}}</span>
						</div>
					</li>
					<li v-for="(priority,index) in priorities" :key="index" @click="handleChangeFilter('activityPriorityId',priority.activityPriorityId)">
						<div class="priority" v-bind:class="{'text-bold': activityPriorityId == priority.activityPriorityId }">
							<span class="color" :style="{'background':`${priority.hexColor}`}"></span>
							<span class="ms-2 me-2">{{priority.title}}</span>
						</div>
					</li>
				</ul>
			</kaveh-overlay>
		</div>
		<div class="col-9">
			<ul class="nav nav-tabs">
				<li class="nav-item cursor-pointer">
					<div class="nav-link text-dark bg-light">
						<kaveh-icon-bootstrap name="bi-file-earmark-text" size="18px" />
						<span class="ms-2 me-2">{{$loc.fromResource('aut.ext.enums.activityCategory.public')}}</span>
					</div>
				</li>
			</ul>
			<kaveh-overlay :is-loading="activitiesState == 'loading'" :is-empty="activitiesState != 'loading' && activities.length == 0" class="bg-light pt-4 pb-4 ps-2 pe-2">
				<template v-slot:noContent>
					{{$loc.fromResource('aut.ext.activity.list.empty')}}
				</template>
				<div v-for="(item, index) in activities" class="activity-item" :key="index">
					<div class="row">
						<div class="col-12 mb-3">
							<table class="table table-sm table-borderless table-backgroundless">
								<tbody>
									<tr>
										<td style="width: 150px;">شناسه فعالیت</td>
										<td>{{item.activityId}}</td>
									</tr>
									<tr>
										<td>وضعیت</td>
										<td>
											<small :style="{'color': item.activityStatusHexColor}">
												{{ item.activityStatusTitle }}
											</small>
										</td>
									</tr>
									<tr>
										<td>عنوان فرآیند</td>
										<td>{{ item.processTitle }}</td>
									</tr>
									<tr>
										<td>آغازگر</td>
										<td>
											<span>{{item.nodes[0].activistName}} <small class="text-muted"><i>[ {{ item.nodes[0].activistRoleName }} ]</i></small></span>
										</td>
									</tr>
									<tr>
										<td>اولویت</td>
										<td>
											{{ item.activityPriorityTitle }}
										</td>
									</tr>
									<tr>
										<td>تاریخ ثبت</td>
										<td>
											<small dir="ltr">{{ item.localCreateDate }} - {{item.createTime}}</small>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col-12 mb-3">
							<activity-slider :node="item" />
						</div>
						<div class="col-12 mt-3">
							<button class="btn btn-gray w-100" @click="showDetail(item)">
								{{$loc.fromResource('aut.ext.activity.list.showDetail')}}
							</button>
						</div>
					</div>
				</div>
			</kaveh-overlay>
		</div>
	</div>
</template>

<script>
	import { url } from '@/plugins'
	import { ActivityPriorityService, ActivityService } from '@/modules/aut/services'
	import ActivitySlider from '../slider/activity-slider'

	export default {

		components: {
			'activity-slider': ActivitySlider
		},

		data()
		{
			return {
				priorities: [],
				activities: [],
				prioritiesState: "loading",
				activitiesState: "loading",
				activityPage: "currents",
				activityIcons: ['', 'bi-envelope', 'bi-reply', 'bi-forward'],
			}
		},


		async beforeMount()
		{
			if (this.branchId == 0)
			{
				this.$router.push({ name: 'hub.branch.accessDenied' })
			}
			else
			{
				await this.initActivityPriorities();
				await this.initActivities();
			}
		},

		computed:
		{
			branchId()
			{
				return this.$store.state.client.branchId
			},

			pageName()
			{
				return this.$route.query.pageName || "currents";
			},

			activityPriorityId()
			{
				return this.$route.query.activityPriorityId || null;
			},
		},

		methods:
		{
			showDetail(item)
			{
				this.$router.push({
					name: 'aut.activity.state', query: {
						'key': url.encript(item.activityId)
					}
				});
			},

			async handleChangeFilter(key, value)
			{
				var query =
					Object.assign({},
						this.$route.query
					);

				query[key] = value
				await this.$router.replace({ query });

				await this.initActivities();
			},

			async initActivityPriorities()
			{
				var items = [];

				try
				{
					const res = await new ActivityPriorityService().list({
						start: 0,
						length: 1000
					});

					if (res.status == 200)
					{
						items = this.$loc.fromList(res.data.items || []);
					}

				} catch (e) { console.log(e); }

				this.priorities = items;
				this.prioritiesState = items.length > 0 ? "ready" : "empty"
			},

			async initActivities()
			{
				var items = [];

				try
				{
					this.activitiesState = "loading"
					const res = await new ActivityService().list({
						start: 0,
						length: 1000,
						filter: {
							activityPriorityId: this.activityPriorityId,
							pageName: this.pageName
						}
					}, { HSR: false });

					if (res.status == 200)
					{
						items = this.$loc.fromList(res.data.items || []);
					}

				} catch (e) { console.log(e); }

				this.activities = items;
				this.activitiesState = items.length > 0 ? "ready" : "empty"
			}
		}
	}

</script>
<style>

	.activity-list .page-items,
	.activity-list .priority-items {
		list-style: none;
		padding: 20px 10px;
	}

	.activity-list .page,
	.activity-list .priority {
		margin: 10px 0;
		cursor: pointer;
		transition: all .2s;
	}

		.activity-list .page:hover,
		.activity-list .priority:hover {
			padding-right: 5px;
		}

			.activity-list .page:hover > i,
			.activity-list .priority:hover > i {
				color: var(--kaveh-base-color) !important;
			}

		.activity-list .priority .color {
			width: 15px;
			height: 15px;
			border-radius: 100%;
			display: inline-block;
			border: #ddd solid 1px;
		}


	.activity-list .activity-item {
		width: 100%;
		height: auto;
		margin-bottom: 15px;
		padding: 20px;
		border-radius:10px;
		background:#fff;
	}
</style>
