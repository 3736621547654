//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
export default class Node extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'node';
	schema = ENUMS.SCHEMA_TYPE.NONE;
	resourceKey = 'aut.activityFullReport.node.self';
	props = {
		activityNodeId: {
			primarykey: true,
			name: 'activityNodeId',
			resourceKey: 'aut.activityFullReport.nodes.activityNodeId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		formValue: {
			name: 'formValue',
			resourceKey: 'aut.activityFullReport.nodes.formValue',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		comment: {
			name: 'comment',
			resourceKey: 'aut.activityFullReport.nodes.comment',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		branchId: {
			name: 'branchId',
			resourceKey: 'aut.activityFullReport.nodes.branchId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		workerId: {
			foreignKey: true,
			name: 'workerId',
			resourceKey: 'aut.activityFullReport.nodes.workerId',
			type: ENUMS.PROP_TYPE.INT32,
			serverAddress: '/aut/worker/list',
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'title',
				valueKey: 'worker',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		activistName: {
			name: 'activistName',
			resourceKey: 'aut.activityFullReport.nodes.activistName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		activistRoleName: {
			name: 'activistRoleName',
			resourceKey: 'aut.activityFullReport.nodes.activistRoleName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		activityActionId: {
			foreignKey: true,
			name: 'activityActionId',
			resourceKey: 'aut.activityFullReport.nodes.activityActionId',
			type: ENUMS.PROP_TYPE.BYTE,
			serverAddress: '/aut/activity-action/list',
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'title',
				valueKey: 'activityAction',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		activityActionTitle: {
			name: 'activityActionTitle',
			resourceKey: 'aut.activityFullReport.nodes.activityActionTitle',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		processNodeId: {
			foreignKey: true,
			name: 'processNodeId',
			resourceKey: 'aut.activityFullReport.nodes.processNodeId',
			type: ENUMS.PROP_TYPE.INT32,
			serverAddress: '/aut/process-node/list',
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'title',
				valueKey: 'processNode',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		processNodeTitle: {
			name: 'processNodeTitle',
			resourceKey: 'aut.activityFullReport.nodes.processNodeTitle',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 50,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		processNodeCaption: {
			name: 'processNodeCaption',
			resourceKey: 'aut.activityFullReport.nodes.processNodeCaption',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 100,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		orderNumber: {
			name: 'orderNumber',
			resourceKey: 'aut.activityFullReport.nodes.orderNumber',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['number','required'],
			},
			errors: [],
			value: null
		},
		hasAccessToReturn: {
			name: 'hasAccessToReturn',
			resourceKey: 'aut.activityFullReport.nodes.hasAccessToReturn',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		hasAccessToContinue: {
			name: 'hasAccessToContinue',
			resourceKey: 'aut.activityFullReport.nodes.hasAccessToContinue',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		hasAccessToComplete: {
			name: 'hasAccessToComplete',
			resourceKey: 'aut.activityFullReport.nodes.hasAccessToComplete',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		hasAccessToStop: {
			name: 'hasAccessToStop',
			resourceKey: 'aut.activityFullReport.nodes.hasAccessToStop',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		returnTitle: {
			name: 'returnTitle',
			resourceKey: 'aut.activityFullReport.nodes.returnTitle',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		continueTitle: {
			name: 'continueTitle',
			resourceKey: 'aut.activityFullReport.nodes.continueTitle',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		stopTitle: {
			name: 'stopTitle',
			resourceKey: 'aut.activityFullReport.nodes.stopTitle',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		completeTitle: {
			name: 'completeTitle',
			resourceKey: 'aut.activityFullReport.nodes.completeTitle',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		isArrayFormValue: {
			name: 'isArrayFormValue',
			resourceKey: 'aut.activityFullReport.nodes.isArrayFormValue',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		messagePatternName: {
			name: 'messagePatternName',
			resourceKey: 'aut.activityFullReport.nodes.messagePatternName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 20,
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		returnNodes: {
			name: 'returnNodes',
			resourceKey: 'aut.activityFullReport.nodes.returnNodes',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 200,
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		formPattern: {
			name: 'formPattern',
			resourceKey: 'aut.activityFullReport.nodes.formPattern',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		printPattern: {
			name: 'printPattern',
			resourceKey: 'aut.activityFullReport.nodes.printPattern',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		captionPattern: {
			name: 'captionPattern',
			resourceKey: 'aut.activityFullReport.nodes.captionPattern',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		localCreateDate: {
			name: 'localCreateDate',
			resourceKey: 'aut.activityFullReport.nodes.localCreateDate',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		createTime: {
			name: 'createTime',
			resourceKey: 'aut.activityFullReport.nodes.createTime',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TIME_PICKER,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}