//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class RegionService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[Region.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/region/create', params || { });
	};

	//GeneratorFlag:[Region.Delete]
	async delete(regionId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:regionId
		}
	
		return await _server.delete('/hub/region/delete', {'params':params})
	};

	//GeneratorFlag:[Region.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/hub/region/detail', {'params':params});
	};

	//GeneratorFlag:[Region.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/hub/region/list', {'params':params});
	};

	//GeneratorFlag:[Region.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/region/update', params || { });
	};

//GeneratorFlag:[NewAction]
}